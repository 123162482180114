import React, { lazy } from 'react'

const Home = lazy(() =>
  import('./Home').then(module => ({
    default: module.Home,
  }))
)

const HomeRoutes = [
  {
    props: { exact: true, path: '/' },
    component: <Home />,
  },
  {
    props: { exact: true, path: '/home' },
    component: <Home />,
  },
]

export default HomeRoutes
