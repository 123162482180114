import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AccCurrentMonthPreviousPayrollCycleOutput = {
  __typename?: 'AccCurrentMonthPreviousPayrollCycleOutput';
  EmployeeID: Scalars['String'];
  TotalExemptNormalRemunerationAmount: Scalars['Float'];
  TotalExemptAdditionalRemunerationAmount: Scalars['Float'];
  TotalChargableTax1: Scalars['Float'];
  TotalChargableTax2: Scalars['Float'];
  TotalChargableEPF: Scalars['Float'];
  TotalChargableAdditionalRemunerationEPF: Scalars['Float'];
  TotalChargableNormalRemunerationEPF: Scalars['Float'];
  EPFPaidByNormalRemunerationPaid: Scalars['Float'];
  EPFPaidByAdditionalRemuneration: Scalars['Float'];
  TotalChargableEPFVE: Scalars['Float'];
  TotalChargableSocso: Scalars['Float'];
  TotalChargableEIS: Scalars['Float'];
  TotalChargableHRDF: Scalars['Float'];
  EmployeeEPF: Scalars['Float'];
  EmployerEPF: Scalars['Float'];
  EmployeeEPFVE: Scalars['Float'];
  EmployerEPFVE: Scalars['Float'];
  TotalChargableTax: Scalars['Float'];
  TotalChargableAddRemuneration: Scalars['Float'];
  Tax: Scalars['Float'];
  Zakat: Scalars['Float'];
  EmployeeEIS: Scalars['Float'];
  EmployerEIS: Scalars['Float'];
  EmployeeSocso: Scalars['Float'];
  EmployerSocso: Scalars['Float'];
};

export type AccumulatedPreviousMonthOutput = {
  __typename?: 'AccumulatedPreviousMonthOutput';
  AccEPFPaid: Scalars['Float'];
  AccEPFVEPaid: Scalars['Float'];
  AccNormalRemunerationEPFPaid: Scalars['Float'];
  AccAdditionalRemunerationEPFPaid: Scalars['Float'];
  AccPCBPaid: Scalars['Float'];
  AccZakatPaid: Scalars['Float'];
  AccGrossTaxablePaid: Scalars['Float'];
  AccSocsoPaid: Scalars['Float'];
  AccTaxPaid: Scalars['Float'];
  AccTaxRelief: Scalars['Float'];
};

export enum AdditionalOtPayType {
  PayMaximumHours = 'PAY_MAXIMUM_HOURS',
  PayItem = 'PAY_ITEM',
  ReplacementLeave = 'REPLACEMENT_LEAVE'
}

/** JsonType */
export type Address = {
  __typename?: 'Address';
  siteName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  AddressType?: Maybe<AddressType>;
};

export type Address2 = {
  __typename?: 'Address2';
  Line1?: Maybe<Scalars['String']>;
  Line2?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  PostalCode?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  Country?: Maybe<Country>;
};

export type AddressInput = {
  siteName?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  AddressType?: Maybe<AddressType>;
};

export type AddressInput2 = {
  Line1?: Maybe<Scalars['String']>;
  Line2?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  PostalCode?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  Country?: Maybe<Country>;
};

export enum AddressType {
  Primary = 'PRIMARY',
  Delivery = 'DELIVERY',
  Site = 'SITE'
}

export type AllowanceBenefitInput = {
  CompanyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type AllowanceBenfitEntity = InterfaceBase & {
  __typename?: 'AllowanceBenfitEntity';
  Status: Status;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AllowanceBenefitID: Scalars['String'];
  CompanyID: Scalars['String'];
  Name: Scalars['String'];
  Company: CompanyEntity;
};

export type AllowanceDeductionInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  RoundingCriteria?: Maybe<Array<Scalars['JSON']>>;
  Name?: Maybe<Scalars['String']>;
};

export type AllowanceDeductionRoundingEntity = InterfaceBase & {
  __typename?: 'AllowanceDeductionRoundingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AllowanceDeductionID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  RoundingCriteria?: Maybe<Array<Scalars['JSON']>>;
  Company?: Maybe<CompanyEntity>;
  CompanyID?: Maybe<Scalars['String']>;
  DailyAllowance?: Maybe<Array<DailyAllowancePolicyEntity>>;
  MonthlyAllowance?: Maybe<Array<MonthlyAllowancePolicyEntity>>;
};

export enum AllowanceFrequency {
  Monthly = 'Monthly',
  Yearly = 'Yearly',
  Lifetime = 'Lifetime'
}

export type AllowanceItemEntity = InterfaceBase & {
  __typename?: 'AllowanceItemEntity';
  Status: Status;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AllowanceItemID: Scalars['String'];
  AllowanceBenefitID: Scalars['String'];
  Name: Scalars['String'];
  PayItemID: Scalars['String'];
  AllowanceFrequency: AllowanceFrequency;
  TotalAmount: Scalars['Float'];
  AllowanceBenefit: AllowanceBenfitEntity;
};

export type AllowanceItemInput = {
  AllowanceBenefitID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  AllowanceFrequency?: Maybe<AllowanceFrequency>;
  TotalAmount?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ApprovalClaimVerificationInput = {
  CompanyID?: Maybe<Scalars['String']>;
  DateFrom?: Maybe<Scalars['DateTime']>;
  DateTo?: Maybe<Scalars['DateTime']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
  ApprovalStatus?: Maybe<Array<Scalars['String']>>;
  ClaimTypeIDs?: Maybe<Array<Scalars['String']>>;
};

export enum ApprovalStatus {
  Draft = 'Draft',
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Cancelled = 'Cancelled'
}

export type ApproveClaimRecordsInput = {
  VerifyStatus?: Maybe<ApprovalStatus>;
  ApproverID?: Maybe<Scalars['String']>;
  SubscriptionUserID?: Maybe<Scalars['String']>;
  ClaimHeaderIDs?: Maybe<Array<Scalars['String']>>;
};

export type ApproveClaimVerificationInput = {
  CompanyID?: Maybe<Scalars['String']>;
};

export type ApproveClaimVerificationOutput = {
  __typename?: 'ApproveClaimVerificationOutput';
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ApprovelStatus?: Maybe<Scalars['String']>;
  EmpName?: Maybe<Scalars['String']>;
  EmpID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['String']>;
  TotalAmtClaim?: Maybe<Scalars['Float']>;
  TotalClaim?: Maybe<Scalars['String']>;
  ClaimRemark?: Maybe<Scalars['String']>;
  ApprovelBy?: Maybe<Scalars['String']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  HeaderTotalAmt?: Maybe<Scalars['String']>;
  HeaderTotalClaim?: Maybe<Scalars['String']>;
  FinnanceStatus?: Maybe<Scalars['String']>;
  CheckComplete?: Maybe<Scalars['String']>;
  ApprovedDate?: Maybe<Scalars['String']>;
};

export type ApprovedClaimDetail = {
  __typename?: 'ApprovedClaimDetail';
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalRemark?: Maybe<Scalars['String']>;
  ApprovalByID?: Maybe<Scalars['String']>;
  ApprovalByName?: Maybe<Scalars['String']>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
};

export type ApprovedClaimVerificationDetailsOutput = {
  __typename?: 'ApprovedClaimVerificationDetailsOutput';
  ClaimHeaderID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  JobAccountID?: Maybe<Scalars['String']>;
  JobAccountName?: Maybe<Scalars['String']>;
  HeaderStatus?: Maybe<Scalars['String']>;
  CountClaimDetails?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  ApprovedClaimDetail?: Maybe<ApprovedClaimDetail>;
  MyClaimDetails?: Maybe<Array<MyClaimDetailOutput>>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  CountVerifiedClaim?: Maybe<Scalars['Float']>;
};

export type AttachmentInput = {
  Index?: Maybe<Scalars['Float']>;
  Filename?: Maybe<Scalars['String']>;
  Format?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Scalars['String']>;
};

export type AttachmentOutput = {
  __typename?: 'AttachmentOutput';
  Index?: Maybe<Scalars['Float']>;
  Info?: Maybe<Scalars['String']>;
};

export type AttendanceCalculationEntity = InterfaceBase & {
  __typename?: 'AttendanceCalculationEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AttendanceCalculationID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ShiftDayID?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  Date: Scalars['DateTime'];
  Value?: Maybe<Scalars['Float']>;
  Type?: Maybe<Scalars['String']>;
  AdjustedValue?: Maybe<Scalars['Float']>;
  MonthlyAdjustedValue?: Maybe<Scalars['Float']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  PostStatus?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Company?: Maybe<CompanyEntity>;
  Shift?: Maybe<ShiftEntity>;
  ShiftDay?: Maybe<ShiftDayEntity>;
  PayItem?: Maybe<PayItemEntity>;
};

export type AttendanceCalculationInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  AttendanceCalculationID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['DateTime']>;
  ShiftID?: Maybe<Scalars['String']>;
  ShiftDayID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['Float']>;
  AdjustedValue?: Maybe<Scalars['Float']>;
  PostStatus?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  Type?: Maybe<Scalars['String']>;
};

export type AttendanceDetailOutput = {
  __typename?: 'AttendanceDetailOutput';
  Date?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  ShiftName?: Maybe<Scalars['String']>;
  DayType?: Maybe<Scalars['String']>;
  ShiftTimeIn?: Maybe<Scalars['String']>;
  ShiftTimeOut?: Maybe<Scalars['String']>;
  ActualIn?: Maybe<Scalars['String']>;
  ActualOut?: Maybe<Scalars['String']>;
  PreOT?: Maybe<Scalars['Float']>;
  PostOT?: Maybe<Scalars['Float']>;
  ShiftHour?: Maybe<Scalars['Float']>;
  WorkHour?: Maybe<Scalars['Float']>;
  ActualWorkHour?: Maybe<Scalars['Float']>;
  RawOTHours?: Maybe<Scalars['Float']>;
  LateIn?: Maybe<Scalars['Float']>;
  EarlyOut?: Maybe<Scalars['Float']>;
  Absenteeism?: Maybe<Scalars['String']>;
  TimeOff?: Maybe<Scalars['Float']>;
  ApprovedOTHours?: Maybe<Scalars['Float']>;
  Leave?: Maybe<Scalars['String']>;
  TravelTime?: Maybe<Scalars['Float']>;
  UnproductiveTime?: Maybe<Scalars['Float']>;
  Irregular?: Maybe<Scalars['String']>;
  IncompleteClocking?: Maybe<Scalars['String']>;
  FixedHours?: Maybe<Scalars['Float']>;
};

export type AttendancePostInput = {
  SubscriptionUserID?: Maybe<Scalars['String']>;
  PayrollPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  AttendanceCalculationIDs?: Maybe<Array<Scalars['String']>>;
};

export type AttendanceProcessingOutput = {
  __typename?: 'AttendanceProcessingOutput';
  Date?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  ShiftName?: Maybe<Scalars['String']>;
  ShiftPatternName?: Maybe<Scalars['String']>;
  ShiftPatternID?: Maybe<Scalars['String']>;
  ShiftGroupName?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  DayType?: Maybe<Scalars['String']>;
  ShiftTimeIn?: Maybe<Scalars['String']>;
  ShiftTimeOut?: Maybe<Scalars['String']>;
  ActualIn?: Maybe<Scalars['String']>;
  ActualOut?: Maybe<Scalars['String']>;
  PreOT?: Maybe<Scalars['Float']>;
  PostOT?: Maybe<Scalars['Float']>;
  ShiftHour?: Maybe<Scalars['Float']>;
  WorkHour?: Maybe<Scalars['Float']>;
  ActualWorkHour?: Maybe<Scalars['Float']>;
  RawOTHours?: Maybe<Scalars['Float']>;
  LateIn?: Maybe<Scalars['Float']>;
  EarlyOut?: Maybe<Scalars['Float']>;
  Absenteeism?: Maybe<Scalars['String']>;
  TimeOff?: Maybe<Scalars['Float']>;
  ApprovedOTHours?: Maybe<Scalars['Float']>;
  Leave?: Maybe<Scalars['String']>;
  TravelTime?: Maybe<Scalars['Float']>;
  UnproductiveTime?: Maybe<Scalars['Float']>;
  Irregular?: Maybe<Scalars['String']>;
  IncompleteClocking?: Maybe<Scalars['String']>;
  FixedHours?: Maybe<Scalars['Float']>;
  PayItemID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
};

export type AttendanceRecordEntity = InterfaceBase & {
  __typename?: 'AttendanceRecordEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  AttendanceRecordID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  IntegrationClockType?: Maybe<IntegrationClockType>;
  ExcelFileName?: Maybe<Scalars['String']>;
  Location?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Lat?: Maybe<Scalars['Float']>;
  Long?: Maybe<Scalars['Float']>;
  Radius?: Maybe<Scalars['Float']>;
  Temperature?: Maybe<Scalars['Float']>;
  WearMask?: Maybe<Scalars['Boolean']>;
  Remark?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  DateTime?: Maybe<Scalars['DateTime']>;
};

export type AttendanceRecordInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  AttendanceRecordID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  IntegrationClockType?: Maybe<IntegrationClockType>;
  DateTime?: Maybe<Scalars['DateTime']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  Location?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Lat?: Maybe<Scalars['Float']>;
  Long?: Maybe<Scalars['Float']>;
  Radius?: Maybe<Scalars['Float']>;
};

export type AttendanceRecordOutput = {
  __typename?: 'AttendanceRecordOutput';
  Date?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['String']>;
  AdjustedValue?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  ShiftDayID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['String']>;
};

export type AuditEntity = {
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
};

export enum AvailableBasis {
  FullEntitlement = 'Full_Entitlement',
  EarnByProration = 'Earn_By_Proration',
  NoProration = 'No_Proration'
}

export enum BackdatedOtMethod {
  CalendarDay = 'CALENDAR_DAY',
  WorkingDay = 'WORKING_DAY'
}

export enum Bank {
  AffinBankBerhad = 'Affin_Bank_Berhad',
  AllianceBankMalaysiaBerhad = 'Alliance_Bank_Malaysia_Berhad',
  AmBankMBerhad = 'Am_Bank_M_Berhad',
  BangkokBankBerhad = 'Bangkok_Bank_Berhad',
  BankOfAmericaMalaysiaBerhad = 'Bank_of_America_Malaysia_Berhad',
  BankOfChinaMalaysiaBerhad = 'Bank_of_China_Malaysia_Berhad',
  BnpParibasMalaysiaBerhad = 'BNP_Paribas_Malaysia_Berhad',
  ChinaConstructionBankMalaysiaBerhad = 'China_Construction_Bank_Malaysia_Berhad',
  CimbBankBerhad = 'CIMB_Bank_Berhad',
  CitibankBerhad = 'Citibank_Berhad',
  DeutscheBankMalaysiaBerhad = 'Deutsche_Bank_Malaysia_Berhad',
  HongLeongBankBerhad = 'HongLeong_Bank_Berhad',
  HsbcBankMalaysiaBerhad = 'HSBC_Bank_Malaysia_Berhad',
  IndiaInternationalBankMalaysiaBerhad = 'India_International_Bank_Malaysia_Berhad',
  IndustrialAndCommercialBankOfChinaMalaysiaBerhad = 'Industrial_and_Commercial_Bank_of_China_Malaysia_Berhad',
  JpMorganChaseBank = 'JP_Morgan_Chase_Bank',
  MalayanBankingBerhad = 'Malayan_Banking_Berhad',
  MizuhoBankMalaysiaBerhad = 'Mizuho_Bank_Malaysia_Berhad',
  MufgBankMalaysiaBerhad = 'MUFG_Bank_Malaysia_Berhad',
  OcbcBankMalaysiaBerhad = 'OCBC_Bank_Malaysia_Berhad',
  PublicBankBerhad = 'Public_Bank_Berhad',
  RhbBankBerhad = 'RHB_Bank_Berhad',
  StandardCharteredBankMalaysiaBerhad = 'Standard_Chartered_Bank_Malaysia_Berhad',
  SumitomoMitsuiBankingCorporationMalaysiaBerhad = 'Sumitomo_Mitsui_Banking_Corporation_Malaysia_Berhad',
  TheBankOfNovaScotiaBerhad = 'The_Bank_of_Nova_Scotia_Berhad',
  UnitedOverseasBankMalaysiaBerhad = 'United_Overseas_Bank_Malaysia_Berhad'
}

export type BankInfoEntity = InterfaceBase & {
  __typename?: 'BankInfoEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  BankInfoID: Scalars['String'];
  CompanyID: Scalars['String'];
  Name: Scalars['String'];
  AccountName: Scalars['String'];
  AccountNo: Scalars['String'];
  SwiftCode?: Maybe<Scalars['String']>;
  OrganizationCode?: Maybe<Scalars['String']>;
  Address?: Maybe<Address2>;
  CompanyBank: CompanyEntity;
  ContactNo?: Maybe<Scalars['String']>;
  ContactName?: Maybe<Scalars['String']>;
};

export type BankInfoInputs = {
  CompanyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  AccountName?: Maybe<Scalars['String']>;
  AccountNo?: Maybe<Scalars['String']>;
  SwiftCode?: Maybe<Scalars['String']>;
  OrganizationCode?: Maybe<Scalars['String']>;
  Address?: Maybe<AddressInput2>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  ContactName?: Maybe<Scalars['String']>;
};

export type BaseInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type BatchEntryByShiftEntity = InterfaceBase & {
  __typename?: 'BatchEntryByShiftEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  BatchEntryByShiftID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  ShiftGroupID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  Shift?: Maybe<ShiftEntity>;
  ShiftGroup?: Maybe<ShiftGroupEntity>;
};

export type BatchEntryByShiftInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  BatchEntryByShiftID?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
};

export type BatchEntryByShiftPatternEntity = InterfaceBase & {
  __typename?: 'BatchEntryByShiftPatternEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  BatchEntryByShiftPatternID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ShiftPatternID: Scalars['String'];
  ShiftGroupID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  ShiftPattern?: Maybe<ShiftPatternEntity>;
  ShiftGroup?: Maybe<ShiftGroupEntity>;
};

export type BatchEntryByShiftPatternInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  BatchEntryByShiftPatternID?: Maybe<Scalars['String']>;
  ShiftPatternID?: Maybe<Scalars['String']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
};

export type BiYearError = {
  __typename?: 'BiYearError';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

export type BiYearlyError = {
  __typename?: 'BiYearlyError';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

export type BreakTimeEntity = InterfaceBase & {
  __typename?: 'BreakTimeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  BreakTimeID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Type: BreakTimeType;
  Start: Scalars['String'];
  End: Scalars['String'];
  ShiftID: Scalars['String'];
  shift?: Maybe<ShiftEntity>;
};

export type BreakTimeInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  Type: BreakTimeType;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Start: Scalars['String'];
  End: Scalars['String'];
  BreakTimeID?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
};

export enum BreakTimeType {
  OtBreak = 'OT_BREAK',
  ShiftBreak = 'SHIFT_BREAK'
}

export type BusinessInsightClaimTakenOuput = {
  __typename?: 'BusinessInsightClaimTakenOuput';
  Month?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  ClaimTakenByLeaveType?: Maybe<Array<ClaimTakenByLeaveType>>;
};

export type BusinessInsightCompanyOutput = {
  __typename?: 'BusinessInsightCompanyOutput';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  children?: Maybe<Array<BusinessInsightDepartmentOutput>>;
};

export type BusinessInsightDepartmentOutput = {
  __typename?: 'BusinessInsightDepartmentOutput';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  children?: Maybe<Array<BusinessInsightDivisionOutput>>;
};

export type BusinessInsightDivisionOutput = {
  __typename?: 'BusinessInsightDivisionOutput';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type BusinessInsightGroupingOutput = {
  __typename?: 'BusinessInsightGroupingOutput';
  TotalEmp?: Maybe<Scalars['Float']>;
  groupName?: Maybe<Scalars['String']>;
  TotalAmount?: Maybe<Scalars['Float']>;
};

export type BusinessInsightLeaveTakenOutput = {
  __typename?: 'BusinessInsightLeaveTakenOutput';
  TotalAllLeave?: Maybe<Scalars['Float']>;
  TotalAnnualLeave?: Maybe<Scalars['Float']>;
  TotalSickLeave?: Maybe<Scalars['Float']>;
  TotalHospitalizationLeave?: Maybe<Scalars['Float']>;
  TotalMaternityLeave?: Maybe<Scalars['Float']>;
  TotalCompassionateLeave?: Maybe<Scalars['Float']>;
  TotalUnpaidLeave?: Maybe<Scalars['Float']>;
  TotalReplacementLeave?: Maybe<Scalars['Float']>;
  Month?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
};

export type BusinessInsightLeaveTypeOutput = {
  __typename?: 'BusinessInsightLeaveTypeOutput';
  TotalAnnualLeave?: Maybe<Scalars['Float']>;
  TotalSickLeave?: Maybe<Scalars['Float']>;
  TotalHospitalizationLeave?: Maybe<Scalars['Float']>;
  TotalMaternityLeave?: Maybe<Scalars['Float']>;
  TotalCompassionateLeave?: Maybe<Scalars['Float']>;
  TotalUnpaidLeave?: Maybe<Scalars['Float']>;
  TotalReplacementLeave?: Maybe<Scalars['Float']>;
  Month?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  OthersLeave?: Maybe<Scalars['Float']>;
};

export type BusinessInsightListingOutput = {
  __typename?: 'BusinessInsightListingOutput';
  CompanyID?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  DivisionID?: Maybe<Scalars['String']>;
  RecuritmentSourceID?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  JobGrade?: Maybe<Scalars['String']>;
  RecruitmentSource?: Maybe<Scalars['String']>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  Age?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<Scalars['String']>;
  Gender?: Maybe<Scalars['String']>;
  Race?: Maybe<Scalars['String']>;
  Religion?: Maybe<Scalars['String']>;
  Marital?: Maybe<Scalars['String']>;
  ResignedDate?: Maybe<Scalars['DateTime']>;
  IsResigned?: Maybe<Scalars['Float']>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  LengthOfService?: Maybe<Scalars['Float']>;
  CurrentYearNewJoin?: Maybe<Scalars['Float']>;
  CurrentYearResigned?: Maybe<Scalars['Float']>;
};

export type BusinessInsightOutput = {
  __typename?: 'BusinessInsightOutput';
  FilterType?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  DivisionID?: Maybe<Scalars['String']>;
  TotalEmp?: Maybe<Scalars['Float']>;
};

export type BusinessInsightPayrollPayItemOutput = {
  __typename?: 'BusinessInsightPayrollPayItemOutput';
  NetAmount?: Maybe<Scalars['Float']>;
  TotalEarning?: Maybe<Scalars['Float']>;
  TotalOvertime?: Maybe<Scalars['Float']>;
  TotalNonpayable?: Maybe<Scalars['Float']>;
  TotalDeduction?: Maybe<Scalars['Float']>;
  Month?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
};

export type BusinessInsightPayrollSalaryOverheadOutput = {
  __typename?: 'BusinessInsightPayrollSalaryOverheadOutput';
  TotalEmp?: Maybe<Scalars['Float']>;
  Month?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  NetPay?: Maybe<Scalars['Float']>;
  TotalGross?: Maybe<Scalars['Float']>;
  TotalDeduction?: Maybe<Scalars['Float']>;
};

export type BusinessInsightPayrollStatutoryOutput = {
  __typename?: 'BusinessInsightPayrollStatutoryOutput';
  Month?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  EmployerContribution?: Maybe<Scalars['Float']>;
  EmployeeContribution?: Maybe<Scalars['Float']>;
  EmployerEPF?: Maybe<Scalars['Float']>;
  EmployeeEPF?: Maybe<Scalars['Float']>;
  EmployerEIS?: Maybe<Scalars['Float']>;
  EmployeeEIS?: Maybe<Scalars['Float']>;
  EmployerSOCSO?: Maybe<Scalars['Float']>;
  EmployeeSOCSO?: Maybe<Scalars['Float']>;
};

export type BusinessInsightSummaryOutput = {
  __typename?: 'BusinessInsightSummaryOutput';
  FilterType?: Maybe<Scalars['String']>;
  Race?: Maybe<Array<BusinessInsightGroupingOutput>>;
  Gender?: Maybe<Array<BusinessInsightGroupingOutput>>;
  Religion?: Maybe<Array<BusinessInsightGroupingOutput>>;
  Marital?: Maybe<Array<BusinessInsightGroupingOutput>>;
  Age?: Maybe<Array<BusinessInsightGroupingOutput>>;
  EmploymentType?: Maybe<Array<BusinessInsightGroupingOutput>>;
  JobGrade?: Maybe<Array<BusinessInsightGroupingOutput>>;
  LengthOfService?: Maybe<Array<BusinessInsightGroupingOutput>>;
  RecruitmentSource?: Maybe<Array<BusinessInsightGroupingOutput>>;
  StaffTurnover?: Maybe<Array<BusinessInsightGroupingOutput>>;
};

export type BusinessInsightSummaryPayrollOutput = {
  __typename?: 'BusinessInsightSummaryPayrollOutput';
  FilterType?: Maybe<Scalars['String']>;
  SalaryOverhead?: Maybe<Array<BusinessInsightPayrollSalaryOverheadOutput>>;
  StatutoryContribution?: Maybe<Array<BusinessInsightPayrollStatutoryOutput>>;
  PayItems?: Maybe<Array<BusinessInsightPayrollPayItemOutput>>;
};

export enum CfMethod {
  PercentageEntitlement = 'Percentage_Entitlement',
  PercentageBalance = 'Percentage_Balance',
  Day = 'Day'
}

export type Cp159Entity = InterfaceBase & {
  __typename?: 'CP159Entity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CP159ID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  ReceiptNoPCB?: Maybe<Scalars['String']>;
  ReceiptDatePCB?: Maybe<Scalars['DateTime']>;
  ReceiptNoCP38?: Maybe<Scalars['String']>;
  ReceiptDateCP38?: Maybe<Scalars['DateTime']>;
  Company?: Maybe<CompanyEntity>;
};

export type Cp159Input = {
  CP159ID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  ReceiptNoPCB?: Maybe<Scalars['String']>;
  ReceiptDatePCB?: Maybe<Scalars['DateTime']>;
  ReceiptNoCP38?: Maybe<Scalars['String']>;
  ReceiptDateCP38?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum CalculationMethod {
  ServiceDay = 'Service_Day',
  ServiceMonth = 'Service_Month'
}

export type CalendarOffDayEmployeeOutput = {
  __typename?: 'CalendarOffDayEmployeeOutput';
  StateID: Scalars['String'];
  EmployeeID: Scalars['String'];
  DesignationName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  CalendarOffDayID: Scalars['String'];
  StateName?: Maybe<Scalars['String']>;
};

export type CalendarOffDayEntity = InterfaceBase & {
  __typename?: 'CalendarOffDayEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CalendarOffDayID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  StateID?: Maybe<Scalars['String']>;
  States?: Maybe<StatesEntity>;
  Employee?: Maybe<EmployeeEntity>;
  TotalEmployee?: Maybe<Scalars['Float']>;
};

export type CalendarOffDayInput = {
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
  StateID: Scalars['String'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type CalendarOffDayOutput = {
  __typename?: 'CalendarOffDayOutput';
  StateID: Scalars['String'];
  Description: Scalars['String'];
  TotalEmployee?: Maybe<Scalars['Float']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
};

export type CalendarPolicyOutput = {
  __typename?: 'CalendarPolicyOutput';
  StateID: Scalars['String'];
  StateName?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  Default?: Maybe<Scalars['Boolean']>;
};

export type CareerLogEntity = InterfaceBase & {
  __typename?: 'CareerLogEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CareerLogID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  SalaryType?: Maybe<SalaryType>;
  MovementType?: Maybe<MovementType>;
  FromCompany?: Maybe<Scalars['String']>;
  ToCompany?: Maybe<Scalars['String']>;
  FromDepartment?: Maybe<Scalars['String']>;
  ToDepartment?: Maybe<Scalars['String']>;
  FromDivision?: Maybe<Scalars['String']>;
  ToDivision?: Maybe<Scalars['String']>;
  FromPosition?: Maybe<Scalars['String']>;
  ToPosition?: Maybe<Scalars['String']>;
  FromJobGrade?: Maybe<Scalars['String']>;
  ToJobGrade?: Maybe<Scalars['String']>;
  FromSalary?: Maybe<Scalars['String']>;
  ToSalary?: Maybe<Scalars['String']>;
  SalaryAdjustmentAmount?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ExitReason?: Maybe<ExitReason>;
  FromDate?: Maybe<Scalars['DateTime']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  ReportTo?: Maybe<Scalars['String']>;
  Reason?: Maybe<EmployeeSalaryReason>;
  IsCareerLog?: Maybe<Scalars['Boolean']>;
  IsSalaryLog?: Maybe<Scalars['Boolean']>;
  FromEmploymentType?: Maybe<EmploymentType>;
  ToEmploymentType?: Maybe<EmploymentType>;
  Employee?: Maybe<EmployeeEntity>;
  ToPositionTable?: Maybe<JobDesignationEntity>;
  ToDepartmentTable?: Maybe<DepartmentEntity>;
  Document?: Maybe<DocumentsEntity>;
};

export type CareerLogInput = {
  CareerLogID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  MovementType?: Maybe<MovementType>;
  SalaryType?: Maybe<SalaryType>;
  FromCompany?: Maybe<Scalars['String']>;
  ToCompany?: Maybe<Scalars['String']>;
  FromDepartment?: Maybe<Scalars['String']>;
  ToDepartment?: Maybe<Scalars['String']>;
  FromDivision?: Maybe<Scalars['String']>;
  ToDivision?: Maybe<Scalars['String']>;
  FromPosition?: Maybe<Scalars['String']>;
  ToPosition?: Maybe<Scalars['String']>;
  FromJobGrade?: Maybe<Scalars['String']>;
  ToJobGrade?: Maybe<Scalars['String']>;
  FromSalary?: Maybe<Scalars['String']>;
  ToSalary?: Maybe<Scalars['String']>;
  SalaryAdjustmentAmount?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  ReportTo?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ExitReason?: Maybe<ExitReason>;
  Reason?: Maybe<EmployeeSalaryReason>;
  IsCareerLog?: Maybe<Scalars['Boolean']>;
  IsSalaryLog?: Maybe<Scalars['Boolean']>;
  FromEmploymentType?: Maybe<EmploymentType>;
  ToEmploymentType?: Maybe<EmploymentType>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ChangeShiftEntity = InterfaceBase & {
  __typename?: 'ChangeShiftEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ChangeShiftID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Date: Scalars['String'];
  Reason?: Maybe<Scalars['String']>;
  FromShift?: Maybe<ShiftEntity>;
  FromShiftID: Scalars['String'];
  Employee?: Maybe<EmployeeEntity>;
  EmployeeID?: Maybe<Scalars['String']>;
  ToShift?: Maybe<ShiftEntity>;
  ToShiftID?: Maybe<Scalars['String']>;
  ChangeShiftStatus: ChangeShiftStatus;
  RejectReason?: Maybe<Scalars['String']>;
  Approver?: Maybe<Scalars['String']>;
  ReplacedEmployee?: Maybe<EmployeeEntity>;
  ReplacedBy?: Maybe<Scalars['String']>;
  ApprovedDate?: Maybe<Scalars['String']>;
  CancelledBy?: Maybe<Scalars['String']>;
  ChangeShiftHistory?: Maybe<Array<ChangeShiftEntity>>;
};

export type ChangeShiftInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  ChangeShiftID?: Maybe<Scalars['String']>;
  FromShiftID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  ToShiftID?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  ChangeShiftStatus?: Maybe<Scalars['String']>;
  ReplacedBy?: Maybe<Scalars['String']>;
  Approver?: Maybe<Scalars['String']>;
  ApprovedDate?: Maybe<Scalars['String']>;
  CancelledBy?: Maybe<Scalars['String']>;
};

export enum ChangeShiftStatus {
  Draft = 'DRAFT',
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED'
}

export type ClaimAppDetailsOutput = {
  __typename?: 'ClaimAppDetailsOutput';
  ReceiptNo?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  ClaimRemarks?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};

export type ClaimAppOutput = {
  __typename?: 'ClaimAppOutput';
  Subject?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  Details?: Maybe<Array<ClaimAppDetailsOutput>>;
};

export type ClaimBenefitEntity = InterfaceBase & {
  __typename?: 'ClaimBenefitEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimBenefitID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ClaimItems?: Maybe<Array<ClaimItemEntity>>;
};

export type ClaimBenefitInput = {
  ClaimBenefitID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ClaimCutOffPeriodEntity = InterfaceBase & {
  __typename?: 'ClaimCutOffPeriodEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimCutOffPeriodID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYearMonth: Scalars['DateTime'];
  FromDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  Company?: Maybe<CompanyEntity>;
};

export type ClaimDetails = {
  __typename?: 'ClaimDetails';
  ApproverName?: Maybe<Scalars['String']>;
  ClaimSubject?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  TotalClaim?: Maybe<Scalars['Float']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ClaimDetailsListing?: Maybe<Array<ClaimDetailsEntity>>;
};

export type ClaimDetailsEntity = InterfaceBase & {
  __typename?: 'ClaimDetailsEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimDetailsID: Scalars['String'];
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  DocumentID?: Maybe<Scalars['String']>;
  ClaimRemarks?: Maybe<Scalars['String']>;
  FinanceApproveDT?: Maybe<Scalars['DateTime']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  FinanceRemarks?: Maybe<Scalars['String']>;
  FinanceApproveStatus?: Maybe<ApprovalStatus>;
  ClaimAmountByFinance?: Maybe<Scalars['Float']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  ClaimHeader?: Maybe<ClaimHeaderEntity>;
  ClaimType?: Maybe<ClaimTypeEntity>;
  Documents?: Maybe<DocumentsEntity>;
};

export type ClaimDetailsInput = {
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  DocumentID?: Maybe<Scalars['String']>;
  ClaimRemarks: Scalars['String'];
  FinanceApproveDT?: Maybe<Scalars['DateTime']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  FinanceRemarks?: Maybe<Scalars['String']>;
  FinanceApproveStatus: ApprovalStatus;
  ClaimAmountByFinance?: Maybe<Scalars['Float']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum ClaimFrequency {
  Monthly = 'Monthly',
  Yearly = 'Yearly',
  BiYearly = 'Bi_Yearly',
  Lifetime = 'Lifetime'
}

export type ClaimHeaderEntity = InterfaceBase & {
  __typename?: 'ClaimHeaderEntity';
  Status: Status;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimHeaderID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  JobAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus: ApprovalStatus;
  ApproverID?: Maybe<Scalars['String']>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ClaimNo?: Maybe<Scalars['String']>;
  ClaimDate?: Maybe<Scalars['DateTime']>;
  IsFinanceAction?: Maybe<Scalars['Boolean']>;
  ClaimHistory?: Maybe<Array<ClaimHeaderEntity>>;
  ClaimDetails: Array<ClaimDetailsEntity>;
  Employee?: Maybe<EmployeeEntity>;
  Company?: Maybe<CompanyEntity>;
  JobAccount: JobAccountEntity;
  Approver?: Maybe<EmployeeEntity>;
};

export type ClaimHeaderInput = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  JobAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus: ApprovalStatus;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  IsFinanceAction?: Maybe<Scalars['Boolean']>;
};

export type ClaimHistoryListingOutput = {
  __typename?: 'ClaimHistoryListingOutput';
  ApprovalStatus?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  TotalClaim?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  ClaimDetails?: Maybe<Array<ClaimDetailsEntity>>;
};

export type ClaimInsertOutput = {
  __typename?: 'ClaimInsertOutput';
  RespStatus?: Maybe<Scalars['String']>;
  RespMsg?: Maybe<Array<ArrMesg>>;
};

export type ClaimItemEntity = InterfaceBase & {
  __typename?: 'ClaimItemEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimItemID: Scalars['String'];
  ClaimBenefitID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ClaimFrequency?: Maybe<ClaimFrequency>;
  TotalAmt?: Maybe<Scalars['Float']>;
  Occurrence?: Maybe<Scalars['Float']>;
  OccurrenceLimit?: Maybe<Scalars['Float']>;
  IsLimit?: Maybe<Scalars['Boolean']>;
  IsRemarks?: Maybe<Scalars['Boolean']>;
  IsAttachment?: Maybe<Scalars['Boolean']>;
  ClaimBenefit?: Maybe<ClaimBenefitEntity>;
  ClaimType?: Maybe<ClaimTypeEntity>;
  ClaimTemplates?: Maybe<Array<ClaimTemplateEntity>>;
};

export type ClaimItemInput = {
  ClaimItemID?: Maybe<Scalars['String']>;
  ClaimBenefitID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ClaimFrequency?: Maybe<ClaimFrequency>;
  TotalAmt?: Maybe<Scalars['Float']>;
  Occurrence?: Maybe<Scalars['Float']>;
  OccurrenceLimit?: Maybe<Scalars['Float']>;
  IsLimit?: Maybe<Scalars['Boolean']>;
  IsRemarks?: Maybe<Scalars['Boolean']>;
  IsAttachment?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ClaimManagementListing = {
  __typename?: 'ClaimManagementListing';
  Title?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
};

export type ClaimPostedListingOutput = {
  __typename?: 'ClaimPostedListingOutput';
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollMonth?: Maybe<Scalars['DateTime']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayrollCycleName?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
};

export type ClaimPostingInput = {
  PayPeriodID?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
};

export type ClaimPostingListingOutput = {
  __typename?: 'ClaimPostingListingOutput';
  ClaimHeaderID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  Subject?: Maybe<Scalars['String']>;
  CountClaimDetails?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  CountVerifiedClaim?: Maybe<Scalars['Float']>;
  TotalVerifiedClaimAmount?: Maybe<Scalars['Float']>;
  CountClaimHistory?: Maybe<Scalars['Float']>;
  HeaderStatus?: Maybe<Scalars['String']>;
  DetailStatus?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ApprovalName?: Maybe<Scalars['String']>;
  FinanceApprovalDate?: Maybe<Scalars['DateTime']>;
  FinanceApprovalName?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  ClaimPostedListing?: Maybe<ClaimPostedListingOutput>;
};

export type ClaimPostingManagementInput = {
  CompanyID?: Maybe<Scalars['String']>;
  DateFrom?: Maybe<Scalars['String']>;
  DateTo?: Maybe<Scalars['String']>;
};

export type ClaimPostingManagementOutput = {
  __typename?: 'ClaimPostingManagementOutput';
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ApprovelStatus?: Maybe<Scalars['String']>;
  EmpName?: Maybe<Scalars['String']>;
  EmpID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['String']>;
  TotalAmtClaim?: Maybe<Scalars['Float']>;
  TotalClaim?: Maybe<Scalars['String']>;
  ClaimRemark?: Maybe<Scalars['String']>;
  ApprovelBy?: Maybe<Scalars['String']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  HeaderTotalAmt?: Maybe<Scalars['String']>;
  HeaderTotalClaim?: Maybe<Scalars['String']>;
};

export type ClaimPostingPeriodListingInput = {
  Year?: Maybe<Scalars['Float']>;
  CompanyID?: Maybe<Scalars['String']>;
};

export type ClaimPostingPeriodListingOutput = {
  __typename?: 'ClaimPostingPeriodListingOutput';
  ClaimCutOffPeriodID?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  ListingStatus?: Maybe<Scalars['String']>;
  ClaimPostingPeriodStatusList?: Maybe<Array<ClaimPostingPeriodStatus>>;
};

export type ClaimPostingPeriodStatus = {
  __typename?: 'ClaimPostingPeriodStatus';
  ClaimPostingPeriodStatus?: Maybe<Scalars['String']>;
  CountClaimDetails?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
};

export type ClaimRecordHistoryInput = {
  EmployeeID?: Maybe<Scalars['String']>;
};

export type ClaimRecordHistoryOutput = {
  __typename?: 'ClaimRecordHistoryOutput';
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ApprovelStatus?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  EmpName?: Maybe<Scalars['String']>;
  EmpID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['String']>;
  TotalAmtClaim?: Maybe<Scalars['Float']>;
  TotalClaim?: Maybe<Scalars['String']>;
  ClaimRemark?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  IsFinanceAction?: Maybe<Scalars['Boolean']>;
  FinnanceStatus?: Maybe<Scalars['String']>;
  ApprovelBy?: Maybe<Scalars['String']>;
};

export type ClaimRecordInput = {
  CompanyID?: Maybe<Scalars['String']>;
};

export type ClaimRecordOutput = {
  __typename?: 'ClaimRecordOutput';
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ApprovelStatus?: Maybe<Scalars['String']>;
  EmpName?: Maybe<Scalars['String']>;
  EmpID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['String']>;
  TotalAmtClaim?: Maybe<Scalars['Float']>;
  TotalClaim?: Maybe<Scalars['String']>;
  ClaimRemark?: Maybe<Scalars['String']>;
  ApprovedBy?: Maybe<Scalars['String']>;
  ApprovedDate?: Maybe<Scalars['String']>;
  VerifyBy?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  History?: Maybe<Scalars['String']>;
  FinnanceStatus?: Maybe<Scalars['String']>;
  CheckComplete?: Maybe<Scalars['String']>;
  ApprovelBy?: Maybe<Scalars['String']>;
};

export type ClaimRecordsDetail = {
  __typename?: 'ClaimRecordsDetail';
  ClaimDetailsID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ClaimTypeName?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocumentName?: Maybe<Scalars['String']>;
  DocumentUrl?: Maybe<Scalars['String']>;
  ClaimRemarks?: Maybe<Scalars['String']>;
  VerifiedAmount?: Maybe<Scalars['Float']>;
  VerifiedRemark?: Maybe<Scalars['String']>;
  FinanceApproveDate?: Maybe<Scalars['DateTime']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  FinanceRemarks?: Maybe<Scalars['String']>;
  FinanceApproveStatus?: Maybe<ApprovalStatus>;
  Document?: Maybe<DocumentsEntity>;
};

export type ClaimRecordsDetailHeaderOutput = {
  __typename?: 'ClaimRecordsDetailHeaderOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  JobAccountName?: Maybe<Scalars['String']>;
  DepartmentName?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  Subject?: Maybe<Scalars['String']>;
  CountClaimDetails?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ApprovalName?: Maybe<Scalars['String']>;
  PostingStatus?: Maybe<Scalars['String']>;
  ClaimRecordsDetail?: Maybe<Array<ClaimRecordsDetail>>;
};

export type ClaimRecordsInput = {
  CompanyID?: Maybe<Scalars['String']>;
  DateFrom?: Maybe<Scalars['DateTime']>;
  DateTo?: Maybe<Scalars['DateTime']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
  ApprovalStatus?: Maybe<Array<Scalars['String']>>;
  ClaimTypeIDs?: Maybe<Array<Scalars['String']>>;
};

export type ClaimRecordsItemInput = {
  ClaimDetailsID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  Document?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  DocInput?: Maybe<DocumentsInput>;
  IsAttachmentRemoved?: Maybe<Scalars['Boolean']>;
};

export type ClaimRecordsListing = {
  __typename?: 'ClaimRecordsListing';
  ClaimHeaderID?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  Subject?: Maybe<Scalars['String']>;
  CountClaimDetails?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  CountVerifiedClaim?: Maybe<Scalars['Float']>;
  TotalVerifiedClaimAmount?: Maybe<Scalars['Float']>;
  CountClaimHistory?: Maybe<Scalars['Float']>;
  HeaderStatus?: Maybe<Scalars['String']>;
  DetailStatus?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ApprovalName?: Maybe<Scalars['String']>;
  FinanceApprovalDate?: Maybe<Scalars['DateTime']>;
  FinanceApprovalName?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
};

export type ClaimSettingEntity = InterfaceBase & {
  __typename?: 'ClaimSettingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimSettingID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ClaimTrxDayLimit?: Maybe<Scalars['Float']>;
};

export type ClaimSettingInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ClaimTrxDayLimit?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ClaimSettingsListing = {
  __typename?: 'ClaimSettingsListing';
  Title?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CountRecords?: Maybe<Scalars['Float']>;
  Sequence?: Maybe<Scalars['Float']>;
};

export type ClaimTakenByLeaveType = {
  __typename?: 'ClaimTakenByLeaveType';
  ClaimTypeID?: Maybe<Scalars['String']>;
  ClaimTypeName?: Maybe<Scalars['String']>;
  ClaimTaken?: Maybe<Scalars['Float']>;
};

export type ClaimTemplateEntity = InterfaceBase & {
  __typename?: 'ClaimTemplateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimTemplateID: Scalars['String'];
  JobGradePolicyID?: Maybe<Scalars['String']>;
  ClaimPolicyID?: Maybe<Scalars['String']>;
  JobGradePolicy?: Maybe<JobGradePolicyEntity>;
  ClaimItem?: Maybe<ClaimItemEntity>;
};

export type ClaimTemplateInput = {
  ClaimTemplateID?: Maybe<Scalars['String']>;
  ClaimPolicyID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type ClaimToApproveListingOutput = {
  __typename?: 'ClaimToApproveListingOutput';
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ApproverName?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  TotalClaim?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  ClaimDetails?: Maybe<Array<ClaimDetailsEntity>>;
  ClaimHistory?: Maybe<Array<ClaimHistoryListingOutput>>;
};

export type ClaimTrxError = {
  __typename?: 'ClaimTrxError';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

export type ClaimTypeEntity = InterfaceBase & {
  __typename?: 'ClaimTypeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ClaimTypeID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PaymentMethod?: Maybe<PaymentMethod>;
  PayItem?: Maybe<PayItemEntity>;
  ClaimPolicies?: Maybe<Array<ClaimItemEntity>>;
};

export type ClaimTypeInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PaymentMethod?: Maybe<PaymentMethod>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum ClockPairingType {
  Yesterday = 'YESTERDAY',
  Today = 'TODAY',
  Tomorrow = 'TOMORROW'
}

export enum CommonStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Block = 'BLOCK'
}

export type CompanyEntity = InterfaceBase & {
  __typename?: 'CompanyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  BizRegNo?: Maybe<Scalars['String']>;
  Sector?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  WorkDaysAMonth?: Maybe<Scalars['Float']>;
  WorkHoursADay?: Maybe<Scalars['Float']>;
  PayrollCutOffDay?: Maybe<Scalars['Float']>;
  LeaveCutOffDay?: Maybe<Scalars['Float']>;
  LeaveRoundingID?: Maybe<Scalars['String']>;
  ClaimCutOffDay?: Maybe<Scalars['Float']>;
  CalendarStateID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  ClaimTrxDayLimit?: Maybe<Scalars['Float']>;
  Departments?: Maybe<Array<DepartmentEntity>>;
  Employees?: Maybe<Array<EmployeeEntity>>;
  BankInfos?: Maybe<Array<BankInfoEntity>>;
  CompanyOffDays?: Maybe<Array<CompanyOffDayEntity>>;
  RecurringRestOffDays?: Maybe<Array<RecurringRestOffDayEntity>>;
  LeaveRounding?: Maybe<LeaveRoundingEntity>;
  Documents?: Maybe<Array<DocumentsEntity>>;
  Contact?: Maybe<ContactEntity>;
  CompanyStatutory?: Maybe<CompanyStatutoryEntity>;
  LeaveCompulsory?: Maybe<Array<LeaveCompulsoryPolicyEntity>>;
  JobGrade?: Maybe<Array<JobGradePolicyEntity>>;
};

export type CompanyInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  BizRegNo?: Maybe<Scalars['String']>;
  Sector?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  WorkDaysAMonth?: Maybe<Scalars['Float']>;
  WorkHoursADay?: Maybe<Scalars['Float']>;
  PayrollCutOffDay?: Maybe<Scalars['Float']>;
  CalendarStateID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  ClaimTrxDayLimit?: Maybe<Scalars['Float']>;
  LeaveCutOffDay?: Maybe<Scalars['Float']>;
  LeaveRoundingID?: Maybe<Scalars['String']>;
  ClaimCutOffDay?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type CompanyOffDayEntity = InterfaceBase & {
  __typename?: 'CompanyOffDayEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyOffDayID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYear: Scalars['Float'];
  RecurringRestOffDayID?: Maybe<Scalars['String']>;
  HolidayID?: Maybe<Scalars['String']>;
  DayOffDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  Company: CompanyEntity;
  Holiday: HolidayEntity;
};

export type CompanyOffDayInput = {
  CompanyID?: Maybe<Scalars['String']>;
  RecurringRestOffDayID?: Maybe<Scalars['String']>;
  HolidayID?: Maybe<Scalars['String']>;
  DayOffDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  StateID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type CompanyOffDayOutput = {
  __typename?: 'CompanyOffDayOutput';
  DayOffDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
};

export type CompanyRunningNoEntity = InterfaceBase & {
  __typename?: 'CompanyRunningNoEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyRunningNoID: Scalars['String'];
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  DocumentNumberAssignmentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  NextNumber?: Maybe<Scalars['Float']>;
};

export type CompanyRunningNoInput = {
  CompanyRunningNoID?: Maybe<Scalars['String']>;
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DocumentNumberAssignmentID?: Maybe<Scalars['String']>;
  NextNumber?: Maybe<Scalars['Float']>;
};

export type CompanyStatutoryEntity = InterfaceBase & {
  __typename?: 'CompanyStatutoryEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CompanyStatutoryID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EPFNo?: Maybe<Scalars['String']>;
  SocsoNo?: Maybe<Scalars['String']>;
  PCBNo?: Maybe<Scalars['String']>;
  EISNo?: Maybe<Scalars['String']>;
  IsAngkasa?: Maybe<Scalars['Boolean']>;
  AngkasaNo?: Maybe<Scalars['String']>;
  IsASB?: Maybe<Scalars['Boolean']>;
  ASBNo?: Maybe<Scalars['String']>;
  IsKWAP?: Maybe<Scalars['Boolean']>;
  KWAPNo?: Maybe<Scalars['String']>;
  IsTBH?: Maybe<Scalars['Boolean']>;
  TBHNo?: Maybe<Scalars['String']>;
  IsZakat?: Maybe<Scalars['Boolean']>;
  ZakatNo?: Maybe<Scalars['String']>;
  HRDFNo?: Maybe<Scalars['String']>;
  HRDFRate?: Maybe<Scalars['Float']>;
  SignBy?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  SignByCompany?: Maybe<CompanyEntity>;
};

export type CompanyStatutoryInput = {
  CompanyID?: Maybe<Scalars['String']>;
  IsEPF?: Maybe<Scalars['Boolean']>;
  EPFNo?: Maybe<Scalars['String']>;
  IsSocso?: Maybe<Scalars['Boolean']>;
  SocsoNo?: Maybe<Scalars['String']>;
  IsPCB?: Maybe<Scalars['Boolean']>;
  PCBNo?: Maybe<Scalars['String']>;
  IsEIS?: Maybe<Scalars['Boolean']>;
  EISNo?: Maybe<Scalars['String']>;
  IsAngkasa?: Maybe<Scalars['Boolean']>;
  AngkasaNo?: Maybe<Scalars['String']>;
  IsASB?: Maybe<Scalars['Boolean']>;
  ASBNo?: Maybe<Scalars['String']>;
  IsKWAP?: Maybe<Scalars['Boolean']>;
  KWAPNo?: Maybe<Scalars['String']>;
  IsTBH?: Maybe<Scalars['Boolean']>;
  TBHNo?: Maybe<Scalars['String']>;
  IsZakat?: Maybe<Scalars['Boolean']>;
  ZakatNo?: Maybe<Scalars['String']>;
  IsHRDF?: Maybe<Scalars['Boolean']>;
  HRDFNo?: Maybe<Scalars['String']>;
  HRDFRate?: Maybe<Scalars['Float']>;
  SignBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type CompanyViewLastUpdatedOutput = {
  __typename?: 'CompanyViewLastUpdatedOutput';
  CompanyInfoLastPostedDate?: Maybe<Scalars['DateTime']>;
  CompanyInfoIsNewLastUpdated?: Maybe<Scalars['Boolean']>;
  StatutoryLastPostedDate?: Maybe<Scalars['DateTime']>;
  DepartmentLastPostedDate?: Maybe<Scalars['DateTime']>;
  CompanyAccessAuthLastPostedDate?: Maybe<Scalars['DateTime']>;
  ExpensesAccountLastPostedDate?: Maybe<Scalars['DateTime']>;
  BankInfoLastPostedDate?: Maybe<Scalars['DateTime']>;
  CalendarPolicyLastPostedDate?: Maybe<Scalars['DateTime']>;
  CalendarOffDayPolicyLastPostedDate?: Maybe<Scalars['DateTime']>;
  LeavePolicyLastPostedDate?: Maybe<Scalars['DateTime']>;
  ClaimPolicyLastPostedDate?: Maybe<Scalars['DateTime']>;
  PayrollPolicyLastPostedDate?: Maybe<Scalars['DateTime']>;
  EmployeeLastPostedDate?: Maybe<Scalars['DateTime']>;
  NotificationPolicyLastPostedDate?: Maybe<Scalars['DateTime']>;
  CalendarPolicyLastUpdated?: Maybe<Scalars['DateTime']>;
  CalendarPolicyListLastPostedDate?: Maybe<Scalars['DateTime']>;
  NoOfGeneralDocument?: Maybe<Scalars['Float']>;
  TotalSizeOfGeneralDocument?: Maybe<Scalars['Float']>;
  RoleLastUpdated?: Maybe<Scalars['DateTime']>;
  RoleLastPostedDate?: Maybe<Scalars['DateTime']>;
  NoOfDepartment?: Maybe<Scalars['Float']>;
  NoOfRoleAssignment?: Maybe<Scalars['Float']>;
};

export type CompulsoryLeaveOutput = {
  __typename?: 'CompulsoryLeaveOutput';
  LeaveCompulsoryID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  LeaveFromDate?: Maybe<Scalars['DateTime']>;
  LeaveToDate?: Maybe<Scalars['DateTime']>;
  TotalDay?: Maybe<Scalars['Float']>;
  SessionType?: Maybe<SessionType>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocumentName?: Maybe<Scalars['String']>;
  CountEmployees?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PostedByName?: Maybe<Scalars['String']>;
  Employees?: Maybe<Array<EmployeeIndentity>>;
};

export type ContactEntity = InterfaceBase & {
  __typename?: 'ContactEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ContactID: Scalars['String'];
  ParentID?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  NickName?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  OfficeNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  NRIC?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Nationality>;
  Marital?: Maybe<Marital>;
  Gender?: Maybe<Gender>;
  Race?: Maybe<Race>;
  Religion?: Maybe<Religion>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  Address?: Maybe<Address2>;
  ProfilePicture?: Maybe<Scalars['String']>;
  WebUrl?: Maybe<Scalars['String']>;
  BucketFileName?: Maybe<Scalars['String']>;
  PermanentAddress?: Maybe<Address2>;
  SameAddress?: Maybe<Scalars['Boolean']>;
};

export type ContactInput = {
  ContactID?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  NickName?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  OfficeNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  NRIC?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Nationality>;
  Marital?: Maybe<Marital>;
  Gender?: Maybe<Gender>;
  Race?: Maybe<Race>;
  Religion?: Maybe<Religion>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  Address?: Maybe<AddressInput2>;
  PermanentAddress?: Maybe<AddressInput2>;
  SameAddress?: Maybe<Scalars['Boolean']>;
  ProfilePicture?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Scalars['Upload']>;
  WebUrl?: Maybe<Scalars['String']>;
  BucketFileName?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

/** JsonType */
export type ContactPerson = {
  __typename?: 'ContactPerson';
  name?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type ContactPersonInput = {
  name?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type CoreBaseEntity = AuditEntity & {
  __typename?: 'CoreBaseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};

export type CoreEntityRoleUserAssignmentEntity = AuditEntity & {
  __typename?: 'CoreEntityRoleUserAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  entityID: Scalars['String'];
  roleID: Scalars['String'];
};

export type CorePermissionEntity = {
  __typename?: 'CorePermissionEntity';
  index: Scalars['String'];
  name: Scalars['String'];
};

export type CoreRoleEntity = AuditEntity & {
  __typename?: 'CoreRoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
};

export type CoreRolePermissionAssignmentEntity = AuditEntity & {
  __typename?: 'CoreRolePermissionAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  roleID: Scalars['String'];
  permissionID: Scalars['Float'];
};

export enum Country {
  Algeria = 'Algeria',
  AmericanSamoa = 'American_Samoa',
  Andorra = 'Andorra',
  Angola = 'Angola',
  Anguilla = 'Anguilla',
  AntiguaAndBarbuda = 'Antigua_and_Barbuda',
  Argentina = 'Argentina',
  Armenia = 'Armenia',
  Aruba = 'Aruba',
  Australia = 'Australia',
  Austria = 'Austria',
  Azerbaijan = 'Azerbaijan',
  Bahamas = 'Bahamas',
  Bahrain = 'Bahrain',
  Bangladesh = 'Bangladesh',
  Barbados = 'Barbados',
  Belarus = 'Belarus',
  Belgium = 'Belgium',
  Belize = 'Belize',
  Benin = 'Benin',
  Bermuda = 'Bermuda',
  Bolivia = 'Bolivia',
  BosniaHerzegovina = 'Bosnia_Herzegovina',
  Brazil = 'Brazil',
  BritishVirginIslands = 'British_Virgin_Islands',
  Brunei = 'Brunei',
  Bulgaria = 'Bulgaria',
  BurkinaFaso = 'Burkina_Faso',
  Burundi = 'Burundi',
  Cambodia = 'Cambodia',
  Cameroon = 'Cameroon',
  Canada = 'Canada',
  CapeVerde = 'Cape_Verde',
  CaymanIslands = 'Cayman_Islands',
  CentralAfricanRepublic = 'Central_African_Republic',
  Chad = 'Chad',
  Chile = 'Chile',
  China = 'China',
  Colombia = 'Colombia',
  Comoros = 'Comoros',
  Congo = 'Congo',
  CookIslands = 'Cook_Islands',
  CostaRica = 'Costa_Rica',
  CoteDIvoire = 'Cote_d_Ivoire',
  Croatia = 'Croatia',
  Cuba = 'Cuba',
  Cyprus = 'Cyprus',
  CzechRepublic = 'Czech_Republic',
  Djibouti = 'Djibouti',
  DominicaCommonwealth = 'Dominica_Commonwealth',
  DominicanRepublic = 'Dominican_Republic',
  Ecuador = 'Ecuador',
  ElSalvador = 'El_Salvador',
  Eritrea = 'Eritrea',
  Estonia = 'Estonia',
  Ethiopia = 'Ethiopia',
  FaeroeIsland = 'Faeroe_Island',
  FalklandIslands = 'Falkland_Islands',
  Fiji = 'Fiji',
  Finland = 'Finland',
  France = 'France',
  FrenchGuiana = 'French_Guiana',
  FrenchPolynesia = 'French_Polynesia',
  Gabon = 'Gabon',
  Gambia = 'Gambia',
  Georgia = 'Georgia',
  Germany = 'Germany',
  Ghana = 'Ghana',
  Gibraltar = 'Gibraltar',
  Greece = 'Greece',
  Greenland = 'Greenland',
  Grenada = 'Grenada',
  Guadaloupe = 'Guadaloupe',
  Guam = 'Guam',
  Guatemala = 'Guatemala',
  Guinea = 'Guinea',
  GuineaBissau = 'Guinea_Bissau',
  Guyana = 'Guyana',
  Haiti = 'Haiti',
  Honduras = 'Honduras',
  HongKong = 'HongKong',
  Hungary = 'Hungary',
  Iceland = 'Iceland',
  India = 'India',
  Indonesia = 'Indonesia',
  Iran = 'Iran',
  Iraq = 'Iraq',
  Ireland = 'Ireland',
  Israel = 'Israel',
  Italy = 'Italy',
  Jamaica = 'Jamaica',
  Japan = 'Japan',
  Jordan = 'Jordan',
  Kazakhstan = 'Kazakhstan',
  Kenya = 'Kenya',
  Kiribati = 'Kiribati',
  NorthKorea = 'North_Korea',
  SouthKorea = 'South_Korea',
  Kuwait = 'Kuwait',
  Kyrgyzstan = 'Kyrgyzstan',
  Laos = 'Laos',
  Latvia = 'Latvia',
  Lebanon = 'Lebanon',
  Lesotho = 'Lesotho',
  Liberia = 'Liberia',
  Libya = 'Libya',
  Liechtenstein = 'Liechtenstein',
  Lithuania = 'Lithuania',
  Luxembourg = 'Luxembourg',
  Macau = 'Macau',
  Macedonia = 'Macedonia',
  Madagascar = 'Madagascar',
  Malawi = 'Malawi',
  Malaysia = 'Malaysia',
  Maldives = 'Maldives',
  Mali = 'Mali',
  Malta = 'Malta',
  MarshallIslands = 'Marshall_Islands',
  Martinique = 'Martinique',
  Mauritania = 'Mauritania',
  Mauritius = 'Mauritius',
  Mayotte = 'Mayotte',
  Mexico = 'Mexico',
  Moldova = 'Moldova',
  Monaco = 'Monaco',
  Mongolia = 'Mongolia',
  Montserrat = 'Montserrat',
  Morocco = 'Morocco',
  Mozambique = 'Mozambique',
  Myanmar = 'Myanmar',
  Namibia = 'Namibia',
  Nauru = 'Nauru',
  Nepal = 'Nepal',
  Netherlands = 'Netherlands',
  NetherlandsAntilles = 'Netherlands_Antilles',
  NewCaledonia = 'New_Caledonia',
  NewZealand = 'New_Zealand',
  Nicaragua = 'Nicaragua',
  Niger = 'Niger',
  Nigeria = 'Nigeria',
  NiueIsland = 'Niue_Island',
  NorfolkIsland = 'Norfolk_Island',
  NorthernMarianaIslands = 'Northern_Mariana_Islands',
  Norway = 'Norway',
  Oman = 'Oman',
  Pakistan = 'Pakistan',
  Palau = 'Palau',
  Panama = 'Panama',
  PapuaNewGuinea = 'Papua_New_Guinea',
  Paraguay = 'Paraguay',
  Peru = 'Peru',
  Philippines = 'Philippines',
  Poland = 'Poland',
  Portugal = 'Portugal',
  PuertoRico = 'Puerto_Rico',
  Qatar = 'Qatar',
  Reunion = 'Reunion',
  Romania = 'Romania',
  Russia = 'Russia',
  Rwanda = 'Rwanda',
  Samoa = 'Samoa',
  SanMarino = 'San_Marino',
  SaoTomeAndPrincipe = 'Sao_Tome_and_Principe',
  SaudiArabia = 'Saudi_Arabia',
  Senegal = 'Senegal',
  Seychelles = 'Seychelles',
  SierraLeone = 'Sierra_Leone',
  Singapore = 'Singapore',
  Slovenia = 'Slovenia',
  SolomonIslands = 'Solomon_Islands',
  Somalia = 'Somalia',
  SouthAfrica = 'South_Africa',
  Spain = 'Spain',
  SriLanka = 'Sri_Lanka',
  StHelena = 'St_Helena',
  StKittsAndNevis = 'St_Kitts_and_Nevis',
  StLucia = 'St_Lucia',
  StPierreAndMiquelon = 'St_Pierre_and_Miquelon',
  StVincentAndTheGrenadines = 'St_Vincent_and_the_Grenadines',
  Sudan = 'Sudan',
  Suriname = 'Suriname',
  Swaziland = 'Swaziland',
  Sweden = 'Sweden',
  Switzerland = 'Switzerland',
  Syria = 'Syria',
  Taiwan = 'Taiwan',
  Tajikistan = 'Tajikistan',
  Tanzania = 'Tanzania',
  Thailand = 'Thailand',
  Togo = 'Togo',
  Tokelau = 'Tokelau',
  Tonga = 'Tonga',
  TrinidadAndTobago = 'Trinidad_and_Tobago',
  Tunisia = 'Tunisia',
  Turkey = 'Turkey',
  Turkmenistan = 'Turkmenistan',
  TurksAndCaicosIslands = 'Turks_and_Caicos_Islands',
  Tuvalu = 'Tuvalu',
  UsVirginIslands = 'US_Virgin_Islands',
  Uganda = 'Uganda',
  Ukraine = 'Ukraine',
  UnitedArabEmirates = 'United_Arab_Emirates',
  UnitedKingdom = 'United_Kingdom',
  UnitedStatesOfAmerica = 'United_States_of_America',
  Uruguay = 'Uruguay',
  Uzbekistan = 'Uzbekistan',
  Vanuatu = 'Vanuatu',
  Venezuela = 'Venezuela',
  Vietnam = 'Vietnam',
  WallisAndFutuna = 'Wallis_and_Futuna',
  Yemen = 'Yemen',
  Zambia = 'Zambia',
  Zimbabwe = 'Zimbabwe'
}

export type CreateAuditInput = {
  createdBy?: Maybe<Scalars['String']>;
};

export type CreatePermRoleAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  permId: Scalars['String'];
  roleId: Scalars['String'];
};

export enum CrossDayType {
  No = 'NO',
  ShiftStartTime = 'SHIFT_START_TIME',
  ShiftFirstHalfEndTime = 'SHIFT_FIRST_HALF_END_TIME',
  ShiftSecondHalfStartTime = 'SHIFT_SECOND_HALF_START_TIME',
  ShiftEndTime = 'SHIFT_END_TIME',
  ClockOutTime = 'CLOCK_OUT_TIME'
}

export type DmImported = {
  __typename?: 'DMImported';
  MigratedData_Bank?: Maybe<Scalars['Float']>;
  MigratedData_Company?: Maybe<Scalars['Float']>;
  MigratedData_DepartmentDivision?: Maybe<Scalars['Float']>;
  MigratedData_Employee?: Maybe<Scalars['Float']>;
  MigratedData_EmployeeDependent?: Maybe<Scalars['Float']>;
  MigratedData_EmployeeJobInfo?: Maybe<Scalars['Float']>;
  MigratedData_EmployeeSalary?: Maybe<Scalars['Float']>;
  MigratedData_EmployeeStatutoryInfo?: Maybe<Scalars['Float']>;
  MigratedData_FixedAllwDed?: Maybe<Scalars['Float']>;
  MigratedData_JobDesignation?: Maybe<Scalars['Float']>;
  MigratedData_JobGrade?: Maybe<Scalars['Float']>;
  MigratedData_PayItem?: Maybe<Scalars['Float']>;
  MigratedData_PayrollCutOff?: Maybe<Scalars['Float']>;
  MigratedData_PayrollCycle?: Maybe<Scalars['Float']>;
  MigratedData_PayrollHistory?: Maybe<Scalars['Float']>;
  MigratedData_RecruitmentSource?: Maybe<Scalars['Float']>;
};

export type DailyAllowanceInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  PayItemID: Scalars['String'];
  RoundingPolicyID?: Maybe<Scalars['String']>;
  Condition?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['String']>;
};

export type DailyAllowancePolicyEntity = InterfaceBase & {
  __typename?: 'DailyAllowancePolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DailyAllowanceID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Condition: Scalars['String'];
  Value: Scalars['String'];
  PayItem?: Maybe<PayItemEntity>;
  PayItemID?: Maybe<Scalars['String']>;
  RoundingPolicy?: Maybe<AllowanceDeductionRoundingEntity>;
  RoundingPolicyID?: Maybe<Scalars['String']>;
};


export enum DayType {
  NormalDay = 'NORMAL_DAY',
  OffDay = 'OFF_DAY',
  RestDay = 'REST_DAY',
  PublicHoliday = 'PUBLIC_HOLIDAY'
}

export type DeductionPolicyEntity = InterfaceBase & {
  __typename?: 'DeductionPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DeductionPolicyID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
  RoundingPolicyID?: Maybe<Scalars['String']>;
  Eligibility: Scalars['String'];
  Value: Scalars['String'];
  PayItemID: Scalars['String'];
  PayItem?: Maybe<PayItemEntity>;
  RoundingPolicy?: Maybe<AllowanceDeductionRoundingEntity>;
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
};

export type DeductionPolicyInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  DeductionPolicyID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  RoundingPolicyID?: Maybe<Scalars['String']>;
  Eligibility?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['String']>;
};

export type DepartmentEntity = InterfaceBase & {
  __typename?: 'DepartmentEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DepartmentID: Scalars['String'];
  ParentID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DepartmentType: DepartmentType;
  Company: CompanyEntity;
};

export type DepartmentInput = {
  ParentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  DepartmentType: DepartmentType;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum DepartmentType {
  Department = 'Department',
  Division = 'Division'
}

export type DepartmentUpdateInput = {
  DepartmentID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type DisplayReportTypeSequenceOutput = {
  __typename?: 'DisplayReportTypeSequenceOutput';
  CP22aReportSeq?: Maybe<Scalars['Float']>;
  CP21ReportSeq?: Maybe<Scalars['Float']>;
  EAFormReportSeq?: Maybe<Scalars['Float']>;
  LHDNReportID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EAForm?: Maybe<Scalars['String']>;
  CP21?: Maybe<Scalars['String']>;
  CP22a?: Maybe<Scalars['String']>;
  EAFormSetupID?: Maybe<Scalars['String']>;
  PayItemType?: Maybe<PayItemType>;
};

export type DocumentGrouping = {
  __typename?: 'DocumentGrouping';
  DocumentType: DocumentType;
  createdDT?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  modifiedDT?: Maybe<Scalars['String']>;
  modifiedBy?: Maybe<Scalars['String']>;
};

export type DocumentNumberAssignmentEntity = InterfaceBase & {
  __typename?: 'DocumentNumberAssignmentEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DocumentNumberAssignmentID: Scalars['String'];
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  RefTableName?: Maybe<Scalars['String']>;
  ProgramRef?: Maybe<Scalars['String']>;
  RefTableID?: Maybe<Scalars['String']>;
  EnableDocNum?: Maybe<Scalars['Boolean']>;
};

export type DocumentNumberAssignmentInput = {
  DocumentNumberAssignmentID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  RefTableName?: Maybe<Scalars['String']>;
  RefTableID?: Maybe<Scalars['String']>;
  ProgramRef?: Maybe<Scalars['String']>;
  EnableDocNum?: Maybe<Scalars['Boolean']>;
};

export type DocumentNumberDetailEntity = InterfaceBase & {
  __typename?: 'DocumentNumberDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DocumentNumberDetailID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  FormatType?: Maybe<Scalars['String']>;
  FormatValue?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  DocNumHeader?: Maybe<DocumentNumberHeaderEntity>;
};

export type DocumentNumberDetailInput = {
  DocumentNumberDetailID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  FormatType?: Maybe<Scalars['String']>;
  FormatValue?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
};

export type DocumentNumberHeaderEntity = InterfaceBase & {
  __typename?: 'DocumentNumberHeaderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DocumentNumberHeaderID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  SampleOutput?: Maybe<Scalars['String']>;
  DocNumDetail?: Maybe<Array<DocumentNumberDetailEntity>>;
};

export type DocumentNumberHeaderInput = {
  DocumentNumberDetailID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  FormatType?: Maybe<Scalars['String']>;
  FormatValue?: Maybe<Scalars['String']>;
};

export type DocumentNumberingInput = {
  DocumentNumberingHeaderID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  SampleOutput?: Maybe<Scalars['String']>;
  Format?: Maybe<Array<DocumentNumberDetailInput>>;
};

export enum DocumentType {
  Payslip = 'Payslip',
  EaForm = 'EA_FORM',
  TaxRelief = 'Tax_Relief',
  GeneralDoc = 'General_Doc',
  PersonalDoc = 'Personal_Doc',
  Claim = 'Claim',
  Leave = 'Leave',
  Employee = 'Employee',
  News = 'News',
  Requisition = 'Requisition',
  Company = 'Company'
}

export type DocumentsEntity = InterfaceBase & {
  __typename?: 'DocumentsEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DocumentID: Scalars['String'];
  DocumentType: DocumentType;
  Description?: Maybe<Scalars['String']>;
  DocumentFile?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  ParentID?: Maybe<Scalars['String']>;
  BucketFileName?: Maybe<Scalars['String']>;
  FileSize?: Maybe<Scalars['Float']>;
  Company: CompanyEntity;
};

export type DocumentsInput = {
  DocumentID?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<DocumentType>;
  Description?: Maybe<Scalars['String']>;
  DocumentFile?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Scalars['Upload']>;
  Status?: Maybe<Status>;
  ParentID?: Maybe<Scalars['String']>;
  BucketFileName?: Maybe<Scalars['String']>;
  FileSize?: Maybe<Scalars['Float']>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type DutyRosterEntity = InterfaceBase & {
  __typename?: 'DutyRosterEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  DutyRosterID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Date: Scalars['String'];
  Color?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  shift?: Maybe<ShiftEntity>;
  ShiftID?: Maybe<Scalars['String']>;
  shiftDay?: Maybe<ShiftDayEntity>;
  ShiftDayID?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  EmployeeID?: Maybe<Scalars['String']>;
  ShiftPattern?: Maybe<ShiftPatternEntity>;
  ShiftPatternID?: Maybe<Scalars['String']>;
  ShiftGroup?: Maybe<ShiftGroupEntity>;
  ShiftGroupID?: Maybe<Scalars['String']>;
};

export type DutyRosterInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  DutyRosterID?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  ShiftDayID?: Maybe<Scalars['String']>;
  Date?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  ShiftPatternID?: Maybe<Scalars['String']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
};

export type EaFormSetupEntity = InterfaceBase & {
  __typename?: 'EAFormSetupEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EAFormSetupID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  PayItemID?: Maybe<Scalars['String']>;
  EAForm?: Maybe<Scalars['String']>;
  CP21?: Maybe<Scalars['String']>;
  CP22?: Maybe<Scalars['String']>;
  CP22a?: Maybe<Scalars['String']>;
  IsShow?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  PayItem?: Maybe<PayItemEntity>;
};

export type EaFormSetupInput = {
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  PayItemID?: Maybe<Scalars['String']>;
  EAForm?: Maybe<Scalars['String']>;
  CP21?: Maybe<Scalars['String']>;
  CP22?: Maybe<Scalars['String']>;
  CP22a?: Maybe<Scalars['String']>;
  IsShow?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EaPartFEntity = InterfaceBase & {
  __typename?: 'EAPartFEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EAPartFID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  LimitAmt?: Maybe<Scalars['Float']>;
  ReportYear?: Maybe<Scalars['Float']>;
};

export type EaPartFInput = {
  Description?: Maybe<Scalars['String']>;
  LimitAmt?: Maybe<Scalars['Float']>;
  ReportYear?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EisCalculationInput = {
  EmployeeID: Scalars['String'];
  Age: Scalars['Float'];
  EISChargableAmount: Scalars['Float'];
};

export type EisCalculationOutput = {
  __typename?: 'EISCalculationOutput';
  EmployeeID: Scalars['String'];
  EISAmount: Scalars['Float'];
  EISRequirement?: Maybe<Scalars['String']>;
  EmployeeEISAmount?: Maybe<Scalars['Float']>;
  EmployerEISAmount?: Maybe<Scalars['Float']>;
  FromAmount?: Maybe<Scalars['Float']>;
  ToAmount?: Maybe<Scalars['Float']>;
};

export type EisPolicyEntity = InterfaceBase & {
  __typename?: 'EISPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EISPolicyID: Scalars['String'];
  PolicyCode: Scalars['String'];
  NationalityType?: Maybe<NationalityType>;
  AgeFrom?: Maybe<Scalars['Float']>;
  AgeTo?: Maybe<Scalars['Float']>;
  Formula: Scalars['String'];
  Remarks: Scalars['String'];
};

export type EisPolicyInput = {
  PolicyCode?: Maybe<Scalars['String']>;
  NationalityType?: Maybe<NationalityType>;
  AgeFrom?: Maybe<Scalars['Float']>;
  AgeTo?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EisRefEntity = InterfaceBase & {
  __typename?: 'EISRefEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EISRefID: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  FromAmount: Scalars['Float'];
  ToAmount: Scalars['Float'];
  CmpAmount: Scalars['Float'];
  EmpAmount: Scalars['Float'];
};

export type EisRefInput = {
  EISRefID?: Maybe<Scalars['String']>;
  EffectiveDate: Scalars['DateTime'];
  FromAmount: Scalars['Float'];
  ToAmount: Scalars['Float'];
  CmpAmount?: Maybe<Scalars['Float']>;
  EmpAmount?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EpfCalculationInput = {
  EmployeeID: Scalars['String'];
  NationalityType?: Maybe<NationalityType>;
  Age: Scalars['Float'];
  EPFChargableAmount: Scalars['Float'];
  CurrentMonthEPFBonusChargableAmount?: Maybe<Scalars['Float']>;
  EPFNormalRemunerationChargableAmount?: Maybe<Scalars['Float']>;
  AccumulatedEPFBonusChargableAmount?: Maybe<Scalars['Float']>;
};

export type EpfCalculationInputV2 = {
  EmployeeID: Scalars['String'];
  NationalityType?: Maybe<NationalityType>;
  Age: Scalars['Float'];
  BasicSalary: Scalars['Float'];
  NormalRemuneration: Scalars['Float'];
  AdditionalRemuneration?: Maybe<Scalars['Float']>;
  EPFChargableAmount: Scalars['Float'];
  EmployerEPFVERate?: Maybe<Scalars['Float']>;
  EmployeeEPFVERate?: Maybe<Scalars['Float']>;
};

export type EpfCalculationOutput = {
  __typename?: 'EPFCalculationOutput';
  EmployeeID: Scalars['String'];
  EPFAmount: Scalars['Float'];
  EPFRoundingAmount: Scalars['Float'];
  EmployeeEPFAmount?: Maybe<Scalars['Float']>;
  EmployerEPFAmount?: Maybe<Scalars['Float']>;
  EmployeeRoundingEPFAmount?: Maybe<Scalars['Float']>;
  EmployerRoundingEPFAmount?: Maybe<Scalars['Float']>;
  EmployeeEPFVEAmount?: Maybe<Scalars['Float']>;
  EmployerEPFVEAmount?: Maybe<Scalars['Float']>;
  EmployeeRoundingEPFVEAmount?: Maybe<Scalars['Float']>;
  EmployerRoundingEPFVEAmount?: Maybe<Scalars['Float']>;
  EmployeeEPFTotalAmount?: Maybe<Scalars['Float']>;
  EmployerEPFTotalAmount?: Maybe<Scalars['Float']>;
  EmployeeRoundingEPFTotalAmount?: Maybe<Scalars['Float']>;
  EmployerRoundingEPFTotalAmount?: Maybe<Scalars['Float']>;
  EmployerRate: Scalars['Float'];
  EmployeeRate: Scalars['Float'];
  EmployerVERate: Scalars['Float'];
  EmployeeVERate: Scalars['Float'];
  TotalEmployerRate: Scalars['Float'];
  TotalEmployeeRate: Scalars['Float'];
};

export type EpfPolicyEntity = InterfaceBase & {
  __typename?: 'EPFPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EPFPolicyID: Scalars['String'];
  PolicyCode: Scalars['String'];
  NationalityType: Scalars['String'];
  Age: Scalars['Float'];
  EPFChargableAmount: Scalars['Float'];
  EmployerRate: Scalars['Float'];
  EmployeeRate: Scalars['Float'];
  Formula: Scalars['String'];
  Remarks?: Maybe<Scalars['String']>;
};

export type EpfPolicyInput = {
  EPFPolicyID?: Maybe<Scalars['String']>;
  PolicyCode?: Maybe<Scalars['String']>;
  NationalityType?: Maybe<NationalityType>;
  Age: Scalars['Float'];
  EPFChargableAmount: Scalars['Float'];
  EmployerRate: Scalars['Float'];
  EmployeeRate: Scalars['Float'];
  Formula?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EarnLeaveEntity = InterfaceBase & {
  __typename?: 'EarnLeaveEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EarnLeaveID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  LeaveTypeID?: Maybe<Scalars['String']>;
  SubLeaveTypeID: Scalars['String'];
  LeaveHeaderType: LeaveHeaderType;
  PeriodYear: Scalars['Float'];
  TotalDay: Scalars['Float'];
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  IsShortPeriodNotice?: Maybe<Scalars['Boolean']>;
  TotalCFTaken: Scalars['Float'];
  ApprovalStatus: ApprovalStatus;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Employee?: Maybe<EmployeeEntity>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  Document?: Maybe<DocumentsEntity>;
  Approver?: Maybe<EmployeeEntity>;
  LeaveHistory?: Maybe<Array<LeaveHeaderEntity>>;
};

export enum Education {
  No = 'No',
  Under_18YearsOld = 'Under_18_years_old',
  CertificateMarticulation = 'CertificateMarticulation',
  DiplomaDegree = 'DiplomaDegree'
}

export type EmmployeeListingInput = {
  CompanyID: Scalars['String'];
  Year?: Maybe<Scalars['Float']>;
};

export type EmpCalendarLeaveListing = {
  CompanyID?: Maybe<Scalars['String']>;
  LeaveDate?: Maybe<Scalars['DateTime']>;
};

export type EmpClaimBalance = {
  __typename?: 'EmpClaimBalance';
  ClaimItems?: Maybe<ClaimItemEntity>;
  Drafted?: Maybe<Scalars['Float']>;
  PendingSuperior?: Maybe<Scalars['Float']>;
  PendingFinance?: Maybe<Scalars['Float']>;
  PendingPayroll?: Maybe<Scalars['Float']>;
  Posted?: Maybe<Scalars['Float']>;
  Balance?: Maybe<Scalars['Float']>;
  OccurenceBalance?: Maybe<Scalars['Float']>;
  ErrorMessage?: Maybe<Scalars['String']>;
};

export type EmpClaimBalanceOutput = {
  __typename?: 'EmpClaimBalanceOutput';
  Employee?: Maybe<EmployeeEntity>;
  ClaimBalance?: Maybe<Array<EmpClaimBalance>>;
};

export type EmpClaimEntitlementByClaimTypeOutput = {
  __typename?: 'EmpClaimEntitlementByClaimTypeOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Summary?: Maybe<EntitlementSummary>;
};

export type EmpClaimEntitlementByEmployeeOutput = {
  __typename?: 'EmpClaimEntitlementByEmployeeOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Summarries?: Maybe<Array<EntitlementSummary>>;
};

export type EmpClaimEntitlementInput = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  AsToDate?: Maybe<Scalars['DateTime']>;
};

export type EmpClaimEntitlementOutput = {
  __typename?: 'EmpClaimEntitlementOutput';
  Employee?: Maybe<EmployeeEntity>;
  ClaimItems?: Maybe<Array<ClaimItemEntity>>;
};

export type EmpClaimTypeValidationOutput = {
  __typename?: 'EmpClaimTypeValidationOutput';
  ClaimTypeID?: Maybe<Scalars['String']>;
  ErrorMessage?: Maybe<Array<Scalars['String']>>;
};

export type EmpLeaveApplicationInput = {
  EmployeeID?: Maybe<Scalars['String']>;
  LeaveHeaderID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  SessionType?: Maybe<SessionType>;
  TotalDay?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Scalars['String']>;
  SaveAsType: ApprovalStatus;
};

export type EmpLeaveBalanceInput = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  AsToDate?: Maybe<Scalars['DateTime']>;
};

export type EmpLeaveBalanceOuput = {
  __typename?: 'EmpLeaveBalanceOuput';
  Employee?: Maybe<EmployeeEntity>;
  LeaveTypeBalance?: Maybe<Array<LeaveTypeBalance>>;
};

export type EmpLeaveDetailInput = {
  AsToDate?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  LeaveHeaderID?: Maybe<Scalars['String']>;
  SubscriptionUserID?: Maybe<Scalars['String']>;
};

export type EmpLeaveDetailOuput = {
  __typename?: 'EmpLeaveDetailOuput';
  LeaveItems?: Maybe<Array<LeaveItemEntity>>;
  Employee?: Maybe<EmployeeEntity>;
  LeaveHeader?: Maybe<LeaveHeaderEntity>;
};

export type EmpLeaveEntitlementOuput = {
  __typename?: 'EmpLeaveEntitlementOuput';
  Employee?: Maybe<EmployeeEntity>;
  LOSByMonth?: Maybe<Scalars['Float']>;
  LOSByDay?: Maybe<Scalars['Float']>;
  EmpLeaveItems?: Maybe<Array<EmpLeaveItemEntitlement>>;
};

export type EmpLeaveItemEntitlement = {
  __typename?: 'EmpLeaveItemEntitlement';
  LeaveItem?: Maybe<LeaveItemEntity>;
  LeaveBenefitRules?: Maybe<Array<LeaveBenefitRuleEntity>>;
  EntitlementDayByLOS?: Maybe<Scalars['Float']>;
};

export type EmpLeaveListingInput = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
  DateFrom?: Maybe<Scalars['DateTime']>;
  DateTo?: Maybe<Scalars['DateTime']>;
  LeaveTypeIDs?: Maybe<Array<Scalars['String']>>;
  ApprovalStatusList?: Maybe<Array<ApprovalStatus>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
};

export type EmpLeaveListingOutput = {
  __typename?: 'EmpLeaveListingOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  LeaveHeaderID?: Maybe<Scalars['String']>;
  LeaveItemName?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  TotalDay?: Maybe<Scalars['Float']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};

export type EmpLeaveTypeValidationOutput = {
  __typename?: 'EmpLeaveTypeValidationOutput';
  LeaveTypeID?: Maybe<Scalars['String']>;
  ErrorMessage?: Maybe<Array<Scalars['String']>>;
};

export type EmpRequisitionInput = {
  AsToDate?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
  RequisitionHeaderID?: Maybe<Scalars['String']>;
  SubscriptionUserID?: Maybe<Scalars['String']>;
  RejectionRemarks?: Maybe<Scalars['String']>;
};

export type EmpSalary = {
  __typename?: 'EmpSalary';
  EmployeeID?: Maybe<Scalars['String']>;
  BasicSalary?: Maybe<Scalars['Float']>;
  StandardWages?: Maybe<Scalars['Float']>;
  PreStandardWages?: Maybe<Scalars['Float']>;
  EffectiveDate: Scalars['DateTime'];
  SalaryType?: Maybe<Scalars['String']>;
};

export type EmployeeAppLoginResponse = {
  __typename?: 'EmployeeAppLoginResponse';
  accessToken?: Maybe<Scalars['String']>;
  employee?: Maybe<EmployeeResponse>;
};

export type EmployeeAppraisalEntity = InterfaceBase & {
  __typename?: 'EmployeeAppraisalEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeAppraisalID: Scalars['String'];
  EmployeeID: Scalars['String'];
  AppraisalRecord: Scalars['String'];
  Date: Scalars['DateTime'];
  Rating?: Maybe<Scalars['Float']>;
  Score?: Maybe<Scalars['Float']>;
  AppraiseeRemark: Scalars['String'];
  AppraiserRemark: Scalars['String'];
  AppraiserName?: Maybe<Scalars['String']>;
  DocumentFile?: Maybe<Scalars['String']>;
  BucketFileName?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Documents?: Maybe<DocumentsEntity>;
};

export type EmployeeAppraisalInput = {
  EmployeeID: Scalars['String'];
  AppraisalRecord: Scalars['String'];
  Date: Scalars['DateTime'];
  Rating?: Maybe<Scalars['Float']>;
  Score?: Maybe<Scalars['Float']>;
  AppraiseeRemark: Scalars['String'];
  AppraiserRemark: Scalars['String'];
  AppraiserName?: Maybe<Scalars['String']>;
  DocumentFile?: Maybe<Scalars['String']>;
  BucketFileName?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeeAssetEntity = InterfaceBase & {
  __typename?: 'EmployeeAssetEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeAssetID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  DateAssign?: Maybe<Scalars['DateTime']>;
  Item?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
};

export type EmployeeAssetInput = {
  EmployeeAssetID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  DateAssign?: Maybe<Scalars['DateTime']>;
  Item?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeeBankInfoEntity = InterfaceBase & {
  __typename?: 'EmployeeBankInfoEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeBankInfoID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  Bank?: Maybe<Bank>;
  AcctNo?: Maybe<Scalars['String']>;
  PaymentMethod?: Maybe<PaymentMethod>;
  Employee?: Maybe<EmployeeEntity>;
};

export type EmployeeBankInfoInput = {
  EmployeeBankInfoID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Bank?: Maybe<Bank>;
  AcctNo?: Maybe<Scalars['String']>;
  PaymentMethod?: Maybe<PaymentMethod>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeeBenefitInKindEntity = InterfaceBase & {
  __typename?: 'EmployeeBenefitInKindEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeBenefitInKindID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  PaymentDetails?: Maybe<Scalars['String']>;
  GratuityFromDate?: Maybe<Scalars['DateTime']>;
  GratuityToDate?: Maybe<Scalars['DateTime']>;
  IncomeTypeA?: Maybe<Scalars['String']>;
  IncomeTypeB?: Maybe<Scalars['String']>;
  IncomeAmount?: Maybe<Scalars['Float']>;
  BIKDescription?: Maybe<Scalars['String']>;
  MotorcarDate?: Maybe<Scalars['DateTime']>;
  MotorcarYear?: Maybe<Scalars['Float']>;
  MotorcarType?: Maybe<Scalars['String']>;
  MotorcarModel?: Maybe<Scalars['String']>;
  MotorcarPetrol?: Maybe<Scalars['Float']>;
  MotorcarDriverProviderAmount?: Maybe<Scalars['Float']>;
  HouseholdAmount?: Maybe<Scalars['Float']>;
  TelephoneAmount?: Maybe<Scalars['Float']>;
  RecreationalAmount?: Maybe<Scalars['Float']>;
  GardenerAmount?: Maybe<Scalars['Float']>;
  HouseholdServantAmount?: Maybe<Scalars['Float']>;
  ValueOfLivingAddresss?: Maybe<Scalars['String']>;
  ValueOfLivingAmount?: Maybe<Scalars['Float']>;
  RefundUnapprovedFunds?: Maybe<Scalars['Float']>;
  CompensationEmployment?: Maybe<Scalars['Float']>;
  Employee?: Maybe<EmployeeEntity>;
};

export type EmployeeBenefitInKindInput = {
  EmployeeBenefitInKindID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  PaymentDetails?: Maybe<Scalars['String']>;
  GratuityFromDate?: Maybe<Scalars['DateTime']>;
  GratuityToDate?: Maybe<Scalars['DateTime']>;
  IncomeTypeA?: Maybe<Scalars['String']>;
  IncomeTypeB?: Maybe<Scalars['String']>;
  IncomeAmount?: Maybe<Scalars['Float']>;
  BIKDescription?: Maybe<Scalars['String']>;
  MotorcarDate?: Maybe<Scalars['DateTime']>;
  MotorcarYear?: Maybe<Scalars['Float']>;
  MotorcarType?: Maybe<Scalars['String']>;
  MotorcarModel?: Maybe<Scalars['String']>;
  MotorcarPetrol?: Maybe<Scalars['Float']>;
  MotorcarDriverProviderAmount?: Maybe<Scalars['Float']>;
  HouseholdAmount?: Maybe<Scalars['Float']>;
  TelephoneAmount?: Maybe<Scalars['Float']>;
  RecreationalAmount?: Maybe<Scalars['Float']>;
  GardenerAmount?: Maybe<Scalars['Float']>;
  HouseholdServantAmount?: Maybe<Scalars['Float']>;
  ValueOfLivingAddresss?: Maybe<Scalars['String']>;
  ValueOfLivingAmount?: Maybe<Scalars['Float']>;
  RefundUnapprovedFunds?: Maybe<Scalars['Float']>;
  CompensationEmployment?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeeBirthdaysOutput = {
  __typename?: 'EmployeeBirthdaysOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  BirthDate?: Maybe<Scalars['DateTime']>;
};

export type EmployeeBusinessInsightListing = {
  FilterType?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
  CompanyIDs?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};

export type EmployeeCp22Entity = InterfaceBase & {
  __typename?: 'EmployeeCP22Entity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeCP22ID: Scalars['String'];
  EmployeeID: Scalars['String'];
  LHDNReportID: Scalars['String'];
  Amount?: Maybe<Scalars['Float']>;
  FormStatus?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  LHDNReport?: Maybe<LhdnReportEntity>;
};

export type EmployeeCp22Input = {
  EmployeeCP22ID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  LHDNReportID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  FormStatus?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeeCp22Output = {
  __typename?: 'EmployeeCP22Output';
  EmployeeCP22ID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  LHDNReportID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ReportSequence?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
};

export type EmployeeCalendarOutput = {
  __typename?: 'EmployeeCalendarOutput';
  companyOffDays?: Maybe<Array<CompanyOffDayOutput>>;
  employeeBirthdays?: Maybe<Array<EmployeeBirthdaysOutput>>;
  employeeLeaves?: Maybe<Array<EmployeeLeavesOutput>>;
  employeeStaffLeaves?: Maybe<Array<EmployeeLeavesOutput>>;
};

export type EmployeeDependentsEntity = InterfaceBase & {
  __typename?: 'EmployeeDependentsEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeDependentsID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  Relationship?: Maybe<Relationship>;
  Education?: Maybe<Education>;
  ContactID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  IsWorking?: Maybe<Scalars['Boolean']>;
  IsOKU?: Maybe<Scalars['Boolean']>;
  ChildEligibleRate?: Maybe<Scalars['Float']>;
  Occupation?: Maybe<Scalars['String']>;
  IsEmergencyContact?: Maybe<Scalars['Boolean']>;
  PCBNo?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Contact?: Maybe<ContactEntity>;
};

export type EmployeeDependentsInput = {
  EmployeeDependentsID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Relationship?: Maybe<Relationship>;
  Education?: Maybe<Education>;
  ContactID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  IsWorking?: Maybe<Scalars['Boolean']>;
  IsOKU?: Maybe<Scalars['Boolean']>;
  ChildEligibleRate?: Maybe<Scalars['Float']>;
  Occupation?: Maybe<Scalars['String']>;
  IsEmergencyContact?: Maybe<Scalars['Boolean']>;
  PCBNo?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeeDiaryEntity = InterfaceBase & {
  __typename?: 'EmployeeDiaryEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeDiaryID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  Date: Scalars['DateTime'];
  CaseNo?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  Countermeasure?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  Documents?: Maybe<DocumentsEntity>;
};

export type EmployeeDiaryInput = {
  EmployeeID: Scalars['String'];
  EmployeeDiaryID?: Maybe<Scalars['String']>;
  Date: Scalars['DateTime'];
  CaseNo?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  Countermeasure?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  Document?: Maybe<DocumentsInput>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeeEntity = InterfaceBase & {
  __typename?: 'EmployeeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
  SubscriptionUserID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  DivisionID?: Maybe<Scalars['String']>;
  DesignationID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  RecuritmentSourceID?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<EmploymentType>;
  ReportTo?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  ConfirmedDate?: Maybe<Scalars['DateTime']>;
  ResignedDate?: Maybe<Scalars['DateTime']>;
  IsOKU?: Maybe<Scalars['Boolean']>;
  IsHOD?: Maybe<Scalars['Boolean']>;
  Probation?: Maybe<Scalars['Float']>;
  IsSuperUser?: Maybe<Scalars['Boolean']>;
  Password?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Contact?: Maybe<ContactEntity>;
  EmployeeStatutory?: Maybe<EmployeeStatutoryEntity>;
  EmployeePrevContribution?: Maybe<EmployeePrevContributionEntity>;
  EmployeeDependents?: Maybe<Array<EmployeeDependentsEntity>>;
  EmployeeCareerLogs?: Maybe<Array<CareerLogEntity>>;
  EmployeeSalaryLogs?: Maybe<Array<CareerLogEntity>>;
  EmployeeTaxReliefs?: Maybe<Array<EmployeeTaxReliefEntity>>;
  LeaveHeaders?: Maybe<Array<LeaveHeaderEntity>>;
  Designation?: Maybe<JobDesignationEntity>;
  JobGrade?: Maybe<JobGradeEntity>;
  FormStatus?: Maybe<FormStatus>;
  RecruitmentSource?: Maybe<RecruitmentSourceEntity>;
  EmployeeBankInfo?: Maybe<EmployeeBankInfoEntity>;
  EmployeeAssets?: Maybe<Array<EmployeeAssetEntity>>;
  EmployeeBenefitInKind?: Maybe<Array<EmployeeBenefitInKindEntity>>;
  Documents?: Maybe<DocumentsEntity>;
  Supervisor?: Maybe<EmployeeEntity>;
  Division?: Maybe<DepartmentEntity>;
  Department?: Maybe<DepartmentEntity>;
  EmployeeAppraisals?: Maybe<Array<EmployeeAppraisalEntity>>;
  EmployeeDiaries?: Maybe<Array<EmployeeDiaryEntity>>;
  EmployeeTrainings?: Maybe<Array<EmployeeTrainingEntity>>;
  EmployeeInsuranceSchemes?: Maybe<Array<EmployeeInsuranceSchemeEntity>>;
  changeShiftList?: Maybe<Array<ChangeShiftEntity>>;
  OvertimeList?: Maybe<Array<OvertimeEntity>>;
  LastestAccessedEntity?: Maybe<Scalars['String']>;
  EmployeeDepartment?: Maybe<DepartmentEntity>;
  GeneralDocCount: Scalars['Float'];
  GeneralDocTotalSize: Scalars['Float'];
  PersonalDocCount: Scalars['Float'];
  PersonalDocTotalSize: Scalars['Float'];
  PaySlipDocCount: Scalars['Float'];
  PaySlipDocTotalSize: Scalars['Float'];
  EAFormDocCount: Scalars['Float'];
  EAFormDocTotalSize: Scalars['Float'];
  TotalLeave: Scalars['Float'];
  TotalAnnualLeave: Scalars['Float'];
  TotalSickLeave: Scalars['Float'];
  TotalUnpaidLeave: Scalars['Float'];
  TotalClaim: Scalars['Float'];
  TotalClaimApproved: Scalars['Float'];
  TotalClaimAmountApproved: Scalars['Float'];
  TotalTaxRelief: Scalars['Float'];
  TotalRequisition: Scalars['Float'];
  TotalTravelRequest: Scalars['Float'];
  TotalPurchaseRequisition: Scalars['Float'];
  TotalCashAdvanceRequisition: Scalars['Float'];
  TotalOtherRequisition: Scalars['Float'];
  TotalSubordinate: Scalars['Float'];
  TotalUnreadNews: Scalars['Float'];
  LastUpdatedDates: LastUpdatedDatesOutput;
  MyTodo: Scalars['Float'];
  MyNotification: Scalars['Float'];
  EmployeeSubordinates: Array<EmployeeEntity>;
};

export type EmployeeFilterInput = {
  CompanyID: Scalars['String'];
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EmploymentTypes?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
};

export type EmployeeIndentity = {
  __typename?: 'EmployeeIndentity';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
};

export type EmployeeInput = {
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionUserID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ContactID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  DivisionID?: Maybe<Scalars['String']>;
  DesignationID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<EmploymentType>;
  RecuritmentSourceID?: Maybe<Scalars['String']>;
  ReportTo?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  FormStatus?: Maybe<FormStatus>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  ConfirmedDate?: Maybe<Scalars['DateTime']>;
  ResignedDate?: Maybe<Scalars['DateTime']>;
  Probation?: Maybe<Scalars['Float']>;
  IsOKU?: Maybe<Scalars['Boolean']>;
  IsHOD?: Maybe<Scalars['Boolean']>;
  IsSuperUser?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeeInsuranceSchemeEntity = InterfaceBase & {
  __typename?: 'EmployeeInsuranceSchemeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeInsuranceSchemeID: Scalars['String'];
  EmployeeID: Scalars['String'];
  InsuranceSchemeID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  Employee?: Maybe<EmployeeEntity>;
  InsuranceScheme?: Maybe<InsuranceSchemeEntity>;
};

export type EmployeeInsuranceSchemeInput = {
  EmployeeID: Scalars['String'];
  InsuranceSchemeID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeeLeavePolicy = {
  __typename?: 'EmployeeLeavePolicy';
  LeavePolicy?: Maybe<LeaveItemEntity>;
  LeaveBalanceSummary?: Maybe<LeaveBalanceSummary>;
};

export type EmployeeLeavesOutput = {
  __typename?: 'EmployeeLeavesOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  LeaveType?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  LeaveDate?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  IsOffDay?: Maybe<Scalars['Boolean']>;
  IsRestDay?: Maybe<Scalars['Boolean']>;
  IsPublicHoliday?: Maybe<Scalars['Boolean']>;
};

export type EmployeeLeavesSummary = {
  __typename?: 'EmployeeLeavesSummary';
  Employee?: Maybe<EmployeeEntity>;
  LOSByMonth?: Maybe<Scalars['Float']>;
  LOSByDay?: Maybe<Scalars['Float']>;
  EmployeeLeavePolicies?: Maybe<Array<EmployeeLeavePolicy>>;
};

export type EmployeeListOutput = {
  __typename?: 'EmployeeListOutput';
  EmployeeNo?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  Designation?: Maybe<Scalars['String']>;
};

export type EmployeeNotificationOutput = {
  __typename?: 'EmployeeNotificationOutput';
  NotificationID?: Maybe<Scalars['String']>;
  Topic?: Maybe<Scalars['String']>;
  Message?: Maybe<Scalars['String']>;
  RequestType?: Maybe<Scalars['String']>;
  NotificationType?: Maybe<Scalars['String']>;
  ReadStatus?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  LeaveDetails?: Maybe<LeaveDetails>;
  ClaimDetails?: Maybe<ClaimDetails>;
  RequisitionDetails?: Maybe<RequisitionDetails>;
};

export type EmployeePrevContributionEntity = InterfaceBase & {
  __typename?: 'EmployeePrevContributionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeePrevContributionID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  GrossTaxable?: Maybe<Scalars['Float']>;
  EPFPaid?: Maybe<Scalars['Float']>;
  PCBPaid?: Maybe<Scalars['Float']>;
  ZAKATPaid?: Maybe<Scalars['Float']>;
  Employee?: Maybe<EmployeeEntity>;
};

export type EmployeePrevContributionInput = {
  EmployeePrevContributionID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  PeriodYear: Scalars['Float'];
  GrossTaxable: Scalars['Float'];
  EPFPaid: Scalars['Float'];
  PCBPaid: Scalars['Float'];
  ZAKATPaid: Scalars['Float'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeeResponse = {
  __typename?: 'EmployeeResponse';
  subscriptionAccountID?: Maybe<Scalars['String']>;
  employeeID?: Maybe<Scalars['String']>;
  employeeNo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  mobileNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  LastestAccessedEntity?: Maybe<Scalars['String']>;
  SuperUserID?: Maybe<Scalars['String']>;
};

export enum EmployeeSalaryReason {
  AnnualReview = 'Annual_Review',
  ChangePosition = 'Change_Position',
  ConfirmationOfProbation = 'Confirmation_of_Probation',
  ContractRenewal = 'Contract_Renewal',
  Increment = 'Increment',
  InternalTransfer = 'Internal_Transfer',
  MarketAdjustment = 'Market_Adjustment',
  Others = 'Others',
  Promotion = 'Promotion',
  Redesignation = 'Redesignation',
  NewHire = 'New_Hire'
}

export type EmployeeStatutoryEntity = InterfaceBase & {
  __typename?: 'EmployeeStatutoryEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeStatutoryID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  IsEPF?: Maybe<Scalars['Boolean']>;
  EPFNo?: Maybe<Scalars['String']>;
  EPFVERate?: Maybe<Scalars['Float']>;
  EPFVERateCmp?: Maybe<Scalars['Float']>;
  IsSocso?: Maybe<Scalars['Boolean']>;
  SocsoNo?: Maybe<Scalars['String']>;
  IsPCB?: Maybe<Scalars['Boolean']>;
  PCBNo?: Maybe<Scalars['String']>;
  PCBBranch?: Maybe<Scalars['String']>;
  TaxMaritialStatus?: Maybe<TaxMaritialStatus>;
  ResidentStatus?: Maybe<ResidentStatus>;
  IsEIS?: Maybe<Scalars['Boolean']>;
  EISNo?: Maybe<Scalars['String']>;
  IsAngkasa?: Maybe<Scalars['Boolean']>;
  AngkasaNo?: Maybe<Scalars['String']>;
  IsASB?: Maybe<Scalars['Boolean']>;
  ASBNo?: Maybe<Scalars['String']>;
  IsKWAP?: Maybe<Scalars['Boolean']>;
  KWAPNo?: Maybe<Scalars['String']>;
  IsTBH?: Maybe<Scalars['Boolean']>;
  TBHNo?: Maybe<Scalars['String']>;
  IsZakat?: Maybe<Scalars['Boolean']>;
  ZakatNo?: Maybe<Scalars['String']>;
  ZakatState?: Maybe<Scalars['String']>;
  ZakatAmount?: Maybe<Scalars['Float']>;
  ZakatRate?: Maybe<Scalars['Float']>;
  IsHRDF?: Maybe<Scalars['Boolean']>;
  Employee?: Maybe<EmployeeEntity>;
};

export type EmployeeStatutoryInput = {
  EmployeeStatutoryID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  IsEPF?: Maybe<Scalars['Boolean']>;
  EPFNo?: Maybe<Scalars['String']>;
  EPFVERate?: Maybe<Scalars['Float']>;
  EPFVERateCmp?: Maybe<Scalars['Float']>;
  IsSocso?: Maybe<Scalars['Boolean']>;
  SocsoNo?: Maybe<Scalars['String']>;
  IsPCB?: Maybe<Scalars['Boolean']>;
  PCBNo?: Maybe<Scalars['String']>;
  PCBBranch?: Maybe<Scalars['String']>;
  TaxMaritialStatus?: Maybe<TaxMaritialStatus>;
  ResidentStatus?: Maybe<ResidentStatus>;
  IsEIS?: Maybe<Scalars['Boolean']>;
  EISNo?: Maybe<Scalars['String']>;
  IsAngkasa?: Maybe<Scalars['Boolean']>;
  AngkasaNo?: Maybe<Scalars['String']>;
  IsASB?: Maybe<Scalars['Boolean']>;
  ASBNo?: Maybe<Scalars['String']>;
  IsKWAP?: Maybe<Scalars['Boolean']>;
  KWAPNo?: Maybe<Scalars['String']>;
  IsTBH?: Maybe<Scalars['Boolean']>;
  TBHNo?: Maybe<Scalars['String']>;
  IsZakat?: Maybe<Scalars['Boolean']>;
  ZakatNo?: Maybe<Scalars['String']>;
  ZakatState?: Maybe<Scalars['String']>;
  ZakatAmount?: Maybe<Scalars['Float']>;
  ZakatRate?: Maybe<Scalars['Float']>;
  IsHRDF?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeeSummary = {
  __typename?: 'EmployeeSummary';
  TimeOff?: Maybe<Scalars['JSON']>;
  Overtime?: Maybe<Scalars['Float']>;
  ChangeShift?: Maybe<Scalars['Float']>;
  Roster?: Maybe<Scalars['Float']>;
  OT?: Maybe<Scalars['JSON']>;
  CheckInDate?: Maybe<Scalars['DateTime']>;
  CheckOutDate?: Maybe<Scalars['DateTime']>;
  Shift?: Maybe<Scalars['JSON']>;
  TimeOffDate?: Maybe<Scalars['DateTime']>;
  ChangeShiftDate?: Maybe<Scalars['DateTime']>;
  DutyRosterDate?: Maybe<Scalars['DateTime']>;
};

export type EmployeeTaxReliefEntity = InterfaceBase & {
  __typename?: 'EmployeeTaxReliefEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeTaxReliefID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  TaxReliefID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  ReliefAmount?: Maybe<Scalars['Float']>;
  Employee?: Maybe<EmployeeEntity>;
  PayPeriod?: Maybe<PayPeriodEntity>;
  TaxRelief?: Maybe<TaxReliefEntity>;
};

export type EmployeeTaxReliefInput = {
  EmployeeTaxReliefID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  TaxReliefID?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  ReliefAmount?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeeTrainingEntity = InterfaceBase & {
  __typename?: 'EmployeeTrainingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  EmployeeTrainingID: Scalars['String'];
  EmployeeID: Scalars['String'];
  CourseName: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  TrainingHour: Scalars['Float'];
  TrainingProvider: Scalars['String'];
  Trainer: Scalars['String'];
  TrainerRating?: Maybe<Scalars['Float']>;
  TrainerScore?: Maybe<Scalars['Float']>;
  Superior?: Maybe<Scalars['String']>;
  SuperiorRating?: Maybe<Scalars['Float']>;
  SuperiorScore?: Maybe<Scalars['Float']>;
  Employee?: Maybe<EmployeeEntity>;
};

export type EmployeeTrainingInput = {
  EmployeeID: Scalars['String'];
  CourseName: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  TrainingHour: Scalars['Float'];
  TrainingProvider: Scalars['String'];
  Trainer: Scalars['String'];
  TrainerRating?: Maybe<Scalars['Float']>;
  TrainerScore?: Maybe<Scalars['Float']>;
  Superior?: Maybe<Scalars['String']>;
  SuperiorRating?: Maybe<Scalars['Float']>;
  SuperiorScore?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type EmployeeTransactionOutput = {
  __typename?: 'EmployeeTransactionOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeFullName?: Maybe<Scalars['String']>;
  PayItems?: Maybe<Array<PayItemOutput>>;
  TotalAmount?: Maybe<Scalars['Float']>;
  TotalQuantity?: Maybe<Scalars['Float']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
};

export enum EmploymentType {
  Contract = 'Contract',
  Internship = 'Internship',
  Permanent = 'Permanent'
}

export type EntRoleUsrArgCountOutput = {
  __typename?: 'EntRoleUsrArgCountOutput';
  roleId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Float']>;
};

export type EntRoleUsrArgEntity = {
  __typename?: 'EntRoleUsrArgEntity';
  id: Scalars['String'];
  entId: Scalars['String'];
  roleId: Scalars['String'];
  accId: Scalars['String'];
  usrId?: Maybe<Scalars['String']>;
  createdTs?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  modTs?: Maybe<Scalars['DateTime']>;
  modBy?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Role?: Maybe<RoleEntity>;
};

export type EntRoleUsrArgInput = {
  id?: Maybe<Scalars['String']>;
  entId?: Maybe<Scalars['String']>;
  roleId?: Maybe<Scalars['String']>;
  accId?: Maybe<Scalars['String']>;
  usrId?: Maybe<Scalars['String']>;
  createdTs?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  modTs?: Maybe<Scalars['DateTime']>;
  modBy?: Maybe<Scalars['String']>;
};

export enum EntitlementBasedOn {
  DateJoin = 'Date_Join',
  JoinReferenceDate = 'Join_Reference_Date'
}

export type EntitlementSummary = {
  __typename?: 'EntitlementSummary';
  ClaimType?: Maybe<Scalars['String']>;
  ClaimFrequency?: Maybe<Scalars['String']>;
  Entitled?: Maybe<Scalars['Float']>;
  Claimed?: Maybe<Scalars['Float']>;
  Balance?: Maybe<Scalars['Float']>;
};

export type EntityRoleUserAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  companyID: Scalars['String'];
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type ExcelPayItemTransactionOutput = {
  __typename?: 'ExcelPayItemTransactionOutput';
  ExcelFileName?: Maybe<Scalars['String']>;
  PayPeriod?: Maybe<Scalars['DateTime']>;
  PayrollCycle?: Maybe<Scalars['String']>;
  TotalEarning?: Maybe<Scalars['Float']>;
  TotalDeduction?: Maybe<Scalars['Float']>;
  TotalNonPayable?: Maybe<Scalars['Float']>;
  UploadedDate?: Maybe<Scalars['DateTime']>;
};

export enum ExitReason {
  Resigned = 'Resigned',
  Terminated = 'Terminated',
  Deceased = 'Deceased',
  Retired = 'Retired',
  Inactive = 'Inactive',
  Illness = 'Illness',
  Absconded = 'Absconded',
  DepartureFromMalaysia = 'DepartureFromMalaysia'
}

export type FieldError = {
  __typename?: 'FieldError';
  field: Scalars['String'];
  message: Scalars['String'];
};

export type FinalClaimApprovalUpdateInput = {
  FinanceApproveDT?: Maybe<Scalars['DateTime']>;
  FinanceApproveBy?: Maybe<Scalars['String']>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum FormStatus {
  Completed = 'Completed',
  Draft = 'Draft'
}

export type FreezePayrollEntity = InterfaceBase & {
  __typename?: 'FreezePayrollEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  FreezePayrollID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  Employees?: Maybe<EmployeeEntity>;
};

export type FreezePayrollInput = {
  FreezePayrollID?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum Gender {
  Male = 'Male',
  Female = 'Female'
}

export type GeneralSettingsLastUpdatedOutput = {
  __typename?: 'GeneralSettingsLastUpdatedOutput';
  RoleSettingsLastPostedDate?: Maybe<Scalars['DateTime']>;
  RoleSettingsIsLastUpdated?: Maybe<Scalars['Boolean']>;
  PayrollSettingsLastPostedDate?: Maybe<Scalars['DateTime']>;
  PayrollSettingsIsLastUpdated?: Maybe<Scalars['Boolean']>;
  LeaveSettingsLastPostedDate?: Maybe<Scalars['DateTime']>;
  LeaveSettingsIsLastUpddated?: Maybe<Scalars['Boolean']>;
  ClaimSettingsLastPostedDate?: Maybe<Scalars['DateTime']>;
  ClaimSettingsIsLastUpddated?: Maybe<Scalars['Boolean']>;
  JobGradeLastPostedDate?: Maybe<Scalars['DateTime']>;
  JobGradeIsLastUpddated?: Maybe<Scalars['Boolean']>;
  JobGradeNoOfActiveRecords?: Maybe<Scalars['Float']>;
  JobDesignationLastPostedDate?: Maybe<Scalars['DateTime']>;
  JobDesignationIsLastUpddated?: Maybe<Scalars['Boolean']>;
  JobDesignationNoOfActiveRecords?: Maybe<Scalars['Float']>;
  RecruitmentSourceCount?: Maybe<Scalars['Float']>;
  RecruitmentSourceIsLastUpddated?: Maybe<Scalars['Boolean']>;
  PayrollItemLastPostedDate?: Maybe<Scalars['DateTime']>;
  PayrollItemIsLastUpddated?: Maybe<Scalars['Boolean']>;
  PayrollItemNoOfActiveRecords?: Maybe<Scalars['Float']>;
  LHDNReportsNoOfActiveRecords?: Maybe<Scalars['Float']>;
  CompanylastUpdate?: Maybe<Scalars['Boolean']>;
  CompanylastPosted?: Maybe<Scalars['DateTime']>;
  NewslastUpdate?: Maybe<Scalars['Boolean']>;
  NewslastPosted?: Maybe<Scalars['DateTime']>;
};

export type GenerateClaimCutOffPeriodInput = {
  CompanyID?: Maybe<Scalars['String']>;
  ClaimCutOffDay?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  SubscriptionUserID?: Maybe<Scalars['String']>;
};

export type GenerateLeaveCutOffPeriodInput = {
  CompanyID?: Maybe<Scalars['String']>;
  LeaveCutOffDay?: Maybe<Scalars['Float']>;
  Year?: Maybe<Scalars['Float']>;
  SubscriptionUserID?: Maybe<Scalars['String']>;
};

export type GetApprovedDetailInput = {
  ClaimHeaderID?: Maybe<Scalars['String']>;
};

export type GetApprovedDetailOutput = {
  __typename?: 'GetApprovedDetailOutput';
  Date?: Maybe<Scalars['String']>;
  ApprovedBySuperior?: Maybe<Scalars['String']>;
  ApprovedAmt?: Maybe<Scalars['Float']>;
};

export type GetCompletedDetailInput = {
  ClaimHeaderID?: Maybe<Scalars['String']>;
};

export type GetCompletedDetailOutput = {
  __typename?: 'GetCompletedDetailOutput';
  ClaimType?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['String']>;
  ClaimApprovedAmount?: Maybe<Scalars['Float']>;
  ClaimRemark?: Maybe<Scalars['String']>;
  JobCode?: Maybe<Scalars['String']>;
  ClaimLimit?: Maybe<Scalars['String']>;
  ClaimStatus?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
};

export type GetEmpClaimListingInput = {
  EmployeeID?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['Float']>;
};

export type GetEmpClaimListingOutput = {
  __typename?: 'GetEmpClaimListingOutput';
  Subject?: Maybe<Scalars['String']>;
  SummaryTotalAmount?: Maybe<Scalars['Float']>;
  TotalClaimItem?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  ClaimStatus?: Maybe<Scalars['String']>;
  ClaimDate?: Maybe<Scalars['String']>;
};

export type GetMainClaimListingOutput = {
  __typename?: 'GetMainClaimListingOutput';
  Year?: Maybe<Scalars['Float']>;
  TotalClaimApproved?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
};

export type GroupCountEmployeeRoleListingOutput = {
  __typename?: 'GroupCountEmployeeRoleListingOutput';
  RecruitmentSourceID?: Maybe<Scalars['String']>;
  totalEmployees?: Maybe<Scalars['Float']>;
};

export type HrdfCalculationInput = {
  EmployeeID: Scalars['String'];
  HRDFChargableAmount: Scalars['Float'];
  HRDFRate: Scalars['Float'];
};

export type HrdfCalculationOutput = {
  __typename?: 'HRDFCalculationOutput';
  EmployeeID: Scalars['String'];
  EmployerHRDFAmount?: Maybe<Scalars['Float']>;
  HRDFRate: Scalars['Float'];
};

export type HolidayEntity = InterfaceBase & {
  __typename?: 'HolidayEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  HolidayID: Scalars['String'];
  RestOffType: RestOffType;
  HolidayDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  States?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  CompanyOffDays?: Maybe<Array<CompanyOffDayEntity>>;
};

export type HolidayInput = {
  HolidayID?: Maybe<Scalars['String']>;
  RestOffType?: Maybe<RestOffType>;
  HolidayDate?: Maybe<Scalars['DateTime']>;
  Description?: Maybe<Scalars['String']>;
  States?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type HomeCalendarOutput = {
  __typename?: 'HomeCalendarOutput';
  currentEmployeeID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  LeaveTypeHeader?: Maybe<Scalars['String']>;
  LeaveDate?: Maybe<Scalars['DateTime']>;
  UntillDate?: Maybe<Scalars['DateTime']>;
  BirthDate?: Maybe<Scalars['DateTime']>;
  myBirthDate?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  TotalDay?: Maybe<Scalars['Float']>;
  leaveType?: Maybe<Scalars['String']>;
  HolidayDescription?: Maybe<Scalars['String']>;
  publicHolidayOffDayDate?: Maybe<Scalars['DateTime']>;
};

export enum HrPermission {
  HomePersonnelExecSummaryRead = 'HOME_PERSONNEL_EXEC_SUMMARY____READ',
  HomePersonnelEmployeeCreate = 'HOME_PERSONNEL_EMPLOYEE____CREATE',
  HomePersonnelEmployeeRead = 'HOME_PERSONNEL_EMPLOYEE____READ',
  HomePersonnelEmployeeInfoRead = 'HOME_PERSONNEL_EMPLOYEE_INFO____READ',
  HomePersonnelEmployeeInfoPersonalInfoRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_PERSONAL_INFO_READ',
  HomePersonnelEmployeeInfoPersonalInfoUpdate = 'HOME_PERSONNEL_EMPLOYEE_INFO_PERSONAL_INFO_UPDATE',
  HomePersonnelEmployeeInfoJobInfoRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_JOB_INFO_READ',
  HomePersonnelEmployeeInfoJobInfoUpdate = 'HOME_PERSONNEL_EMPLOYEE_INFO_JOB_INFO_UPDATE',
  HomePersonnelEmployeeInfoStatutoryInfoRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_STATUTORY_INFO_READ',
  HomePersonnelEmployeeInfoStatutoryInfoUpdate = 'HOME_PERSONNEL_EMPLOYEE_INFO_STATUTORY_INFO_UPDATE',
  HomePersonnelEmployeeInfoTp3Read = 'HOME_PERSONNEL_EMPLOYEE_INFO_TP3_READ',
  HomePersonnelEmployeeInfoTp3Update = 'HOME_PERSONNEL_EMPLOYEE_INFO_TP3_UPDATE',
  HomePersonnelEmployeeInfoDependentRead = 'HOME_PERSONNEL_EMPLOYEE_INFO_DEPENDENT_READ',
  HomePersonnelEmployeeInfoDependentUpdate = 'HOME_PERSONNEL_EMPLOYEE_INFO_DEPENDENT_UPDATE',
  HomePersonnelEmployeeInfoDependentDelete = 'HOME_PERSONNEL_EMPLOYEE_INFO_DEPENDENT_DELETE',
  HomePersonnelAssetAssignedCreate = 'HOME_PERSONNEL_ASSET_ASSIGNED____CREATE',
  HomePersonnelAssetAssignedRead = 'HOME_PERSONNEL_ASSET_ASSIGNED____READ',
  HomePersonnelAssetAssignedUpdate = 'HOME_PERSONNEL_ASSET_ASSIGNED____UPDATE',
  HomePersonnelAssetAssignedDelete = 'HOME_PERSONNEL_ASSET_ASSIGNED____DELETE',
  HomePersonnelCareerProgressionCreate = 'HOME_PERSONNEL_CAREER_PROGRESSION____CREATE',
  HomePersonnelCareerProgressionRead = 'HOME_PERSONNEL_CAREER_PROGRESSION____READ',
  HomePersonnelCareerProgressionUpdate = 'HOME_PERSONNEL_CAREER_PROGRESSION____UPDATE',
  HomePersonnelSubordinateRead = 'HOME_PERSONNEL_SUBORDINATE____READ',
  HomePersonnelDigitalDocGeneralDocRead = 'HOME_PERSONNEL_DIGITAL_DOC_GENERAL_DOC_READ',
  HomePersonnelDigitalDocPersonalDocCreate = 'HOME_PERSONNEL_DIGITAL_DOC_PERSONAL_DOC_CREATE',
  HomePersonnelDigitalDocPersonalDocRead = 'HOME_PERSONNEL_DIGITAL_DOC_PERSONAL_DOC_READ',
  HomePersonnelDigitalDocPersonalDocDelete = 'HOME_PERSONNEL_DIGITAL_DOC_PERSONAL_DOC_DELETE',
  HomePersonnelDigitalDocPayslipRead = 'HOME_PERSONNEL_DIGITAL_DOC_PAYSLIP_READ',
  HomePersonnelDigitalDocEaformRead = 'HOME_PERSONNEL_DIGITAL_DOC_EAFORM_READ',
  HomePayrollExecSummaryRead = 'HOME_PAYROLL_EXEC_SUMMARY____READ',
  HomePayrollEmployeeSalaryRead = 'HOME_PAYROLL_EMPLOYEE_SALARY____READ',
  HomePayrollEmployeeSalaryCreate = 'HOME_PAYROLL_EMPLOYEE_SALARY____CREATE',
  HomePayrollEmployeeSalaryUpdate = 'HOME_PAYROLL_EMPLOYEE_SALARY____UPDATE',
  HomePayrollBikRead = 'HOME_PAYROLL_BIK____READ',
  HomePayrollBikUpdate = 'HOME_PAYROLL_BIK____UPDATE',
  HomePayrollTaxReliefCreate = 'HOME_PAYROLL_TAX_RELIEF____CREATE',
  HomePayrollTaxReliefRead = 'HOME_PAYROLL_TAX_RELIEF____READ',
  HomePayrollTaxReliefUpdate = 'HOME_PAYROLL_TAX_RELIEF____UPDATE',
  HomePayrollFixedAllowanceDeductionRead = 'HOME_PAYROLL_FIXED_ALLOWANCE_DEDUCTION____READ',
  HomePayrollFixedAllowanceDeductionManualEntryCreate = 'HOME_PAYROLL_FIXED_ALLOWANCE_DEDUCTION_MANUAL_ENTRY_CREATE',
  HomePayrollFixedAllowanceDeductionManualEntryRead = 'HOME_PAYROLL_FIXED_ALLOWANCE_DEDUCTION_MANUAL_ENTRY_READ',
  HomePayrollFixedAllowanceDeductionManualEntryUpdate = 'HOME_PAYROLL_FIXED_ALLOWANCE_DEDUCTION_MANUAL_ENTRY_UPDATE',
  HomePayrollFixedAllowanceDeductionManualEntryDelete = 'HOME_PAYROLL_FIXED_ALLOWANCE_DEDUCTION_MANUAL_ENTRY_DELETE',
  HomePayrollFixedAllowanceDeductionExcelUploadRead = 'HOME_PAYROLL_FIXED_ALLOWANCE_DEDUCTION_EXCEL_UPLOAD_READ',
  HomePayrollFixedAllowanceDeductionExcelUploadUpload = 'HOME_PAYROLL_FIXED_ALLOWANCE_DEDUCTION_EXCEL_UPLOAD_UPLOAD',
  HomePayrollFixedAllowanceDeductionExcelUploadDownload = 'HOME_PAYROLL_FIXED_ALLOWANCE_DEDUCTION_EXCEL_UPLOAD_DOWNLOAD',
  HomePayrollFixedAllowanceDeductionExportDataDownload = 'HOME_PAYROLL_FIXED_ALLOWANCE_DEDUCTION_EXPORT_DATA_DOWNLOAD',
  HomePayrollMonthlyTrxRead = 'HOME_PAYROLL_MONTHLY_TRX____READ',
  HomePayrollMonthlyTrxByEmployeeCreate = 'HOME_PAYROLL_MONTHLY_TRX_BY_EMPLOYEE_CREATE',
  HomePayrollMonthlyTrxByEmployeeRead = 'HOME_PAYROLL_MONTHLY_TRX_BY_EMPLOYEE_READ',
  HomePayrollMonthlyTrxByEmployeeUpdate = 'HOME_PAYROLL_MONTHLY_TRX_BY_EMPLOYEE_UPDATE',
  HomePayrollMonthlyTrxByEmployeeDelete = 'HOME_PAYROLL_MONTHLY_TRX_BY_EMPLOYEE_DELETE',
  HomePayrollMonthlyTrxByPayitemCreate = 'HOME_PAYROLL_MONTHLY_TRX_BY_PAYITEM_CREATE',
  HomePayrollMonthlyTrxByPayitemRead = 'HOME_PAYROLL_MONTHLY_TRX_BY_PAYITEM_READ',
  HomePayrollMonthlyTrxByPayitemUpdate = 'HOME_PAYROLL_MONTHLY_TRX_BY_PAYITEM_UPDATE',
  HomePayrollMonthlyTrxByPayitemDelete = 'HOME_PAYROLL_MONTHLY_TRX_BY_PAYITEM_DELETE',
  HomePayrollMonthlyTrxExcelUploadRead = 'HOME_PAYROLL_MONTHLY_TRX_EXCEL_UPLOAD_READ',
  HomePayrollMonthlyTrxExcelUploadUpload = 'HOME_PAYROLL_MONTHLY_TRX_EXCEL_UPLOAD_UPLOAD',
  HomePayrollMonthlyTrxExcelUploadDownload = 'HOME_PAYROLL_MONTHLY_TRX_EXCEL_UPLOAD_DOWNLOAD',
  HomePayrollMonthlyTrxExportDataDownload = 'HOME_PAYROLL_MONTHLY_TRX_EXPORT_DATA_DOWNLOAD',
  HomePayrollFreezePayrollRead = 'HOME_PAYROLL_FREEZE_PAYROLL____READ',
  HomePayrollFreezePayrollUpdate = 'HOME_PAYROLL_FREEZE_PAYROLL____UPDATE',
  HomePayrollFreezePayrollDelete = 'HOME_PAYROLL_FREEZE_PAYROLL____DELETE',
  HomePayrollPayrollProcessRead = 'HOME_PAYROLL_PAYROLL_PROCESS____READ',
  HomePayrollPayrollProcessProcess = 'HOME_PAYROLL_PAYROLL_PROCESS____PROCESS',
  HomePayrollPayrollClosingRead = 'HOME_PAYROLL_PAYROLL_CLOSING____READ',
  HomePayrollPayrollClosingLock = 'HOME_PAYROLL_PAYROLL_CLOSING____LOCK',
  HomePayrollReadPayrollHistoryRead = 'HOME_PAYROLL_READ_PAYROLL_HISTORY____READ',
  HomePayrollPublishPayslipRead = 'HOME_PAYROLL_PUBLISH_PAYSLIP____READ',
  HomePayrollPublishPayslipPublish = 'HOME_PAYROLL_PUBLISH_PAYSLIP____PUBLISH',
  HomePayrollPublishEaformRead = 'HOME_PAYROLL_PUBLISH_EAFORM____READ',
  HomePayrollPublishEaformPublish = 'HOME_PAYROLL_PUBLISH_EAFORM____PUBLISH',
  HomePayrollCp159ManagementRead = 'HOME_PAYROLL_CP159_MANAGEMENT____READ',
  HomePayrollCp159ManagementUpdate = 'HOME_PAYROLL_CP159_MANAGEMENT____UPDATE',
  GeneralSettingPayrollRead = 'GENERAL_SETTING_PAYROLL_______READ',
  GeneralSettingPayrollCutoffDateUpdate = 'GENERAL_SETTING_PAYROLL_CUTOFF_DATE____UPDATE',
  GeneralSettingPayrollPayrollCycleCreate = 'GENERAL_SETTING_PAYROLL_PAYROLL_CYCLE____CREATE',
  GeneralSettingPayrollPayrollCycleRead = 'GENERAL_SETTING_PAYROLL_PAYROLL_CYCLE____READ',
  GeneralSettingPayrollPayrollCycleUpdate = 'GENERAL_SETTING_PAYROLL_PAYROLL_CYCLE____UPDATE',
  GeneralSettingPayrollPayrollCycleDelete = 'GENERAL_SETTING_PAYROLL_PAYROLL_CYCLE____DELETE',
  GeneralSettingPayrollBankCreate = 'GENERAL_SETTING_PAYROLL_BANK____CREATE',
  GeneralSettingPayrollBankRead = 'GENERAL_SETTING_PAYROLL_BANK____READ',
  GeneralSettingPayrollBankUpdate = 'GENERAL_SETTING_PAYROLL_BANK____UPDATE',
  GeneralSettingPayrollBankDelete = 'GENERAL_SETTING_PAYROLL_BANK____DELETE',
  GeneralSettingPayrollBankInterbankCreate = 'GENERAL_SETTING_PAYROLL_BANK_INTERBANK_CREATE',
  GeneralSettingPayrollBankInterbankRead = 'GENERAL_SETTING_PAYROLL_BANK_INTERBANK_READ',
  GeneralSettingPayrollBankInterbankUpdate = 'GENERAL_SETTING_PAYROLL_BANK_INTERBANK_UPDATE',
  GeneralSettingPayrollBankInterbankDelete = 'GENERAL_SETTING_PAYROLL_BANK_INTERBANK_DELETE',
  GeneralSettingPayrollStatutorySignatoryUpdate = 'GENERAL_SETTING_PAYROLL_STATUTORY_SIGNATORY____UPDATE',
  HomeLeaveExecSummaryRead = 'HOME_LEAVE_EXEC_SUMMARY____READ',
  HomeLeaveOutOfStateCreate = 'HOME_LEAVE_OUT_OF_STATE____CREATE',
  HomeLeaveOutOfStateRead = 'HOME_LEAVE_OUT_OF_STATE____READ',
  HomeLeaveOutOfStateUpdate = 'HOME_LEAVE_OUT_OF_STATE____UPDATE',
  HomeLeaveOutOfStateDelete = 'HOME_LEAVE_OUT_OF_STATE____DELETE',
  HomeLeaveApplicationCreate = 'HOME_LEAVE_APPLICATION____CREATE',
  HomeLeaveApplicationRead = 'HOME_LEAVE_APPLICATION____READ',
  HomeLeaveApplicationUpdate = 'HOME_LEAVE_APPLICATION____UPDATE',
  HomeLeaveApplicationDelete = 'HOME_LEAVE_APPLICATION____DELETE',
  HomeLeaveApplicationApprove = 'HOME_LEAVE_APPLICATION____APPROVE',
  HomeLeaveApplicationReject = 'HOME_LEAVE_APPLICATION____REJECT',
  HomeLeaveAdjustmentCreate = 'HOME_LEAVE_ADJUSTMENT____CREATE',
  HomeLeaveAdjustmentRead = 'HOME_LEAVE_ADJUSTMENT____READ',
  HomeLeaveAdjustmentUpdate = 'HOME_LEAVE_ADJUSTMENT____UPDATE',
  HomeLeaveAdjustmentDelete = 'HOME_LEAVE_ADJUSTMENT____DELETE',
  HomeLeaveCompulsoryLeaveCreate = 'HOME_LEAVE_COMPULSORY_LEAVE____CREATE',
  HomeLeaveCompulsoryLeaveRead = 'HOME_LEAVE_COMPULSORY_LEAVE____READ',
  HomeLeavePostingRead = 'HOME_LEAVE_POSTING____READ',
  HomeLeavePostingPost = 'HOME_LEAVE_POSTING____POST',
  HomeLeaveYearendCarryforwardRead = 'HOME_LEAVE_YEAREND_CARRYFORWARD____READ',
  HomeLeaveYearendCarryforwardPost = 'HOME_LEAVE_YEAREND_CARRYFORWARD____POST',
  GeneralSettingLeaveCutoffDateRead = 'GENERAL_SETTING_LEAVE_CUTOFF_DATE____READ',
  GeneralSettingLeaveCutoffDateUpdate = 'GENERAL_SETTING_LEAVE_CUTOFF_DATE____UPDATE',
  GeneralSettingLeaveRoundingCreate = 'GENERAL_SETTING_LEAVE_ROUNDING____CREATE',
  GeneralSettingLeaveRoundingRead = 'GENERAL_SETTING_LEAVE_ROUNDING____READ',
  GeneralSettingLeaveRoundingUpdate = 'GENERAL_SETTING_LEAVE_ROUNDING____UPDATE',
  GeneralSettingLeaveRoundingDelete = 'GENERAL_SETTING_LEAVE_ROUNDING____DELETE',
  GeneralSettingLeaveTemplateCreate = 'GENERAL_SETTING_LEAVE_TEMPLATE____CREATE',
  GeneralSettingLeaveTemplateRead = 'GENERAL_SETTING_LEAVE_TEMPLATE____READ',
  GeneralSettingLeaveTemplateUpdate = 'GENERAL_SETTING_LEAVE_TEMPLATE____UPDATE',
  GeneralSettingLeaveCompulsoryDeductionRead = 'GENERAL_SETTING_LEAVE_COMPULSORY_DEDUCTION____READ',
  GeneralSettingLeaveCompulsoryDeductionUpdate = 'GENERAL_SETTING_LEAVE_COMPULSORY_DEDUCTION____UPDATE',
  GeneralSettingLeaveNotificationRead = 'GENERAL_SETTING_LEAVE_NOTIFICATION____READ',
  GeneralSettingLeaveNotificationShortNoticeCreate = 'GENERAL_SETTING_LEAVE_NOTIFICATION_SHORT_NOTICE_CREATE',
  GeneralSettingLeaveNotificationShortNoticeRead = 'GENERAL_SETTING_LEAVE_NOTIFICATION_SHORT_NOTICE_READ',
  GeneralSettingLeaveNotificationShortNoticeUpdate = 'GENERAL_SETTING_LEAVE_NOTIFICATION_SHORT_NOTICE_UPDATE',
  GeneralSettingLeaveNotificationNoofdayAppCreate = 'GENERAL_SETTING_LEAVE_NOTIFICATION_NOOFDAY_APP_CREATE',
  GeneralSettingLeaveNotificationNoofdayAppRead = 'GENERAL_SETTING_LEAVE_NOTIFICATION_NOOFDAY_APP_READ',
  GeneralSettingLeaveNotificationNoofdayAppUpdate = 'GENERAL_SETTING_LEAVE_NOTIFICATION_NOOFDAY_APP_UPDATE',
  GeneralSettingLeaveNotificationPendingApprovalCreate = 'GENERAL_SETTING_LEAVE_NOTIFICATION_PENDING_APPROVAL_CREATE',
  GeneralSettingLeaveNotificationPendingApprovalRead = 'GENERAL_SETTING_LEAVE_NOTIFICATION_PENDING_APPROVAL_READ',
  GeneralSettingLeaveNotificationPendingApprovalUpdate = 'GENERAL_SETTING_LEAVE_NOTIFICATION_PENDING_APPROVAL_UPDATE',
  HomeClaimExecSummaryCreate = 'HOME_CLAIM_EXEC_SUMMARY____CREATE',
  HomeClaimExecSummaryRead = 'HOME_CLAIM_EXEC_SUMMARY____READ',
  HomeClaimOutOfStateCreate = 'HOME_CLAIM_OUT_OF_STATE____CREATE',
  HomeClaimOutOfStateRead = 'HOME_CLAIM_OUT_OF_STATE____READ',
  HomeClaimOutOfStateUpdate = 'HOME_CLAIM_OUT_OF_STATE____UPDATE',
  HomeClaimOutOfStateDelete = 'HOME_CLAIM_OUT_OF_STATE____DELETE',
  HomeClaimApplicationCreate = 'HOME_CLAIM_APPLICATION____CREATE',
  HomeClaimApplicationRead = 'HOME_CLAIM_APPLICATION____READ',
  HomeClaimApplicationUpdate = 'HOME_CLAIM_APPLICATION____UPDATE',
  HomeClaimApplicationDelete = 'HOME_CLAIM_APPLICATION____DELETE',
  HomeClaimApplicationApprove = 'HOME_CLAIM_APPLICATION____APPROVE',
  HomeClaimApplicationReject = 'HOME_CLAIM_APPLICATION____REJECT',
  HomeClaimApprovedClaimVerifyRead = 'HOME_CLAIM_APPROVED_CLAIM_VERIFY____READ',
  HomeClaimApprovedClaimVerifyReject = 'HOME_CLAIM_APPROVED_CLAIM_VERIFY____REJECT',
  HomeClaimApprovedClaimVerifyVerify = 'HOME_CLAIM_APPROVED_CLAIM_VERIFY____VERIFY',
  HomeClaimPostingRead = 'HOME_CLAIM_POSTING____READ',
  HomeClaimPostingPost = 'HOME_CLAIM_POSTING____POST',
  HomeClaimPostingReverse = 'HOME_CLAIM_POSTING____REVERSE',
  GeneralSettingClaimValidityDurationRead = 'GENERAL_SETTING_CLAIM_VALIDITY_DURATION____READ',
  GeneralSettingClaimValidityDurationUpdate = 'GENERAL_SETTING_CLAIM_VALIDITY_DURATION____UPDATE',
  GeneralSettingClaimCutoffDateRead = 'GENERAL_SETTING_CLAIM_CUTOFF_DATE____READ',
  GeneralSettingClaimCutoffDateUpdate = 'GENERAL_SETTING_CLAIM_CUTOFF_DATE____UPDATE',
  GeneralSettingClaimTemplateCreate = 'GENERAL_SETTING_CLAIM_TEMPLATE____CREATE',
  GeneralSettingClaimTemplateRead = 'GENERAL_SETTING_CLAIM_TEMPLATE____READ',
  GeneralSettingClaimTemplateUpdate = 'GENERAL_SETTING_CLAIM_TEMPLATE____UPDATE',
  GeneralSettingClaimNotificationRead = 'GENERAL_SETTING_CLAIM_NOTIFICATION____READ',
  GeneralSettingClaimNotificationSubmissionAmtCreate = 'GENERAL_SETTING_CLAIM_NOTIFICATION_SUBMISSION_AMT_CREATE',
  GeneralSettingClaimNotificationSubmissionAmtRead = 'GENERAL_SETTING_CLAIM_NOTIFICATION_SUBMISSION_AMT_READ',
  GeneralSettingClaimNotificationSubmissionAmtUpdate = 'GENERAL_SETTING_CLAIM_NOTIFICATION_SUBMISSION_AMT_UPDATE',
  GeneralSettingClaimNotificationPendingApprovalCreate = 'GENERAL_SETTING_CLAIM_NOTIFICATION_PENDING_APPROVAL_CREATE',
  GeneralSettingClaimNotificationPendingApprovalRead = 'GENERAL_SETTING_CLAIM_NOTIFICATION_PENDING_APPROVAL_READ',
  GeneralSettingClaimNotificationPendingApprovalUpdate = 'GENERAL_SETTING_CLAIM_NOTIFICATION_PENDING_APPROVAL_UPDATE',
  HomeTmsExecSummaryRead = 'HOME_TMS_EXEC_SUMMARY____READ',
  HomeTmsAttendanceRecordCreate = 'HOME_TMS_ATTENDANCE_RECORD____CREATE',
  HomeTmsAttendanceRecordRead = 'HOME_TMS_ATTENDANCE_RECORD____READ',
  HomeTmsAttendanceRecordUpdate = 'HOME_TMS_ATTENDANCE_RECORD____UPDATE',
  HomeTmsAttendanceRecordDelete = 'HOME_TMS_ATTENDANCE_RECORD____DELETE',
  HomeRequisitionCreate = 'HOME_REQUISITION_______CREATE',
  HomeRequisitionRead = 'HOME_REQUISITION_______READ',
  HomeRequisitionUpdate = 'HOME_REQUISITION_______UPDATE',
  HomeRequisitionDelete = 'HOME_REQUISITION_______DELETE',
  HomeRequisitionApprove = 'HOME_REQUISITION_______APPROVE',
  HomeRequisitionReject = 'HOME_REQUISITION_______REJECT',
  GeneralSettingRequisitionNotificationRead = 'GENERAL_SETTING_REQUISITION_NOTIFICATION____READ',
  GeneralSettingRequisitionNotificationRequisitionAmtCreate = 'GENERAL_SETTING_REQUISITION_NOTIFICATION_REQUISITION_AMT_CREATE',
  GeneralSettingRequisitionNotificationRequisitionAmtRead = 'GENERAL_SETTING_REQUISITION_NOTIFICATION_REQUISITION_AMT_READ',
  GeneralSettingRequisitionNotificationRequisitionAmtDelete = 'GENERAL_SETTING_REQUISITION_NOTIFICATION_REQUISITION_AMT_DELETE',
  GeneralSettingRequisitionNotificationRequisitionAmtUpdate = 'GENERAL_SETTING_REQUISITION_NOTIFICATION_REQUISITION_AMT_UPDATE',
  GeneralSettingRequisitionNotificationPendingApprovalCreate = 'GENERAL_SETTING_REQUISITION_NOTIFICATION_PENDING_APPROVAL_CREATE',
  GeneralSettingRequisitionNotificationPendingApprovalRead = 'GENERAL_SETTING_REQUISITION_NOTIFICATION_PENDING_APPROVAL_READ',
  GeneralSettingRequisitionNotificationPendingApprovalUpdate = 'GENERAL_SETTING_REQUISITION_NOTIFICATION_PENDING_APPROVAL_UPDATE',
  GeneralSettingRequisitionNotificationPendingApprovalDelete = 'GENERAL_SETTING_REQUISITION_NOTIFICATION_PENDING_APPROVAL_DELETE',
  DigitalReportingPersonnelRptBasicinfoView = 'DIGITAL_REPORTING_PERSONNEL_RPT_BASICINFO____VIEW',
  DigitalReportingPersonnelRptConfirmationView = 'DIGITAL_REPORTING_PERSONNEL_RPT_CONFIRMATION____VIEW',
  DigitalReportingPersonnelRptDependentView = 'DIGITAL_REPORTING_PERSONNEL_RPT_DEPENDENT____VIEW',
  DigitalReportingPersonnelRptNewView = 'DIGITAL_REPORTING_PERSONNEL_RPT_NEW____VIEW',
  DigitalReportingPersonnelRptRegisnationView = 'DIGITAL_REPORTING_PERSONNEL_RPT_REGISNATION____VIEW',
  DigitalReportingPayrollRptBankBankPayoutView = 'DIGITAL_REPORTING_PAYROLL_RPT_BANK_BANK_PAYOUT_VIEW',
  DigitalReportingPayrollRptBankCashcheckPayoutView = 'DIGITAL_REPORTING_PAYROLL_RPT_BANK_CASHCHECK_PAYOUT_VIEW',
  DigitalReportingPayrollRptPayrollEmployeePayView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_EMPLOYEE_PAY_VIEW',
  DigitalReportingPayrollRptPayrollEmployeeSalaryView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_EMPLOYEE_SALARY_VIEW',
  DigitalReportingPayrollRptPayrollHistoryTrxView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_HISTORY_TRX_VIEW',
  DigitalReportingPayrollRptPayrollMonthlyTrxView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_MONTHLY_TRX_VIEW',
  DigitalReportingPayrollRptPayrollPayrollDetailView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_PAYROLL_DETAIL_VIEW',
  DigitalReportingPayrollRptPayrollSalarydetailView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_SALARYDETAIL_VIEW',
  DigitalReportingPayrollRptPayrollSalarysummaryView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_SALARYSUMMARY_VIEW',
  DigitalReportingPayrollRptPayrollTrxDetailView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL_TRX_DETAIL_VIEW',
  DigitalReportingPayrollRptPayslipView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYSLIP____VIEW',
  DigitalReportingPayrollRptKwspBorangaView = 'DIGITAL_REPORTING_PAYROLL_RPT_KWSP_BORANGA_VIEW',
  DigitalReportingPayrollRptSocsoBorang8AView = 'DIGITAL_REPORTING_PAYROLL_RPT_SOCSO_BORANG8A_VIEW',
  DigitalReportingPayrollRptEisLampiran1View = 'DIGITAL_REPORTING_PAYROLL_RPT_EIS_LAMPIRAN1_VIEW',
  DigitalReportingPayrollRptLhdnBorangeView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_BORANGE_VIEW',
  DigitalReportingPayrollRptLhdnBorangeaView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_BORANGEA_VIEW',
  DigitalReportingPayrollRptLhdnCp159View = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_CP159_VIEW',
  DigitalReportingPayrollRptLhdnCp21View = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_CP21_VIEW',
  DigitalReportingPayrollRptLhdnCp22View = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_CP22_VIEW',
  DigitalReportingPayrollRptLhdnCp22AView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_CP22A_VIEW',
  DigitalReportingPayrollRptLhdnCp39View = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_CP39_VIEW',
  DigitalReportingPayrollRptLhdnCp39AView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_CP39A_VIEW',
  DigitalReportingPayrollRptLhdnCp8DView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN_CP8D_VIEW',
  DigitalReportingPayrollRptIntegrationHlbView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_HLB_VIEW',
  DigitalReportingPayrollRptIntegrationRhbView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION_RHB_VIEW',
  DigitalReportingLeaveView = 'DIGITAL_REPORTING_LEAVE_______VIEW',
  DigitalReportingLeaveRptLeaveAnalysisView = 'DIGITAL_REPORTING_LEAVE_RPT_LEAVE_ANALYSIS____VIEW',
  DigitalReportingClaimView = 'DIGITAL_REPORTING_CLAIM_______VIEW',
  DigitalReportingClaimRptClaimBalanceAnalysisView = 'DIGITAL_REPORTING_CLAIM_RPT_CLAIM_BALANCE_ANALYSIS____VIEW',
  DigitalReportingClaimRptClaimStatusAnalysisView = 'DIGITAL_REPORTING_CLAIM_RPT_CLAIM_STATUS_ANALYSIS____VIEW',
  DigitalReportingClaimRptIntegrationView = 'DIGITAL_REPORTING_CLAIM_RPT_INTEGRATION____VIEW',
  DigitalReportingClaimRptIntegrationHlbView = 'DIGITAL_REPORTING_CLAIM_RPT_INTEGRATION_HLB_VIEW',
  BusinessInsightsPersonnelBiGenderView = 'BUSINESS_INSIGHTS_PERSONNEL_BI_GENDER____VIEW',
  BusinessInsightsPersonnelBiRaceView = 'BUSINESS_INSIGHTS_PERSONNEL_BI_RACE____VIEW',
  BusinessInsightsPersonnelBiReligionView = 'BUSINESS_INSIGHTS_PERSONNEL_BI_RELIGION____VIEW',
  BusinessInsightsPersonnelBiMaritalView = 'BUSINESS_INSIGHTS_PERSONNEL_BI_MARITAL____VIEW',
  BusinessInsightsPersonnelBiAgeView = 'BUSINESS_INSIGHTS_PERSONNEL_BI_AGE____VIEW',
  BusinessInsightsPersonnelBiEmployeeClassificationView = 'BUSINESS_INSIGHTS_PERSONNEL_BI_EMPLOYEE_CLASSIFICATION____VIEW',
  BusinessInsightsPersonnelBiJobgradeView = 'BUSINESS_INSIGHTS_PERSONNEL_BI_JOBGRADE____VIEW',
  BusinessInsightsPersonnelBiYearofserviceView = 'BUSINESS_INSIGHTS_PERSONNEL_BI_YEAROFSERVICE____VIEW',
  BusinessInsightsPersonnelBiRecruitmentsourceView = 'BUSINESS_INSIGHTS_PERSONNEL_BI_RECRUITMENTSOURCE____VIEW',
  BusinessInsightsPersonnelBiStaffTurnoverView = 'BUSINESS_INSIGHTS_PERSONNEL_BI_STAFF_TURNOVER____VIEW',
  BusinessInsightsPayrollBiSalaryOverheadView = 'BUSINESS_INSIGHTS_PAYROLL_BI_SALARY_OVERHEAD____VIEW',
  BusinessInsightsPayrollBiStatutoryContributeView = 'BUSINESS_INSIGHTS_PAYROLL_BI_STATUTORY_CONTRIBUTE____VIEW',
  BusinessInsightsPayrollBiPayitemsView = 'BUSINESS_INSIGHTS_PAYROLL_BI_PAYITEMS____VIEW',
  BusinessInsightsLeaveBiLeavetakenView = 'BUSINESS_INSIGHTS_LEAVE_BI_LEAVETAKEN____VIEW',
  BusinessInsightsLeaveBiLeavetypeView = 'BUSINESS_INSIGHTS_LEAVE_BI_LEAVETYPE____VIEW',
  BusinessInsightsClaimBiClaimtakenView = 'BUSINESS_INSIGHTS_CLAIM_BI_CLAIMTAKEN____VIEW',
  BusinessInsightsClaimBiClaimtypeView = 'BUSINESS_INSIGHTS_CLAIM_BI_CLAIMTYPE____VIEW',
  BusinessInsightsRequisitionBiRequisitiontakenView = 'BUSINESS_INSIGHTS_REQUISITION_BI_REQUISITIONTAKEN____VIEW',
  BusinessInsightsRequisitionBiRequisitiontypeView = 'BUSINESS_INSIGHTS_REQUISITION_BI_REQUISITIONTYPE____VIEW',
  BusinessInsightsTmsView = 'BUSINESS_INSIGHTS_TMS_______VIEW',
  HomeBusinessInsightsView = 'HOME_BUSINESS_INSIGHTS_______VIEW',
  HomeDigitalReportingView = 'HOME_DIGITAL_REPORTING_______VIEW',
  DigitalReportingPersonnelView = 'DIGITAL_REPORTING_PERSONNEL_______VIEW',
  DigitalReportingPayrollBankPayoutView = 'DIGITAL_REPORTING_PAYROLL____BANK_PAYOUT_VIEW',
  BusinessInsightsPersonnelView = 'BUSINESS_INSIGHTS_PERSONNEL_______VIEW',
  BusinessInsightsPayrollView = 'BUSINESS_INSIGHTS_PAYROLL_______VIEW',
  BusinessInsightsLeaveView = 'BUSINESS_INSIGHTS_LEAVE_______VIEW',
  BusinessInsightsclaimignoreignorereadbusinessInsightsClaimView = 'BUSINESS_INSIGHTSCLAIMIGNOREIGNOREREADBUSINESS_INSIGHTS_CLAIM_______VIEW',
  BusinessInsightsRequisitionView = 'BUSINESS_INSIGHTS_REQUISITION_______VIEW',
  DigitalReportingPayrollRptBankView = 'DIGITAL_REPORTING_PAYROLL_RPT_BANK____VIEW',
  DigitalReportingPayrollRptPayrollView = 'DIGITAL_REPORTING_PAYROLL_RPT_PAYROLL____VIEW',
  DigitalReportingPayrollRptLhdnView = 'DIGITAL_REPORTING_PAYROLL_RPT_LHDN____VIEW',
  DigitalReportingPayrollRptIntegrationView = 'DIGITAL_REPORTING_PAYROLL_RPT_INTEGRATION____VIEW',
  BusinessInsightsClaimView = 'BUSINESS_INSIGHTS_CLAIM_______VIEW',
  DigitalReportingPayrollView = 'DIGITAL_REPORTING_PAYROLL_______VIEW'
}

export type HuaweiStorageOutput = {
  __typename?: 'HuaweiStorageOutput';
  BucketFileName: Scalars['String'];
  FileName: Scalars['String'];
  Url: Scalars['String'];
  MediaType: Scalars['String'];
  IsPrivate?: Maybe<Scalars['Boolean']>;
  PrivateUrl?: Maybe<Scalars['String']>;
};

export type InsuranceSchemeEntity = InterfaceBase & {
  __typename?: 'InsuranceSchemeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  InsuranceSchemeID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  EmployeeInsuranceSchemes?: Maybe<Array<EmployeeInsuranceSchemeEntity>>;
};

export type InsuranceSchemeInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum IntegrationClockType {
  In = 'IN',
  Out = 'OUT'
}

export type InterbankGiroEntity = InterfaceBase & {
  __typename?: 'InterbankGiroEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  InterbankGiroID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  BankInfoID?: Maybe<Scalars['String']>;
  EmployeeBank?: Maybe<Bank>;
  IBGCode?: Maybe<Scalars['String']>;
  Company: CompanyEntity;
};

export type InterbankGiroInput = {
  InterbankGiroID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  BankInfoID?: Maybe<Scalars['String']>;
  EmployeeBank?: Maybe<Bank>;
  IBGCode?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type InterfaceBase = {
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type InterfaceBaseInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
};


export type JobAccountEntity = InterfaceBase & {
  __typename?: 'JobAccountEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  JobAccountID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  IsChargeable?: Maybe<Scalars['Boolean']>;
  Company?: Maybe<CompanyEntity>;
  MyJobAccount?: Maybe<Array<MyJobAccountEntity>>;
  ClaimHeader?: Maybe<Array<ClaimHeaderEntity>>;
};

export type JobAccountInput = {
  JobAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  IsChargeable?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type JobDesignationEntity = InterfaceBase & {
  __typename?: 'JobDesignationEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  JobDesignationID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  IsAdmin?: Maybe<Scalars['Boolean']>;
  JobDesignationGrades?: Maybe<Array<JobDesignationGradeEntity>>;
  Employees?: Maybe<Array<EmployeeEntity>>;
};

export type JobDesignationGradeEntity = InterfaceBase & {
  __typename?: 'JobDesignationGradeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  JobDesignationGradeID: Scalars['String'];
  JobDesignationID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  JobGrade: JobGradeEntity;
  JobDesignation: JobDesignationEntity;
};

export type JobDesignationGradeInput = {
  JobDesignationID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type JobDesignationInput = {
  JobDesignationID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  IsAdmin?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type JobGradeByJobDesignationOutput = {
  __typename?: 'JobGradeByJobDesignationOutput';
  JobDesignationID?: Maybe<Scalars['String']>;
  JobGrade?: Maybe<JobGradeOutput>;
};

export type JobGradeEntity = InterfaceBase & {
  __typename?: 'JobGradeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  JobGradeID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  LeaveBenefitID?: Maybe<Scalars['String']>;
  ClaimBenefitID?: Maybe<Scalars['String']>;
  LeaveBenefit?: Maybe<LeaveBenefitEntity>;
  ClaimBenefit?: Maybe<ClaimBenefitEntity>;
  JobDesignationGrades?: Maybe<Array<JobDesignationGradeEntity>>;
  JobGradePolicies?: Maybe<Array<JobGradePolicyEntity>>;
};

export type JobGradeInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  LeaveBenefitID?: Maybe<Scalars['String']>;
  ClaimBenefitID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type JobGradeOutput = {
  __typename?: 'JobGradeOutput';
  JobGradeID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
};

export type JobGradePolicyEntity = InterfaceBase & {
  __typename?: 'JobGradePolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  JobGradePolicyID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  JobGrade?: Maybe<JobGradeEntity>;
  LeaveTemplates?: Maybe<Array<LeaveTemplateEntity>>;
  ClaimTemplates?: Maybe<Array<ClaimTemplateEntity>>;
};

export type JobGradePolicyInput = {
  JobGradePolicyID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type JobGradePolicyMatrixEntity = InterfaceBase & {
  __typename?: 'JobGradePolicyMatrixEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  JobGradePolicyMatrixID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  JobGradeID: Scalars['String'];
  OvertimeClaimTableID: Scalars['String'];
  DailyAllowanceIDs?: Maybe<Array<Scalars['String']>>;
  MonthlyAllowanceIDs?: Maybe<Array<Scalars['String']>>;
  DeductionPolicyIDs?: Maybe<Array<Scalars['String']>>;
  OvertimeClaimTable?: Maybe<OvertimeClaimTableEntity>;
};

export type JobGradePolicyMatrixInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  JobGradePolicyMatrixID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  JobGradeID?: Maybe<Scalars['String']>;
  OvertimeClaimTableID?: Maybe<Scalars['String']>;
  DailyAllowanceIDs?: Maybe<Array<Scalars['String']>>;
  MonthlyAllowanceIDs?: Maybe<Array<Scalars['String']>>;
  DeductionPolicyIDs?: Maybe<Array<Scalars['String']>>;
};

export type JobGradesEntity = InterfaceBase & {
  __typename?: 'JobGradesEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type JobGradesInput = {
  name?: Maybe<Scalars['String']>;
};

export type JoinedDateError = {
  __typename?: 'JoinedDateError';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

export type LhdnReportEntity = InterfaceBase & {
  __typename?: 'LHDNReportEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LHDNReportID: Scalars['String'];
  ReportType: ReportType;
  ReportTypeSequence?: Maybe<Scalars['Float']>;
  ReportYear?: Maybe<Scalars['Float']>;
  ENDescription?: Maybe<Scalars['String']>;
  BMDescription?: Maybe<Scalars['String']>;
};

export type LhdnReportInput = {
  ReportType?: Maybe<ReportType>;
  ReportTypeSequence?: Maybe<Scalars['Float']>;
  ReportYear?: Maybe<Scalars['Float']>;
  ENDescription?: Maybe<Scalars['String']>;
  BMDescription?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LosInput = {
  Condition?: Maybe<Scalars['String']>;
  Month?: Maybe<Scalars['String']>;
  Day?: Maybe<Scalars['String']>;
  LeaveBenefitRuleID?: Maybe<Scalars['String']>;
};

export type LastUpdatedDatesOutput = {
  __typename?: 'LastUpdatedDatesOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeLastDate?: Maybe<Scalars['DateTime']>;
  AssetsLastDate?: Maybe<Scalars['DateTime']>;
  BIKLastDate?: Maybe<Scalars['DateTime']>;
  CareerLastDate?: Maybe<Scalars['DateTime']>;
  SubordinateLastDate?: Maybe<Scalars['DateTime']>;
  TaxLastDate?: Maybe<Scalars['DateTime']>;
};

export type LeaveAdjustmentListingInput = {
  SubscriptionUserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DateFrom?: Maybe<Scalars['DateTime']>;
  DateTo?: Maybe<Scalars['DateTime']>;
  LeaveTypeIDs?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeID?: Maybe<Scalars['String']>;
  WantDraft?: Maybe<Scalars['Boolean']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  PeriodYear?: Maybe<Scalars['Float']>;
};

export type LeaveAdjustmentOutput = {
  __typename?: 'LeaveAdjustmentOutput';
  LeaveHeaderID?: Maybe<Scalars['String']>;
  ManualEntryID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  LeaveTypeName?: Maybe<Scalars['String']>;
  TotalDay?: Maybe<Scalars['Float']>;
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  ExpiryDate?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
  Taken?: Maybe<Scalars['Float']>;
  Balance?: Maybe<Scalars['Float']>;
  LeaveFrequency?: Maybe<LeaveFrequency>;
};

export type LeaveAppOutput = {
  __typename?: 'LeaveAppOutput';
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  TotalDay?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  Name?: Maybe<Scalars['String']>;
};

export type LeaveApprovalUpdateInput = {
  LeaveTypeID?: Maybe<Scalars['String']>;
  ApprovalStatus: ApprovalStatus;
  ApprovalDT?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  RejectedRemarks?: Maybe<Scalars['String']>;
};

export type LeaveBalanceSummary = {
  __typename?: 'LeaveBalanceSummary';
  BroughtForward?: Maybe<Scalars['Float']>;
  Adjustment?: Maybe<Scalars['Float']>;
  Entitled?: Maybe<Scalars['Float']>;
  Occurrence?: Maybe<Scalars['Float']>;
  Earned?: Maybe<Scalars['Float']>;
  Taken?: Maybe<Scalars['Float']>;
  TakenYTD?: Maybe<Scalars['Float']>;
  Forfeited?: Maybe<Scalars['Float']>;
  Balance?: Maybe<Scalars['Float']>;
  BalanceYTD?: Maybe<Scalars['Float']>;
  CarryForward?: Maybe<Scalars['Float']>;
};

export type LeaveBenefitEntity = InterfaceBase & {
  __typename?: 'LeaveBenefitEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveBenefitID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  LeaveItems?: Maybe<Array<LeaveItemEntity>>;
};

export type LeaveBenefitInput = {
  LeaveBenefitID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeaveBenefitRuleEntity = InterfaceBase & {
  __typename?: 'LeaveBenefitRuleEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveBenefitRuleID: Scalars['String'];
  LeaveItemID: Scalars['String'];
  FormulaText: Scalars['String'];
  FormulaValue: Scalars['String'];
  TotalDay: Scalars['Float'];
  LeaveRule: LeaveRuleType;
  LOSRange: Scalars['String'];
  LeaveItem?: Maybe<LeaveItemEntity>;
};

export type LeaveBenefitRuleInput = {
  LeaveBenefitRuleID?: Maybe<Scalars['String']>;
  LeaveItemID?: Maybe<Scalars['String']>;
  FormulaText?: Maybe<Scalars['String']>;
  FormulaValue?: Maybe<Scalars['String']>;
  TotalDay?: Maybe<Scalars['Float']>;
  LeaveRule?: Maybe<LeaveRuleType>;
  LOSRange?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeaveCompulsoryEmployeeEntity = InterfaceBase & {
  __typename?: 'LeaveCompulsoryEmployeeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveCompulsoryEmployeeID: Scalars['String'];
  LeaveCompulsoryID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  LeaveCompulsory?: Maybe<LeaveCompulsoryEntity>;
};

export type LeaveCompulsoryEmployeeInput = {
  LeaveCompulsoryEmployeeID?: Maybe<Scalars['String']>;
  LeaveCompulsoryID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeaveCompulsoryEntity = InterfaceBase & {
  __typename?: 'LeaveCompulsoryEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveCompulsoryID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  SessionType?: Maybe<SessionType>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  LeaveCompulsoryEmployees?: Maybe<Array<LeaveCompulsoryEmployeeEntity>>;
};

export type LeaveCompulsoryInput = {
  LeaveCompulsoryID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  SessionType?: Maybe<SessionType>;
  Remarks?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeaveCompulsoryPolicyEntity = InterfaceBase & {
  __typename?: 'LeaveCompulsoryPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveCompulsoryPolicyID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  Sequence?: Maybe<Sequence>;
};

export type LeaveCompulsoryPolicyInput = {
  LeaveCompulsoryPolicyID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeaveCutOffPeriodEntity = InterfaceBase & {
  __typename?: 'LeaveCutOffPeriodEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveCutOffPeriodID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYearMonth: Scalars['DateTime'];
  FromDate: Scalars['DateTime'];
  ToDate: Scalars['DateTime'];
  Company?: Maybe<CompanyEntity>;
};

export type LeaveCutOffPeriodInput = {
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeaveDetails = {
  __typename?: 'LeaveDetails';
  ApprovalStatus?: Maybe<ApprovalStatus>;
  TotalDay?: Maybe<Scalars['Float']>;
  LeaveType?: Maybe<Scalars['String']>;
  ApproverName?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
};

export type LeaveDetailsEntity = InterfaceBase & {
  __typename?: 'LeaveDetailsEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveDetailsID: Scalars['String'];
  LeaveHeaderID: Scalars['String'];
  PayPeriodID?: Maybe<Scalars['String']>;
  SessionType?: Maybe<SessionType>;
  LeaveDate?: Maybe<Scalars['DateTime']>;
  NumberOfDay?: Maybe<Scalars['Float']>;
  ManualEntryID?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  LeaveHeader?: Maybe<LeaveHeaderEntity>;
};

export type LeaveDetailsInput = {
  LeaveHeaderID?: Maybe<Scalars['String']>;
  SessionType?: Maybe<SessionType>;
  LeaveDate?: Maybe<Scalars['DateTime']>;
  NumberOfDay?: Maybe<Scalars['Float']>;
  ManualEntryID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeaveEntitlementByLeaveTypeListing = {
  __typename?: 'LeaveEntitlementByLeaveTypeListing';
  LeaveType?: Maybe<LeaveTypeEntity>;
  Employee?: Maybe<EmployeeEntity>;
  LeaveBalanceSummary?: Maybe<LeaveBalanceSummary>;
};

export type LeaveEntitlementTemplate = {
  __typename?: 'LeaveEntitlementTemplate';
  LeaveEntitlementName?: Maybe<Scalars['String']>;
  LeaveEntitlementDesc?: Maybe<Scalars['String']>;
  LeaveEntitlementTypes?: Maybe<Array<LeaveEntitlementTypes>>;
};

export type LeaveEntitlementTypes = {
  __typename?: 'LeaveEntitlementTypes';
  LeaveType?: Maybe<LeaveTypeEntity>;
  LeaveItem?: Maybe<LeaveItemEntity>;
};

export enum LeaveFrequency {
  AnnualIncrement = 'Annual_Increment',
  Occurrence = 'Occurrence',
  ManualEntry = 'Manual_Entry'
}

export type LeaveHeaderEntity = InterfaceBase & {
  __typename?: 'LeaveHeaderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveHeaderID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  LeaveTypeID?: Maybe<Scalars['String']>;
  SubLeaveTypeID: Scalars['String'];
  LeaveHeaderType: LeaveHeaderType;
  PeriodYear: Scalars['Float'];
  TotalDay: Scalars['Float'];
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  IsShortPeriodNotice?: Maybe<Scalars['Boolean']>;
  TotalCFTaken: Scalars['Float'];
  ApprovalStatus: ApprovalStatus;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  RejectedRemarks?: Maybe<Scalars['String']>;
  LeaveDetails?: Maybe<Array<LeaveDetailsEntity>>;
  Company?: Maybe<CompanyEntity>;
  Employee?: Maybe<EmployeeEntity>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  Document?: Maybe<DocumentsEntity>;
  Approver?: Maybe<EmployeeEntity>;
  LeaveHistory?: Maybe<Array<LeaveHeaderEntity>>;
  IsEmergencyLeave?: Maybe<Scalars['Boolean']>;
};

export type LeaveHeaderInput = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  SubLeaveTypeID?: Maybe<Scalars['String']>;
  LeaveHeaderType?: Maybe<LeaveHeaderType>;
  PeriodYear?: Maybe<Scalars['Float']>;
  TotalDay?: Maybe<Scalars['Float']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  Document?: Maybe<DocumentsInput>;
  Remarks?: Maybe<Scalars['String']>;
  IsShortPeriodNotice?: Maybe<Scalars['Boolean']>;
  TotalCFTaken?: Maybe<Scalars['Float']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalDT?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum LeaveHeaderType {
  OpeningBalance = 'Opening_Balance',
  ClosingBalance = 'Closing_Balance',
  LeaveApplication = 'Leave_Application',
  LeaveAdjustment = 'Leave_Adjustment',
  SystemAdjustment = 'System_Adjustment',
  CompulsoryLeave = 'Compulsory_Leave',
  CarryForward = 'Carry_Forward',
  BroughtForward = 'Brought_Forward'
}

export type LeaveHomeSummaryOutput = {
  __typename?: 'LeaveHomeSummaryOutput';
  OutOfStateActiveRecords?: Maybe<Scalars['Float']>;
  OutOfStateIsLastUpdated?: Maybe<Scalars['Boolean']>;
  PendingApproval?: Maybe<Scalars['Float']>;
  PendingApprovalIsLastUpdated?: Maybe<Scalars['Boolean']>;
  AdjustmentNoOfRecords?: Maybe<Scalars['Float']>;
  AdjustmentIsLastUpdated?: Maybe<Scalars['Boolean']>;
  NoOfEmployee?: Maybe<Scalars['Float']>;
  EmployeeIsLastUpdated?: Maybe<Scalars['Boolean']>;
  NoOfLeaveType?: Maybe<Scalars['Float']>;
  LeaveTypeIsLastUpdated?: Maybe<Scalars['Boolean']>;
  CompulsoryNoOfRecords?: Maybe<Scalars['Float']>;
  CompulsoryIsLastUpdated?: Maybe<Scalars['Boolean']>;
  PayDeductionLastPostedDate?: Maybe<Scalars['DateTime']>;
  PayDeductionIsLastUpdated?: Maybe<Scalars['Boolean']>;
  YearEndCarryForwardLastPostedDate?: Maybe<Scalars['DateTime']>;
  YearEndCarryForwardIsLastUpdated?: Maybe<Scalars['Boolean']>;
};

export type LeaveIndentity = {
  __typename?: 'LeaveIndentity';
  LeaveTypeID?: Maybe<Scalars['String']>;
  LeaveTypeName?: Maybe<Scalars['String']>;
  LeaveEntitledDay?: Maybe<Scalars['Float']>;
  PendingApprovalTotalDay?: Maybe<Scalars['Float']>;
};

export type LeaveItemEntity = InterfaceBase & {
  __typename?: 'LeaveItemEntity';
  Status: Status;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveItemID: Scalars['String'];
  LeaveBenefitID?: Maybe<Scalars['String']>;
  TotalDay?: Maybe<Scalars['Float']>;
  LeaveFrequency?: Maybe<LeaveFrequency>;
  CutOffDate?: Maybe<Scalars['DateTime']>;
  Occurrence?: Maybe<Occurrence>;
  NoOccurrence?: Maybe<Scalars['Float']>;
  AvailableBasis?: Maybe<AvailableBasis>;
  CalculationMethod?: Maybe<CalculationMethod>;
  NewCutOffDay?: Maybe<Scalars['Float']>;
  ResignCutOffDay?: Maybe<Scalars['Float']>;
  MonthlyCutOffDay?: Maybe<MonthlyCutOff>;
  ShortNoticeType?: Maybe<ShortNoticeType>;
  ShortNoticePeriod?: Maybe<Scalars['Float']>;
  CFMethod?: Maybe<CfMethod>;
  CFValue?: Maybe<Scalars['Float']>;
  CFMonth?: Maybe<Scalars['Float']>;
  IsOffDay?: Maybe<Scalars['Boolean']>;
  IsRestDay?: Maybe<Scalars['Boolean']>;
  IsPublicHoliday?: Maybe<Scalars['Boolean']>;
  IsHalfDay?: Maybe<Scalars['Boolean']>;
  IsAttachment?: Maybe<Scalars['Boolean']>;
  IsRemarks?: Maybe<Scalars['Boolean']>;
  IsRounding?: Maybe<Scalars['Boolean']>;
  IsConfirmedStaff?: Maybe<Scalars['Boolean']>;
  Title?: Maybe<Scalars['String']>;
  EntitlementBasedOn?: Maybe<EntitlementBasedOn>;
  PlatformLeaveItemID?: Maybe<Scalars['String']>;
  LeaveBenefit?: Maybe<LeaveBenefitEntity>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  LeaveTemplates?: Maybe<Array<LeaveTemplateEntity>>;
  LOS?: Maybe<Array<LeaveBenefitRuleEntity>>;
  OccurrenceEntitlementDay?: Maybe<Scalars['Float']>;
};

export type LeaveItemInput = {
  LeaveItemID?: Maybe<Scalars['String']>;
  LeaveBenefitID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  TotalDay?: Maybe<Scalars['Float']>;
  LeaveFrequency?: Maybe<LeaveFrequency>;
  CutOffDate?: Maybe<Scalars['DateTime']>;
  Occurrence?: Maybe<Occurrence>;
  NoOccurrence?: Maybe<Scalars['Float']>;
  AvailableBasis?: Maybe<AvailableBasis>;
  CalculationMethod?: Maybe<CalculationMethod>;
  NewCutOffDay?: Maybe<Scalars['Float']>;
  ResignCutOffDay?: Maybe<Scalars['Float']>;
  MonthlyCutOffDay?: Maybe<Scalars['Float']>;
  CFMethod?: Maybe<CfMethod>;
  CFValue?: Maybe<Scalars['Float']>;
  CFMonth?: Maybe<Scalars['Float']>;
  IsPublicHoliday?: Maybe<Scalars['Boolean']>;
  IsOffDay?: Maybe<Scalars['Boolean']>;
  IsRestDay?: Maybe<Scalars['Boolean']>;
  IsHalfDay?: Maybe<Scalars['Boolean']>;
  IsConfirmedStaff?: Maybe<Scalars['Boolean']>;
  IsAttachment?: Maybe<Scalars['Boolean']>;
  IsRemarks?: Maybe<Scalars['Boolean']>;
  IsRounding?: Maybe<Scalars['Boolean']>;
  ShortNoticeType?: Maybe<ShortNoticeType>;
  ShortNoticePeriod?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeaveManagementListingInput = {
  SubscriptionUserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DateFrom?: Maybe<Scalars['DateTime']>;
  DateTo?: Maybe<Scalars['DateTime']>;
};

export type LeaveOutOfStateEntity = InterfaceBase & {
  __typename?: 'LeaveOutOfStateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveOutOfStateID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  FromStateID?: Maybe<Scalars['String']>;
  ToStateID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  Employee?: Maybe<EmployeeEntity>;
};

export type LeaveOutOfStateInput = {
  LeaveOutOfStateID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  FromStateID?: Maybe<Scalars['String']>;
  ToStateID?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeavePolicyInput = {
  Title: Scalars['String'];
  LeaveTypeID: Scalars['String'];
  LeaveFrequency: LeaveFrequency;
  AvailableBasis?: Maybe<AvailableBasis>;
  EntitlementBasedOn?: Maybe<EntitlementBasedOn>;
  CalculationMethod?: Maybe<CalculationMethod>;
  NewCutOffDay?: Maybe<Scalars['Float']>;
  ResignCutOffDay?: Maybe<Scalars['Float']>;
  MonthlyCutOffDay?: Maybe<MonthlyCutOff>;
  Occurrence?: Maybe<Occurrence>;
  NoOccurrence?: Maybe<Scalars['Float']>;
  OccurrenceEntitlementDay?: Maybe<Scalars['Float']>;
  CFMethod?: Maybe<CfMethod>;
  CFValue?: Maybe<Scalars['Float']>;
  CFMonth?: Maybe<Scalars['Float']>;
  IsPublicHoliday?: Maybe<Scalars['Boolean']>;
  IsOffDay?: Maybe<Scalars['Boolean']>;
  IsRestDay?: Maybe<Scalars['Boolean']>;
  IsHalfDay?: Maybe<Scalars['Boolean']>;
  IsConfirmedStaff?: Maybe<Scalars['Boolean']>;
  IsAttachment?: Maybe<Scalars['Boolean']>;
  IsRemarks?: Maybe<Scalars['Boolean']>;
  IsRounding?: Maybe<Scalars['Boolean']>;
  ShortNoticeType?: Maybe<ShortNoticeType>;
  ShortNoticePeriod?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveItemID?: Maybe<Scalars['String']>;
};

export type LeavePostingInput = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  LeaveHeaderID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  TotalDay?: Maybe<Scalars['Float']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeavePostingListingInput = {
  SubscriptionUserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DateFrom?: Maybe<Scalars['DateTime']>;
  DateTo?: Maybe<Scalars['DateTime']>;
  EmployeeID?: Maybe<Scalars['String']>;
};

export type LeavePostingOutput = {
  __typename?: 'LeavePostingOutput';
  LeaveHeaderStatus?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  LeaveHeaderID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  LeaveTypeName?: Maybe<Scalars['String']>;
  LeaveFromDate?: Maybe<Scalars['DateTime']>;
  LeaveToDate?: Maybe<Scalars['DateTime']>;
  TotalDay?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocumentName?: Maybe<Scalars['String']>;
  DocumentFile?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ApprovedBy?: Maybe<Scalars['String']>;
  LeaveDetails?: Maybe<Array<LeaveDetailsEntity>>;
  IsShortPeriodNotice?: Maybe<Scalars['Boolean']>;
  ReportTo?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  IsEmergencyLeave?: Maybe<Scalars['Boolean']>;
  CancelledDate?: Maybe<Scalars['DateTime']>;
  CancelledBy?: Maybe<Scalars['String']>;
  RejectedRemarks?: Maybe<Scalars['String']>;
};

export type LeavePostingPeriodOutput = {
  __typename?: 'LeavePostingPeriodOutput';
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  CountUnpostedLeave?: Maybe<Scalars['Float']>;
  CountPostedLeave?: Maybe<Scalars['Float']>;
  CountLeaveRecords?: Maybe<Scalars['Float']>;
  PeriodStatus?: Maybe<Scalars['String']>;
  PeriodFromDate?: Maybe<Scalars['DateTime']>;
  PeriodToDate?: Maybe<Scalars['DateTime']>;
};

export type LeaveRecordsListingInput = {
  SubscriptionUserID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DateFrom?: Maybe<Scalars['DateTime']>;
  DateTo?: Maybe<Scalars['DateTime']>;
  LeaveTypeIDs?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeID?: Maybe<Scalars['String']>;
  WantDraft?: Maybe<Scalars['Boolean']>;
};

export type LeaveRecordsOutput = {
  __typename?: 'LeaveRecordsOutput';
  LeaveHeaderStatus?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  LeaveHeaderID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  LeaveTypeName?: Maybe<Scalars['String']>;
  LeaveFromDate?: Maybe<Scalars['DateTime']>;
  LeaveToDate?: Maybe<Scalars['DateTime']>;
  TotalDay?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocumentName?: Maybe<Scalars['String']>;
  DocumentFile?: Maybe<Scalars['String']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
  ApprovedBy?: Maybe<Scalars['String']>;
  LeaveDetails?: Maybe<Array<LeaveDetailsEntity>>;
  IsShortPeriodNotice?: Maybe<Scalars['Boolean']>;
  ReportTo?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  IsEmergencyLeave?: Maybe<Scalars['Boolean']>;
  CancelledDate?: Maybe<Scalars['DateTime']>;
  CancelledBy?: Maybe<Scalars['String']>;
  RejectedRemarks?: Maybe<Scalars['String']>;
};

export type LeaveRoundingDetailEntity = InterfaceBase & {
  __typename?: 'LeaveRoundingDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveRoundingDetailID: Scalars['String'];
  LeaveRoundingID?: Maybe<Scalars['String']>;
  StartValue: Scalars['Float'];
  EndValue: Scalars['Float'];
  RoundTo: Scalars['Float'];
  LeaveRounding?: Maybe<LeaveRoundingEntity>;
};

export type LeaveRoundingDetailInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  LeaveRoundingDetailID?: Maybe<Scalars['String']>;
  StartValue: Scalars['Float'];
  EndValue: Scalars['Float'];
  RoundTo: Scalars['Float'];
  UserLogs?: Maybe<UserLogInput>;
};

export type LeaveRoundingEntity = InterfaceBase & {
  __typename?: 'LeaveRoundingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveRoundingID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  LeaveRoundingDetails?: Maybe<Array<LeaveRoundingDetailEntity>>;
  Companies?: Maybe<Array<CompanyEntity>>;
};

export type LeaveRoundingInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  LeaveRoundingID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  UserLogs?: Maybe<UserLogInput>;
};

export enum LeaveRuleType {
  Los = 'LOS',
  Gender = 'Gender',
  IsConfirmed = 'Is_Confirmed'
}

export type LeaveTempEntitlementEntity = InterfaceBase & {
  __typename?: 'LeaveTempEntitlementEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveTempEntitlementID: Scalars['String'];
  EmployeeID: Scalars['String'];
  LeaveTypeID: Scalars['String'];
  LeaveHeaderID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  Year: Scalars['Float'];
  TotalDay: Scalars['Float'];
  AccumulateTotalDay: Scalars['Float'];
};

export type LeaveTempEntitlementInput = {
  EmployeeID: Scalars['String'];
  LeaveTypeID: Scalars['String'];
  LeaveHeaderID: Scalars['String'];
  StartDate: Scalars['DateTime'];
  EndDate: Scalars['DateTime'];
  Year: Scalars['Float'];
  TotalDay: Scalars['Float'];
  AccumulateTotalDay: Scalars['Float'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeaveTemplateEntity = InterfaceBase & {
  __typename?: 'LeaveTemplateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveTemplateID: Scalars['String'];
  JobGradePolicyID?: Maybe<Scalars['String']>;
  LeavePolicyID?: Maybe<Scalars['String']>;
  JobGradePolicy?: Maybe<JobGradePolicyEntity>;
  LeaveItem?: Maybe<LeaveItemEntity>;
};

export type LeaveTemplateInput = {
  LeaveTemplateID?: Maybe<Scalars['String']>;
  LeavePolicyID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LeaveTypeBalance = {
  __typename?: 'LeaveTypeBalance';
  LeaveItem?: Maybe<LeaveItemEntity>;
  EntitlementDayByLOS?: Maybe<Scalars['Float']>;
  ToDateEarned?: Maybe<Scalars['Float']>;
  ErrorMessage?: Maybe<Scalars['String']>;
  Entitlement?: Maybe<Scalars['Float']>;
  BroughtForward?: Maybe<Scalars['Float']>;
  Taken?: Maybe<Scalars['Float']>;
  Balance?: Maybe<Scalars['Float']>;
  OccurrenceBalance?: Maybe<Scalars['Float']>;
};

export type LeaveTypeEntity = InterfaceBase & {
  __typename?: 'LeaveTypeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveTypeID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  LeaveTypeCode?: Maybe<Scalars['String']>;
  PayItem?: Maybe<PayItemEntity>;
  LeavePolicies?: Maybe<Array<LeaveItemEntity>>;
};

export type LeaveTypeInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  LeaveTypeCode?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type LifeTimeError = {
  __typename?: 'LifeTimeError';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

export type ListEntityRoleUserAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  companyID: Scalars['String'];
  roleUserIDs: Array<RoleUserInput>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String'];
  user: UserResponse;
};

export type Md_BankEntity = {
  __typename?: 'MD_BankEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  BankName?: Maybe<Scalars['String']>;
  AccountName?: Maybe<Scalars['String']>;
  AccountNumber?: Maybe<Scalars['String']>;
  BankSwiftCode?: Maybe<Scalars['String']>;
  OrganizationCode?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Postcode?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  ContactName?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  EmployeeBank?: Maybe<Scalars['String']>;
  IBGCode?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_CompanyEntity = {
  __typename?: 'MD_CompanyEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  RegistrationNo?: Maybe<Scalars['String']>;
  EmailAddress?: Maybe<Scalars['String']>;
  CompanyWebsiteURL?: Maybe<Scalars['String']>;
  TelNo?: Maybe<Scalars['String']>;
  FaxNo?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Postcode?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  State?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  EPFNo?: Maybe<Scalars['String']>;
  SOCSONo?: Maybe<Scalars['String']>;
  LHDNNo?: Maybe<Scalars['String']>;
  EISNo?: Maybe<Scalars['String']>;
  Angkasa?: Maybe<Scalars['String']>;
  AngkasaNo?: Maybe<Scalars['String']>;
  ASB?: Maybe<Scalars['String']>;
  ASBNo?: Maybe<Scalars['String']>;
  KWAP?: Maybe<Scalars['String']>;
  KWAPNo?: Maybe<Scalars['String']>;
  TabungHaji?: Maybe<Scalars['String']>;
  TabungHajiNo?: Maybe<Scalars['String']>;
  Zakat?: Maybe<Scalars['String']>;
  ZakatNo?: Maybe<Scalars['String']>;
  HRDFNo?: Maybe<Scalars['String']>;
  HRDFRate?: Maybe<Scalars['Float']>;
  StatutorySignatoryEmployeeNo?: Maybe<Scalars['String']>;
  StatutorySignatoryEmployeeName?: Maybe<Scalars['String']>;
  StatutorySignatoryCompany?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
  Sector?: Maybe<Scalars['String']>;
};

export type Md_DepartmentDivisionEntity = {
  __typename?: 'MD_DepartmentDivisionEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  DepartmentType?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_EmployeeDependentEntity = {
  __typename?: 'MD_EmployeeDependentEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  DependentName?: Maybe<Scalars['String']>;
  Relationship?: Maybe<Scalars['String']>;
  EmergencyContactPerson?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Scalars['String']>;
  NRICNo?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  DateofBirth?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  Occupation?: Maybe<Scalars['String']>;
  PCBNo?: Maybe<Scalars['String']>;
  ChildEducation?: Maybe<Scalars['String']>;
  ChildEligibleRate?: Maybe<Scalars['String']>;
  Disabled?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_EmployeeEntity = {
  __typename?: 'MD_EmployeeEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  NickName?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  MobileNo?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Scalars['String']>;
  NRICNo?: Maybe<Scalars['String']>;
  PassportNo?: Maybe<Scalars['String']>;
  DateofBirth?: Maybe<Scalars['String']>;
  Gender?: Maybe<Scalars['String']>;
  MaritalStatus?: Maybe<Scalars['String']>;
  Race?: Maybe<Scalars['String']>;
  Religion?: Maybe<Scalars['String']>;
  Disabled?: Maybe<Scalars['Boolean']>;
  PermanentAddress?: Maybe<Scalars['String']>;
  PermanentPostcode?: Maybe<Scalars['String']>;
  PermanentCity?: Maybe<Scalars['String']>;
  PermanentState?: Maybe<Scalars['String']>;
  PermanentCountry?: Maybe<Scalars['String']>;
  IsSameAddress?: Maybe<Scalars['String']>;
  PresentAddress?: Maybe<Scalars['String']>;
  PresentPostcode?: Maybe<Scalars['String']>;
  PresentCity?: Maybe<Scalars['String']>;
  PresentState?: Maybe<Scalars['String']>;
  PresentCountry?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_EmployeeJobInfoEntity = {
  __typename?: 'MD_EmployeeJobInfoEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  HeadofDepartment?: Maybe<Scalars['String']>;
  JobDesignation?: Maybe<Scalars['String']>;
  JobGrade?: Maybe<Scalars['String']>;
  SupervisorEmployeeNo?: Maybe<Scalars['String']>;
  SupervisorName?: Maybe<Scalars['String']>;
  SupervisorCompany?: Maybe<Scalars['String']>;
  EmployeeStatus?: Maybe<Scalars['String']>;
  EmployeeType?: Maybe<Scalars['String']>;
  JoinDate?: Maybe<Scalars['DateTime']>;
  ResignedDate?: Maybe<Scalars['DateTime']>;
  Probation?: Maybe<Scalars['String']>;
  ConfirmedDate?: Maybe<Scalars['DateTime']>;
  CalendarState?: Maybe<Scalars['String']>;
  RecruitmentSource?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_EmployeeSalaryEntity = {
  __typename?: 'MD_EmployeeSalaryEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  Reason?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_EmployeeStatutoryInfoEntity = {
  __typename?: 'MD_EmployeeStatutoryInfoEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  Company?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  Bank?: Maybe<Scalars['String']>;
  PayMethod?: Maybe<Scalars['String']>;
  BankAccountNo?: Maybe<Scalars['String']>;
  EPF?: Maybe<Scalars['Boolean']>;
  EPFNo?: Maybe<Scalars['String']>;
  EPFEEVERate?: Maybe<Scalars['String']>;
  EPFERVERate?: Maybe<Scalars['String']>;
  SOCSO?: Maybe<Scalars['Boolean']>;
  SOCSONo?: Maybe<Scalars['String']>;
  LHDN?: Maybe<Scalars['Boolean']>;
  LHDNNo?: Maybe<Scalars['String']>;
  TaxMaritalStatus?: Maybe<Scalars['String']>;
  ResidentStatus?: Maybe<Scalars['String']>;
  EIS?: Maybe<Scalars['Boolean']>;
  EISNo?: Maybe<Scalars['String']>;
  Angkasa?: Maybe<Scalars['Boolean']>;
  AngkasaNo?: Maybe<Scalars['String']>;
  ASB?: Maybe<Scalars['Boolean']>;
  ASBNo?: Maybe<Scalars['String']>;
  KWAP?: Maybe<Scalars['Boolean']>;
  KWAPNo?: Maybe<Scalars['String']>;
  TabungHaji?: Maybe<Scalars['Boolean']>;
  TabungHajiNo?: Maybe<Scalars['String']>;
  Zakat?: Maybe<Scalars['Boolean']>;
  ZakatNo?: Maybe<Scalars['String']>;
  ZakatState?: Maybe<Scalars['String']>;
  ZakatPercentage?: Maybe<Scalars['String']>;
  HRDF?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_FixedAllwDedEntity = {
  __typename?: 'MD_FixedAllwDedEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  PayCategory?: Maybe<Scalars['String']>;
  PayItem?: Maybe<Scalars['String']>;
  StartMonth?: Maybe<Scalars['DateTime']>;
  EndMonth?: Maybe<Scalars['DateTime']>;
  Quantity?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  PayrollCycle?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_JobDesignationEntity = {
  __typename?: 'MD_JobDesignationEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  JobDesignationName?: Maybe<Scalars['String']>;
  JobGradeName?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_JobGradeEntity = {
  __typename?: 'MD_JobGradeEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  JobGradeName?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_PayItemEntity = {
  __typename?: 'MD_PayItemEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  PayItemType?: Maybe<Scalars['String']>;
  EAPartF?: Maybe<Scalars['String']>;
  Formula?: Maybe<Scalars['String']>;
  LHDN?: Maybe<Scalars['Boolean']>;
  EPF?: Maybe<Scalars['Boolean']>;
  SOCSO?: Maybe<Scalars['Boolean']>;
  AddRemuneration?: Maybe<Scalars['Boolean']>;
  EPFVE?: Maybe<Scalars['Boolean']>;
  Bonus?: Maybe<Scalars['Boolean']>;
  EIS?: Maybe<Scalars['Boolean']>;
  HRDF?: Maybe<Scalars['Boolean']>;
  EAForm?: Maybe<Scalars['String']>;
  CP21?: Maybe<Scalars['String']>;
  CP22A?: Maybe<Scalars['String']>;
  CP22B?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_PayrollCutOffEntity = {
  __typename?: 'MD_PayrollCutOffEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  PayrollCutOffDate?: Maybe<Scalars['Float']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_PayrollCycleEntity = {
  __typename?: 'MD_PayrollCycleEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  PayrollCycle?: Maybe<Scalars['String']>;
  EPF?: Maybe<Scalars['Boolean']>;
  EPFVE?: Maybe<Scalars['Boolean']>;
  SOCSO?: Maybe<Scalars['Boolean']>;
  MTD?: Maybe<Scalars['Boolean']>;
  Zakat?: Maybe<Scalars['Boolean']>;
  EIS?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_PayrollHistoryEntity = {
  __typename?: 'MD_PayrollHistoryEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  PayrollMonth?: Maybe<Scalars['DateTime']>;
  PayrollCycle?: Maybe<Scalars['String']>;
  PayItem?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type Md_RecruitmentSourceEntity = {
  __typename?: 'MD_RecruitmentSourceEntity';
  Id: Scalars['Float'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  No?: Maybe<Scalars['String']>;
  RecruitmentSource?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  ImportRemark?: Maybe<Scalars['String']>;
};

export type MtdCalcOutput = {
  __typename?: 'MTDCalcOutput';
  MTD?: Maybe<Scalars['Float']>;
  NetMTD?: Maybe<Scalars['Float']>;
  CurrentMonthMTD?: Maybe<Scalars['Float']>;
  BasicSalary?: Maybe<Scalars['Float']>;
  CalcInput?: Maybe<MtdCalculationInput>;
  TotalOneTimeChargableTax?: Maybe<Scalars['Float']>;
  TotalOneTimeChargableEPF?: Maybe<Scalars['Float']>;
  Zakat?: Maybe<Scalars['Float']>;
  EPF?: Maybe<Scalars['Float']>;
  MTD_A?: Maybe<Scalars['Float']>;
  MTD_B?: Maybe<Scalars['Float']>;
  MTD_C?: Maybe<Scalars['Float']>;
  CS?: Maybe<Scalars['Float']>;
  NonResidentRate?: Maybe<Scalars['Float']>;
};

export type MtdCalculationInput = {
  __typename?: 'MTDCalculationInput';
  Y?: Maybe<Scalars['Float']>;
  K?: Maybe<Scalars['Float']>;
  Y1?: Maybe<Scalars['Float']>;
  K1?: Maybe<Scalars['Float']>;
  Yt?: Maybe<Scalars['Float']>;
  Kt?: Maybe<Scalars['Float']>;
  Y2?: Maybe<Scalars['Float']>;
  K2?: Maybe<Scalars['Float']>;
  KA?: Maybe<Scalars['Float']>;
  YtA?: Maybe<Scalars['Float']>;
  KtA?: Maybe<Scalars['Float']>;
  K2A?: Maybe<Scalars['Float']>;
  CS?: Maybe<Scalars['Float']>;
  B2?: Maybe<Scalars['Float']>;
  T2?: Maybe<Scalars['Float']>;
  P2?: Maybe<Scalars['Float']>;
  M2?: Maybe<Scalars['Float']>;
  R2?: Maybe<Scalars['Float']>;
  n?: Maybe<Scalars['Float']>;
  n1?: Maybe<Scalars['Float']>;
  D?: Maybe<Scalars['Float']>;
  Du?: Maybe<Scalars['Float']>;
  S?: Maybe<Scalars['Float']>;
  Su?: Maybe<Scalars['Float']>;
  Qc?: Maybe<Scalars['Float']>;
  ELP?: Maybe<Scalars['Float']>;
  LP1?: Maybe<Scalars['Float']>;
  P?: Maybe<Scalars['Float']>;
  P1?: Maybe<Scalars['Float']>;
  K22?: Maybe<Scalars['Float']>;
  Y22?: Maybe<Scalars['Float']>;
  M?: Maybe<Scalars['Float']>;
  R?: Maybe<Scalars['Float']>;
  B?: Maybe<Scalars['Float']>;
  T?: Maybe<Scalars['Float']>;
  Z?: Maybe<Scalars['Float']>;
  X?: Maybe<Scalars['Float']>;
  TaxResult?: Maybe<Scalars['Float']>;
};

export type MtdOutput = {
  __typename?: 'MTDOutput';
  EmployeeID: Scalars['String'];
  FullName: Scalars['String'];
  IsBasicSalary?: Maybe<Scalars['Boolean']>;
  NetMTDBeforeZakat?: Maybe<Scalars['Float']>;
  NetMTD?: Maybe<Scalars['Float']>;
  RoundedNetMTD?: Maybe<Scalars['Float']>;
  Zakat?: Maybe<Scalars['Float']>;
  TaxPartA1MTDOutput?: Maybe<MtdCalcOutput>;
  TaxPartA2MTDOutput?: Maybe<MtdCalcOutput>;
};

export enum Marital {
  Divorced = 'Divorced',
  Married = 'Married',
  Seperated = 'Seperated',
  Single = 'Single',
  Widowed = 'Widowed'
}

export type MaxOtPayItemInput = {
  PayItemID?: Maybe<Scalars['String']>;
};

export type MaxOtPayItemObject = {
  __typename?: 'MaxOTPayItemObject';
  PayItemID?: Maybe<Scalars['String']>;
};

export type MobileCheckInEntity = InterfaceBase & {
  __typename?: 'MobileCheckInEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  MobileCheckInID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Lat?: Maybe<Scalars['Float']>;
  Long?: Maybe<Scalars['Float']>;
  Radius: Scalars['Float'];
  Company?: Maybe<CompanyEntity>;
  CompanyID?: Maybe<Scalars['String']>;
};

export type MobileCheckInInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  MobileCheckInID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['String']>;
  Lat?: Maybe<Scalars['Float']>;
  Long?: Maybe<Scalars['Float']>;
  Radius?: Maybe<Scalars['Float']>;
};

export enum Module {
  Tms = 'TMS',
  TmsJobGradePolicy = 'TMS__JOB_GRADE_POLICY',
  TmsShiftGroupAssignment = 'TMS__SHIFT_GROUP_ASSIGNMENT',
  TmsAttendanceExclusionList = 'TMS__ATTENDANCE_EXCLUSION_LIST'
}

export type MonthlyAllowanceInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  PayItemID: Scalars['String'];
  RoundingPolicyID?: Maybe<Scalars['String']>;
  Condition?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['String']>;
};

export type MonthlyAllowancePolicyEntity = InterfaceBase & {
  __typename?: 'MonthlyAllowancePolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  MonthlyAllowanceID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Condition: Scalars['String'];
  Value: Scalars['String'];
  PayItem?: Maybe<PayItemEntity>;
  PayItemID?: Maybe<Scalars['String']>;
  RoundingPolicy?: Maybe<AllowanceDeductionRoundingEntity>;
  RoundingPolicyID?: Maybe<Scalars['String']>;
};

export enum MonthlyCutOff {
  EarnOnStartingOfTheMonth = 'Earn_On_Starting_Of_The_Month',
  EarnOnEndOfTheMonth = 'Earn_On_End_Of_The_Month',
  EarnAfterCompletedMonth = 'Earn_After_Completed_Month'
}

export type MonthlyError = {
  __typename?: 'MonthlyError';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

export type MonthlyTransactionInput = {
  CompanyID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  PayCycleIDs?: Maybe<Array<Scalars['String']>>;
  EmploymentTypes?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
};

export enum MovementType {
  ProbationExtension = 'Probation_Extension',
  NewHire = 'NewHire',
  Confirmation = 'Confirmation',
  Promotion = 'Promotion',
  Transfer = 'Transfer',
  Demotion = 'Demotion',
  ExitCompany = 'Exit_Company'
}

export type Mutation = {
  __typename?: 'Mutation';
  PostAttendanceRecord: Scalars['Boolean'];
  ReverseAttendanceRecord?: Maybe<Scalars['Boolean']>;
  deleteAttendanceRecord: Scalars['Boolean'];
  createAttendanceRecord: AttendanceRecordEntity;
  updateAttendanceRecord: Scalars['Float'];
  DeleteTimeAttendanceRecord: Scalars['Boolean'];
  AttendanceRecordBatchDelete: Scalars['Boolean'];
  AttendanceRecordBatch: Scalars['Boolean'];
  SubmitMyClaim: ClaimHeaderEntity;
  DeleteClaimRecords: Scalars['Boolean'];
  SubmitClaimForApproval: Scalars['Boolean'];
  DoUpdateClaim: Scalars['Boolean'];
  MyClaimInsert: ClaimInsertOutput;
  ClaimValidityUpdate: Scalars['Boolean'];
  ClaimValidityInsert: Scalars['Boolean'];
  ClaimTypeInsert: ClaimTypeEntity;
  ClaimTypeUpdate: Scalars['Boolean'];
  ClaimTypeDelete: Scalars['Boolean'];
  ClaimTypeTemplateInsert: Scalars['Boolean'];
  ClaimTypeTemplateUpdate: Scalars['Boolean'];
  ClaimTypeTemplateDelete: Scalars['Boolean'];
  ClaimJobGradePolicyInsert: Scalars['Boolean'];
  ClaimJobGradePolicyUpdate: Scalars['Boolean'];
  SubmitManagementClaimRecords?: Maybe<Scalars['Boolean']>;
  ApproveClaimRecords?: Maybe<Scalars['Boolean']>;
  CancelClaimRecords?: Maybe<Scalars['Boolean']>;
  DeleteApprovedClaimRecords?: Maybe<Scalars['Boolean']>;
  VerifyApprovedClaimDetail?: Maybe<Scalars['Boolean']>;
  PostClaimRecords?: Maybe<Scalars['Boolean']>;
  ReverseClaimRecords?: Maybe<Scalars['Boolean']>;
  InsertClaimCutOffPeriod: Scalars['Boolean'];
  BankInsert: BankInfoEntity;
  BankUpdate: Scalars['Boolean'];
  BankDelete: Scalars['Boolean'];
  CompanyOffDaysDelete: Scalars['Boolean'];
  CompanyOffDaysBulkInsert: Scalars['Boolean'];
  OffRestDayDelete: Scalars['Boolean'];
  OffRestDayBulkInsert: Scalars['Boolean'];
  CalendarOffDayUpdate: Scalars['Boolean'];
  CalendarOffDayInsert: CalendarOffDayEntity;
  CompanyDocumentInsert: DocumentsEntity;
  CompanyDocumentDelete: Scalars['Boolean'];
  DocumentInsert: DocumentsEntity;
  DocumentUpdate: DocumentsEntity;
  DocumentUpdateParentID: Scalars['Boolean'];
  DocumentDelete: Scalars['Boolean'];
  EmployeeProfileInsertUpdate: Scalars['Boolean'];
  CompanyInsert: Scalars['Boolean'];
  CompanyUpdate: Scalars['Boolean'];
  CompanyLeaveSettingUpdate: Scalars['Boolean'];
  CompanyClaimSettingUpdate: Scalars['Boolean'];
  CompanyOnlyUpdate: Scalars['Boolean'];
  JobDesignationInsert: Scalars['Boolean'];
  JobDesignationUpdate: Scalars['Boolean'];
  JobDesignationDelete: Scalars['Boolean'];
  RecruitmentSourceInsert: RecruitmentSourceEntity;
  RecruitmentSourceUpdate: Scalars['Boolean'];
  RecruitmentSourceDelete: Scalars['Boolean'];
  CompanyStatutoryInsert: CompanyStatutoryEntity;
  CompanyStatutoryUpdate: Scalars['Boolean'];
  DepartmentInsert: DepartmentEntity;
  DepartmentUpdate: Scalars['Boolean'];
  DepartmentDelete: Scalars['Boolean'];
  DivisionInsert: DepartmentEntity;
  DivisionUpdate: Scalars['Boolean'];
  DivisionDelete: Scalars['Boolean'];
  CreateInsuranceScheme: Scalars['Boolean'];
  UpdateInsuranceScheme: Scalars['Boolean'];
  DeleteInsuranceScheme: Scalars['Boolean'];
  InterbankInsert: InterbankGiroEntity;
  InterbankBulkInsert: Scalars['Boolean'];
  InterbankDelete: Scalars['Boolean'];
  InterbankUpdate: Scalars['Boolean'];
  InterbankSingleUpdate: Scalars['Boolean'];
  JobAccountInsert: Scalars['Boolean'];
  JobAccountUpdate: Scalars['Boolean'];
  JobAccountDelete: Scalars['Boolean'];
  JobGradeInsert: JobGradeEntity;
  JobGradeUpdate: Scalars['Boolean'];
  JobGradeDelete: Scalars['Boolean'];
  LeaveRoundingBulkInsert: Scalars['Boolean'];
  LeaveRoundingInsert: Scalars['Boolean'];
  UpdateLeaveRounding: Scalars['Boolean'];
  LeaveRoundingUpdate: Scalars['Boolean'];
  LeaveRoundingDelete: Scalars['Boolean'];
  LeaveCompulsoryPolicyBulkInsert: Scalars['Boolean'];
  LeaveCompulsoryPolicyUpdate: Scalars['Boolean'];
  LHDNReportInsert: Scalars['Boolean'];
  LHDNReportUpdate: Scalars['Boolean'];
  LHDNReportCopyInsert: Scalars['Boolean'];
  NotificationPolicyInsert: NotificationPolicyEntity;
  NotificationPolicyUpdate: Scalars['Boolean'];
  NotificationPolicyDelete: Scalars['Boolean'];
  NotificationPolicyConditionInsert: NotificationPolicyConditionEntity;
  NotificationPolicyConditionUpdate: Scalars['Boolean'];
  PayrollCycleInsert: PayrollCycleEntity;
  PayrollCycleUpdate: Scalars['Boolean'];
  PayrollCycleDelete: Scalars['Boolean'];
  CreateSkillSet: Scalars['Boolean'];
  UpdateSkillSet: Scalars['Boolean'];
  DeleteSkillSet: Scalars['Boolean'];
  NewsDelete: Scalars['Boolean'];
  NewsInsert: Scalars['Boolean'];
  NewsUpdate: Scalars['Boolean'];
  NewsPublish: Scalars['Boolean'];
  NewsDuplicate: Scalars['Boolean'];
  testDocNum: Scalars['String'];
  DocumentNumberingInsert: Scalars['Boolean'];
  DocumentNumberingUpdate: Scalars['Boolean'];
  DocumentNumberingDelete: Scalars['Boolean'];
  DocumentNumberingUpdateStatus: Scalars['Boolean'];
  DocumentNumberAssignment: Scalars['Boolean'];
  BatchEntryByShiftInsert: Scalars['Boolean'];
  updateBatchEntryByShiftPattern: Scalars['Boolean'];
  CreateEmployeeAppraisal: Scalars['Boolean'];
  UpdateEmployeeAppraisal: Scalars['Boolean'];
  DeleteEmployeeAppraisal: Scalars['Boolean'];
  CareerLogInsert: Scalars['Boolean'];
  CareerLogUpdate: Scalars['Boolean'];
  CreateEmployeeDiary: Scalars['Boolean'];
  UpdateEmployeeDiary: Scalars['Boolean'];
  DeleteEmployeeDiary: Scalars['Boolean'];
  EmployeeInfoInsert: EmployeeEntity;
  EmployeeProfilePicUpdate: Scalars['Boolean'];
  EmployeeInfoUpdate: Scalars['Boolean'];
  EmployeeCareerLogUpdate: Scalars['Boolean'];
  EmployeeStatutoryInsert: EmployeeStatutoryEntity;
  EmployeeStatutoryUpdate: Scalars['Boolean'];
  EmployeePrevContributionInsert: EmployeePrevContributionEntity;
  EmployeePrevContributionUpdate: Scalars['Boolean'];
  EmployeeDependentInsert: EmployeeDependentsEntity;
  EmployeeDependentUpdate: Scalars['Boolean'];
  EmployeeTaxReliefBulkInsert: Scalars['Boolean'];
  EmployeeTaxReliefBulkUpdate: Scalars['Boolean'];
  EmployeeTaxReliefInsert: Scalars['Boolean'];
  EmployeeTaxReliefUpdate: Scalars['Boolean'];
  EmployeeAssetInsert: EmployeeAssetEntity;
  EmployeeAssetUpdate: Scalars['Boolean'];
  EmployeeAssetDelete: Scalars['Boolean'];
  EmployeeBenefitInKindInsert: EmployeeBenefitInKindEntity;
  EmployeeBenefitInKindUpdate: Scalars['Boolean'];
  EmployeeBenefitInKindDelete: Scalars['Boolean'];
  EmployeeDependentDelete: Scalars['Boolean'];
  EmployeeSalaryInsert: Scalars['Boolean'];
  EmployeeSalaryUpdate: Scalars['Boolean'];
  EmployeeSalaryDelete: Scalars['Boolean'];
  EmployeeProfileUpload: EmployeeEntity;
  UpdateEmployeeLastLoginCompany: Scalars['Boolean'];
  EmployeeCP22Update: Scalars['Boolean'];
  NewsReadInsert: Scalars['Boolean'];
  NotificationUpdate: Scalars['Boolean'];
  CreateEmployeeInsuranceScheme: Scalars['Boolean'];
  UpdateEmployeeInsuranceScheme: Scalars['Boolean'];
  DeleteEmployeeInsuranceScheme: Scalars['Boolean'];
  UpdateNotificationReadStatus: Scalars['Boolean'];
  UpdateAllNotificationReadStatus: Scalars['Boolean'];
  CreateEmployeeTraining: Scalars['Boolean'];
  UpdateEmployeeTraining: Scalars['Boolean'];
  DeleteEmployeeTraining: Scalars['Boolean'];
  PostFromPlatformLeaveTypes: Scalars['String'];
  SubmitMyLeaveApplication: Scalars['Boolean'];
  PostLeaveApplication: Scalars['Boolean'];
  SubmitLeaveApplication: Scalars['Boolean'];
  SubmitLeaveApplicationV2: LeaveHeaderEntity;
  DeleteLeaveApplication: Scalars['Boolean'];
  DeletePendingLeaveApplication: Scalars['Boolean'];
  DeleteApprovedLeaveApplication: Scalars['Boolean'];
  PostLeaveOpeningYear: Scalars['Boolean'];
  LeaveTypeInsert: LeaveTypeEntity;
  LeaveTypeUpdate: Scalars['Boolean'];
  LeaveTypeDelete: Scalars['Boolean'];
  LeaveJobGradePolicyInsert: Scalars['Boolean'];
  LeaveJobGradePolicyUpdate: Scalars['Boolean'];
  LeavePolicyUpdate: Scalars['Boolean'];
  LeavePolicyDelete: Scalars['Boolean'];
  LeavePolicyInsert: Scalars['Boolean'];
  LeaveTypeTemplateInsert: Scalars['Boolean'];
  LeaveTypeTemplateUpdate: Scalars['Boolean'];
  LeaveTypeTemplateDelete: Scalars['Boolean'];
  LeaveRecordsApproval: Scalars['Boolean'];
  LeaveRecordsInsert: LeaveHeaderEntity;
  LeaveRecordsUpdate: Scalars['Boolean'];
  LeaveAdjustmentInsert: Scalars['Boolean'];
  LeaveStatusUpdate: Scalars['Boolean'];
  LeaveAdjustmentUpdate: Scalars['Boolean'];
  LeaveAdjustmentDelete: Scalars['Boolean'];
  PostLeaveRecords: Scalars['Boolean'];
  RevertLeaveRecords: Scalars['Boolean'];
  SaveCompulsoryLeave: Scalars['Boolean'];
  InsertLeaveCutOffPeriods: Scalars['Boolean'];
  SubmitYearEndCarryForward: Scalars['Boolean'];
  LeaveOutOfStateInsert: LeaveOutOfStateEntity;
  LeaveOufOfStateUpdate: Scalars['Boolean'];
  LeaveOutOfStateDelete: Scalars['Boolean'];
  OvertimeInsert: OvertimeEntity;
  OvertimeDraft: Scalars['Boolean'];
  OvertimeBatch: Scalars['Boolean'];
  OvertimeUpdate: Scalars['Boolean'];
  OvertimeDelete: Scalars['Boolean'];
  OvertimeRoundingInsert: OvertimeRoundingSettingEntity;
  OvertimeRoundingSettingUpdate: Scalars['Boolean'];
  OvertimeRoundingSettingDelete: Scalars['Boolean'];
  OvertimeTemplateInsert: OvertimeTemplateEntity;
  OvertimeTemplateUpdate: Scalars['Boolean'];
  OvertimeTemplateDelete: Scalars['Boolean'];
  DeletePayrollHistory: Scalars['Boolean'];
  DeletePayrollProcess: Scalars['Boolean'];
  UpdatePayrollProcessStatus: Scalars['Boolean'];
  InsertPayPeriodListing: Scalars['Boolean'];
  InsertSubsequentPayPeriodListing: Array<PayPeriodEntity>;
  PayItemInsert: Scalars['Boolean'];
  PayItemUpdate: Scalars['Boolean'];
  PayItemDelete: Scalars['Boolean'];
  RecurringPayInsert: Scalars['Boolean'];
  RecurringPayUpdate: Scalars['Boolean'];
  RecurringPayDelete: Scalars['Boolean'];
  CP159Update: Scalars['Boolean'];
  OneTimePayDelete: Scalars['Boolean'];
  OneTimePayInsert: Scalars['Boolean'];
  OneTimePayUpdate: Scalars['Boolean'];
  InsertPublishPaySlip: Scalars['Boolean'];
  PublishPaySlip: Scalars['Boolean'];
  PublishEA: Scalars['Boolean'];
  DeleteMonthlyTrx: Scalars['Boolean'];
  FreezePayrollInsert: Scalars['Boolean'];
  EmployeeFreezePayrollDelete: Scalars['Boolean'];
  DeleteApprovedRequisition: Scalars['Boolean'];
  RequisitionManagementApprovalUpdate: Scalars['Boolean'];
  RequisitionInsert: RequisitionEntity;
  RequisitionUpdate: Scalars['Boolean'];
  RequisitionDelete: Scalars['Boolean'];
  RequisitionSubmit: Scalars['Boolean'];
  EAPartFInsert: Scalars['Boolean'];
  EAPartFUpdate: Scalars['Boolean'];
  EAPartFDelete: Scalars['Boolean'];
  EAPartFCopyInsert: Scalars['Boolean'];
  EISPolicyUpdate: Scalars['Boolean'];
  EPFPolicyUpdate: Scalars['Boolean'];
  HolidayBulkInsert: Scalars['Boolean'];
  HolidayBulkDelete: Scalars['Boolean'];
  HolidayBulkUpdate: Scalars['Boolean'];
  SocsoPolicyUpdate: Scalars['Boolean'];
  TaxReliefInsert: Scalars['Boolean'];
  TaxReliefUpdate: Scalars['Boolean'];
  TaxReliefDelete: Scalars['Boolean'];
  TaxReliefCopyInsert: Scalars['Boolean'];
  MonthlyAttendanceProcessing: Scalars['Boolean'];
  AttendanceProcessing: Scalars['Boolean'];
  UpdateAdjustedValue: Scalars['Boolean'];
  createChangeShift: ChangeShiftEntity;
  updateChangeShift: Scalars['Boolean'];
  deleteChangeShift: Scalars['Boolean'];
  updateChangeShiftSubmit: Scalars['Boolean'];
  createDailyAllowance: Scalars['Boolean'];
  updateDailyAllowance: Scalars['Boolean'];
  deleteDailyAllowance: Scalars['Boolean'];
  createDeductionPolicy: DeductionPolicyEntity;
  updateDeductionPolicyStatus: Scalars['Boolean'];
  updateDeductionPolicy: Scalars['Boolean'];
  createDutyRoster: Scalars['Boolean'];
  DutyRosterBatch: Scalars['Boolean'];
  createBatchEntryByShiftPattern: Scalars['Boolean'];
  DeleteDutyRosterByEmployee: Scalars['Boolean'];
  DeleteDutyRosterForAll: Scalars['Boolean'];
  PostEarnedLeave: Scalars['Boolean'];
  ReverseEarnedLeave: Scalars['Boolean'];
  createJobGradePolicyMatrix: JobGradePolicyMatrixEntity;
  createMobileCheckIn: Scalars['Boolean'];
  updateMobileCheckIn: Scalars['Boolean'];
  deleteMobileCheckIn: Scalars['Boolean'];
  createMonthlyAllowance: Scalars['Boolean'];
  updateMonthlyAllowance: Scalars['Boolean'];
  deleteMonthlyAllowance: Scalars['Boolean'];
  createOvertimeClaimTable: OvertimeClaimTableEntity;
  updateOvertimeClaimTableStatus: Scalars['Boolean'];
  OTReasonUpdate: Scalars['Boolean'];
  OTReasonDelete: Scalars['Boolean'];
  OTReasonStatus: Scalars['Boolean'];
  OTReasonInsert: OvertimeReasonEntity;
  OvertimePolicyInsert: Scalars['Boolean'];
  OvertimePolicyUpdate: Scalars['Boolean'];
  OvertimePolicyDelete: Scalars['Boolean'];
  createAllowanceDeduction: Scalars['Boolean'];
  updateAllowanceDeduction: Scalars['Boolean'];
  deleteAllowanceDeduction: Scalars['Boolean'];
  createOTRounding: Scalars['Boolean'];
  updateOTRounding: Scalars['Boolean'];
  deleteOTRounding: Scalars['Boolean'];
  ShiftSettingInsert: Scalars['Boolean'];
  ShiftSetupUpdate: Scalars['Boolean'];
  ShiftSetupDelete: Scalars['Boolean'];
  createShiftGroup: Scalars['Boolean'];
  updateShiftGroup: Scalars['Boolean'];
  updateShiftGroupStatus: Scalars['Boolean'];
  createShiftPattern: Scalars['Boolean'];
  updateShiftPattern: Scalars['Boolean'];
  deleteShiftPattern: Scalars['Boolean'];
  updateTemplateLibraryAssignment: TemplateLibraryAssignmentEntity;
  createTemplateLibraryAssignment: Scalars['Float'];
  replaceTemplateLibraryAssignment: Scalars['Float'];
  deleteTemplateLibraryAssignment: Scalars['Float'];
  ShiftGroupAssignment: Scalars['Float'];
  TimeOffInsert: TimeOffEntity;
  TimeOffUpdate: Scalars['Boolean'];
  TimeOffDelete: Scalars['Boolean'];
  createTimeOff: Scalars['Boolean'];
  TimeOffReasonInput: TimeOffReasonEntity;
  TimeOffReasonUpdate: Scalars['Boolean'];
  TimeOffReasonDelete: Scalars['Boolean'];
  TimeOffSettingInsert: TimeOffSettingEntity;
  TimeOffSettingUpdate: Scalars['Boolean'];
  TimeOffSettingDelete: Scalars['Boolean'];
  ToDoStatusUpdate: Scalars['Boolean'];
  CreateRolePermission: Scalars['Boolean'];
  UpdateRolePermission: Scalars['Boolean'];
  DeleteRolePermission: Scalars['Boolean'];
  CreateEntityRoleUser: Scalars['Boolean'];
  CreateListEntityRoleUser: Scalars['Boolean'];
  AllowancePolicyInsert: AllowanceBenfitEntity;
  AllowancePolicyUpdate: Scalars['Boolean'];
  AllowanceEntitlementInsert: AllowanceItemEntity;
  AllowanceEntitlementUpdate: Scalars['Boolean'];
  UpdateLastProcess: Scalars['Boolean'];
  PayProcessInsert: Scalars['Boolean'];
  employeeAppLogin: EmployeeAppLoginResponse;
  createUser: UserEntity;
  createSuperUser: UserEntity;
  resendInvitation: Scalars['Boolean'];
  updateUser: UserEntity;
  activateUser: Scalars['Boolean'];
  login: LoginResponse;
  changePassword: Scalars['Boolean'];
  logout: Scalars['Boolean'];
  employeeLogout: Scalars['Boolean'];
  forgotPassword: Scalars['Boolean'];
  forgotEmployeePassword: Scalars['Boolean'];
  resetEmployeePassword: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  totpSecret: Scalars['String'];
  totpValidate: Scalars['Boolean'];
  createPassword: Scalars['Boolean'];
  unblockUser: Scalars['Boolean'];
  changeEmployeePassword: Scalars['Boolean'];
  UpdateLastLoginCompany: Scalars['Boolean'];
  UserProfilePicUpdate: Scalars['Boolean'];
};


export type MutationPostAttendanceRecordArgs = {
  input: AttendancePostInput;
};


export type MutationReverseAttendanceRecordArgs = {
  AttendanceCalculationIDs: Array<Scalars['String']>;
};


export type MutationDeleteAttendanceRecordArgs = {
  input: AttendanceRecordInput;
};


export type MutationCreateAttendanceRecordArgs = {
  input: AttendanceRecordInput;
};


export type MutationUpdateAttendanceRecordArgs = {
  input: AttendanceRecordInput;
};


export type MutationDeleteTimeAttendanceRecordArgs = {
  AttendanceRecordID: Array<Scalars['String']>;
};


export type MutationAttendanceRecordBatchDeleteArgs = {
  Input: Array<AttendanceRecordInput>;
};


export type MutationAttendanceRecordBatchArgs = {
  Input: Array<AttendanceRecordInput>;
};


export type MutationSubmitMyClaimArgs = {
  input: SubmitClaimRecordsInput;
};


export type MutationDeleteClaimRecordsArgs = {
  ClaimHeaderIDs: Array<Scalars['String']>;
  SubscriptionUserID: Scalars['String'];
};


export type MutationSubmitClaimForApprovalArgs = {
  ClaimHeaderIDs: Array<Scalars['String']>;
};


export type MutationDoUpdateClaimArgs = {
  ClaimDetailsInput: Array<ClaimDetailsInput>;
  input: ClaimHeaderInput;
  ClaimHeaderID: Scalars['String'];
};


export type MutationMyClaimInsertArgs = {
  ClaimDetailsInput: Array<ClaimDetailsInput>;
  ClaimHeaderInput: ClaimHeaderInput;
};


export type MutationClaimValidityUpdateArgs = {
  ModifiedBy: Scalars['String'];
  CreatedBy: Scalars['String'];
  ClaimTrxDayLimit: Scalars['Float'];
  SubscriptionAccountID: Scalars['String'];
};


export type MutationClaimValidityInsertArgs = {
  ClaimSettingInput: ClaimSettingInput;
};


export type MutationClaimTypeInsertArgs = {
  input: ClaimTypeInput;
};


export type MutationClaimTypeUpdateArgs = {
  ClaimTypeID: Scalars['String'];
  input: ClaimTypeInput;
};


export type MutationClaimTypeDeleteArgs = {
  ClaimTypeID: Scalars['String'];
};


export type MutationClaimTypeTemplateInsertArgs = {
  claimItemInput: Array<ClaimItemInput>;
  claimBenefitInput: ClaimBenefitInput;
};


export type MutationClaimTypeTemplateUpdateArgs = {
  claimItemInput: Array<ClaimItemInput>;
  claimBenefitInput: ClaimBenefitInput;
};


export type MutationClaimTypeTemplateDeleteArgs = {
  ClaimBenefitID: Scalars['String'];
};


export type MutationClaimJobGradePolicyInsertArgs = {
  ClaimTemplateInput?: Maybe<Array<ClaimTemplateInput>>;
  JobGradePolicyInput: JobGradePolicyInput;
};


export type MutationClaimJobGradePolicyUpdateArgs = {
  JobGradePolicyID: Scalars['String'];
  ClaimTemplateInput?: Maybe<Array<ClaimTemplateInput>>;
  JobGradePolicyInput: JobGradePolicyInput;
};


export type MutationSubmitManagementClaimRecordsArgs = {
  input: SubmitClaimRecordsInput;
};


export type MutationApproveClaimRecordsArgs = {
  input: ApproveClaimRecordsInput;
};


export type MutationCancelClaimRecordsArgs = {
  SubscriptionUserID: Scalars['String'];
  ClaimHeaderID: Scalars['String'];
};


export type MutationDeleteApprovedClaimRecordsArgs = {
  Remark: Scalars['String'];
  input: ApproveClaimRecordsInput;
};


export type MutationVerifyApprovedClaimDetailArgs = {
  input: VerifyApprovedClaimDetailInput;
};


export type MutationPostClaimRecordsArgs = {
  input: PostClaimRecordsInput;
};


export type MutationReverseClaimRecordsArgs = {
  SubscriptionUserID: Scalars['String'];
  ClaimHeaderIDs: Array<Scalars['String']>;
};


export type MutationInsertClaimCutOffPeriodArgs = {
  input: GenerateClaimCutOffPeriodInput;
};


export type MutationBankInsertArgs = {
  BankInfoInputs: BankInfoInputs;
};


export type MutationBankUpdateArgs = {
  BankInfoID: Scalars['String'];
  input: BankInfoInputs;
};


export type MutationBankDeleteArgs = {
  BankInfoID: Scalars['String'];
};


export type MutationCompanyOffDaysDeleteArgs = {
  CompanyOffDayID: Array<Scalars['String']>;
};


export type MutationCompanyOffDaysBulkInsertArgs = {
  StateID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  IsCompanyDefaultCalendar?: Maybe<Scalars['Boolean']>;
  CompanyOffDayInput: Array<CompanyOffDayInput>;
};


export type MutationOffRestDayDeleteArgs = {
  RecurringRestOffDayID: Array<Scalars['String']>;
};


export type MutationOffRestDayBulkInsertArgs = {
  IsCompanyDefaultCalendar?: Maybe<Scalars['Boolean']>;
  RecurringRestOffDayInput: Array<RecurringRestOffDayInput>;
};


export type MutationCalendarOffDayUpdateArgs = {
  input: CalendarOffDayInput;
  CalendarOffDayID: Scalars['String'];
};


export type MutationCalendarOffDayInsertArgs = {
  input: CalendarOffDayInput;
};


export type MutationCompanyDocumentInsertArgs = {
  DocumentsInput: DocumentsInput;
};


export type MutationCompanyDocumentDeleteArgs = {
  DocumentID: Scalars['String'];
};


export type MutationDocumentInsertArgs = {
  DocumentsInput: DocumentsInput;
};


export type MutationDocumentUpdateArgs = {
  DocumentsID: Scalars['String'];
  DocumentsInput: DocumentsInput;
};


export type MutationDocumentUpdateParentIdArgs = {
  DocumentID: Scalars['String'];
  ParentID: Scalars['String'];
};


export type MutationDocumentDeleteArgs = {
  DocumentID: Scalars['String'];
};


export type MutationEmployeeProfileInsertUpdateArgs = {
  DocumentID?: Maybe<Scalars['String']>;
  DocumentsInput?: Maybe<DocumentsInput>;
};


export type MutationCompanyInsertArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  ContactInput: ContactInput;
  CompanyInput: CompanyInput;
};


export type MutationCompanyUpdateArgs = {
  DocumentID?: Maybe<Scalars['String']>;
  DocumentsInput?: Maybe<DocumentsInput>;
  ContactInput: ContactInput;
  CompanyInput: CompanyInput;
  ContactID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type MutationCompanyLeaveSettingUpdateArgs = {
  CompanyInput: CompanyInput;
  CompanyID: Scalars['String'];
};


export type MutationCompanyClaimSettingUpdateArgs = {
  CompanyInput: CompanyInput;
  CompanyID: Scalars['String'];
};


export type MutationCompanyOnlyUpdateArgs = {
  CompanyInput: CompanyInput;
  CompanyID: Scalars['String'];
};


export type MutationJobDesignationInsertArgs = {
  DesignationGradeInput: Array<JobDesignationGradeInput>;
  DesignationInput: JobDesignationInput;
};


export type MutationJobDesignationUpdateArgs = {
  DesignationGradeInput: Array<JobDesignationGradeInput>;
  DesignationInput: JobDesignationInput;
};


export type MutationJobDesignationDeleteArgs = {
  JobDesignationID: Scalars['String'];
};


export type MutationRecruitmentSourceInsertArgs = {
  input: RecruitmentSourceInput;
};


export type MutationRecruitmentSourceUpdateArgs = {
  input: RecruitmentSourceInput;
  RecruitmentSourceID: Scalars['String'];
};


export type MutationRecruitmentSourceDeleteArgs = {
  RecruitmentSourceID: Scalars['String'];
};


export type MutationCompanyStatutoryInsertArgs = {
  CompanyStatutoryInput: CompanyStatutoryInput;
};


export type MutationCompanyStatutoryUpdateArgs = {
  CompanyStatutoryInput: CompanyStatutoryInput;
  CompanyStatutoryID: Scalars['String'];
};


export type MutationDepartmentInsertArgs = {
  input: DepartmentInput;
};


export type MutationDepartmentUpdateArgs = {
  DepartmentID: Scalars['String'];
  input: DepartmentUpdateInput;
};


export type MutationDepartmentDeleteArgs = {
  DepartmentUpdateInput: DepartmentUpdateInput;
};


export type MutationDivisionInsertArgs = {
  DepartmentInput: DepartmentInput;
};


export type MutationDivisionUpdateArgs = {
  DepartmentID: Scalars['String'];
  input: DepartmentUpdateInput;
};


export type MutationDivisionDeleteArgs = {
  DepartmentID: Scalars['String'];
};


export type MutationCreateInsuranceSchemeArgs = {
  InsuranceSchemeInput: InsuranceSchemeInput;
};


export type MutationUpdateInsuranceSchemeArgs = {
  InsuranceSchemeInput: InsuranceSchemeInput;
  InsuranceSchemeID: Scalars['String'];
};


export type MutationDeleteInsuranceSchemeArgs = {
  InsuranceSchemeID: Scalars['String'];
};


export type MutationInterbankInsertArgs = {
  InterbankGiroInput: InterbankGiroInput;
};


export type MutationInterbankBulkInsertArgs = {
  InterbankGiroInput: Array<InterbankGiroInput>;
};


export type MutationInterbankDeleteArgs = {
  InterbankGiroID: Scalars['String'];
};


export type MutationInterbankUpdateArgs = {
  InterbankGiroInput: Array<InterbankGiroInput>;
};


export type MutationInterbankSingleUpdateArgs = {
  InterbankGiroInput: InterbankGiroInput;
  InterbankGiroID: Scalars['String'];
};


export type MutationJobAccountInsertArgs = {
  MyJobAccountInput: Array<MyJobAccountInput>;
  JobAccountInput: JobAccountInput;
};


export type MutationJobAccountUpdateArgs = {
  MyJobAccountInput: Array<MyJobAccountInput>;
  JobAccountInput: JobAccountInput;
};


export type MutationJobAccountDeleteArgs = {
  JobAccountID: Scalars['String'];
};


export type MutationJobGradeInsertArgs = {
  input: JobGradeInput;
};


export type MutationJobGradeUpdateArgs = {
  JobGradeID: Scalars['String'];
  input: JobGradeInput;
};


export type MutationJobGradeDeleteArgs = {
  JobGradeID: Scalars['String'];
};


export type MutationLeaveRoundingBulkInsertArgs = {
  LeaveRoundingInput: Array<LeaveRoundingInput>;
};


export type MutationLeaveRoundingInsertArgs = {
  LeaveRoundingDetailInput: Array<LeaveRoundingDetailInput>;
  LeaveRoundingInput: LeaveRoundingInput;
};


export type MutationUpdateLeaveRoundingArgs = {
  LeaveRoundingDetailInput: Array<LeaveRoundingDetailInput>;
  LeaveRoundingInput: LeaveRoundingInput;
  LeaveRoundingID: Scalars['String'];
};


export type MutationLeaveRoundingUpdateArgs = {
  LeaveRoundingInput: Array<LeaveRoundingInput>;
};


export type MutationLeaveRoundingDeleteArgs = {
  LeaveRoundingID: Scalars['String'];
};


export type MutationLeaveCompulsoryPolicyBulkInsertArgs = {
  LeaveCompulsoryPolicyInput: Array<LeaveCompulsoryPolicyInput>;
};


export type MutationLeaveCompulsoryPolicyUpdateArgs = {
  LeaveCompulsoryPolicyInput: Array<LeaveCompulsoryPolicyInput>;
};


export type MutationLhdnReportInsertArgs = {
  input: EaFormSetupInput;
};


export type MutationLhdnReportUpdateArgs = {
  input: EaFormSetupInput;
  EAFormSetupID: Scalars['String'];
};


export type MutationLhdnReportCopyInsertArgs = {
  ToYear: Scalars['Float'];
  FromYear: Scalars['Float'];
  SubscriptionAccountID: Scalars['String'];
};


export type MutationNotificationPolicyInsertArgs = {
  NotificationPolicyInput: NotificationPolicyInput;
};


export type MutationNotificationPolicyUpdateArgs = {
  NotificationPolicyID: Scalars['String'];
  NotificationPolicyInput: NotificationPolicyInput;
};


export type MutationNotificationPolicyDeleteArgs = {
  NotificationPolicyInput: NotificationPolicyInput;
};


export type MutationNotificationPolicyConditionInsertArgs = {
  NotificationPolicyConditionInput: NotificationPolicyConditionInput;
};


export type MutationNotificationPolicyConditionUpdateArgs = {
  NotificationPolicyConditionID: Scalars['String'];
  NotificationPolicyConditionInput: NotificationPolicyConditionInput;
};


export type MutationPayrollCycleInsertArgs = {
  input: PayrollCycleInput;
};


export type MutationPayrollCycleUpdateArgs = {
  PayrollCycleID: Scalars['String'];
  input: PayrollCycleInput;
};


export type MutationPayrollCycleDeleteArgs = {
  PayrollCycleID: Scalars['String'];
};


export type MutationCreateSkillSetArgs = {
  SkillSetDetailInput: Array<SkillSetDetailInput>;
  SkillSetInput: SkillSetInput;
};


export type MutationUpdateSkillSetArgs = {
  SkillSetDetailInput: Array<SkillSetDetailInput>;
  SkillSetInput: SkillSetInput;
  SkillSetID: Scalars['String'];
};


export type MutationDeleteSkillSetArgs = {
  SkillSetID: Scalars['String'];
};


export type MutationNewsDeleteArgs = {
  DocumentID: Scalars['String'];
  NewsID: Scalars['String'];
};


export type MutationNewsInsertArgs = {
  ContentURL?: Maybe<Scalars['Upload']>;
  DocumentsInput: DocumentsInput;
  NewsInput: NewsInput;
};


export type MutationNewsUpdateArgs = {
  ContentURL?: Maybe<Scalars['Upload']>;
  DocumentID: Scalars['String'];
  NewsID: Scalars['String'];
  DocumentsInput: DocumentsInput;
  NewsInput: NewsInput;
};


export type MutationNewsPublishArgs = {
  PostStatus: PostStatus;
  NewsID: Scalars['String'];
};


export type MutationNewsDuplicateArgs = {
  ContentURL?: Maybe<Scalars['Upload']>;
  NewsID: Scalars['String'];
};


export type MutationDocumentNumberingInsertArgs = {
  input: DocumentNumberingInput;
};


export type MutationDocumentNumberingUpdateArgs = {
  input: DocumentNumberingInput;
};


export type MutationDocumentNumberingDeleteArgs = {
  DocumentNumberHeaderID: Scalars['String'];
};


export type MutationDocumentNumberingUpdateStatusArgs = {
  DocStatus: Scalars['String'];
  DocumentNumberHeaderID: Scalars['String'];
};


export type MutationDocumentNumberAssignmentArgs = {
  input: Array<DocumentNumberAssignmentInput>;
};


export type MutationBatchEntryByShiftInsertArgs = {
  input: Array<DutyRosterInput>;
};


export type MutationUpdateBatchEntryByShiftPatternArgs = {
  BatchEntryByShiftPatternID: Scalars['String'];
  BatchEntryByShiftPatternInput: BatchEntryByShiftPatternInput;
};


export type MutationCreateEmployeeAppraisalArgs = {
  DocumentsInput: DocumentsInput;
  EmployeeAppraisalInput: EmployeeAppraisalInput;
};


export type MutationUpdateEmployeeAppraisalArgs = {
  DocumentsInput: DocumentsInput;
  EmployeeAppraisalID: Scalars['String'];
  EmployeeAppraisalInput: EmployeeAppraisalInput;
};


export type MutationDeleteEmployeeAppraisalArgs = {
  EmployeeAppraisalID: Scalars['String'];
};


export type MutationCareerLogInsertArgs = {
  DocumentsInput: DocumentsInput;
  CareerLogInput: CareerLogInput;
};


export type MutationCareerLogUpdateArgs = {
  DocumentID: Scalars['String'];
  DocumentsInput: DocumentsInput;
  CareerLogInput: CareerLogInput;
};


export type MutationCreateEmployeeDiaryArgs = {
  DocumentsInput: DocumentsInput;
  EmployeeDiaryInput: EmployeeDiaryInput;
};


export type MutationUpdateEmployeeDiaryArgs = {
  DocumentID: Scalars['String'];
  EmployeeDiaryID: Scalars['String'];
  DocumentsInput: DocumentsInput;
  EmployeeDiaryInput: EmployeeDiaryInput;
};


export type MutationDeleteEmployeeDiaryArgs = {
  EmployeeDiaryID: Scalars['String'];
};


export type MutationEmployeeInfoInsertArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  EmployeeInput: EmployeeInput;
  ContactInput: ContactInput;
};


export type MutationEmployeeProfilePicUpdateArgs = {
  DocumentID?: Maybe<Scalars['String']>;
  DocumentsInput?: Maybe<DocumentsInput>;
  EmployeeID: Scalars['String'];
};


export type MutationEmployeeInfoUpdateArgs = {
  DocumentID?: Maybe<Scalars['String']>;
  DocumentsInput?: Maybe<DocumentsInput>;
  EmployeeInput: EmployeeInput;
  ContactInput: ContactInput;
};


export type MutationEmployeeCareerLogUpdateArgs = {
  EmployeeInput: EmployeeInput;
};


export type MutationEmployeeStatutoryInsertArgs = {
  EmployeeStatutoryInput: EmployeeStatutoryInput;
  EmployeeBankInfoInput: EmployeeBankInfoInput;
};


export type MutationEmployeeStatutoryUpdateArgs = {
  EmployeeStatutoryInput: EmployeeStatutoryInput;
  EmployeeBankInfoInput: EmployeeBankInfoInput;
};


export type MutationEmployeePrevContributionInsertArgs = {
  EmployeePrevContributionInput: EmployeePrevContributionInput;
};


export type MutationEmployeePrevContributionUpdateArgs = {
  EmployeePrevContributionInput: EmployeePrevContributionInput;
};


export type MutationEmployeeDependentInsertArgs = {
  EmployeeDependentsInput: EmployeeDependentsInput;
  DependentContactInput: ContactInput;
};


export type MutationEmployeeDependentUpdateArgs = {
  EmployeeDependentsInput: EmployeeDependentsInput;
  DependentContactInput: ContactInput;
};


export type MutationEmployeeTaxReliefBulkInsertArgs = {
  inputs: Array<EmployeeTaxReliefInput>;
};


export type MutationEmployeeTaxReliefBulkUpdateArgs = {
  inputs: Array<EmployeeTaxReliefInput>;
};


export type MutationEmployeeTaxReliefInsertArgs = {
  input: EmployeeTaxReliefInput;
};


export type MutationEmployeeTaxReliefUpdateArgs = {
  input: EmployeeTaxReliefInput;
  EmployeeTaxReliefID: Scalars['String'];
};


export type MutationEmployeeAssetInsertArgs = {
  EmployeeAssetInput: EmployeeAssetInput;
};


export type MutationEmployeeAssetUpdateArgs = {
  EmployeeAssetInput: EmployeeAssetInput;
  EmployeeAssetID: Scalars['String'];
};


export type MutationEmployeeAssetDeleteArgs = {
  EmployeeAssetID: Scalars['String'];
};


export type MutationEmployeeBenefitInKindInsertArgs = {
  EmployeeBenefitInKindInput: EmployeeBenefitInKindInput;
};


export type MutationEmployeeBenefitInKindUpdateArgs = {
  EmployeeBenefitInKindInput: EmployeeBenefitInKindInput;
  EmployeeBenefitInKindID: Scalars['String'];
};


export type MutationEmployeeBenefitInKindDeleteArgs = {
  EmployeeBenefitInKindID: Scalars['String'];
};


export type MutationEmployeeDependentDeleteArgs = {
  EmployeeDependentsID: Scalars['String'];
};


export type MutationEmployeeSalaryInsertArgs = {
  Input: CareerLogInput;
};


export type MutationEmployeeSalaryUpdateArgs = {
  Input: CareerLogInput;
  CareerLogID: Scalars['String'];
};


export type MutationEmployeeSalaryDeleteArgs = {
  CareerLogID: Scalars['String'];
};


export type MutationEmployeeProfileUploadArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  EmployeeID: Scalars['String'];
  DocumentID: Scalars['String'];
  EmployeeInput: EmployeeInput;
};


export type MutationUpdateEmployeeLastLoginCompanyArgs = {
  LastestAccessedEntity: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type MutationEmployeeCp22UpdateArgs = {
  Input: Array<EmployeeCp22Input>;
};


export type MutationNewsReadInsertArgs = {
  NewsReadInput: NewsReadInput;
};


export type MutationNotificationUpdateArgs = {
  input: NotificationInput;
};


export type MutationCreateEmployeeInsuranceSchemeArgs = {
  EmployeeInsuranceSchemeInput: EmployeeInsuranceSchemeInput;
};


export type MutationUpdateEmployeeInsuranceSchemeArgs = {
  EmployeeInsuranceSchemeID: Scalars['String'];
  EmployeeInsuranceSchemeInput: EmployeeInsuranceSchemeInput;
};


export type MutationDeleteEmployeeInsuranceSchemeArgs = {
  EmployeeInsuranceSchemeID: Scalars['String'];
};


export type MutationUpdateNotificationReadStatusArgs = {
  NotificationID: Scalars['String'];
};


export type MutationUpdateAllNotificationReadStatusArgs = {
  EmployeeID: Scalars['String'];
};


export type MutationCreateEmployeeTrainingArgs = {
  EmployeeTrainingInput: EmployeeTrainingInput;
};


export type MutationUpdateEmployeeTrainingArgs = {
  EmployeeTrainingInput: EmployeeTrainingInput;
  EmployeeTrainingID: Scalars['String'];
};


export type MutationDeleteEmployeeTrainingArgs = {
  EmployeeTrainingID: Scalars['String'];
};


export type MutationPostFromPlatformLeaveTypesArgs = {
  SubscriptionUserID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};


export type MutationSubmitMyLeaveApplicationArgs = {
  input: EmpLeaveApplicationInput;
};


export type MutationPostLeaveApplicationArgs = {
  input: EmpLeaveApplicationInput;
};


export type MutationSubmitLeaveApplicationArgs = {
  input: EmpLeaveDetailInput;
};


export type MutationSubmitLeaveApplicationV2Args = {
  input: EmpLeaveDetailInput;
};


export type MutationDeleteLeaveApplicationArgs = {
  input: EmpLeaveDetailInput;
};


export type MutationDeletePendingLeaveApplicationArgs = {
  input: EmpLeaveDetailInput;
};


export type MutationDeleteApprovedLeaveApplicationArgs = {
  Remark: Scalars['String'];
  input: EmpLeaveDetailInput;
};


export type MutationPostLeaveOpeningYearArgs = {
  Year: Scalars['Float'];
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type MutationLeaveTypeInsertArgs = {
  input: LeaveTypeInput;
};


export type MutationLeaveTypeUpdateArgs = {
  input: LeaveTypeInput;
  LeaveTypeID: Scalars['String'];
};


export type MutationLeaveTypeDeleteArgs = {
  LeaveTypeID: Scalars['String'];
};


export type MutationLeaveJobGradePolicyInsertArgs = {
  LeaveTemplateInput?: Maybe<Array<LeaveTemplateInput>>;
  JobGradePolicyInput: JobGradePolicyInput;
};


export type MutationLeaveJobGradePolicyUpdateArgs = {
  JobGradePolicyID: Scalars['String'];
  LeaveTemplateInput?: Maybe<Array<LeaveTemplateInput>>;
  JobGradePolicyInput: JobGradePolicyInput;
};


export type MutationLeavePolicyUpdateArgs = {
  LOSInput?: Maybe<Array<LosInput>>;
  leavePolicyInput: LeavePolicyInput;
};


export type MutationLeavePolicyDeleteArgs = {
  LeavePolicyID: Scalars['String'];
};


export type MutationLeavePolicyInsertArgs = {
  LOSInput?: Maybe<Array<LosInput>>;
  leavePolicyInput: LeavePolicyInput;
};


export type MutationLeaveTypeTemplateInsertArgs = {
  leaveItemInput: Array<LeaveItemInput>;
  leaveBenefitInput: LeaveBenefitInput;
};


export type MutationLeaveTypeTemplateUpdateArgs = {
  leaveItemInput: Array<LeaveItemInput>;
  leaveBenefitInput: LeaveBenefitInput;
};


export type MutationLeaveTypeTemplateDeleteArgs = {
  LeaveBenefitID: Scalars['String'];
};


export type MutationLeaveRecordsApprovalArgs = {
  input: Array<LeaveApprovalUpdateInput>;
};


export type MutationLeaveRecordsInsertArgs = {
  leaveDetailsInput: Array<LeaveDetailsInput>;
  leaveHeaderInput: LeaveHeaderInput;
};


export type MutationLeaveRecordsUpdateArgs = {
  isRemovedAttachment?: Maybe<Scalars['Boolean']>;
  leaveDetailsInput: Array<LeaveDetailsInput>;
  leaveHeaderInput: LeaveHeaderInput;
  leaveHeaderID: Scalars['String'];
};


export type MutationLeaveAdjustmentInsertArgs = {
  input: Array<LeaveHeaderInput>;
};


export type MutationLeaveStatusUpdateArgs = {
  ApprovalStatus: ApprovalStatus;
  LeaveHeaderIDs: Array<Scalars['String']>;
};


export type MutationLeaveAdjustmentUpdateArgs = {
  input: LeaveHeaderInput;
  LeaveHeaderID: Scalars['String'];
};


export type MutationLeaveAdjustmentDeleteArgs = {
  input: LeaveHeaderInput;
  LeaveHeaderID: Scalars['String'];
};


export type MutationPostLeaveRecordsArgs = {
  input: Array<LeavePostingInput>;
};


export type MutationRevertLeaveRecordsArgs = {
  input: Array<LeavePostingInput>;
};


export type MutationSaveCompulsoryLeaveArgs = {
  LeaveCompulsoryEmployeeInputs: Array<LeaveCompulsoryEmployeeInput>;
  LeaveCompulsoryInput: LeaveCompulsoryInput;
};


export type MutationInsertLeaveCutOffPeriodsArgs = {
  input: GenerateLeaveCutOffPeriodInput;
};


export type MutationSubmitYearEndCarryForwardArgs = {
  leaveHeaderInput: Array<LeaveHeaderInput>;
};


export type MutationLeaveOutOfStateInsertArgs = {
  input: LeaveOutOfStateInput;
};


export type MutationLeaveOufOfStateUpdateArgs = {
  input: LeaveOutOfStateInput;
};


export type MutationLeaveOutOfStateDeleteArgs = {
  LeaveOutOfStateID: Scalars['String'];
};


export type MutationOvertimeInsertArgs = {
  input: OvertimeInput;
};


export type MutationOvertimeDraftArgs = {
  input: OvertimeInput;
};


export type MutationOvertimeBatchArgs = {
  Input: Array<OvertimeInput>;
};


export type MutationOvertimeUpdateArgs = {
  input: Array<OvertimeInput>;
};


export type MutationOvertimeDeleteArgs = {
  OvertimeID: Scalars['String'];
};


export type MutationOvertimeRoundingInsertArgs = {
  input: OvertimeRoundingSettingInput;
};


export type MutationOvertimeRoundingSettingUpdateArgs = {
  input: OvertimeRoundingSettingInput;
};


export type MutationOvertimeRoundingSettingDeleteArgs = {
  OvertimeRoundingSettingID: Scalars['String'];
};


export type MutationOvertimeTemplateInsertArgs = {
  input: OvertimeTemplateInput;
};


export type MutationOvertimeTemplateUpdateArgs = {
  input: OvertimeTemplateInput;
};


export type MutationOvertimeTemplateDeleteArgs = {
  OvertimeTemplateID: Scalars['String'];
};


export type MutationDeletePayrollHistoryArgs = {
  PayrollHeaderID: Scalars['String'];
};


export type MutationDeletePayrollProcessArgs = {
  PayrollCycleID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type MutationUpdatePayrollProcessStatusArgs = {
  ModifiedBy: Scalars['String'];
  CreatedBy: Scalars['String'];
  PayrollCycleStatus: Scalars['String'];
  PayrollCycleID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type MutationInsertPayPeriodListingArgs = {
  input: PayPeriodInput;
};


export type MutationInsertSubsequentPayPeriodListingArgs = {
  input: PayPeriodInput;
};


export type MutationPayItemInsertArgs = {
  input: PayItemInput;
};


export type MutationPayItemUpdateArgs = {
  input: PayItemInput;
  PayItemID: Scalars['String'];
};


export type MutationPayItemDeleteArgs = {
  PayItemID: Scalars['String'];
};


export type MutationRecurringPayInsertArgs = {
  RecurringPayInput: Array<RecurringPayInput>;
};


export type MutationRecurringPayUpdateArgs = {
  input: RecurringPayInput;
  RecurringPayID: Scalars['String'];
};


export type MutationRecurringPayDeleteArgs = {
  RecurringPayID: Scalars['String'];
};


export type MutationCp159UpdateArgs = {
  CP159Input: Cp159Input;
};


export type MutationOneTimePayDeleteArgs = {
  OneTimePayID: Scalars['String'];
};


export type MutationOneTimePayInsertArgs = {
  OneTimePayInput: Array<OneTimePayInput>;
};


export type MutationOneTimePayUpdateArgs = {
  OneTimePayInput: Array<OneTimePayInput>;
};


export type MutationInsertPublishPaySlipArgs = {
  PublishPayslipInput: Array<PublishPayslipInput>;
};


export type MutationPublishPaySlipArgs = {
  PublishPaySlipID: Scalars['String'];
};


export type MutationPublishEaArgs = {
  PublishEAID: Scalars['String'];
};


export type MutationDeleteMonthlyTrxArgs = {
  OneTimePayIDs: Array<Scalars['String']>;
};


export type MutationFreezePayrollInsertArgs = {
  input: FreezePayrollInput;
};


export type MutationEmployeeFreezePayrollDeleteArgs = {
  FreezePayrollID: Scalars['String'];
};


export type MutationDeleteApprovedRequisitionArgs = {
  input: EmpRequisitionInput;
};


export type MutationRequisitionManagementApprovalUpdateArgs = {
  input: Array<RequisitionUpdateInput>;
};


export type MutationRequisitionInsertArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  input: RequisitionInput;
};


export type MutationRequisitionUpdateArgs = {
  IsRemovedAttachment?: Maybe<Scalars['Boolean']>;
  DocumentsInput?: Maybe<DocumentsInput>;
  RequisitionInput: RequisitionInput;
};


export type MutationRequisitionDeleteArgs = {
  DocumentID?: Maybe<Scalars['String']>;
  RequisitionHeaderID: Scalars['String'];
};


export type MutationRequisitionSubmitArgs = {
  RequisitionHeaderID: Scalars['String'];
};


export type MutationEaPartFInsertArgs = {
  input: EaPartFInput;
};


export type MutationEaPartFUpdateArgs = {
  EAPartFID: Scalars['String'];
  input: EaPartFInput;
};


export type MutationEaPartFDeleteArgs = {
  EAPartFID: Scalars['String'];
};


export type MutationEaPartFCopyInsertArgs = {
  ToYear: Scalars['Float'];
  FromYear: Scalars['Float'];
};


export type MutationEisPolicyUpdateArgs = {
  EISPolicyID: Scalars['String'];
  input: EisPolicyInput;
};


export type MutationEpfPolicyUpdateArgs = {
  EPFPolicyID: Scalars['String'];
  input: EpfPolicyInput;
};


export type MutationHolidayBulkInsertArgs = {
  HolidayInput: Array<HolidayInput>;
};


export type MutationHolidayBulkDeleteArgs = {
  HolidayID: Array<Scalars['String']>;
};


export type MutationHolidayBulkUpdateArgs = {
  HolidayID: Array<Scalars['String']>;
  HolidayInput: Array<HolidayInput>;
};


export type MutationSocsoPolicyUpdateArgs = {
  SocsoPolicyID: Scalars['String'];
  input: SocsoPolicyInput;
};


export type MutationTaxReliefInsertArgs = {
  input: TaxReliefInput;
};


export type MutationTaxReliefUpdateArgs = {
  TaxReliefID: Scalars['String'];
  input: TaxReliefInput;
};


export type MutationTaxReliefDeleteArgs = {
  TaxReliefID: Scalars['String'];
};


export type MutationTaxReliefCopyInsertArgs = {
  ToYear: Scalars['Float'];
  FromYear: Scalars['Float'];
};


export type MutationMonthlyAttendanceProcessingArgs = {
  ToDate: Scalars['String'];
  AccountID: Scalars['String'];
  FromDate: Scalars['String'];
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type MutationAttendanceProcessingArgs = {
  ToDate: Scalars['String'];
  AccountID: Scalars['String'];
  FromDate: Scalars['String'];
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type MutationUpdateAdjustedValueArgs = {
  input: AttendanceCalculationInput;
};


export type MutationCreateChangeShiftArgs = {
  DocumentsInput?: Maybe<DocumentsInput>;
  ChangeShiftInput: ChangeShiftInput;
};


export type MutationUpdateChangeShiftArgs = {
  RejectReason?: Maybe<Scalars['String']>;
  CSStatus: Scalars['String'];
  Input: Array<ChangeShiftInput>;
};


export type MutationDeleteChangeShiftArgs = {
  ChangeShiftID: Scalars['String'];
};


export type MutationUpdateChangeShiftSubmitArgs = {
  input: ChangeShiftInput;
};


export type MutationCreateDailyAllowanceArgs = {
  input: DailyAllowanceInput;
};


export type MutationUpdateDailyAllowanceArgs = {
  input: DailyAllowanceInput;
  DailyAllowanceID: Scalars['String'];
};


export type MutationDeleteDailyAllowanceArgs = {
  DailyAllowanceID: Scalars['String'];
};


export type MutationCreateDeductionPolicyArgs = {
  input: DeductionPolicyInput;
};


export type MutationUpdateDeductionPolicyStatusArgs = {
  Status: Status;
  DeductionPolicyID: Scalars['String'];
};


export type MutationUpdateDeductionPolicyArgs = {
  input: DeductionPolicyInput;
  DeductionPolicyID: Scalars['String'];
};


export type MutationCreateDutyRosterArgs = {
  DutyRosterInput: DutyRosterInput;
};


export type MutationDutyRosterBatchArgs = {
  Input: Array<DutyRosterInput>;
};


export type MutationCreateBatchEntryByShiftPatternArgs = {
  Input: Array<DutyRosterInput>;
};


export type MutationDeleteDutyRosterByEmployeeArgs = {
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type MutationDeleteDutyRosterForAllArgs = {
  CompanyID: Scalars['String'];
};


export type MutationPostEarnedLeaveArgs = {
  Remarks?: Maybe<Scalars['String']>;
  UntilDate: Scalars['String'];
  FromDate: Scalars['String'];
  LeaveHeaderIDs: Array<Scalars['String']>;
};


export type MutationReverseEarnedLeaveArgs = {
  LeaveHeaderIDs: Array<Scalars['String']>;
};


export type MutationCreateJobGradePolicyMatrixArgs = {
  input: JobGradePolicyMatrixInput;
};


export type MutationCreateMobileCheckInArgs = {
  input: MobileCheckInInput;
};


export type MutationUpdateMobileCheckInArgs = {
  input: MobileCheckInInput;
  MobileCheckInID: Scalars['String'];
};


export type MutationDeleteMobileCheckInArgs = {
  MobileCheckInID: Scalars['String'];
};


export type MutationCreateMonthlyAllowanceArgs = {
  input: MonthlyAllowanceInput;
};


export type MutationUpdateMonthlyAllowanceArgs = {
  input: MonthlyAllowanceInput;
  MonthlyAllowanceID: Scalars['String'];
};


export type MutationDeleteMonthlyAllowanceArgs = {
  MonthlyAllowanceID: Scalars['String'];
};


export type MutationCreateOvertimeClaimTableArgs = {
  input: OvertimeClaimTableInput;
};


export type MutationUpdateOvertimeClaimTableStatusArgs = {
  Status: Status;
  OvertimeClaimTableID: Scalars['String'];
};


export type MutationOtReasonUpdateArgs = {
  OvertimeReasonID: Scalars['String'];
  Input: OvertimeReasonInput;
};


export type MutationOtReasonDeleteArgs = {
  OvertimeReasonID: Scalars['String'];
};


export type MutationOtReasonStatusArgs = {
  OvertimeReasonID: Scalars['String'];
  Status: Scalars['String'];
};


export type MutationOtReasonInsertArgs = {
  Input: OvertimeReasonInput;
};


export type MutationOvertimePolicyInsertArgs = {
  input: OvertimePolicyInput;
};


export type MutationOvertimePolicyUpdateArgs = {
  input: OvertimePolicyInput;
};


export type MutationOvertimePolicyDeleteArgs = {
  OvertimePolicyID: Scalars['String'];
};


export type MutationCreateAllowanceDeductionArgs = {
  input: AllowanceDeductionInput;
};


export type MutationUpdateAllowanceDeductionArgs = {
  input: AllowanceDeductionInput;
  AllowanceDeductionID: Scalars['String'];
};


export type MutationDeleteAllowanceDeductionArgs = {
  AllowanceDeductionID: Scalars['String'];
};


export type MutationCreateOtRoundingArgs = {
  input: OtRoundingInput;
};


export type MutationUpdateOtRoundingArgs = {
  OTRoundingID: Scalars['String'];
  input: OtRoundingInput;
};


export type MutationDeleteOtRoundingArgs = {
  OTRoundingID: Scalars['String'];
};


export type MutationShiftSettingInsertArgs = {
  BreakTimeInput: Array<BreakTimeInput>;
  ShiftDayInput: Array<ShiftDayInput>;
  ShiftInput: ShiftInput;
};


export type MutationShiftSetupUpdateArgs = {
  BreakTimeInput: Array<BreakTimeInput>;
  ShiftDayInput: Array<ShiftDayInput>;
  ShiftInput: ShiftInput;
  ShiftID: Scalars['String'];
};


export type MutationShiftSetupDeleteArgs = {
  ShiftID: Scalars['String'];
};


export type MutationCreateShiftGroupArgs = {
  ShiftGroupShiftInput?: Maybe<Array<ShiftGroupShiftInput>>;
  ShiftGroupInput: ShiftGroupInput;
};


export type MutationUpdateShiftGroupArgs = {
  ShiftGroupShiftInput?: Maybe<Array<ShiftGroupShiftInput>>;
  ShiftGroupInput: ShiftGroupInput;
  ShiftGroupID: Scalars['String'];
};


export type MutationUpdateShiftGroupStatusArgs = {
  Status: Status;
  ShiftGroupID: Scalars['String'];
};


export type MutationCreateShiftPatternArgs = {
  ShiftPatternDetailInput: Array<ShiftPatternDetailInput>;
  ShiftPatternInput: ShiftPatternInput;
};


export type MutationUpdateShiftPatternArgs = {
  ShiftPatternDetailInput: Array<ShiftPatternDetailInput>;
  ShiftPatternInput: ShiftPatternInput;
  ShiftPatternID: Scalars['String'];
};


export type MutationDeleteShiftPatternArgs = {
  ShiftPatternID: Scalars['String'];
};


export type MutationUpdateTemplateLibraryAssignmentArgs = {
  input: TemplateLibraryAssignmentInput;
};


export type MutationCreateTemplateLibraryAssignmentArgs = {
  inputs: Array<TemplateLibraryAssignmentInput>;
};


export type MutationReplaceTemplateLibraryAssignmentArgs = {
  inputs: Array<TemplateLibraryAssignmentInput>;
};


export type MutationDeleteTemplateLibraryAssignmentArgs = {
  inputs: Array<TemplateLibraryAssignmentInput>;
};


export type MutationShiftGroupAssignmentArgs = {
  EmployeeIDs: Array<Scalars['String']>;
  ShiftGroupID: Scalars['String'];
};


export type MutationTimeOffInsertArgs = {
  input: TimeOffInput;
};


export type MutationTimeOffUpdateArgs = {
  input: Array<TimeOffInput>;
};


export type MutationTimeOffDeleteArgs = {
  TimeOffID: Scalars['String'];
};


export type MutationCreateTimeOffArgs = {
  input: TimeOffPolicyInput;
};


export type MutationTimeOffReasonInputArgs = {
  input: TimeOffReasonInput;
};


export type MutationTimeOffReasonUpdateArgs = {
  input: TimeOffReasonInput;
};


export type MutationTimeOffReasonDeleteArgs = {
  TimeOffReasonID: Scalars['String'];
};


export type MutationTimeOffSettingInsertArgs = {
  input: TimeOffSettingInput;
};


export type MutationTimeOffSettingUpdateArgs = {
  input: TimeOffSettingInput;
};


export type MutationTimeOffSettingDeleteArgs = {
  TimeOffSettingID: Scalars['String'];
};


export type MutationToDoStatusUpdateArgs = {
  ApprovalStatus: ApprovalStatus;
  ChangeShiftIDs: Array<Scalars['String']>;
  TimeOffIDs: Array<Scalars['String']>;
  OvertimeIDs: Array<Scalars['String']>;
  ClaimHeaderIDs: Array<Scalars['String']>;
  RequisitionIDs: Array<Scalars['String']>;
  LeaveHeaderIDs: Array<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  RejectedRemarks?: Maybe<Scalars['String']>;
};


export type MutationCreateRolePermissionArgs = {
  permissionArr: Array<HrPermission>;
  input: RoleInput;
};


export type MutationUpdateRolePermissionArgs = {
  permissionArr: Array<HrPermission>;
  input: RoleInput;
};


export type MutationDeleteRolePermissionArgs = {
  roleID: Scalars['String'];
};


export type MutationCreateEntityRoleUserArgs = {
  input: EntityRoleUserAsgInput;
};


export type MutationCreateListEntityRoleUserArgs = {
  roleUserIDs: Array<RoleUserInput>;
  companyID: Scalars['String'];
};


export type MutationAllowancePolicyInsertArgs = {
  input: AllowanceBenefitInput;
};


export type MutationAllowancePolicyUpdateArgs = {
  AllowanceBenefitID: Scalars['String'];
  input: AllowanceBenefitInput;
};


export type MutationAllowanceEntitlementInsertArgs = {
  input: AllowanceItemInput;
};


export type MutationAllowanceEntitlementUpdateArgs = {
  AllowanceItemID: Scalars['String'];
  input: AllowanceItemInput;
};


export type MutationUpdateLastProcessArgs = {
  IsLastProcess: Scalars['Boolean'];
  PayrollCyclePeriodID: Scalars['String'];
};


export type MutationPayProcessInsertArgs = {
  input: PayrollDetailInput;
};


export type MutationEmployeeAppLoginArgs = {
  password: Scalars['String'];
  loginId: Scalars['String'];
};


export type MutationCreateUserArgs = {
  accountName: Scalars['String'];
  input: UserInput;
};


export type MutationCreateSuperUserArgs = {
  accountID: Scalars['String'];
  input: UserInput;
};


export type MutationResendInvitationArgs = {
  accountName: Scalars['String'];
  userID: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  input: UserInput;
};


export type MutationActivateUserArgs = {
  token: Scalars['String'];
};


export type MutationLoginArgs = {
  softwareCode: SoftwareCode;
  password: Scalars['String'];
  loginId: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationForgotEmployeePasswordArgs = {
  email: Scalars['String'];
};


export type MutationResetEmployeePasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationTotpValidateArgs = {
  token: Scalars['String'];
};


export type MutationCreatePasswordArgs = {
  input: PasswordInfo;
};


export type MutationUnblockUserArgs = {
  accountID: Scalars['String'];
  input: PasswordInfo;
};


export type MutationChangeEmployeePasswordArgs = {
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
};


export type MutationUpdateLastLoginCompanyArgs = {
  lastestAccessedEntity: Scalars['String'];
  userID: Scalars['String'];
};


export type MutationUserProfilePicUpdateArgs = {
  DocumentID?: Maybe<Scalars['String']>;
  DocumentsInput?: Maybe<DocumentsInput>;
};

export type MyApplicationOutput = {
  __typename?: 'MyApplicationOutput';
  leave?: Maybe<LeaveAppOutput>;
  claim?: Maybe<ClaimAppOutput>;
  requisition?: Maybe<RequisitionAppOutput>;
};

export type MyClaimDetailOutput = {
  __typename?: 'MyClaimDetailOutput';
  ClaimDetailID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  ClaimTypeName?: Maybe<Scalars['String']>;
  ClaimAmount?: Maybe<Scalars['Float']>;
  ClaimBalance?: Maybe<Scalars['Float']>;
  ReceiptDate?: Maybe<Scalars['DateTime']>;
  ReceiptNo?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocumentName?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  VerifiedClaimDetail?: Maybe<VerifiedClaimDetail>;
  PostedClaimDetail?: Maybe<PostedClaimDetail>;
};

export type MyClaimHeaderDetails = {
  __typename?: 'MyClaimHeaderDetails';
  ClaimHeaderID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  JobAccountID?: Maybe<Scalars['String']>;
  JobAccountName?: Maybe<Scalars['String']>;
  HeaderStatus?: Maybe<Scalars['String']>;
  CountClaimDetails?: Maybe<Scalars['Float']>;
  TotalClaimAmount?: Maybe<Scalars['Float']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  ApprovedClaimDetail?: Maybe<ApprovedClaimDetail>;
  MyClaimDetails?: Maybe<Array<MyClaimDetailOutput>>;
};

export type MyClaimListingOutput = {
  __typename?: 'MyClaimListingOutput';
  Year?: Maybe<Scalars['Float']>;
  CountApprovedClaims?: Maybe<Scalars['Float']>;
  TotalApprovedClaimsAmount?: Maybe<Scalars['Float']>;
  ClaimRecords?: Maybe<Array<ClaimRecordsListing>>;
};

export type MyJobAccountEntity = InterfaceBase & {
  __typename?: 'MyJobAccountEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  MyJobAccountID: Scalars['String'];
  JobAccountID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  Company: CompanyEntity;
  JobAccount: JobAccountEntity;
  Department: DepartmentEntity;
};

export type MyJobAccountInput = {
  JobAccountID?: Maybe<Scalars['String']>;
  DepartmentID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type MyLeaveDetailOutput = InterfaceBase & {
  __typename?: 'MyLeaveDetailOutput';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  LeaveHeaderID: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  LeaveTypeID?: Maybe<Scalars['String']>;
  SubLeaveTypeID: Scalars['String'];
  LeaveHeaderType: LeaveHeaderType;
  PeriodYear: Scalars['Float'];
  TotalDay: Scalars['Float'];
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  IsShortPeriodNotice?: Maybe<Scalars['Boolean']>;
  TotalCFTaken: Scalars['Float'];
  ApprovalStatus: ApprovalStatus;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  OneTimePayID?: Maybe<Scalars['String']>;
  RejectedRemarks?: Maybe<Scalars['String']>;
  LeaveDetails?: Maybe<Array<LeaveDetailsEntity>>;
  Company?: Maybe<CompanyEntity>;
  Employee?: Maybe<EmployeeEntity>;
  LeaveType?: Maybe<LeaveTypeEntity>;
  Document?: Maybe<DocumentsEntity>;
  Approver?: Maybe<EmployeeEntity>;
  LeaveHistory?: Maybe<Array<LeaveHeaderEntity>>;
  IsEmergencyLeave?: Maybe<Scalars['Boolean']>;
  DocumentFile?: Maybe<Scalars['String']>;
  ApprovalID?: Maybe<Scalars['String']>;
  ApprovedByEmployeeID?: Maybe<Scalars['String']>;
};

export type MyLeaveEntitlementList = {
  __typename?: 'MyLeaveEntitlementList';
  LeaveItem?: Maybe<LeaveItemEntity>;
  EntitlementDayByLOS?: Maybe<Scalars['Float']>;
  ToDateEarned?: Maybe<Scalars['Float']>;
  ErrorMessage?: Maybe<Scalars['String']>;
};

export type MyLeaveEntitlementOuput = {
  __typename?: 'MyLeaveEntitlementOuput';
  EmployeeID?: Maybe<Scalars['String']>;
  LeaveItemID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  LeaveTypeName?: Maybe<Scalars['String']>;
  BroughtForward?: Maybe<Scalars['Float']>;
  Entitlement?: Maybe<Scalars['Float']>;
  AsToDateEnt?: Maybe<Scalars['Float']>;
  Taken?: Maybe<Scalars['Float']>;
  Balance?: Maybe<Scalars['Float']>;
};

export type MyLeaveInsertInput = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  SubLeaveTypeID?: Maybe<Scalars['String']>;
  LeaveHeaderType?: Maybe<LeaveHeaderType>;
  PeriodYear?: Maybe<Scalars['Float']>;
  TotalDay?: Maybe<Scalars['Float']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  IsShortPeriodNotice?: Maybe<Scalars['Boolean']>;
  TotalCFTaken?: Maybe<Scalars['Float']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalDT?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type MyLeaveListingInput = {
  EmployeeID: Scalars['String'];
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  LeaveTypes?: Maybe<Array<Scalars['String']>>;
};

export type MyLeaveSummaryInput = {
  EmployeeID: Scalars['String'];
  Year?: Maybe<Scalars['Float']>;
};

export type MyLeaveSummaryOutput = {
  __typename?: 'MyLeaveSummaryOutput';
  LeaveType: Scalars['String'];
  EntitledDay: Scalars['Float'];
  TakenDay: Scalars['Float'];
  BalanceDay: Scalars['Float'];
};

export type MyLeaveUpdateInput = {
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  SubLeaveTypeID?: Maybe<Scalars['String']>;
  LeaveHeaderType?: Maybe<LeaveHeaderType>;
  PeriodYear?: Maybe<Scalars['Float']>;
  TotalDay?: Maybe<Scalars['Float']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  UntilDate?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  IsShortPeriodNotice?: Maybe<Scalars['Boolean']>;
  TotalCFTaken?: Maybe<Scalars['Float']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalDT?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type MySubordinatesInput = {
  CompanyID?: Maybe<Scalars['String']>;
  ReportTo?: Maybe<Scalars['Float']>;
};

export enum Nationality {
  Afghan = 'Afghan',
  Albanian = 'Albanian',
  Algerian = 'Algerian',
  American = 'American',
  Argentine = 'Argentine',
  Argentinian = 'Argentinian',
  Australian = 'Australian',
  Austrian = 'Austrian',
  Bangladeshi = 'Bangladeshi',
  Batswana = 'Batswana',
  Belgian = 'Belgian',
  Bolivian = 'Bolivian',
  Brazilian = 'Brazilian',
  British = 'British',
  Bulgarian = 'Bulgarian',
  Cambodian = 'Cambodian',
  Cameroonian = 'Cameroonian',
  Canadian = 'Canadian',
  Chilean = 'Chilean',
  Chinese = 'Chinese',
  Colombian = 'Colombian',
  CostaRican = 'Costa_Rican',
  Croatian = 'Croatian',
  Cuban = 'Cuban',
  Czech = 'Czech',
  Danish = 'Danish',
  Dominican = 'Dominican',
  Dutch = 'Dutch',
  Ecuadorian = 'Ecuadorian',
  Egyptian = 'Egyptian',
  Emirati = 'Emirati',
  English = 'English',
  Estonian = 'Estonian',
  Ethiopian = 'Ethiopian',
  Fijian = 'Fijian',
  Finnish = 'Finnish',
  French = 'French',
  German = 'German',
  Ghanaian = 'Ghanaian',
  Greek = 'Greek',
  Guatemalan = 'Guatemalan',
  Haitian = 'Haitian',
  Honduran = 'Honduran',
  Hungarian = 'Hungarian',
  Icelandic = 'Icelandic',
  Indian = 'Indian',
  Indonesian = 'Indonesian',
  Iranian = 'Iranian',
  Iraqi = 'Iraqi',
  Irish = 'Irish',
  Israeli = 'Israeli',
  Italian = 'Italian',
  Jamaican = 'Jamaican',
  Japanese = 'Japanese',
  Jordanian = 'Jordanian',
  Kenyan = 'Kenyan',
  Korean = 'Korean',
  Kuwaiti = 'Kuwaiti',
  Lao = 'Lao',
  Latvian = 'Latvian',
  Lebanese = 'Lebanese',
  Libyan = 'Libyan',
  Lithuanian = 'Lithuanian',
  Malagasy = 'Malagasy',
  Malaysian = 'Malaysian',
  Malian = 'Malian',
  Maltese = 'Maltese',
  Mexican = 'Mexican',
  Mongolian = 'Mongolian',
  Moroccan = 'Moroccan',
  Mozambican = 'Mozambican',
  Namibian = 'Namibian',
  Nepalese = 'Nepalese',
  NewZealand = 'New_Zealand',
  Nicaraguan = 'Nicaraguan',
  Nigerian = 'Nigerian',
  Norwegian = 'Norwegian',
  Pakistani = 'Pakistani',
  Panamanian = 'Panamanian',
  Paraguayan = 'Paraguayan',
  Peruvian = 'Peruvian',
  Philippine = 'Philippine',
  Polish = 'Polish',
  Portuguese = 'Portuguese',
  Romanian = 'Romanian',
  Russian = 'Russian',
  Salvadorian = 'Salvadorian',
  Saudi = 'Saudi',
  Scottish = 'Scottish',
  Senegalese = 'Senegalese',
  Serbian = 'Serbian',
  Singaporean = 'Singaporean',
  Slovak = 'Slovak',
  SouthAfrican = 'South_African',
  Spanish = 'Spanish',
  SriLankan = 'Sri_Lankan',
  Sudanese = 'Sudanese',
  Swedish = 'Swedish',
  Swiss = 'Swiss',
  Syrian = 'Syrian',
  Taiwanese = 'Taiwanese',
  Tajikistani = 'Tajikistani',
  Thai = 'Thai',
  Tongan = 'Tongan',
  Tunisian = 'Tunisian',
  Turkish = 'Turkish',
  Ukrainian = 'Ukrainian',
  Uruguayan = 'Uruguayan',
  Venezuelan = 'Venezuelan',
  Vietnamese = 'Vietnamese',
  Welsh = 'Welsh',
  Zambian = 'Zambian',
  Zimbabwean = 'Zimbabwean'
}

export enum NationalityType {
  Malaysian = 'Malaysian',
  NonMalaysian = 'Non_Malaysian'
}

export type NewsEntity = InterfaceBase & {
  __typename?: 'NewsEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  NewsID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  Content?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  ValidFrom?: Maybe<Scalars['DateTime']>;
  PostStatus?: Maybe<PostStatus>;
  NewsRead: Array<NewsReadEntity>;
  Documents?: Maybe<DocumentsEntity>;
  Contact?: Maybe<ContactEntity>;
  ContentUrl?: Maybe<Scalars['String']>;
  PublishedBy: Scalars['String'];
};

export type NewsInput = {
  NewsID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
  Title: Scalars['String'];
  Content: Scalars['String'];
  DocumentID?: Maybe<Scalars['String']>;
  ValidFrom: Scalars['DateTime'];
  PostStatus?: Maybe<PostStatus>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ContentUrl?: Maybe<Scalars['String']>;
};

export type NewsReadEntity = InterfaceBase & {
  __typename?: 'NewsReadEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  NewsReadID: Scalars['String'];
  NewsID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  News: NewsEntity;
};

export type NewsReadInput = {
  NewsID: Scalars['String'];
  EmployeeID: Scalars['String'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type NotificationEntity = InterfaceBase & {
  __typename?: 'NotificationEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  NotificationID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  FromEmployeeID?: Maybe<Scalars['String']>;
  ToEmployeeID?: Maybe<Scalars['String']>;
  NotificationType: NotificationType;
  RequestType: RequestType;
  RequestID?: Maybe<Scalars['String']>;
  IsTodo?: Maybe<Scalars['Boolean']>;
  Message?: Maybe<Scalars['String']>;
  ReadStatus: ReadStatus;
  Company: CompanyEntity;
  FromEmployee?: Maybe<EmployeeEntity>;
  ToEmployee?: Maybe<EmployeeEntity>;
};

export type NotificationInput = {
  NotificationID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  FromEmployeeID?: Maybe<Scalars['String']>;
  ToEmployeeID?: Maybe<Scalars['String']>;
  NotificationType: NotificationType;
  RequestType: RequestType;
  RequestID?: Maybe<Scalars['String']>;
  IsTodo?: Maybe<Scalars['Boolean']>;
  Message?: Maybe<Scalars['String']>;
  ReadStatus: ReadStatus;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type NotificationPolicyClaimEntity = InterfaceBase & {
  __typename?: 'NotificationPolicyClaimEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  NotificationPolicyClaimID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  NotificationPolicyID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  NotificationPolicy?: Maybe<NotificationPolicyEntity>;
  ClaimType?: Maybe<ClaimTypeEntity>;
};

export type NotificationPolicyClaimInput = {
  CompanyID?: Maybe<Scalars['String']>;
  NotificationPolicyID?: Maybe<Scalars['String']>;
  ClaimTypeID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type NotificationPolicyConditionEntity = InterfaceBase & {
  __typename?: 'NotificationPolicyConditionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  NotificationPolicyConditionID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  NotificationPolicyID?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['Float']>;
  Value: Scalars['Float'];
  Company?: Maybe<CompanyEntity>;
  NotificationPolicy?: Maybe<NotificationPolicyEntity>;
};

export type NotificationPolicyConditionInput = {
  CompanyID?: Maybe<Scalars['String']>;
  NotificationPolicyID?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['Float']>;
  Value?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type NotificationPolicyEntity = InterfaceBase & {
  __typename?: 'NotificationPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  NotificationPolicyID: Scalars['String'];
  Subject?: Maybe<Scalars['String']>;
  NotificationPolicyType: NotificationPolicyType;
  PolicyType: PolicyType;
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  NotificationPolicyClaim?: Maybe<Array<NotificationPolicyClaimEntity>>;
  NotificationPolicyLeave?: Maybe<Array<NotificationPolicyLeaveEntity>>;
  NotificationPolicyRequisition?: Maybe<Array<NotificationPolicyRequisitionEntity>>;
  NotificationPolicyCondition?: Maybe<Array<NotificationPolicyConditionEntity>>;
};

export type NotificationPolicyInput = {
  NotificationPolicyID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  NotificationPolicyType?: Maybe<NotificationPolicyType>;
  PolicyType?: Maybe<PolicyType>;
  CompanyID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  NotificationPolicyClaim?: Maybe<Array<NotificationPolicyClaimInput>>;
  NotificationPolicyLeave?: Maybe<Array<NotificationPolicyLeaveInput>>;
  NotificationPolicyRequisition?: Maybe<Array<NotificationPolicyRequisitionInput>>;
  NotificationPolicyCondition?: Maybe<Array<NotificationPolicyConditionInput>>;
};

export type NotificationPolicyLastUpdatedOutput = {
  __typename?: 'NotificationPolicyLastUpdatedOutput';
  NotificationEmploymentLastPostedDate?: Maybe<Scalars['DateTime']>;
  NotificationLeaveLastPostedDate?: Maybe<Scalars['DateTime']>;
  NotificationClaimLastPostedDate?: Maybe<Scalars['DateTime']>;
  NotificationRequisitionLastPostedDate?: Maybe<Scalars['DateTime']>;
  NotificationLastPostedDate?: Maybe<Scalars['DateTime']>;
  NotificationIsLastUpdated?: Maybe<Scalars['Boolean']>;
  NotificationEmploymentIsLastUpdated?: Maybe<Scalars['Boolean']>;
  NotificationLeaveIsLastUpdated?: Maybe<Scalars['Boolean']>;
  NotificationClaimIsLastUpdated?: Maybe<Scalars['Boolean']>;
  NotificationRequisitionIsLastUpdated?: Maybe<Scalars['Boolean']>;
};

export type NotificationPolicyLeaveEntity = InterfaceBase & {
  __typename?: 'NotificationPolicyLeaveEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  NotificationPolicyLeaveID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  NotificationPolicyID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  NotificationPolicy?: Maybe<NotificationPolicyEntity>;
  LeaveType?: Maybe<LeaveTypeEntity>;
};

export type NotificationPolicyLeaveInput = {
  CompanyID?: Maybe<Scalars['String']>;
  NotificationPolicyID?: Maybe<Scalars['String']>;
  LeaveTypeID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type NotificationPolicyRequisitionEntity = InterfaceBase & {
  __typename?: 'NotificationPolicyRequisitionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  NotificationPolicyRequisitionID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  NotificationPolicyID?: Maybe<Scalars['String']>;
  RequisitionType: RequisitionType;
  Company?: Maybe<CompanyEntity>;
  NotificationPolicy?: Maybe<NotificationPolicyEntity>;
};

export type NotificationPolicyRequisitionInput = {
  CompanyID?: Maybe<Scalars['String']>;
  NotificationPolicyID?: Maybe<Scalars['String']>;
  RequisitionType?: Maybe<RequisitionType>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum NotificationPolicyType {
  EmploymentStatus = 'EmploymentStatus',
  Leave = 'Leave',
  Claim = 'Claim',
  Requisition = 'Requisition'
}

export enum NotificationType {
  News = 'News',
  Approval = 'Approval',
  SelfNotification = 'Self_Notification'
}

export type OtClaimEntitlement = {
  __typename?: 'OTClaimEntitlement';
  DayType: DayType;
  OTClaimEntitlementListInput?: Maybe<Array<OtClaimEntitlementList>>;
};

export type OtClaimEntitlementInput = {
  DayType: DayType;
  OTClaimEntitlementListInput?: Maybe<Array<OtClaimEntitlementListInput>>;
};

export type OtClaimEntitlementList = {
  __typename?: 'OTClaimEntitlementList';
  MinHours?: Maybe<Scalars['Float']>;
  MaxHours?: Maybe<Scalars['Float']>;
  OTEntitlementType?: Maybe<OtEntitlementType>;
  PayItem?: Maybe<Scalars['String']>;
};

export type OtClaimEntitlementListInput = {
  MinHours?: Maybe<Scalars['Float']>;
  MaxHours?: Maybe<Scalars['Float']>;
  OTEntitlementType?: Maybe<OtEntitlementType>;
  PayItem?: Maybe<Scalars['String']>;
};

export enum OtClaimTableType {
  OtClaims = 'OT_CLAIMS',
  ReplacementLeave = 'REPLACEMENT_LEAVE'
}

export enum OtEntitlementType {
  ActualHour = 'ACTUAL_HOUR',
  MaxHours = 'MAX_HOURS'
}

export type OtRoundingEntity = InterfaceBase & {
  __typename?: 'OTRoundingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  OTRoundingID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  RoundingCriteria?: Maybe<Array<Scalars['JSON']>>;
  Company?: Maybe<CompanyEntity>;
  CompanyID?: Maybe<Scalars['String']>;
};

export type OtRoundingInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  OTRoundingID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  RoundingCriteria?: Maybe<Array<Scalars['JSON']>>;
};

export enum Occurrence {
  CalendarYear = 'Calendar_Year',
  EmploymentPeriod = 'Employment_Period'
}

export type OneTimePayEntity = InterfaceBase & {
  __typename?: 'OneTimePayEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  OneTimePayID: Scalars['String'];
  PayItemID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  RecordSource?: Maybe<RecordSource>;
  ExcelFileName?: Maybe<Scalars['String']>;
  PayPeriod?: Maybe<PayPeriodEntity>;
  PayItem?: Maybe<PayItemEntity>;
  PayrollCycle?: Maybe<PayrollCycleEntity>;
  Employee?: Maybe<EmployeeEntity>;
  User?: Maybe<UserEntity>;
};

export type OneTimePayInput = {
  OneTimePayID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type OneTimePayItemCalculatioOutput = {
  __typename?: 'OneTimePayItemCalculatioOutput';
  TotalOneTimeTax: Scalars['Float'];
  TotalOneTimeAddRemuneration: Scalars['Float'];
  TotalOneTimeEPF: Scalars['Float'];
  TotalOneTimeEPFVE: Scalars['Float'];
  TotalOneTimeNormalRemunerationEPF: Scalars['Float'];
  TotalOneTimeAddRemunerationEPF: Scalars['Float'];
  TotalOneTimeSocso: Scalars['Float'];
  TotalOneTimeEIS: Scalars['Float'];
  TotalOneTimeBonus: Scalars['Float'];
  TotalOneTimeHRDF: Scalars['Float'];
  TotalOneTimeBonusEPF: Scalars['Float'];
  TotalCountOneTimeAddRemuneration: Scalars['Float'];
  OneTimePayItemListing?: Maybe<Array<OneTimePayItemOutput>>;
};

export type OneTimePayItemOutput = {
  __typename?: 'OneTimePayItemOutput';
  PayItemID: Scalars['String'];
  PayItemType?: Maybe<PayItemType>;
  PayName: Scalars['String'];
  PayCode: Scalars['String'];
  Calculation: Scalars['String'];
  Formula: Scalars['String'];
  Quantity: Scalars['Float'];
  Amount: Scalars['Float'];
  ActualAmount: Scalars['Float'];
  TaxAmount: Scalars['Float'];
  TotalAddRemuneration: Scalars['Float'];
  ExemptNormalRemunerationAmount: Scalars['Float'];
  ExemptAdditionalRemunerationAmount: Scalars['Float'];
  AddRemunerationAmount: Scalars['Float'];
  EPFAmount: Scalars['Float'];
  EPFVEAmount: Scalars['Float'];
  SocsoAmount: Scalars['Float'];
  EISAmount: Scalars['Float'];
  BonusAmount: Scalars['Float'];
  HRDFAmount: Scalars['Float'];
};

export type OvertimeClaimTableEntity = InterfaceBase & {
  __typename?: 'OvertimeClaimTableEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  OvertimeClaimTableID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
  OTClaimTableType?: Maybe<OtClaimTableType>;
  OTClaimData?: Maybe<Scalars['JSON']>;
  ReplacementLeaveData?: Maybe<Scalars['JSON']>;
  Company?: Maybe<CompanyEntity>;
};

export type OvertimeClaimTableInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  OvertimeClaimTableID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  OTClaimData?: Maybe<Array<OtClaimEntitlementInput>>;
  ReplacementLeaveData?: Maybe<Array<ReplacementLeaveClaimTypeInput>>;
  OTClaimTableType: OtClaimTableType;
};

export type OvertimeEntity = InterfaceBase & {
  __typename?: 'OvertimeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  OvertimeID?: Maybe<Scalars['String']>;
  OvertimeStatus: OvertimeStatus;
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  EmployeeID?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  OvertimeReasonID?: Maybe<Scalars['String']>;
  WorkDate?: Maybe<Scalars['DateTime']>;
  StartTime?: Maybe<Scalars['DateTime']>;
  EndTime?: Maybe<Scalars['DateTime']>;
  Approver?: Maybe<Scalars['String']>;
  RejectionRemarks?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  CancelledBy?: Maybe<Scalars['String']>;
  ApproveDate?: Maybe<Scalars['DateTime']>;
  otReason?: Maybe<OvertimeReasonEntity>;
  Employee?: Maybe<EmployeeEntity>;
  OvertimeHistory?: Maybe<Array<OvertimeEntity>>;
};

export type OvertimeInput = {
  EmployeeID?: Maybe<Scalars['String']>;
  OvertimeID?: Maybe<Scalars['String']>;
  OvertimeStatus?: Maybe<OvertimeStatus>;
  WorkDate?: Maybe<Scalars['DateTime']>;
  StartTime?: Maybe<Scalars['DateTime']>;
  EndTime?: Maybe<Scalars['DateTime']>;
  OvertimeReasonID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Approver?: Maybe<Scalars['String']>;
  RejectionRemarks?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  CancelledBy?: Maybe<Scalars['String']>;
  ApproveDate?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type OvertimeOutput = {
  __typename?: 'OvertimeOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  OvertimeID?: Maybe<Scalars['String']>;
  OvertimeStatus?: Maybe<OvertimeStatus>;
  WorkDate?: Maybe<Scalars['DateTime']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  StartTime?: Maybe<Scalars['DateTime']>;
  EndTime?: Maybe<Scalars['DateTime']>;
  OvertimeReasonID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  Record?: Maybe<Scalars['String']>;
};

export type OvertimePolicyEntity = InterfaceBase & {
  __typename?: 'OvertimePolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  OvertimePolicyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  OTRoundingID?: Maybe<Scalars['String']>;
  CutOffDate: Scalars['Float'];
  EnableAutoSubmission?: Maybe<Scalars['Boolean']>;
  TimeOff?: Maybe<Scalars['Boolean']>;
  HourlyLeave?: Maybe<Scalars['Boolean']>;
  HalfDayLeave?: Maybe<Scalars['Boolean']>;
  LateIn?: Maybe<Scalars['Boolean']>;
  EarlyOut?: Maybe<Scalars['Boolean']>;
  ShiftBreakLateIn?: Maybe<Scalars['Boolean']>;
  ShiftBreakEarlyOut?: Maybe<Scalars['Boolean']>;
  OvertimeRounding?: Maybe<Scalars['Boolean']>;
  AllowBackdatedOvertime?: Maybe<Scalars['Boolean']>;
  BackdatedOTMethod?: Maybe<BackdatedOtMethod>;
  BackdatedOTDay?: Maybe<Scalars['Float']>;
  EnableMaxOvertimeLimit?: Maybe<Scalars['Boolean']>;
  MaxOTHour?: Maybe<Scalars['Float']>;
  AdditionalOTPayType?: Maybe<AdditionalOtPayType>;
  AdditionalOTPayItem?: Maybe<PayItemEntity>;
  AdditionalOTPayItemID?: Maybe<Scalars['String']>;
  MaxOTPayItem?: Maybe<Scalars['JSON']>;
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  otRounding?: Maybe<OtRoundingEntity>;
};

export type OvertimePolicyInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  OvertimePolicyID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  CutOffDate?: Maybe<Scalars['Float']>;
  EnableAutoSubmission?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  TimeOff?: Maybe<Scalars['Boolean']>;
  HourlyLeave?: Maybe<Scalars['Boolean']>;
  HalfDayLeave?: Maybe<Scalars['Boolean']>;
  LateIn?: Maybe<Scalars['Boolean']>;
  EarlyOut?: Maybe<Scalars['Boolean']>;
  ShiftBreakLateIn?: Maybe<Scalars['Boolean']>;
  ShiftBreakEarlyOut?: Maybe<Scalars['Boolean']>;
  OvertimeRounding?: Maybe<Scalars['Boolean']>;
  AllowBackdatedOvertime?: Maybe<Scalars['Boolean']>;
  BackdatedOTMethod?: Maybe<BackdatedOtMethod>;
  BackdatedOTDay?: Maybe<Scalars['Float']>;
  EnableMaxOvertimeLimit?: Maybe<Scalars['Boolean']>;
  MaxOTHour?: Maybe<Scalars['Float']>;
  AdditionalOTPayType?: Maybe<AdditionalOtPayType>;
  AdditionalOTPayItemID?: Maybe<Scalars['String']>;
  OTRoundingID?: Maybe<Scalars['String']>;
  MaxOTPayItem?: Maybe<Array<MaxOtPayItemInput>>;
};

export type OvertimeReasonEntity = InterfaceBase & {
  __typename?: 'OvertimeReasonEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  OvertimeReasonID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Name: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
};

export type OvertimeReasonInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  OvertimeReasonID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
};

export type OvertimeRoundingSettingEntity = InterfaceBase & {
  __typename?: 'OvertimeRoundingSettingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  OvertimeRoundingSettingID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Name: Scalars['String'];
};

export type OvertimeRoundingSettingInput = {
  SubscriptionAccountID: Scalars['String'];
  OvertimeRoundingSettingID: Scalars['String'];
  Name: Scalars['String'];
};

export enum OvertimeStatus {
  Draft = 'DRAFT',
  Pending = 'PENDING',
  Submitted = 'SUBMITTED',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED'
}

export type OvertimeTemplateEntity = InterfaceBase & {
  __typename?: 'OvertimeTemplateEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  OvertimeTemplateID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  OvertimePolicyIDs: Scalars['String'];
  JobGradeIDs: Scalars['String'];
};

export type OvertimeTemplateInput = {
  OvertimeTemplateID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  OvertimePolicyIDs: Scalars['String'];
  JobGradeIDs: Scalars['String'];
};

export type PcbCalculationInput = {
  EmployeeID: Scalars['String'];
  AccumulatedGrossTaxable: Scalars['Float'];
  AccumulatedEPF: Scalars['Float'];
  AccumulatedPCB: Scalars['Float'];
  AccumulatedZakat: Scalars['Float'];
};

export enum PayCategoryType {
  General = 'General',
  PayItem = 'Pay_Item'
}

export type PayItemEntity = InterfaceBase & {
  __typename?: 'PayItemEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  PayCode?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  PayItemType?: Maybe<PayItemType>;
  EAPartFID?: Maybe<Scalars['String']>;
  Formula?: Maybe<Scalars['String']>;
  IsLHDN?: Maybe<Scalars['Boolean']>;
  IsEPF?: Maybe<Scalars['Boolean']>;
  IsSOCSO?: Maybe<Scalars['Boolean']>;
  IsAddRemuneration?: Maybe<Scalars['Boolean']>;
  IsEPFVE?: Maybe<Scalars['Boolean']>;
  IsBonus?: Maybe<Scalars['Boolean']>;
  IsEIS?: Maybe<Scalars['Boolean']>;
  IsHRDF?: Maybe<Scalars['Boolean']>;
  IsSystem?: Maybe<Scalars['Boolean']>;
  EAFormSetup?: Maybe<EaFormSetupEntity>;
};

export type PayItemFilterInput = {
  CompanyID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayCycleIDs?: Maybe<Array<Scalars['String']>>;
  PayItemTypes?: Maybe<Array<Scalars['String']>>;
  PayItemIDs?: Maybe<Array<Scalars['String']>>;
};

export type PayItemInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PayCode?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  PayItemType?: Maybe<PayItemType>;
  EAPartFID?: Maybe<Scalars['String']>;
  Formula?: Maybe<Scalars['String']>;
  IsLHDN?: Maybe<Scalars['Boolean']>;
  IsEPF?: Maybe<Scalars['Boolean']>;
  IsSOCSO?: Maybe<Scalars['Boolean']>;
  IsAddRemuneration?: Maybe<Scalars['Boolean']>;
  IsEPFVE?: Maybe<Scalars['Boolean']>;
  IsBonus?: Maybe<Scalars['Boolean']>;
  IsEIS?: Maybe<Scalars['Boolean']>;
  IsHRDF?: Maybe<Scalars['Boolean']>;
  IsSystem?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PayItemOutput = {
  __typename?: 'PayItemOutput';
  TransactionID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  PayPeriod?: Maybe<Scalars['DateTime']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycle?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayItemType?: Maybe<Scalars['String']>;
};

export type PayItemTransactionOutput = {
  __typename?: 'PayItemTransactionOutput';
  PayItemID?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  PayrollCycle?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  TotalAmount?: Maybe<Scalars['Float']>;
  TotalQuantity?: Maybe<Scalars['Float']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  Employees?: Maybe<Array<EmployeeTransactionOutput>>;
};

export enum PayItemType {
  Earning = 'Earning',
  Deduction = 'Deduction',
  NonPayable = 'Non_Payable',
  Overtime = 'Overtime'
}

export type PayPeriodEntity = InterfaceBase & {
  __typename?: 'PayPeriodEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PayPeriodID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  ActualWorkDays?: Maybe<Scalars['Float']>;
};

export type PayPeriodInput = {
  PayrollCutOffDate?: Maybe<Scalars['Float']>;
  CompanyID?: Maybe<Scalars['String']>;
  Year?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PayPeriodSelectionInput = {
  CompanyID: Scalars['String'];
  Year?: Maybe<Scalars['Float']>;
};

export enum PaymentMethod {
  Payroll = 'Payroll',
  BankTransfer = 'Bank_Transfer',
  Cash = 'Cash',
  Cheque = 'Cheque'
}

export type PayrollCalculationInput = {
  PayPeriodID: Scalars['String'];
  PayrollCycleID: Scalars['String'];
  PayItemIDs?: Maybe<Array<Scalars['String']>>;
  IsLastProceed: Scalars['Boolean'];
  CompanyID: Scalars['String'];
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  IsIncludedSocso?: Maybe<Scalars['Boolean']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PayrollCalculationItemOutput = {
  __typename?: 'PayrollCalculationItemOutput';
  OneTimePayItemListing?: Maybe<Array<OneTimePayItemOutput>>;
  accPreviousMonthOutput?: Maybe<AccumulatedPreviousMonthOutput>;
  EmployeeID?: Maybe<Scalars['String']>;
  NonResidentNonPayable?: Maybe<Scalars['Float']>;
  EmployeeName?: Maybe<Scalars['String']>;
  ResidentStatus?: Maybe<Scalars['String']>;
  TaxMaritialStatus?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  totalDu?: Maybe<Scalars['Float']>;
  totalSu?: Maybe<Scalars['Float']>;
  total100UnderAge?: Maybe<Scalars['Float']>;
  total50UnderAge?: Maybe<Scalars['Float']>;
  total100Certificate?: Maybe<Scalars['Float']>;
  total50Certificate?: Maybe<Scalars['Float']>;
  total100Diploma?: Maybe<Scalars['Float']>;
  total50Diploma?: Maybe<Scalars['Float']>;
  total100Disabled?: Maybe<Scalars['Float']>;
  total50Disabled?: Maybe<Scalars['Float']>;
  total100DisabledDiploma?: Maybe<Scalars['Float']>;
  total50DisabledDiploma?: Maybe<Scalars['Float']>;
  JoinedDate?: Maybe<Scalars['DateTime']>;
  ResignedDate?: Maybe<Scalars['DateTime']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  Division?: Maybe<Scalars['String']>;
  ActualWorkday?: Maybe<Scalars['Float']>;
  RequiredWorkDay?: Maybe<Scalars['Float']>;
  EPFRoundingAmount?: Maybe<Scalars['Float']>;
  EmployerEPF?: Maybe<Scalars['Float']>;
  EmployeeEPF?: Maybe<Scalars['Float']>;
  EmployeeEPFRate?: Maybe<Scalars['Float']>;
  EmployerEPFRate?: Maybe<Scalars['Float']>;
  EmployeeEPFVERate?: Maybe<Scalars['Float']>;
  EmployerEPFVERate?: Maybe<Scalars['Float']>;
  EmployeeEPF2?: Maybe<Scalars['Float']>;
  TaxableEPF?: Maybe<Scalars['Float']>;
  TaxableEPF2?: Maybe<Scalars['Float']>;
  EmployerEPFVE?: Maybe<Scalars['Float']>;
  EmployeeEPFVE?: Maybe<Scalars['Float']>;
  TotalEmployerEPFRate?: Maybe<Scalars['Float']>;
  TotalEmployeeEPFRate?: Maybe<Scalars['Float']>;
  SocsoRequirement?: Maybe<Scalars['String']>;
  EISRequirement?: Maybe<Scalars['String']>;
  SocsoRule?: Maybe<Scalars['String']>;
  EmployerSocso?: Maybe<Scalars['Float']>;
  EmployeeSocso?: Maybe<Scalars['Float']>;
  EmployerEIS?: Maybe<Scalars['Float']>;
  EmployeeEIS?: Maybe<Scalars['Float']>;
  Tax1?: Maybe<Scalars['Float']>;
  Tax2?: Maybe<Scalars['Float']>;
  NetMTD?: Maybe<Scalars['Float']>;
  Zakat?: Maybe<Scalars['Float']>;
  TaxZakat?: Maybe<Scalars['Float']>;
  EmployerHRDF?: Maybe<Scalars['Float']>;
  ContributeHRDF?: Maybe<Scalars['String']>;
  HRDFRate?: Maybe<Scalars['Float']>;
  BasicSalary?: Maybe<Scalars['Float']>;
  StandardWages?: Maybe<Scalars['Float']>;
  SalaryType?: Maybe<Scalars['String']>;
  TaxBasicSalary?: Maybe<Scalars['Float']>;
  GrossSalary?: Maybe<Scalars['Float']>;
  TotalChargableTax?: Maybe<Scalars['Float']>;
  TotalDeduction?: Maybe<Scalars['Float']>;
  NetSalary?: Maybe<Scalars['Float']>;
  TotalEarningPayItem?: Maybe<Scalars['Float']>;
  TotalDeductionPayItem?: Maybe<Scalars['Float']>;
  TotalNonPayablePayItem?: Maybe<Scalars['Float']>;
  TotalBIKAmount?: Maybe<Scalars['Float']>;
  TotalVOLAAmount?: Maybe<Scalars['Float']>;
  TotalBIKVOLA?: Maybe<Scalars['Float']>;
  TotalChargableTax1?: Maybe<Scalars['Float']>;
  TotalChargableTax2?: Maybe<Scalars['Float']>;
  TotalChargableAddRemuneration?: Maybe<Scalars['Float']>;
  TotalChargableEPF?: Maybe<Scalars['Float']>;
  TotalChargableAdditionalRemunerationEPF: Scalars['Float'];
  TotalChargableNormalRemunerationEPF: Scalars['Float'];
  TotalChargableEPFVE?: Maybe<Scalars['Float']>;
  TotalChargableSocso?: Maybe<Scalars['Float']>;
  TotalChargableEIS?: Maybe<Scalars['Float']>;
  TotalChargableHRDF?: Maybe<Scalars['Float']>;
  TotalChargableBonus?: Maybe<Scalars['Float']>;
  TaxRelief?: Maybe<Scalars['Float']>;
  MTDCalc?: Maybe<MtdOutput>;
  CurrentMonthNormalRemunerationEPF?: Maybe<Scalars['Float']>;
  CurrentMonthAdditionalRemunerationEPF?: Maybe<Scalars['Float']>;
  ProcessLog?: Maybe<Scalars['String']>;
};

export type PayrollCalculationOutput = {
  __typename?: 'PayrollCalculationOutput';
  Result?: Maybe<Scalars['Boolean']>;
  TotalRecord?: Maybe<Scalars['Float']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  Listing?: Maybe<Array<PayrollCalculationItemOutput>>;
  Entity?: Maybe<Array<PayrollHeaderEntity>>;
};

export type PayrollCycleEntity = InterfaceBase & {
  __typename?: 'PayrollCycleEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PayrollCycleID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  EPF?: Maybe<Scalars['Boolean']>;
  EPFVE?: Maybe<Scalars['Boolean']>;
  SOCSO?: Maybe<Scalars['Boolean']>;
  MTD?: Maybe<Scalars['Boolean']>;
  Zakat?: Maybe<Scalars['Boolean']>;
  EIS?: Maybe<Scalars['Boolean']>;
  Company?: Maybe<CompanyEntity>;
  PayrollCyclePeriod?: Maybe<Array<PayrollCyclePeriodEntity>>;
};

export type PayrollCycleInput = {
  CompanyID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  EPF?: Maybe<Scalars['Boolean']>;
  EPFVE?: Maybe<Scalars['Boolean']>;
  SOCSO?: Maybe<Scalars['Boolean']>;
  MTD?: Maybe<Scalars['Boolean']>;
  Zakat?: Maybe<Scalars['Boolean']>;
  EIS?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PayrollCyclePeriodEntity = InterfaceBase & {
  __typename?: 'PayrollCyclePeriodEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PayrollCyclePeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  PayrollCycleID: Scalars['String'];
  SequenceNo: Scalars['Float'];
  PayrollCycleStatus: PayrollCycleStatus;
  IsLastProcess: Scalars['Boolean'];
  PayrollCycle?: Maybe<PayrollCycleEntity>;
  PayPeriod?: Maybe<PayPeriodEntity>;
};

export type PayrollCyclePeriodInput = {
  CompanyID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  PayrollCycleID: Scalars['String'];
  SequenceNo: Scalars['Float'];
  PayrollCycleStatus: PayrollCycleStatus;
  IsLastProcess: Scalars['Boolean'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PayrollCyclePeriodListingOutput = {
  __typename?: 'PayrollCyclePeriodListingOutput';
  PayrollCycleID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SequenceNo?: Maybe<Scalars['Float']>;
  PayrollCycleStatus?: Maybe<Scalars['String']>;
  IsLastProcess?: Maybe<Scalars['Boolean']>;
  RowNumber?: Maybe<Scalars['Float']>;
};

export type PayrollCycleSelectionInput = {
  CompanyID: Scalars['String'];
  Year?: Maybe<Scalars['Float']>;
};

export enum PayrollCycleStatus {
  Calculated = 'Calculated',
  Locked = 'Locked',
  Unlock = 'Unlock',
  NoRecord = 'NoRecord'
}

export type PayrollDetailEntity = InterfaceBase & {
  __typename?: 'PayrollDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PayrollDetailID: Scalars['String'];
  PayrollHeaderID: Scalars['String'];
  PayCategoryType: PayCategoryType;
  PayItemID: Scalars['String'];
  Percentage: Scalars['Float'];
  Amount: Scalars['String'];
  Quantity: Scalars['String'];
  ExemptNormalRemunerationAmount: Scalars['String'];
  ExemptAdditionalRemunerationAmount: Scalars['String'];
  TotalAmount: Scalars['String'];
  AdjustAmount: Scalars['String'];
  PayrollHeader: PayrollHeaderEntity;
  PayItem: PayItemEntity;
};

export type PayrollDetailInput = {
  PayrollHeaderID: Scalars['String'];
  PayCategoryType: PayCategoryType;
  PayItemID: Scalars['String'];
  Percentage?: Maybe<Scalars['Float']>;
  Amount: Scalars['String'];
  AdjustAmount: Scalars['String'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PayrollFixedAllowanceInput = {
  CompanyID?: Maybe<Scalars['String']>;
  StartMonth?: Maybe<Scalars['DateTime']>;
  EndMonth?: Maybe<Scalars['DateTime']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  PayItemTypes?: Maybe<Array<Scalars['String']>>;
  PayItemIDs?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
};

export type PayrollHeaderEntity = InterfaceBase & {
  __typename?: 'PayrollHeaderEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PayrollHeaderID: Scalars['String'];
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
  Employee?: Maybe<EmployeeEntity>;
  Company?: Maybe<CompanyEntity>;
  PayPeriod?: Maybe<PayPeriodEntity>;
  PayrollCycle?: Maybe<PayrollCycleEntity>;
  PayrollCycleID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  ActualWorkday: Scalars['Float'];
  RequiredWorkDay: Scalars['Float'];
  ProcessLog?: Maybe<Scalars['String']>;
  NetPay?: Maybe<Scalars['String']>;
  TotalGross: Scalars['String'];
  BasicSalary: Scalars['String'];
  TotalDeduction: Scalars['String'];
  TaxRelief: Scalars['String'];
  TotalChargableTax: Scalars['String'];
  TotalChargableAddRemuneration: Scalars['String'];
  TotalChargableNormalRemunerationEPF: Scalars['String'];
  TotalChargableAdditionalRemunerationEPF: Scalars['String'];
  TotalChargableEPF: Scalars['String'];
  TotalChargableEPFVE: Scalars['String'];
  TotalChargableSocso: Scalars['String'];
  TotalChargableEIS: Scalars['String'];
  TotalChargableHRDF: Scalars['String'];
  TotalChargableBonus: Scalars['String'];
  EPFPaid: Scalars['String'];
  EPFPaidByAdditionalRemuneration: Scalars['String'];
  EPFPaidByNormalRemunerationPaid: Scalars['String'];
  EPFVEPaid: Scalars['String'];
  SOCSOPaid: Scalars['String'];
  EISPaid: Scalars['String'];
  ZAKATPaid: Scalars['String'];
  TAXPaid: Scalars['String'];
  TotalBIK: Scalars['String'];
  TotalVOLA: Scalars['String'];
  PayrollDetails: Array<PayrollDetailEntity>;
};

export type PayrollHeaderInput = {
  EmployeeID: Scalars['String'];
  PayrollCyclePeriodID: Scalars['String'];
  ActualWorkday: Scalars['Float'];
  RequiredWorkDay: Scalars['Float'];
  ProcessLog?: Maybe<Scalars['String']>;
  NetPay: Scalars['String'];
  TotalGross: Scalars['String'];
  TotalDeduction: Scalars['String'];
  NormalRemn: Scalars['String'];
  AdditionalRemn: Scalars['String'];
  ChargeSOCSO: Scalars['String'];
  ChargeEPF: Scalars['String'];
  ChargeEIS: Scalars['String'];
  ChargeZAKAT: Scalars['String'];
  TaxRelief: Scalars['String'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PayrollProcessEmployeeListingOutput = {
  __typename?: 'PayrollProcessEmployeeListingOutput';
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  Designation?: Maybe<Scalars['String']>;
  EmploymentType?: Maybe<EmploymentType>;
  Status?: Maybe<Status>;
  IsChecked?: Maybe<Scalars['Boolean']>;
};

export type PayrollProcessPayItemListingOutput = {
  __typename?: 'PayrollProcessPayItemListingOutput';
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  EarningPayItem?: Maybe<Array<PayrollProcessPayItemOutput>>;
  DeductionPayItem?: Maybe<Array<PayrollProcessPayItemOutput>>;
  NonPayablePayItem?: Maybe<Array<PayrollProcessPayItemOutput>>;
  OvertimePayItem?: Maybe<Array<PayrollProcessPayItemOutput>>;
};

export type PayrollProcessPayItemOutput = {
  __typename?: 'PayrollProcessPayItemOutput';
  PayItemID?: Maybe<Scalars['String']>;
  PayName?: Maybe<Scalars['String']>;
  PayItemType?: Maybe<PayItemType>;
};

export type PayrollProcessPayrollCycleListingOutput = {
  __typename?: 'PayrollProcessPayrollCycleListingOutput';
  PayPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  LastProcessedDate?: Maybe<Scalars['DateTime']>;
  PayrollProcessStatus?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  SequenceNo?: Maybe<Scalars['Float']>;
  ShowDelete?: Maybe<Scalars['Boolean']>;
  TotalRecords?: Maybe<Scalars['Float']>;
  PayrollCyclePeriodID?: Maybe<Scalars['String']>;
  IsLastProcess?: Maybe<Scalars['Boolean']>;
};

export type PayrollProcessPeriodListingOutput = {
  __typename?: 'PayrollProcessPeriodListingOutput';
  PayPeriodID?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  FromDate?: Maybe<Scalars['DateTime']>;
  ToDate?: Maybe<Scalars['DateTime']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  LastProcessedDate?: Maybe<Scalars['DateTime']>;
  PayrollProcessStatus?: Maybe<Scalars['String']>;
  TotalNetPay?: Maybe<Scalars['Float']>;
  TotalEmployee?: Maybe<Scalars['Float']>;
};

export type PayrollProcessPeriodListingStatusOutput = {
  __typename?: 'PayrollProcessPeriodListingStatusOutput';
  PayPeriodID?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PayrollCycleStatus?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  TotalLastProcess?: Maybe<Scalars['Float']>;
};

export type PayrollSummmaryListingOutput = {
  __typename?: 'PayrollSummmaryListingOutput';
  PayPeriodID?: Maybe<Scalars['String']>;
  NetPay?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
};

export type PlatformLeaveItemEntity = InterfaceBase & {
  __typename?: 'PlatformLeaveItemEntity';
  Status: Status;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PlatformLeaveItemID: Scalars['String'];
  PlatformLeaveTypeID: Scalars['String'];
  TotalDay: Scalars['Float'];
  LeaveFrequency: LeaveFrequency;
  CutOffDate: Scalars['DateTime'];
  Occurrence?: Maybe<Occurrence>;
  NoOccurrence?: Maybe<Scalars['Float']>;
  AvailableBasis?: Maybe<AvailableBasis>;
  CalculationMethod?: Maybe<CalculationMethod>;
  NewCutOffDay: Scalars['Float'];
  ResignCutOffDay: Scalars['Float'];
  MonthlyCutOffDay: Scalars['Float'];
  ShortNoticeType: ShortNoticeType;
  ShortNoticePeriod: Scalars['Float'];
  CFMethod: CfMethod;
  CFValue: Scalars['Float'];
  CFMonth: Scalars['Float'];
  IsOffDay: Scalars['Boolean'];
  IsRestDay: Scalars['Boolean'];
  IsPublicHoliday: Scalars['Boolean'];
  IsHalfDay: Scalars['Boolean'];
  IsAttachment: Scalars['Boolean'];
  IsRemarks: Scalars['Boolean'];
  IsRounding: Scalars['Boolean'];
  IsConfirmedStaff: Scalars['Boolean'];
};

export type PlatformLeaveItemInput = {
  PlatformLeaveTypeID?: Maybe<Scalars['String']>;
  TotalDay?: Maybe<Scalars['Float']>;
  LeaveFrequency?: Maybe<LeaveFrequency>;
  CutOffDate?: Maybe<Scalars['DateTime']>;
  Occurrence?: Maybe<Occurrence>;
  NoOccurrence?: Maybe<Scalars['Float']>;
  AvailableBasis?: Maybe<AvailableBasis>;
  CalculationMethod?: Maybe<CalculationMethod>;
  NewCutOffDay?: Maybe<Scalars['Float']>;
  ResignCutOffDay?: Maybe<Scalars['Float']>;
  MonthlyCutOffDay?: Maybe<Scalars['Float']>;
  ShortNoticeType?: Maybe<ShortNoticeType>;
  ShortNoticePeriod?: Maybe<Scalars['Float']>;
  CFMethod?: Maybe<CfMethod>;
  CFValue?: Maybe<Scalars['Float']>;
  CFMonth?: Maybe<Scalars['Float']>;
  IsOffDay?: Maybe<Scalars['Boolean']>;
  IsRestDay?: Maybe<Scalars['Boolean']>;
  IsPublicHoliday?: Maybe<Scalars['Boolean']>;
  IsHalfDay?: Maybe<Scalars['Boolean']>;
  IsAttachment?: Maybe<Scalars['Boolean']>;
  IsRemarks?: Maybe<Scalars['Boolean']>;
  IsRounding?: Maybe<Scalars['Boolean']>;
  IsConfirmedStaff?: Maybe<Scalars['Boolean']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PlatformLeaveTypeEntity = InterfaceBase & {
  __typename?: 'PlatformLeaveTypeEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PlatformLeaveTypeID: Scalars['String'];
  IntegratedLeaveTypeID: Scalars['String'];
  LeaveTypeCode: Scalars['String'];
  Description: Scalars['String'];
};

export type PlatformLeaveTypeInput = {
  IntegratedLeaveTypeID?: Maybe<Scalars['String']>;
  LeaveTypeCode?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
};

export enum PolicyType {
  EmploymentConfirmation = 'EmploymentConfirmation',
  Transfer = 'Transfer',
  Exit = 'Exit',
  ShortNotice = 'ShortNotice',
  NoOfDayPerApplication = 'NoOfDayPerApplication',
  LeavePendingApproval = 'LeavePendingApproval',
  ClaimSubmissionAmount = 'ClaimSubmissionAmount',
  ClaimPendingApproval = 'ClaimPendingApproval',
  RequisitionAmount = 'RequisitionAmount',
  RequisitionPendingApproval = 'RequisitionPendingApproval'
}

export type PostClaimRecordsInput = {
  SubscriptionUserID?: Maybe<Scalars['String']>;
  PayrollPeriodID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  ClaimHeaderIDs?: Maybe<Array<Scalars['String']>>;
};

export enum PostStatus {
  Draft = 'Draft',
  Posted = 'Posted'
}

export type PostedClaimDetail = {
  __typename?: 'PostedClaimDetail';
  PayrollPeriodID?: Maybe<Scalars['String']>;
  PeriodYearMonth?: Maybe<Scalars['DateTime']>;
  PostedDate?: Maybe<Scalars['DateTime']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayrollCycleDescription?: Maybe<Scalars['String']>;
};

export type PublishEaEntity = InterfaceBase & {
  __typename?: 'PublishEAEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PublishEAID: Scalars['String'];
  CompanyID: Scalars['String'];
  PeriodYear: Scalars['Float'];
  IsPublish?: Maybe<Scalars['Boolean']>;
  PublishedDate?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
};

export type PublishEaInput = {
  CompanyID: Scalars['String'];
  PeriodYear: Scalars['Float'];
  IsPublish?: Maybe<Scalars['Boolean']>;
  PublishedDate?: Maybe<Scalars['DateTime']>;
  Remarks: Scalars['String'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type PublishPayslipEntity = InterfaceBase & {
  __typename?: 'PublishPayslipEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  PublishPaySlipID: Scalars['String'];
  CompanyID: Scalars['String'];
  IsPublish?: Maybe<Scalars['Boolean']>;
  PublishedDate?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  PayPeriod: PayPeriodEntity;
  PayrollCycle: PayrollCycleEntity;
};

export type PublishPayslipInput = {
  CompanyID: Scalars['String'];
  PayrollCyclePeriodID: Scalars['String'];
  IsPublish?: Maybe<Scalars['Boolean']>;
  PublishedDate?: Maybe<Scalars['DateTime']>;
  Remarks?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  AttendancePostingListing: Array<AttendanceCalculationEntity>;
  AttendancePeriodSummary?: Maybe<Scalars['JSON']>;
  getAttendanceRecord: Array<AttendanceRecordEntity>;
  AttendanceRecordSummary?: Maybe<Scalars['JSON']>;
  AttendanceRecordByExcel: Scalars['JSON'];
  AttendanceRecordListing: Array<AttendanceRecordEntity>;
  AttendanceRecordByPeriod: Array<AttendanceRecordEntity>;
  getAttendanceRecordLastModified: Array<Scalars['DateTime']>;
  CompanyBusinessInsight: Array<BusinessInsightCompanyOutput>;
  EmployeeBusinessInsightSummary: BusinessInsightSummaryOutput;
  EmployeeBusinessInsightRawListing: Array<BusinessInsightListingOutput>;
  EmployeeBusinessInsightListing?: Maybe<Array<BusinessInsightOutput>>;
  PayrollBusinessInsightStatutoryListing: Array<BusinessInsightPayrollStatutoryOutput>;
  PayrollBusinessInsightSalaryOverheadListing: Array<BusinessInsightPayrollSalaryOverheadOutput>;
  PayrollBusinessInsightPayItemListing: Array<BusinessInsightPayrollPayItemOutput>;
  PayrollBusinessInsightSummary: BusinessInsightSummaryPayrollOutput;
  TopLeaveTakerByType: Array<LeaveEntitlementByLeaveTypeListing>;
  LeaveBILeaveTakenListing: Array<BusinessInsightLeaveTakenOutput>;
  TopClaimTaker: Array<TopClaimTakerOutput>;
  ClaimBIClaimTaken: Array<BusinessInsightClaimTakenOuput>;
  TopRequisitionTaker: Array<TopRequisitionTakerOutput>;
  RequisitionTakenSummaryByType: Array<RequisitionTakenSummaryByTypeOutput>;
  GetEmpListingbyCompany: Array<EmployeeEntity>;
  GetEmpsClaimEntitlement: Array<EmpClaimEntitlementOutput>;
  GetMyClaimEntitlement: EmpClaimEntitlementOutput;
  GetEmpsClaimBalance: Array<EmpClaimBalanceOutput>;
  GetMyClaimBalance: EmpClaimBalanceOutput;
  GetMyClaimListing: MyClaimListingOutput;
  GetMyClaimDetails: MyClaimHeaderDetails;
  GetMainClaimListing: Array<GetMainClaimListingOutput>;
  GetEmpClaimListing: Array<GetEmpClaimListingOutput>;
  GetApprovedDetail: Array<GetApprovedDetailOutput>;
  GetClaimRecordListing: Array<ClaimRecordOutput>;
  GetApproveClaimVerification: Array<ApproveClaimVerificationOutput>;
  GetClaimPostingManagement: Array<ClaimPostingManagementOutput>;
  GetClaimRecordHistory: Array<ClaimRecordHistoryOutput>;
  GetCompletedDetail: Array<GetCompletedDetailOutput>;
  MyClaimDetail: ClaimDetailsEntity;
  ClaimListing: Array<ClaimHeaderEntity>;
  GetAllExpenseAccount: Array<JobAccountEntity>;
  ClaimValidityDetails: ClaimSettingEntity;
  ClaimTypeListing: Array<ClaimTypeEntity>;
  ClaimTypeTemplateListing: Array<ClaimBenefitEntity>;
  ClaimTypeTemplateDetail: ClaimBenefitEntity;
  GetClaimSettingsListing: Array<ClaimSettingsListing>;
  ClaimTemplateListing: Array<ClaimTemplateEntity>;
  GetClaimManagementListing?: Maybe<Array<ClaimManagementListing>>;
  GetClaimRecordsListing?: Maybe<Array<ClaimRecordsListing>>;
  GetClaimRecordsDetails?: Maybe<ClaimRecordsDetailHeaderOutput>;
  GetClaimRecordsHistoryListing?: Maybe<Array<ClaimRecordsListing>>;
  GetApprovedClaimVerificationListing?: Maybe<Array<ClaimRecordsListing>>;
  GetApprovedClaimVerificationDetails?: Maybe<ApprovedClaimVerificationDetailsOutput>;
  GetClaimPostingListing?: Maybe<Array<ClaimPostingListingOutput>>;
  GetClaimPostingDetail?: Maybe<ClaimRecordsDetailHeaderOutput>;
  GetClaimPostingPeriodListing?: Maybe<Array<ClaimPostingPeriodListingOutput>>;
  GetClaimPostingPeriodListingV2?: Maybe<Array<ClaimPostingPeriodListingOutput>>;
  GetClaimEntitlementByClaimTypeListing?: Maybe<Array<EmpClaimEntitlementByClaimTypeOutput>>;
  GetClaimEntitlementByEmployeeListing?: Maybe<Array<EmpClaimEntitlementByEmployeeOutput>>;
  GetClaimToApprove?: Maybe<Array<ClaimToApproveListingOutput>>;
  GetClaimApprovalHistory?: Maybe<Array<ClaimToApproveListingOutput>>;
  BankDetail: Array<BankInfoEntity>;
  BankListing: Array<BankInfoEntity>;
  HolidayListing: Array<HolidayEntity>;
  CompanyOffDaysListing: Array<CompanyOffDayEntity>;
  OffRestDayListing: Array<RecurringRestOffDayEntity>;
  CalendarStateListing: Array<CalendarOffDayOutput>;
  EmployeeStateAssignListing: Array<CalendarOffDayEmployeeOutput>;
  CalendarPolicyListing: Array<CalendarPolicyOutput>;
  EmployeeHomeCalendar?: Maybe<Array<HomeCalendarOutput>>;
  DocumentListing: Array<DocumentsEntity>;
  CompanyListingByEmail: Array<CompanyEntity>;
  CompanyListing: Array<CompanyEntity>;
  CompanySummary: CompanyEntity;
  JobDesignationDetail: JobDesignationEntity;
  JobDesignationGradeListing: Array<JobDesignationGradeEntity>;
  JobGradeByJobDesignationListing: Array<JobGradeByJobDesignationOutput>;
  JobDesignationListing: Array<JobDesignationEntity>;
  RecruitmentSourceListing: Array<RecruitmentSourceEntity>;
  CompanyStatutoryDetail?: Maybe<CompanyStatutoryEntity>;
  DepartmentListing: Array<DepartmentEntity>;
  DepartmentDivisionListing: Array<DepartmentEntity>;
  DepartmentDetail: Array<DepartmentEntity>;
  DivisionListing: Array<DepartmentEntity>;
  DivisionDetail: Array<DepartmentEntity>;
  GetInsuranceScheme: Array<InsuranceSchemeEntity>;
  InterbankDetail: Array<InterbankGiroEntity>;
  InterbankListingBulk: Array<InterbankGiroEntity>;
  InterbankListing: Array<InterbankGiroEntity>;
  MyJobAccountListing: Array<MyJobAccountEntity>;
  JobAccountListing: Array<JobAccountEntity>;
  JobAccountDetail: Array<JobAccountEntity>;
  JobGradeDetail: JobGradeEntity;
  JobGradeListing: Array<JobGradeEntity>;
  JobGradeListingByCompany: Array<JobGradeEntity>;
  JobGradePolicyListing: Array<JobGradePolicyEntity>;
  LeaveRoundingListing: Array<LeaveRoundingEntity>;
  LeaveCompulsoryPolicyListing: Array<LeaveCompulsoryPolicyEntity>;
  LeaveTemplateListing: Array<LeaveTemplateEntity>;
  LHDNReportListing: Array<PayItemEntity>;
  LHDNReportDropdownListing: Array<LhdnReportEntity>;
  LHDNReportDetail: PayItemEntity;
  GetLHDNReportListing?: Maybe<Array<DisplayReportTypeSequenceOutput>>;
  NotificationPolicyDetail: Array<NotificationPolicyEntity>;
  NotificationPolicyListing: Array<NotificationPolicyEntity>;
  NotificationPolicyConditionListing: Array<NotificationPolicyConditionEntity>;
  NotificationPolicyConditionDetail: NotificationPolicyConditionEntity;
  NotificationPolicySummaryDates: NotificationPolicyLastUpdatedOutput;
  PayrollCycleDetail: PayrollCycleEntity;
  PayrollCycleListing: Array<PayrollCycleEntity>;
  PayrollCycleListingV2: Array<PayrollCycleEntity>;
  GetSkillSet: Array<SkillSetEntity>;
  GetSkillSetDetail: Array<SkillSetDetailEntity>;
  BulletinListing: Scalars['JSON'];
  NewsListing: Array<NewsEntity>;
  DocumentNumberingList: Array<DocumentNumberHeaderEntity>;
  DocumentNumberingAssignmentList: Scalars['JSON'];
  getBatchEntryByShift: Array<BatchEntryByShiftEntity>;
  getBatchEntryByPattern: Array<BatchEntryByShiftPatternEntity>;
  GetEmployeeAppraisal: Array<EmployeeAppraisalEntity>;
  CareerLogListing: Array<CareerLogEntity>;
  GetEmployeeDiary: Array<EmployeeDiaryEntity>;
  EmployeeDocumentListing: Array<DocumentsEntity>;
  EmployeeListing: Array<EmployeeEntity>;
  TMSEmployeeListing: Array<EmployeeEntity>;
  EmployeeListingBySubscriptionAccount: Array<EmployeeEntity>;
  EmployeeAppSummary: Scalars['JSON'];
  EmployeeSummary: EmployeeEntity;
  EmployeeSummaryBySubscription: EmployeeEntity;
  GetEmployeePrevContribution: EmployeePrevContributionEntity;
  FilterRequisitionListing: Array<RequisitionEntity>;
  RequisitionListing: Array<RequisitionEntity>;
  FixedAllowanceListing: Array<RecurringPayEntity>;
  FilterEmployeeLeaveRecords: Array<LeaveHeaderEntity>;
  TaxReliefListingDefaultExcluded: Array<TaxReliefEntity>;
  EmployeeTaxReliefListing: Array<EmployeeTaxReliefEntity>;
  EmployeeTaxReliefDetails: Array<EmployeeTaxReliefEntity>;
  EmployeeAssetListing: Array<EmployeeAssetEntity>;
  EmployeeAssetDetail: EmployeeAssetEntity;
  EmployeeBenefitInKindListing: Array<EmployeeBenefitInKindEntity>;
  EmployeeBenefitInKindDetailForYear: EmployeeBenefitInKindEntity;
  EmployeeBenefitInKindDetail: EmployeeBenefitInKindEntity;
  MyEmployeeInfo: EmployeeEntity;
  MyNewsList: Scalars['Float'];
  MyApplicationDetail: MyApplicationOutput;
  EmployeeCP22Listing: Array<EmployeeCp22Output>;
  NotificationListing: Array<NotificationEntity>;
  NewsReadListing: Array<NewsReadEntity>;
  EmployeeCalendar: EmployeeCalendarOutput;
  GetEmployeeInsuranceScheme: Array<EmployeeInsuranceSchemeEntity>;
  EmployeeNotificationListing: Array<EmployeeNotificationOutput>;
  SubordinateListing: Array<EmployeeEntity>;
  GetEmployeeTraining: Array<EmployeeTrainingEntity>;
  GetLeaveOffDay: Scalars['JSON'];
  GetLeavePolicyByJobGradeID?: Maybe<Array<LeaveItemEntity>>;
  GetPlatformNewLeaveTypePolicy?: Maybe<LeaveEntitlementTemplate>;
  GetMyLeaveEntitlement?: Maybe<EmpLeaveEntitlementOuput>;
  GetEmpLeaveBalance?: Maybe<Array<EmpLeaveBalanceOuput>>;
  GetMyLeaveBalance?: Maybe<EmpLeaveBalanceOuput>;
  EmployeeCalendarLeaveDetails?: Maybe<Array<LeaveDetailsEntity>>;
  EmployeeLeaveListing?: Maybe<Array<LeaveHeaderEntity>>;
  GetEmpLeaveDetail?: Maybe<EmpLeaveDetailOuput>;
  GetLeaveRoundedValue?: Maybe<Scalars['Float']>;
  LeaveTypeListing: Array<LeaveTypeEntity>;
  LeavePolicyListing?: Maybe<Array<LeaveItemEntity>>;
  LeavePolicyByID?: Maybe<LeaveItemEntity>;
  LeaveTypeTemplateListing: Array<LeaveBenefitEntity>;
  LeaveTypeTemplateDetail: LeaveBenefitEntity;
  GetIntegratedLeaveBalance: Scalars['String'];
  GetEmployeesLeaveSummary?: Maybe<Array<EmployeeLeavesSummary>>;
  GetLeaveEntitlementByLeaveTypeListing?: Maybe<Array<LeaveEntitlementByLeaveTypeListing>>;
  ViewLeaveApprovalHistory?: Maybe<Array<LeaveHeaderEntity>>;
  GetLeaveRecordsListing?: Maybe<Array<LeaveRecordsOutput>>;
  GetLeaveReplacementListingByEmployee?: Maybe<Array<LeaveAdjustmentOutput>>;
  GetLeaveAdjustmentListing?: Maybe<Array<LeaveAdjustmentOutput>>;
  GetCompulsoryLeaveListing?: Maybe<Array<CompulsoryLeaveOutput>>;
  GetCompulsoryLeaveDetail?: Maybe<CompulsoryLeaveOutput>;
  GetLeavePostingListing?: Maybe<Array<LeavePostingOutput>>;
  GetLeaveToAprove?: Maybe<Array<LeaveHeaderEntity>>;
  LeaveAdjustmentDetail: LeaveHeaderEntity;
  GetLeavePostingPeriodListing?: Maybe<Array<LeavePostingPeriodOutput>>;
  GetYECFPendingListing?: Maybe<Array<YecfListingOutput>>;
  GetYECFPendingListingWithFilter?: Maybe<Array<YecfListingOutput>>;
  GetYECFPostedListing?: Maybe<Array<YecfListingOutput>>;
  GetYECFPostedListingWithFilter?: Maybe<Array<YecfListingOutput>>;
  GetYearEndCarryForwardListing?: Maybe<Array<YearEndCarryForwardOutput>>;
  FilterGetYearEndCarryForwardListing?: Maybe<Array<YearEndCarryForwardOutput>>;
  LeaveHomeSummary: LeaveHomeSummaryOutput;
  LeaveOutOfStateListing: Array<LeaveOutOfStateEntity>;
  getOvertime: Array<OvertimeEntity>;
  OvertimeListing: Array<OvertimeEntity>;
  OvertimeListingByExcel: Scalars['JSON'];
  OvertimeListingByYear: Array<OvertimeEntity>;
  OvertimeListingByPeriod: Array<OvertimeEntity>;
  GetOvertimeToApprove?: Maybe<Array<OvertimeEntity>>;
  OvertimePeriodSummary?: Maybe<Scalars['JSON']>;
  OvertimeMenuSummary?: Maybe<Scalars['JSON']>;
  OvertimeRoundingListing: Array<OvertimeRoundingSettingEntity>;
  OvertimeTemplateListing: Array<OvertimeTemplateEntity>;
  GetFormulaTest: Scalars['String'];
  EmployeeOutstandingSalaryListing: Array<EmployeeEntity>;
  CP22Count: Array<EmployeeCp22Entity>;
  ViewPayrollHistorySummary: ViewPayrollHistorySummaryOutput;
  TestPayrollCalc: Array<Scalars['JSON']>;
  ViewPayrollHistory: Array<PayrollHeaderEntity>;
  PayrollProcessPeriodListing: Array<PayrollProcessPeriodListingOutput>;
  PayrollProcessPayrollCycleListing: Array<PayrollProcessPayrollCycleListingOutput>;
  PayrollProcessPayItemListing: PayrollProcessPayItemListingOutput;
  PayrollProcessEmployeeListing: Array<PayrollProcessEmployeeListingOutput>;
  CalculatePayroll: PayrollCalculationOutput;
  Rounding: Scalars['String'];
  CalculateHRDF: HrdfCalculationOutput;
  CalculateEPFV2: EpfCalculationOutput;
  CalculateTaxZakat: TaxZakatCalculationOutput;
  CalculateEIS: EisCalculationOutput;
  CalculateSocso: SocsoCalculationOutput;
  PayPeriodDetails: PayPeriodEntity;
  PayPeriodListing: Array<PayPeriodEntity>;
  StartPayPeriodListingByYear: Array<PayPeriodEntity>;
  EndPayPeriodListingByYear: Array<PayPeriodEntity>;
  PayItemListing: Array<PayItemEntity>;
  PayrollProcessingPayItemListing: Array<PayItemEntity>;
  PayrollProcessingPayItemListing2: Array<PayItemEntity>;
  LeaveTypePayItemListing: Array<PayItemEntity>;
  ClaimTypePayItemListing: Array<PayItemEntity>;
  PayItemDetail?: Maybe<PayItemEntity>;
  PayrollFixedAllowanceListing: Array<RecurringPayEntity>;
  PayrollFixedAllowanceDetails: Array<RecurringPayEntity>;
  CP159Listing: Array<Cp159Entity>;
  OneTimePayListing: Array<OneTimePayEntity>;
  PublishPaySlipListing: Array<PublishPayslipEntity>;
  GetSingleUser: Array<UserEntity>;
  PublishEAListing: Array<PublishEaEntity>;
  MonthlyTransactionByEmployee: Array<EmployeeTransactionOutput>;
  MonthlyTransactionByPayItem: Array<PayItemTransactionOutput>;
  MonthlyTransactionByExcel: Array<ExcelPayItemTransactionOutput>;
  FreezepayrollListing: Array<FreezePayrollEntity>;
  PayrollFixedAllowanceByExcel: Array<ExcelPayItemTransactionOutput>;
  RequisitionManagementListing?: Maybe<Array<RequisitionOutput>>;
  EmployeeRequisitionListing: Array<RequisitionEntity>;
  FilterEmployeeRequisitionListing: Array<RequisitionEntity>;
  TravelRequsitionListing: Array<RequisitionEntity>;
  RequisitionDetail: Array<RequisitionEntity>;
  GetRequisitionToAprove?: Maybe<Array<RequisitionEntity>>;
  GetRequisitionApprovalHistory?: Maybe<Array<RequisitionOutput>>;
  StatesListing: Array<StatesEntity>;
  ViewGeneralSettingSummary: GeneralSettingsLastUpdatedOutput;
  EAPartFListing: Array<EaPartFEntity>;
  EAPartFListingByYear: Array<EaPartFEntity>;
  EISPolicyListing: Array<EisPolicyEntity>;
  EISPolicyDetail: EisPolicyEntity;
  EPFPolicyListing: Array<EpfPolicyEntity>;
  EPFPolicyDetail: EpfPolicyEntity;
  PublicHolidayCalendarListing: Array<HolidayEntity>;
  PublicHolidayListing: Array<HolidayEntity>;
  PublicHolidayForStatesListing: Array<HolidayEntity>;
  SocsoPolicyListing: Array<SocsoPolicyEntity>;
  SocsoPolicyDetail: SocsoPolicyEntity;
  SystemAdminSummaryListing: SystemAdminSummaryOutput;
  TaxReliefListing: Array<TaxReliefEntity>;
  TaxReliefListingByYear: Array<TaxReliefEntity>;
  AttendanceAnalysis: Array<AttendanceDetailOutput>;
  AttendanceProcessingList: Array<Scalars['JSON']>;
  MonthlyAttendanceProcessingList: Array<Scalars['JSON']>;
  getChangeShift: Array<ChangeShiftEntity>;
  getShiftByEmployee: Scalars['JSON'];
  getShiftByTLA: Array<ShiftEntity>;
  getShiftGroupByTLA: Array<ShiftGroupEntity>;
  getShiftGroupByShiftPattern: Array<ShiftGroupEntity>;
  getShiftPatternByTLA: Array<ShiftPatternEntity>;
  getEmpByDuty: Array<Scalars['JSON']>;
  getChangeShiftListing: Array<ChangeShiftEntity>;
  GetChangeShiftToApprove?: Maybe<Array<ChangeShiftEntity>>;
  getChangeShiftByYear: Array<ChangeShiftEntity>;
  ChangeShiftMenuSummary?: Maybe<Scalars['JSON']>;
  getDailyAllowance: Array<DailyAllowancePolicyEntity>;
  getDailyAllowanceByTLA: Array<DailyAllowancePolicyEntity>;
  getDeductionPolicy: Array<DeductionPolicyEntity>;
  getDeductionPolicyByTLA: Array<DeductionPolicyEntity>;
  getDutyRoster: Array<DutyRosterEntity>;
  DutyRosterInfoLine: Scalars['JSON'];
  DutyRosterEmployeeSum: Scalars['JSON'];
  DutyRosterDates: Scalars['JSON'];
  DutyRosterSummaryByEmployee: Scalars['JSON'];
  DutyRosterSummary: Scalars['JSON'];
  DutyRosterByExcel: Scalars['JSON'];
  EarnLeavePosting: Scalars['JSON'];
  EarnLeavePeriodSummary?: Maybe<Scalars['JSON']>;
  EmployeeTMSSummary?: Maybe<EmployeeSummary>;
  getJobGradePolicyMatrix: Array<JobGradePolicyMatrixEntity>;
  getMobileCheckIn: Array<MobileCheckInEntity>;
  QRListing: DocumentsEntity;
  AttendanceHistory: Array<Scalars['JSON']>;
  getMonthlyAllowance: Array<MonthlyAllowancePolicyEntity>;
  getMonthlyAllowanceByTLA: Array<MonthlyAllowancePolicyEntity>;
  getOvertimeClaimTable: Array<OvertimeClaimTableEntity>;
  getOvertimeReason: Array<OvertimeReasonEntity>;
  OTReasonListing: Array<OvertimeReasonEntity>;
  OTReasonByTLA: Array<OvertimeReasonEntity>;
  getOvertimePolicy: Array<OvertimePolicyEntity>;
  getAllowanceDeduction: Array<AllowanceDeductionRoundingEntity>;
  getOTRounding: Array<OtRoundingEntity>;
  getShift: Array<ShiftEntity>;
  getShiftDay: Array<ShiftDayEntity>;
  getShiftList: Array<ShiftEntity>;
  getShiftGroup: Array<ShiftGroupEntity>;
  getShiftPattern: Array<ShiftPatternEntity>;
  ShiftPatternListing: Array<ShiftPatternEntity>;
  TemplateLibrarySummary?: Maybe<TemplateLibrarySummaryOutput>;
  TMSSummary?: Maybe<TmsSummary>;
  getTemplateLibrary: Array<TemplateLibraryAssignmentEntity>;
  getTemplateLibraryAssignment: Array<TemplateLibraryAssignmentEntity>;
  getJobGradeByTLA: Array<Scalars['JSON']>;
  TimeOffListing: Array<TimeOffEntity>;
  TimeOffListingByYear: Array<TimeOffEntity>;
  GetTimeOffToApprove?: Maybe<Array<TimeOffEntity>>;
  TimeOffApplicationPeriodSummary?: Maybe<Scalars['JSON']>;
  getTimeOff: Array<TimeOffPolicyEntity>;
  TimeOffReasonByTLA: Array<TimeOffReasonEntity>;
  TimeOffReasonListing: Array<TimeOffReasonEntity>;
  TimeOffSettingListing: Array<TimeOffSettingEntity>;
  getEntityRoleUserAssignment: Array<RoleUserEntAssignmentEntity>;
  getRole: Array<RoleEntity>;
  userRoleIDs: Array<UserRoleId>;
  getCompanyPermission: Scalars['JSON'];
  getRolePermission: Scalars['JSON'];
  getUsersRoleContract: Array<UserEntity>;
  AllowancePolicy: Array<AllowanceBenfitEntity>;
  AllowanceEntitlementListing: Array<AllowanceItemEntity>;
  AllowanceEntitlementDetail: Array<AllowanceItemEntity>;
  CompanySummaryDates: CompanyViewLastUpdatedOutput;
  PayProcessSelectPayPeriod: Array<PayPeriodEntity>;
  PayProcessSelectPayrollCycle: Array<PayrollCycleEntity>;
  PayProcessEmployeeList: Array<EmployeeEntity>;
  PayProcessDetail: Array<PayrollDetailEntity>;
  loggedInEmployeeProfile: EmployeeResponse;
  loggedInUserProfile: UserEntity;
  activeUser: Scalars['Float'];
  getUsersByAccount: Array<UserEntity>;
  getUser: UserEntity;
  getUsersbyIds: Array<UserEntity>;
  isUserValid: Scalars['Boolean'];
  getUsersBySoftware: Array<UserEntity>;
  getSuperUsers: Array<UserEntity>;
  UserProfilePic: Scalars['JSON'];
};


export type QueryAttendancePostingListingArgs = {
  DateTo: Scalars['String'];
  DateFrom: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryAttendancePeriodSummaryArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
};


export type QueryGetAttendanceRecordArgs = {
  input?: Maybe<AttendanceRecordInput>;
  DateTimeStart?: Maybe<Scalars['DateTime']>;
  DateTimeEnd?: Maybe<Scalars['DateTime']>;
  timezoneOffset?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['String']>;
};


export type QueryAttendanceRecordSummaryArgs = {
  CompanyID: Scalars['String'];
};


export type QueryAttendanceRecordByExcelArgs = {
  DateTime?: Maybe<Scalars['DateTime']>;
  CompanyID: Scalars['String'];
};


export type QueryAttendanceRecordListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryAttendanceRecordByPeriodArgs = {
  CompanyID: Scalars['String'];
  EndMonth: Scalars['DateTime'];
  StartMonth: Scalars['DateTime'];
};


export type QueryGetAttendanceRecordLastModifiedArgs = {
  companyID: Scalars['String'];
  timezoneOffset: Scalars['Float'];
  year: Scalars['DateTime'];
};


export type QueryCompanyBusinessInsightArgs = {
  input: EmployeeBusinessInsightListing;
};


export type QueryEmployeeBusinessInsightSummaryArgs = {
  input: EmployeeBusinessInsightListing;
};


export type QueryEmployeeBusinessInsightRawListingArgs = {
  input: EmployeeBusinessInsightListing;
};


export type QueryEmployeeBusinessInsightListingArgs = {
  input: EmployeeBusinessInsightListing;
};


export type QueryPayrollBusinessInsightStatutoryListingArgs = {
  input: EmployeeBusinessInsightListing;
};


export type QueryPayrollBusinessInsightSalaryOverheadListingArgs = {
  input: EmployeeBusinessInsightListing;
};


export type QueryPayrollBusinessInsightPayItemListingArgs = {
  input: EmployeeBusinessInsightListing;
};


export type QueryPayrollBusinessInsightSummaryArgs = {
  input: EmployeeBusinessInsightListing;
};


export type QueryTopLeaveTakerByTypeArgs = {
  LeaveTypeIDs: Array<Scalars['String']>;
  EntityIDs: Array<Scalars['String']>;
  FilterBy: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryLeaveBiLeaveTakenListingArgs = {
  input: EmployeeBusinessInsightListing;
};


export type QueryTopClaimTakerArgs = {
  ClaimTypeIDs: Array<Scalars['String']>;
  EntityIDs: Array<Scalars['String']>;
  FilterBy: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryClaimBiClaimTakenArgs = {
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  CompanyIDs?: Maybe<Array<Scalars['String']>>;
  ClaimTypeIDs: Array<Scalars['String']>;
  Year: Scalars['Float'];
  SubscriptionAccountID: Scalars['String'];
};


export type QueryTopRequisitionTakerArgs = {
  RequisitionType: Array<RequisitionType>;
  EntityIDs: Array<Scalars['String']>;
  FilterBy: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryRequisitionTakenSummaryByTypeArgs = {
  EntityIDs: Array<Scalars['String']>;
  FilterBy: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryGetEmpListingbyCompanyArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetEmpsClaimEntitlementArgs = {
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetMyClaimEntitlementArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryGetEmpsClaimBalanceArgs = {
  input: EmpClaimEntitlementInput;
};


export type QueryGetMyClaimBalanceArgs = {
  AsToDate: Scalars['DateTime'];
  EmployeeID: Scalars['String'];
};


export type QueryGetMyClaimListingArgs = {
  input: ClaimRecordsInput;
};


export type QueryGetMyClaimDetailsArgs = {
  ClaimHeaderID: Scalars['String'];
};


export type QueryGetMainClaimListingArgs = {
  Year: Scalars['Float'];
  EmployeeID: Scalars['String'];
};


export type QueryGetEmpClaimListingArgs = {
  Input: Array<GetEmpClaimListingInput>;
};


export type QueryGetApprovedDetailArgs = {
  ClaimHeaderID: Scalars['String'];
};


export type QueryGetClaimRecordListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetApproveClaimVerificationArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetClaimPostingManagementArgs = {
  DateTo: Scalars['String'];
  DateFrom: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetClaimRecordHistoryArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryGetCompletedDetailArgs = {
  ClaimHeaderID: Scalars['String'];
};


export type QueryMyClaimDetailArgs = {
  ClaimDetailID: Scalars['String'];
};


export type QueryClaimListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryGetAllExpenseAccountArgs = {
  CompanyID: Scalars['String'];
};


export type QueryClaimValidityDetailsArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryClaimTypeListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryClaimTypeTemplateListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryClaimTypeTemplateDetailArgs = {
  ClaimBenefitID: Scalars['String'];
};


export type QueryGetClaimSettingsListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryClaimTemplateListingArgs = {
  JobGradeID?: Maybe<Scalars['String']>;
  JobGradePolicyID?: Maybe<Scalars['String']>;
};


export type QueryGetClaimManagementListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetClaimRecordsListingArgs = {
  input: ClaimRecordsInput;
};


export type QueryGetClaimRecordsDetailsArgs = {
  ClaimHeaderID: Scalars['String'];
};


export type QueryGetClaimRecordsHistoryListingArgs = {
  input: ClaimRecordsInput;
};


export type QueryGetApprovedClaimVerificationListingArgs = {
  input: ApprovalClaimVerificationInput;
};


export type QueryGetApprovedClaimVerificationDetailsArgs = {
  ClaimHeaderID: Scalars['String'];
};


export type QueryGetClaimPostingListingArgs = {
  input: ApprovalClaimVerificationInput;
};


export type QueryGetClaimPostingDetailArgs = {
  ClaimHeaderID: Scalars['String'];
};


export type QueryGetClaimPostingPeriodListingArgs = {
  input: ClaimPostingPeriodListingInput;
};


export type QueryGetClaimPostingPeriodListingV2Args = {
  input: ClaimPostingPeriodListingInput;
};


export type QueryGetClaimEntitlementByClaimTypeListingArgs = {
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  toDate: Scalars['DateTime'];
  ClaimTypeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetClaimEntitlementByEmployeeListingArgs = {
  AsToDate: Scalars['DateTime'];
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetClaimToApproveArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  ApproverID: Scalars['String'];
};


export type QueryGetClaimApprovalHistoryArgs = {
  ApproverID: Scalars['String'];
};


export type QueryBankDetailArgs = {
  BankInfoIDs: Array<Scalars['String']>;
};


export type QueryBankListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryHolidayListingArgs = {
  States: Scalars['String'];
  PeriodYear: Scalars['Float'];
};


export type QueryCompanyOffDaysListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryOffRestDayListingArgs = {
  StateID: Scalars['String'];
  PeriodYear: Scalars['Float'];
  CompanyID: Scalars['String'];
};


export type QueryCalendarStateListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryEmployeeStateAssignListingArgs = {
  IsDefault: Scalars['Boolean'];
  EmployeeFormStatus?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  StateID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryCalendarPolicyListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryEmployeeHomeCalendarArgs = {
  CompanyID: Scalars['String'];
  LeaveDate: Scalars['DateTime'];
};


export type QueryDocumentListingArgs = {
  DocumentType: DocumentType;
  ParentID: Scalars['String'];
};


export type QueryCompanyListingByEmailArgs = {
  EmployeeEmail?: Maybe<Scalars['String']>;
};


export type QueryCompanyListingArgs = {
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  SubscriptionAccountID: Scalars['String'];
};


export type QueryCompanySummaryArgs = {
  CompanyID: Scalars['String'];
};


export type QueryJobDesignationDetailArgs = {
  JobDesignationID: Scalars['String'];
};


export type QueryJobDesignationGradeListingArgs = {
  JobDesignationID: Scalars['String'];
};


export type QueryJobGradeByJobDesignationListingArgs = {
  JobDesignationID: Scalars['String'];
};


export type QueryJobDesignationListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryRecruitmentSourceListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryCompanyStatutoryDetailArgs = {
  CompanyID: Scalars['String'];
};


export type QueryDepartmentListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryDepartmentDivisionListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryDepartmentDetailArgs = {
  DepartmentID: Scalars['String'];
};


export type QueryDivisionListingArgs = {
  DepartmentID: Scalars['String'];
};


export type QueryDivisionDetailArgs = {
  DepartmentID: Scalars['String'];
};


export type QueryGetInsuranceSchemeArgs = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  InsuranceSchemeID?: Maybe<Scalars['String']>;
};


export type QueryInterbankDetailArgs = {
  InterbankGiroIDs: Array<Scalars['String']>;
};


export type QueryInterbankListingBulkArgs = {
  BankInfoID: Array<Scalars['String']>;
};


export type QueryInterbankListingArgs = {
  BankInfoID: Scalars['String'];
};


export type QueryMyJobAccountListingArgs = {
  JobAccountID: Scalars['String'];
};


export type QueryJobAccountListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryJobAccountDetailArgs = {
  JobAccountID: Scalars['String'];
};


export type QueryJobGradeDetailArgs = {
  JobGradeID: Scalars['String'];
};


export type QueryJobGradeListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryJobGradeListingByCompanyArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID: Scalars['String'];
};


export type QueryJobGradePolicyListingArgs = {
  JobGradeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryLeaveRoundingListingArgs = {
  LeaveRoundingID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};


export type QueryLeaveCompulsoryPolicyListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryLeaveTemplateListingArgs = {
  JobGradeID?: Maybe<Scalars['String']>;
  JobGradePolicyID?: Maybe<Scalars['String']>;
};


export type QueryLhdnReportListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryLhdnReportDropdownListingArgs = {
  ReportYear: Scalars['Float'];
};


export type QueryLhdnReportDetailArgs = {
  PayItemID?: Maybe<Scalars['String']>;
};


export type QueryGetLhdnReportListingArgs = {
  SubscriptionAccountID: Scalars['String'];
  periodYear: Scalars['Float'];
};


export type QueryNotificationPolicyDetailArgs = {
  NotificationPolicyID: Scalars['String'];
};


export type QueryNotificationPolicyListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryNotificationPolicyConditionListingArgs = {
  NotificationPolicyID: Scalars['String'];
};


export type QueryNotificationPolicyConditionDetailArgs = {
  NotificationPolicyConditionID: Scalars['String'];
};


export type QueryNotificationPolicySummaryDatesArgs = {
  CompanyID: Scalars['String'];
};


export type QueryPayrollCycleDetailArgs = {
  PayrollCycleID: Scalars['String'];
};


export type QueryPayrollCycleListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryPayrollCycleListingV2Args = {
  UserID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetSkillSetArgs = {
  Name?: Maybe<Scalars['String']>;
  SkillSetID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};


export type QueryGetSkillSetDetailArgs = {
  Name?: Maybe<Scalars['String']>;
  SkillSetID?: Maybe<Scalars['String']>;
  SkillSetDetailID?: Maybe<Scalars['String']>;
};


export type QueryBulletinListingArgs = {
  NewsID: Scalars['String'];
};


export type QueryNewsListingArgs = {
  IsPublishedOnly: Scalars['Boolean'];
  EndMonth: Scalars['String'];
  StartMonth: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};


export type QueryDocumentNumberingListArgs = {
  IsStatusActive?: Maybe<Scalars['Boolean']>;
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
};


export type QueryDocumentNumberingAssignmentListArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetBatchEntryByShiftArgs = {
  BatchEntryByShiftID?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
};


export type QueryGetBatchEntryByPatternArgs = {
  BatchEntryByShiftPatternID?: Maybe<Scalars['String']>;
};


export type QueryGetEmployeeAppraisalArgs = {
  EmployeeAppraisalID?: Maybe<Scalars['String']>;
};


export type QueryCareerLogListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryGetEmployeeDiaryArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryEmployeeDocumentListingArgs = {
  ParentID: Scalars['String'];
};


export type QueryEmployeeListingArgs = {
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryTmsEmployeeListingArgs = {
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryEmployeeListingBySubscriptionAccountArgs = {
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  SubscriptionAccountID: Scalars['String'];
};


export type QueryEmployeeAppSummaryArgs = {
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeSummaryArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeSummaryBySubscriptionArgs = {
  SubscriptionUserID: Scalars['String'];
};


export type QueryGetEmployeePrevContributionArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryFilterRequisitionListingArgs = {
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  CompanyID: Scalars['String'];
  RequisitionType: Array<Scalars['String']>;
  Year: Scalars['Float'];
  EmployeeID: Scalars['String'];
};


export type QueryRequisitionListingArgs = {
  Year: Scalars['Float'];
  EmployeeID: Scalars['String'];
};


export type QueryFixedAllowanceListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryFilterEmployeeLeaveRecordsArgs = {
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  LeaveTypeIDs: Array<Scalars['String']>;
  EmployeeID: Scalars['String'];
};


export type QueryTaxReliefListingDefaultExcludedArgs = {
  PeriodYear: Scalars['Float'];
};


export type QueryEmployeeTaxReliefListingArgs = {
  PeriodYear: Scalars['Float'];
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeTaxReliefDetailsArgs = {
  EmployeeTaxReliefID: Scalars['String'];
};


export type QueryEmployeeAssetListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeAssetDetailArgs = {
  EmployeeAssetID: Scalars['String'];
};


export type QueryEmployeeBenefitInKindListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeBenefitInKindDetailForYearArgs = {
  PeriodYear: Scalars['Float'];
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeBenefitInKindDetailArgs = {
  EmployeeBenefitInKindID: Scalars['String'];
};


export type QueryMyEmployeeInfoArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryMyNewsListArgs = {
  EmployeeID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};


export type QueryMyApplicationDetailArgs = {
  RequestID: Scalars['String'];
  RequestType: Scalars['String'];
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryEmployeeCp22ListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryNotificationListingArgs = {
  EmployeeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryNewsReadListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeCalendarArgs = {
  EmployeeID: Scalars['String'];
  CalendarDate: Scalars['DateTime'];
  CompanyID: Scalars['String'];
};


export type QueryGetEmployeeInsuranceSchemeArgs = {
  EmployeeInsuranceSchemeID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryEmployeeNotificationListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QuerySubordinateListingArgs = {
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  EmployeeID: Scalars['String'];
};


export type QueryGetEmployeeTrainingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
};


export type QueryGetLeaveOffDayArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetLeavePolicyByJobGradeIdArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  JobGradeID: Scalars['String'];
};


export type QueryGetPlatformNewLeaveTypePolicyArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryGetMyLeaveEntitlementArgs = {
  AsToDate: Scalars['DateTime'];
  EmployeeID: Scalars['String'];
};


export type QueryGetEmpLeaveBalanceArgs = {
  AsToDate: Scalars['DateTime'];
  EmployeeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryGetMyLeaveBalanceArgs = {
  AsToDate: Scalars['DateTime'];
  EmployeeID: Scalars['String'];
};


export type QueryEmployeeCalendarLeaveDetailsArgs = {
  input: EmpCalendarLeaveListing;
};


export type QueryEmployeeLeaveListingArgs = {
  input: EmpLeaveListingInput;
};


export type QueryGetEmpLeaveDetailArgs = {
  input: EmpLeaveDetailInput;
};


export type QueryGetLeaveRoundedValueArgs = {
  LeaveValue: Scalars['Float'];
  CompanyID: Scalars['String'];
};


export type QueryLeaveTypeListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryLeavePolicyListingArgs = {
  ShortNoticeOptioIDs?: Maybe<Array<Scalars['String']>>;
  AvailableBasisIDs?: Maybe<Array<Scalars['String']>>;
  EntitlementMethodIDs?: Maybe<Array<Scalars['String']>>;
  LeaveTypeIDs?: Maybe<Array<Scalars['String']>>;
  SubscriptionAccountID: Scalars['String'];
};


export type QueryLeavePolicyByIdArgs = {
  LeavePolicyID: Scalars['String'];
};


export type QueryLeaveTypeTemplateListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryLeaveTypeTemplateDetailArgs = {
  LeaveBenefitID: Scalars['String'];
};


export type QueryGetIntegratedLeaveBalanceArgs = {
  sTaken: Scalars['Float'];
  hTaken: Scalars['Float'];
  sEntilement: Scalars['Float'];
  hEntitlement: Scalars['Float'];
};


export type QueryGetEmployeesLeaveSummaryArgs = {
  AsToDate: Scalars['DateTime'];
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetLeaveEntitlementByLeaveTypeListingArgs = {
  toDate: Scalars['DateTime'];
  LeaveTypeID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryViewLeaveApprovalHistoryArgs = {
  ApproverID: Scalars['String'];
};


export type QueryGetLeaveRecordsListingArgs = {
  effectiveMonth?: Maybe<Scalars['String']>;
  input: LeaveRecordsListingInput;
};


export type QueryGetLeaveReplacementListingByEmployeeArgs = {
  AsToDate?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
};


export type QueryGetLeaveAdjustmentListingArgs = {
  input: LeaveAdjustmentListingInput;
};


export type QueryGetCompulsoryLeaveListingArgs = {
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetCompulsoryLeaveDetailArgs = {
  LeaveCompulsoryID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetLeavePostingListingArgs = {
  input: LeavePostingListingInput;
};


export type QueryGetLeaveToAproveArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  ApproverID: Scalars['String'];
};


export type QueryLeaveAdjustmentDetailArgs = {
  LeaveHeaderID: Scalars['String'];
};


export type QueryGetLeavePostingPeriodListingArgs = {
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryGetYecfPendingListingArgs = {
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryGetYecfPendingListingWithFilterArgs = {
  LeaveTypeIDs?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryGetYecfPostedListingArgs = {
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryGetYecfPostedListingWithFilterArgs = {
  LeaveTypeIDs?: Maybe<Array<Scalars['String']>>;
  DivisionIDs?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryGetYearEndCarryForwardListingArgs = {
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryFilterGetYearEndCarryForwardListingArgs = {
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  LeaveTypeIDs?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  Year: Scalars['Float'];
};


export type QueryLeaveHomeSummaryArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};


export type QueryLeaveOutOfStateListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetOvertimeArgs = {
  WorkDate?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  StartTime?: Maybe<Scalars['String']>;
  EndTime?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
};


export type QueryOvertimeListingArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryOvertimeListingByExcelArgs = {
  CompanyID: Scalars['String'];
};


export type QueryOvertimeListingByYearArgs = {
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  WorkDate: Scalars['DateTime'];
};


export type QueryOvertimeListingByPeriodArgs = {
  CompanyID: Scalars['String'];
  EndMonth: Scalars['DateTime'];
  StartMonth: Scalars['DateTime'];
};


export type QueryGetOvertimeToApproveArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  ApproverID: Scalars['String'];
};


export type QueryOvertimePeriodSummaryArgs = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CompanyID: Scalars['String'];
};


export type QueryOvertimeMenuSummaryArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetFormulaTestArgs = {
  formula: Scalars['String'];
};


export type QueryEmployeeOutstandingSalaryListingArgs = {
  SubscriptionAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryCp22CountArgs = {
  ID: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryViewPayrollHistorySummaryArgs = {
  EmployeeID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryTestPayrollCalcArgs = {
  input: ViewPayrollHistoryInput;
};


export type QueryViewPayrollHistoryArgs = {
  input: ViewPayrollHistoryInput;
};


export type QueryPayrollProcessPeriodListingArgs = {
  IsViewHistory: Scalars['Boolean'];
  Year: Scalars['Float'];
  CompanyID: Scalars['String'];
};


export type QueryPayrollProcessPayrollCycleListingArgs = {
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryPayrollProcessPayItemListingArgs = {
  PayrollCycleID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryPayrollProcessEmployeeListingArgs = {
  input: EmployeeFilterInput;
};


export type QueryCalculatePayrollArgs = {
  PayrollCalculationInput: PayrollCalculationInput;
};


export type QueryRoundingArgs = {
  input: Scalars['Float'];
};


export type QueryCalculateHrdfArgs = {
  HRDFCalculationInput: HrdfCalculationInput;
};


export type QueryCalculateEpfv2Args = {
  EPFCalculationInput: EpfCalculationInputV2;
};


export type QueryCalculateTaxZakatArgs = {
  TAXZAKATCalculationInput: TaxzakatCalculationInput;
};


export type QueryCalculateEisArgs = {
  EISCalculationInput: EisCalculationInput;
};


export type QueryCalculateSocsoArgs = {
  SocsoCalculationInput: SocsoCalculationInput;
};


export type QueryPayPeriodDetailsArgs = {
  PeriodYearMonth: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryPayPeriodListingArgs = {
  PeriodYear: Scalars['Float'];
  CompanyID: Scalars['String'];
};


export type QueryStartPayPeriodListingByYearArgs = {
  CompanyID: Scalars['String'];
  Year: Scalars['String'];
};


export type QueryEndPayPeriodListingByYearArgs = {
  CompanyID: Scalars['String'];
  Year: Scalars['String'];
};


export type QueryPayItemListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryPayrollProcessingPayItemListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryPayrollProcessingPayItemListing2Args = {
  PayrollCycleID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
};


export type QueryLeaveTypePayItemListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryClaimTypePayItemListingArgs = {
  SubscriptionAccountID: Scalars['String'];
};


export type QueryPayItemDetailArgs = {
  PayItemID?: Maybe<Scalars['String']>;
};


export type QueryPayrollFixedAllowanceListingArgs = {
  input: PayrollFixedAllowanceInput;
};


export type QueryPayrollFixedAllowanceDetailsArgs = {
  RecurringPayID: Scalars['String'];
};


export type QueryCp159ListingArgs = {
  PeriodYear: Scalars['Float'];
  CompanyID: Scalars['String'];
};


export type QueryOneTimePayListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryPublishPaySlipListingArgs = {
  Year: Scalars['Float'];
  CompanyID: Scalars['String'];
};


export type QueryGetSingleUserArgs = {
  ID: Array<Scalars['String']>;
};


export type QueryPublishEaListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryMonthlyTransactionByEmployeeArgs = {
  input: MonthlyTransactionInput;
};


export type QueryMonthlyTransactionByPayItemArgs = {
  input: PayItemFilterInput;
};


export type QueryMonthlyTransactionByExcelArgs = {
  input: MonthlyTransactionInput;
};


export type QueryFreezepayrollListingArgs = {
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
};


export type QueryPayrollFixedAllowanceByExcelArgs = {
  CompanyID: Scalars['String'];
};


export type QueryRequisitionManagementListingArgs = {
  DepartmentIDs: Array<Scalars['String']>;
  EmployeeStatus: Array<Scalars['String']>;
  RequisitionType: Array<Scalars['String']>;
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryEmployeeRequisitionListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryFilterEmployeeRequisitionListingArgs = {
  CompanyID: Scalars['String'];
  RequisitionType: Array<Scalars['String']>;
  ApprovalStatus: Array<Scalars['String']>;
  EndDate: Scalars['String'];
  StartDate: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryTravelRequsitionListingArgs = {
  EmployeeID: Scalars['String'];
};


export type QueryRequisitionDetailArgs = {
  RequisitionHeaderID: Scalars['String'];
};


export type QueryGetRequisitionToAproveArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  ApproverID: Scalars['String'];
};


export type QueryGetRequisitionApprovalHistoryArgs = {
  ApproverID: Scalars['String'];
};


export type QueryStatesListingArgs = {
  Country?: Maybe<Scalars['String']>;
};


export type QueryViewGeneralSettingSummaryArgs = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};


export type QueryEaPartFListingByYearArgs = {
  PeriodYear: Scalars['Float'];
};


export type QueryEisPolicyDetailArgs = {
  EISPolicyID: Scalars['String'];
};


export type QueryEpfPolicyDetailArgs = {
  EPFPolicyID: Scalars['String'];
};


export type QueryPublicHolidayCalendarListingArgs = {
  PeriodYear: Scalars['Float'];
};


export type QueryPublicHolidayListingArgs = {
  PeriodYear: Scalars['Float'];
};


export type QueryPublicHolidayForStatesListingArgs = {
  Description: Scalars['String'];
  HolidayDate: Scalars['DateTime'];
};


export type QuerySocsoPolicyDetailArgs = {
  SocsoPolicyID: Scalars['String'];
};


export type QueryTaxReliefListingByYearArgs = {
  PeriodYear: Scalars['Float'];
};


export type QueryAttendanceAnalysisArgs = {
  ToDate?: Maybe<Scalars['String']>;
  FromDate: Scalars['String'];
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryAttendanceProcessingListArgs = {
  ToDate?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  FromDate: Scalars['String'];
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryMonthlyAttendanceProcessingListArgs = {
  ToDate?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  FromDate: Scalars['String'];
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetChangeShiftArgs = {
  CompanyID: Scalars['String'];
  ChangeShiftID?: Maybe<Scalars['String']>;
};


export type QueryGetShiftByTlaArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetShiftGroupByTlaArgs = {
  ShiftID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetShiftGroupByShiftPatternArgs = {
  CompanyID: Scalars['String'];
  ShiftPatternID: Scalars['String'];
};


export type QueryGetShiftPatternByTlaArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetEmpByDutyArgs = {
  DutyDate: Scalars['DateTime'];
  CompanyID: Scalars['String'];
};


export type QueryGetChangeShiftToApproveArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  ApproverID: Scalars['String'];
};


export type QueryGetChangeShiftByYearArgs = {
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  ChangeShiftStatus?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  Date: Scalars['DateTime'];
};


export type QueryChangeShiftMenuSummaryArgs = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CompanyID: Scalars['String'];
};


export type QueryGetDailyAllowanceArgs = {
  DailyAllowanceID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  Condition?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['String']>;
};


export type QueryGetDailyAllowanceByTlaArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetDeductionPolicyArgs = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  DeductionPolicyID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
};


export type QueryGetDeductionPolicyByTlaArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetDutyRosterArgs = {
  ExcelFileName?: Maybe<Scalars['String']>;
  DutyRosterID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  DutyDate?: Maybe<Scalars['DateTime']>;
  CompanyID: Scalars['String'];
};


export type QueryDutyRosterInfoLineArgs = {
  CompanyID: Scalars['String'];
};


export type QueryDutyRosterEmployeeSumArgs = {
  date: Scalars['DateTime'];
  CompanyID: Scalars['String'];
};


export type QueryDutyRosterDatesArgs = {
  date: Scalars['DateTime'];
  CompanyID: Scalars['String'];
};


export type QueryDutyRosterSummaryByEmployeeArgs = {
  date: Scalars['DateTime'];
  CompanyID: Scalars['String'];
};


export type QueryDutyRosterSummaryArgs = {
  date: Scalars['DateTime'];
  CompanyID: Scalars['String'];
};


export type QueryDutyRosterByExcelArgs = {
  CompanyID: Scalars['String'];
};


export type QueryEarnLeavePostingArgs = {
  UntilDate: Scalars['String'];
  FromDate: Scalars['String'];
  EmployeeIDs: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryEarnLeavePeriodSummaryArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
};


export type QueryEmployeeTmsSummaryArgs = {
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetJobGradePolicyMatrixArgs = {
  JobGradePolicyMatrixID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  JobGradeID?: Maybe<Scalars['String']>;
  MonthlyAllowanceIDs?: Maybe<Array<Scalars['String']>>;
  DeductionPolicyIDs?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetMobileCheckInArgs = {
  Name?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  MobileCheckInID?: Maybe<Scalars['String']>;
};


export type QueryQrListingArgs = {
  DocumentID: Scalars['String'];
};


export type QueryAttendanceHistoryArgs = {
  ToDate: Scalars['String'];
  FromDate: Scalars['String'];
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
};


export type QueryGetMonthlyAllowanceArgs = {
  Name?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  Condition?: Maybe<Scalars['String']>;
  Value?: Maybe<Scalars['String']>;
};


export type QueryGetMonthlyAllowanceByTlaArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetOvertimeClaimTableArgs = {
  OvertimeClaimTableID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
};


export type QueryGetOvertimeReasonArgs = {
  OTReason?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};


export type QueryOtReasonListingArgs = {
  Status?: Maybe<Status>;
};


export type QueryOtReasonByTlaArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetOvertimePolicyArgs = {
  OvertimePolicyID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};


export type QueryGetAllowanceDeductionArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  AllowanceDeductionID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};


export type QueryGetOtRoundingArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  OTRoundingID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
};


export type QueryGetShiftArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ShiftID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
};


export type QueryGetShiftDayArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ShiftID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
};


export type QueryGetShiftGroupArgs = {
  Status?: Maybe<Status>;
  EndMonth?: Maybe<Scalars['DateTime']>;
  StartMonth?: Maybe<Scalars['DateTime']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
};


export type QueryGetShiftPatternArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ShiftPatternID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
};


export type QueryShiftPatternListingArgs = {
  EndMonth?: Maybe<Scalars['DateTime']>;
  StartMonth?: Maybe<Scalars['DateTime']>;
  ShiftPatternID?: Maybe<Scalars['String']>;
};


export type QueryTemplateLibrarySummaryArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryTmsSummaryArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetTemplateLibraryArgs = {
  input?: Maybe<TemplateLibraryAssignmentInput>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['String']>;
};


export type QueryGetTemplateLibraryAssignmentArgs = {
  input?: Maybe<TemplateLibraryAssignmentInput>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['String']>;
};


export type QueryGetJobGradeByTlaArgs = {
  CompanyID: Scalars['String'];
};


export type QueryTimeOffListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryTimeOffListingByYearArgs = {
  JobGradeIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeType?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  CompanyID: Scalars['String'];
  WorkDate: Scalars['DateTime'];
};


export type QueryGetTimeOffToApproveArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  ApproverID: Scalars['String'];
};


export type QueryTimeOffApplicationPeriodSummaryArgs = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CompanyID: Scalars['String'];
};


export type QueryGetTimeOffArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  TimeOffPolicyID?: Maybe<Scalars['String']>;
};


export type QueryTimeOffReasonByTlaArgs = {
  CompanyID: Scalars['String'];
};


export type QueryTimeOffReasonListingArgs = {
  Status?: Maybe<Status>;
};


export type QueryTimeOffSettingListingArgs = {
  Status?: Maybe<Status>;
};


export type QueryGetEntityRoleUserAssignmentArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  entityID?: Maybe<Scalars['String']>;
};


export type QueryGetRoleArgs = {
  ID?: Maybe<Scalars['String']>;
};


export type QueryUserRoleIDsArgs = {
  companyID: Scalars['String'];
};


export type QueryGetUsersRoleContractArgs = {
  companyID: Scalars['String'];
  roleID: Scalars['String'];
};


export type QueryAllowancePolicyArgs = {
  CompanyID: Scalars['String'];
};


export type QueryAllowanceEntitlementListingArgs = {
  AllowanceBenefitID: Scalars['String'];
};


export type QueryAllowanceEntitlementDetailArgs = {
  AllowanceItemIDs: Array<Scalars['String']>;
};


export type QueryCompanySummaryDatesArgs = {
  SubscriptionAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryPayProcessSelectPayPeriodArgs = {
  input: PayPeriodSelectionInput;
};


export type QueryPayProcessSelectPayrollCycleArgs = {
  CompanyID: Scalars['String'];
};


export type QueryPayProcessEmployeeListArgs = {
  CompanyID: Scalars['String'];
};


export type QueryPayProcessDetailArgs = {
  PayrollDetailID: Scalars['String'];
};


export type QueryGetUsersByAccountArgs = {
  accountID: Scalars['String'];
};


export type QueryGetUserArgs = {
  ID: Scalars['String'];
};


export type QueryGetUsersbyIdsArgs = {
  userIDs: Array<Scalars['String']>;
};


export type QueryIsUserValidArgs = {
  userID: Scalars['String'];
};


export type QueryUserProfilePicArgs = {
  UserID: Scalars['String'];
};

export enum Race {
  Malay = 'Malay',
  Chinese = 'Chinese',
  Indian = 'Indian',
  Others = 'Others'
}

export enum ReadStatus {
  Read = 'Read',
  Unread = 'Unread'
}

export type Reason = InterfaceBase & {
  __typename?: 'Reason';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ReasonID: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  Name: Scalars['String'];
};

export type ReasonInput = {
  Name: Scalars['String'];
};

export enum RecordSource {
  ExcelUpload = 'ExcelUpload',
  ManualByPayItem = 'ManualByPayItem',
  ManualByEmployee = 'ManualByEmployee',
  RecurringPay = 'RecurringPay'
}

export type RecruitmentSourceEntity = InterfaceBase & {
  __typename?: 'RecruitmentSourceEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RecruitmentSourceID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  EmployeeInfo?: Maybe<EmployeeEntity>;
};

export type RecruitmentSourceInput = {
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type RecurringPayEntity = InterfaceBase & {
  __typename?: 'RecurringPayEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RecurringPayID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  PayItemID?: Maybe<Scalars['String']>;
  PayItem?: Maybe<PayItemEntity>;
  StartPeriod?: Maybe<PayPeriodEntity>;
  EndPeriod?: Maybe<PayPeriodEntity>;
  Employee?: Maybe<EmployeeEntity>;
  StartPeriodID?: Maybe<Scalars['String']>;
  EndPeroidID?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  PayrollCycle?: Maybe<PayrollCycleEntity>;
};

export type RecurringPayInput = {
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PayItemID?: Maybe<Scalars['String']>;
  PayrollCycleID?: Maybe<Scalars['String']>;
  StartPeriodID?: Maybe<Scalars['String']>;
  EndPeroidID?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type RecurringRestOffDayEntity = InterfaceBase & {
  __typename?: 'RecurringRestOffDayEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RecurringRestOffDayID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYear: Scalars['Float'];
  RestOffType: RestOffType;
  DayOfWeek: Scalars['Float'];
  StateID?: Maybe<Scalars['String']>;
  Company: CompanyEntity;
  States?: Maybe<StatesEntity>;
};

export type RecurringRestOffDayInput = {
  RecurringRestOffDayID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PeriodYear: Scalars['Float'];
  RestOffType?: Maybe<RestOffType>;
  DayOfWeek: Scalars['Float'];
  StateID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum Relationship {
  Aunt = 'Aunt',
  Brother = 'Brother',
  Cousin = 'Cousin',
  Daughter = 'Daughter',
  Father = 'Father',
  Husband = 'Husband',
  Mother = 'Mother',
  Nephew = 'Nephew',
  Niece = 'Niece',
  Sister = 'Sister',
  Son = 'Son',
  Uncle = 'Uncle',
  Wife = 'Wife',
  Others = 'Others'
}

export enum Religion {
  Islam = 'Islam',
  Buddhism = 'Buddhism',
  Christianity = 'Christianity',
  Hinduism = 'Hinduism',
  Taoism = 'Taoism',
  Others = 'Others'
}

export type ReplacementLeaveClaimType = {
  __typename?: 'ReplacementLeaveClaimType';
  DayType: DayType;
  ReplacementLeaveClaimTypeListInput?: Maybe<Array<ReplacementLeaveClaimTypeList>>;
};

export type ReplacementLeaveClaimTypeInput = {
  DayType: DayType;
  ReplacementLeaveClaimTypeListInput?: Maybe<Array<ReplacementLeaveClaimTypeListInput>>;
};

export type ReplacementLeaveClaimTypeList = {
  __typename?: 'ReplacementLeaveClaimTypeList';
  OTHoursGreater?: Maybe<Scalars['Float']>;
  OTHoursLess?: Maybe<Scalars['Float']>;
  RLEntitle?: Maybe<Scalars['Float']>;
};

export type ReplacementLeaveClaimTypeListInput = {
  OTHoursGreater?: Maybe<Scalars['Float']>;
  OTHoursLess?: Maybe<Scalars['Float']>;
  RLEntitle?: Maybe<Scalars['Float']>;
};

export enum ReportType {
  EaForm = 'EAForm',
  Cp21 = 'CP21',
  Cp22A = 'CP22A',
  Cp22B = 'CP22B',
  Cp22 = 'CP22'
}

export enum RequestType {
  Leave = 'Leave',
  Claim = 'Claim',
  Requisition = 'Requisition',
  Overtime = 'Overtime',
  ChangeShift = 'Change_Shift',
  TimeOff = 'Time_Off'
}

export type RequisitionAppOutput = {
  __typename?: 'RequisitionAppOutput';
  RequisitionType?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  NumberOfItem?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  TravelFrom?: Maybe<Scalars['String']>;
  TravelTo?: Maybe<Scalars['String']>;
  TransportMode?: Maybe<Scalars['String']>;
  PurposeOfTravel?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  ApprovalDate?: Maybe<Scalars['DateTime']>;
};

export type RequisitionDetails = {
  __typename?: 'RequisitionDetails';
  ApproverName?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<Scalars['String']>;
  RequisitionType?: Maybe<Scalars['String']>;
  DocumentFile?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  NumberOfItem?: Maybe<Scalars['Float']>;
  CostPerItem?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  TotalDays?: Maybe<Scalars['Float']>;
  TravelFrom?: Maybe<Scalars['String']>;
  TravelTo?: Maybe<Scalars['String']>;
  TransportMode?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
};

export type RequisitionEntity = InterfaceBase & {
  __typename?: 'RequisitionEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RequisitionHeaderID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  RequisitionType?: Maybe<RequisitionType>;
  TravelRequestID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  NumberOfItem?: Maybe<Scalars['Float']>;
  CostPerItem?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  TravelFrom?: Maybe<Scalars['String']>;
  TravelTo?: Maybe<Scalars['String']>;
  TransportMode?: Maybe<TransportMode>;
  PurposeOfTravel?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  DocumentID?: Maybe<Scalars['String']>;
  Documents?: Maybe<DocumentsEntity>;
  ApproverID?: Maybe<Scalars['String']>;
  RejectedRemarks?: Maybe<Scalars['String']>;
  EmployeeID: Scalars['String'];
  RequisitionHistory?: Maybe<Array<RequisitionEntity>>;
  Employee?: Maybe<EmployeeEntity>;
};

export type RequisitionInput = {
  CompanyID: Scalars['String'];
  EmployeeID: Scalars['String'];
  RequisitionType: RequisitionType;
  TravelRequestID?: Maybe<Scalars['String']>;
  RequisitionHeaderID?: Maybe<Scalars['String']>;
  Subject: Scalars['String'];
  DocumentID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  NumberOfItem?: Maybe<Scalars['Float']>;
  CostPerItem?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  TravelFrom?: Maybe<Scalars['String']>;
  TravelTo?: Maybe<Scalars['String']>;
  TransportMode?: Maybe<TransportMode>;
  PurposeOfTravel?: Maybe<Scalars['String']>;
  ApprovalStatus: ApprovalStatus;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
};

export type RequisitionOutput = {
  __typename?: 'RequisitionOutput';
  RequisitionHeaderID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  RequisitionType?: Maybe<RequisitionType>;
  TravelRequestID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  NumberOfItem?: Maybe<Scalars['Float']>;
  CostPerItem?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
  TravelFrom?: Maybe<Scalars['String']>;
  TravelTo?: Maybe<Scalars['String']>;
  TransportMode?: Maybe<TransportMode>;
  PurposeOfTravel?: Maybe<Scalars['String']>;
  DocumentID?: Maybe<Scalars['String']>;
  DocumentName?: Maybe<Scalars['String']>;
  DocumentFile?: Maybe<Scalars['String']>;
  SubmissionDate?: Maybe<Scalars['DateTime']>;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  ApprovedBy?: Maybe<Scalars['String']>;
  ReportTo?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  RejectedRemarks?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CancelledDate?: Maybe<Scalars['DateTime']>;
  CancelledBy?: Maybe<Scalars['String']>;
};

export type RequisitionTakenSummaryByTypeOutput = {
  __typename?: 'RequisitionTakenSummaryByTypeOutput';
  RequisitionType?: Maybe<RequisitionType>;
  TotalRequisitionCount?: Maybe<Scalars['Float']>;
  TotalRequisitionAmount?: Maybe<Scalars['Float']>;
  Percentage?: Maybe<Scalars['Float']>;
};

export enum RequisitionType {
  AssetRequisition = 'Asset_Requisition',
  TravelRequest = 'Travel_Request',
  CashAdvance = 'Cash_Advance',
  Others = 'Others'
}

export type RequisitionUpdateDocumentsInput = {
  DocumentID?: Maybe<Scalars['String']>;
  DocumentType?: Maybe<DocumentType>;
  Description?: Maybe<Scalars['String']>;
  DocumentFile?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Scalars['Upload']>;
  Status?: Maybe<Status>;
  ParentID?: Maybe<Scalars['String']>;
  BucketFileName?: Maybe<Scalars['String']>;
  FileSize?: Maybe<Scalars['Float']>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type RequisitionUpdateInput = {
  RequisitionHeaderID: Scalars['String'];
  ApprovalStatus: ApprovalStatus;
  ApprovalDT?: Maybe<Scalars['DateTime']>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  RejectedRemarks?: Maybe<Scalars['String']>;
};

export type ResBiyearly = {
  __typename?: 'ResBiyearly';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

export type ResLifetimely = {
  __typename?: 'ResLifetimely';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

export type ResMonthly = {
  __typename?: 'ResMonthly';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

export type ResYearly = {
  __typename?: 'ResYearly';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

export enum ResidentStatus {
  Resident = 'Resident',
  NonResident = 'Non_Resident',
  ReturningExpertProgram = 'Returning_Expert_Program',
  KnowledgeWorker = 'Knowledge_Worker'
}

export enum RestOffType {
  RestDay = 'Rest_Day',
  OffDay = 'Off_Day',
  PublicHoliday = 'Public_Holiday'
}

export type RoleEntity = AuditEntity & {
  __typename?: 'RoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  rolePerm: Array<RolePermissionAssignmentEntity>;
  roleUserAssignments?: Maybe<Array<RoleUserEntAssignmentEntity>>;
};

export type RoleInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
};

export type RolePermissionAssignmentEntity = AuditEntity & {
  __typename?: 'RolePermissionAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  roleID: Scalars['String'];
  permissionID: Scalars['Float'];
  permName?: Maybe<Scalars['String']>;
};

export type RoleUserEntAssignmentEntity = AuditEntity & {
  __typename?: 'RoleUserEntAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  entityID: Scalars['String'];
  roleID: Scalars['String'];
  accountID: Scalars['String'];
  userID: Scalars['String'];
  role: RoleEntity;
};

export type RoleUserInput = {
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type RoundingCriteria = {
  __typename?: 'RoundingCriteria';
  From?: Maybe<Scalars['Float']>;
  To?: Maybe<Scalars['Float']>;
  RoundedTo?: Maybe<Scalars['Float']>;
};

export type RoundingCriteriaInput = {
  From?: Maybe<Scalars['Float']>;
  To?: Maybe<Scalars['Float']>;
  RoundedTo?: Maybe<Scalars['Float']>;
};

export type RoundingMethodEntity = InterfaceBase & {
  __typename?: 'RoundingMethodEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  RoundingMethodID: Scalars['String'];
  Name: Scalars['String'];
  RoundingType?: Maybe<RoundingType>;
  RoundingList: Scalars['String'];
};

export type RoundingMethodInput = {
  RoundingMethodID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  RoundingType?: Maybe<RoundingType>;
  RoundingList: Scalars['String'];
};

export enum RoundingType {
  Time = 'TIME',
  Unit = 'UNIT'
}

export enum SalaryType {
  Monthly = 'Monthly',
  Daily = 'Daily'
}

export enum Sequence {
  FirstDeduction = 'FIRST_DEDUCTION',
  SecondDeduction = 'SECOND_DEDUCTION',
  ThirdDeduction = 'THIRD_DEDUCTION'
}

export enum SessionType {
  Full = 'Full',
  FirstHalf = 'First_Half',
  SecondHalf = 'Second_Half'
}

export type ShiftDayEntity = InterfaceBase & {
  __typename?: 'ShiftDayEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ShiftDayID?: Maybe<Scalars['String']>;
  DayType: DayType;
  ClockPairing: ClockPairingType;
  CrossDay: CrossDayType;
  FlexiHour?: Maybe<Scalars['Boolean']>;
  TotalWorkingHour?: Maybe<Scalars['Float']>;
  ClockInTime?: Maybe<Scalars['String']>;
  ClockOutTime?: Maybe<Scalars['String']>;
  ShiftStartTime?: Maybe<Scalars['String']>;
  ShiftEndTime?: Maybe<Scalars['String']>;
  FirstHalfEndTime?: Maybe<Scalars['String']>;
  SecondHalfStartTime?: Maybe<Scalars['String']>;
  CoreHourStartTime?: Maybe<Scalars['String']>;
  CoreHourEndTime?: Maybe<Scalars['String']>;
  FlexiBreak: Scalars['Boolean'];
  ShiftBreakInterval?: Maybe<Scalars['Float']>;
  shift?: Maybe<ShiftEntity>;
  ShiftID?: Maybe<Scalars['String']>;
};

export type ShiftDayInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  ClockPairing: ClockPairingType;
  CrossDay: CrossDayType;
  DayType?: Maybe<DayType>;
  FlexiHour?: Maybe<Scalars['Boolean']>;
  TotalWorkingHour?: Maybe<Scalars['Float']>;
  ClockInTime?: Maybe<Scalars['String']>;
  ClockOutTime?: Maybe<Scalars['String']>;
  ShiftStartTime?: Maybe<Scalars['String']>;
  ShiftEndTime?: Maybe<Scalars['String']>;
  FirstHalfEndTime?: Maybe<Scalars['String']>;
  SecondHalfStartTime?: Maybe<Scalars['String']>;
  CoreHourStartTime?: Maybe<Scalars['String']>;
  CoreHourEndTime?: Maybe<Scalars['String']>;
  FlexiBreak: Scalars['Boolean'];
  ShiftBreakInterval?: Maybe<Scalars['Float']>;
  ShiftDayID?: Maybe<Scalars['String']>;
};

export type ShiftEntity = InterfaceBase & {
  __typename?: 'ShiftEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ShiftID: Scalars['String'];
  IsDefault?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  DutyRosterDisplayColour: Scalars['String'];
  IsPreOvertimeMin: Scalars['Boolean'];
  PreOvertimeMin?: Maybe<Scalars['Float']>;
  IsPostOvertimeMin: Scalars['Boolean'];
  PostOvertimeMin?: Maybe<Scalars['Float']>;
  IsFixedOvertimeMin: Scalars['Boolean'];
  FixedOvertimeTotal?: Maybe<Scalars['Float']>;
  fromShiftList?: Maybe<Array<ChangeShiftEntity>>;
  toShiftList?: Maybe<Array<ChangeShiftEntity>>;
  breakTimeList?: Maybe<Array<BreakTimeEntity>>;
  shiftDayList?: Maybe<Array<ShiftDayEntity>>;
  shiftPatternDetailList?: Maybe<Array<ShiftPatternDetailEntity>>;
};

export type ShiftGroupEntity = InterfaceBase & {
  __typename?: 'ShiftGroupEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Start?: Maybe<Scalars['String']>;
  End?: Maybe<Scalars['String']>;
  DefaultShiftGroup?: Maybe<Scalars['Boolean']>;
  ShiftGroupShift: Array<ShiftGroupShiftEntity>;
  Shift?: Maybe<Array<ShiftEntity>>;
  ShiftCount: Scalars['Float'];
  Employee?: Maybe<Array<EmployeeEntity>>;
  EmployeeCount: Scalars['Float'];
};

export type ShiftGroupInput = {
  ShiftGroupID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Start?: Maybe<Scalars['String']>;
  End?: Maybe<Scalars['String']>;
  DefaultShiftGroup?: Maybe<Scalars['Boolean']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
};

export type ShiftGroupShiftEntity = InterfaceBase & {
  __typename?: 'ShiftGroupShiftEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ShiftGroupShiftID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  ShiftGroupID: Scalars['String'];
  ShiftID: Scalars['String'];
  ShiftDayID?: Maybe<Scalars['String']>;
  ShiftGroup: ShiftGroupEntity;
};

export type ShiftGroupShiftInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  ShiftID?: Maybe<Scalars['String']>;
  ShiftDayID?: Maybe<Scalars['String']>;
  ShiftGroupID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
};

export type ShiftInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  DutyRosterDisplayColour: Scalars['String'];
  IsPreOvertimeMin: Scalars['Boolean'];
  PreOvertimeMin?: Maybe<Scalars['Float']>;
  IsPostOvertimeMin: Scalars['Boolean'];
  PostOvertimeMin?: Maybe<Scalars['Float']>;
  IsFixedOvertimeMin: Scalars['Boolean'];
  FixedOvertimeTotal?: Maybe<Scalars['Float']>;
};

export type ShiftPatternDetail = {
  __typename?: 'ShiftPatternDetail';
  days?: Maybe<Scalars['String']>;
  ShiftDay?: Maybe<ShiftDayEntity>;
};

export type ShiftPatternDetailEntity = {
  __typename?: 'ShiftPatternDetailEntity';
  ShiftPatternDetailID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Days?: Maybe<Scalars['Float']>;
  shiftList?: Maybe<Array<ShiftPatternEntity>>;
  ShiftPatternID?: Maybe<Scalars['String']>;
  shiftType?: Maybe<Array<ShiftEntity>>;
  ShiftTypeID?: Maybe<Scalars['String']>;
  shiftDay?: Maybe<ShiftDayEntity>;
  ShiftDayID?: Maybe<Scalars['String']>;
};

export type ShiftPatternDetailInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  ShiftTypeID?: Maybe<Scalars['String']>;
  ShiftDayID?: Maybe<Scalars['String']>;
  Days?: Maybe<Scalars['Float']>;
};

export type ShiftPatternEntity = InterfaceBase & {
  __typename?: 'ShiftPatternEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  ShiftPatternID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Active?: Maybe<Scalars['Boolean']>;
  Name?: Maybe<Scalars['String']>;
  Start?: Maybe<Scalars['DateTime']>;
  End?: Maybe<Scalars['DateTime']>;
  Company?: Maybe<CompanyEntity>;
  CompanyID?: Maybe<Scalars['String']>;
  Details?: Maybe<Array<ShiftPatternDetailEntity>>;
};

export type ShiftPatternInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  Name?: Maybe<Scalars['String']>;
  Start?: Maybe<Scalars['DateTime']>;
  End?: Maybe<Scalars['DateTime']>;
};

export enum ShortNoticeType {
  CalendarDay = 'Calendar_Day',
  WorkingDay = 'Working_Day'
}

export type SkillSetDetailEntity = InterfaceBase & {
  __typename?: 'SkillSetDetailEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SkillSetDetailID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  SkillSet?: Maybe<SkillSetEntity>;
  SkillSetID?: Maybe<Scalars['String']>;
};

export type SkillSetDetailInput = {
  SkillSetDetailID?: Maybe<Scalars['String']>;
  SkillSetID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type SkillSetEntity = InterfaceBase & {
  __typename?: 'SkillSetEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SkillSetID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  SkillSetDetails?: Maybe<Array<SkillSetDetailEntity>>;
};

export type SkillSetInput = {
  SkillSetID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type SocsoCalculationInput = {
  EmployeeID: Scalars['String'];
  Age: Scalars['Float'];
  NationalityType?: Maybe<NationalityType>;
  SocsoChargableAmount: Scalars['Float'];
};

export type SocsoCalculationOutput = {
  __typename?: 'SocsoCalculationOutput';
  EmployeeID: Scalars['String'];
  SocsoAmount: Scalars['Float'];
  FirstCatCmpAmount?: Maybe<Scalars['Float']>;
  FirstCatEmpAmount?: Maybe<Scalars['Float']>;
  SecondCatCmpAmount?: Maybe<Scalars['Float']>;
  FromAmount?: Maybe<Scalars['Float']>;
  ToAmount?: Maybe<Scalars['Float']>;
  SocsoRule?: Maybe<SocsoRule>;
  SocsoRequirement?: Maybe<Scalars['String']>;
};

export type SocsoPolicyEntity = InterfaceBase & {
  __typename?: 'SocsoPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SocsoPolicyID: Scalars['String'];
  PolicyCode: Scalars['String'];
  NationalityType: Scalars['String'];
  Age: Scalars['Float'];
  SocsoRule: Scalars['String'];
  Formula: Scalars['String'];
};

export type SocsoPolicyInput = {
  PolicyCode?: Maybe<Scalars['String']>;
  NationalityType?: Maybe<Scalars['String']>;
  Age: Scalars['Float'];
  SocsoRule?: Maybe<SocsoRule>;
  Formula?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type SocsoRefEntity = InterfaceBase & {
  __typename?: 'SocsoRefEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  SocsoReflD: Scalars['String'];
  EffectiveDate: Scalars['DateTime'];
  FromAmount: Scalars['Float'];
  ToAmount: Scalars['Float'];
  FirstCatCmpAmount: Scalars['Float'];
  FirstCatEmpAmount: Scalars['Float'];
  SecondCatEmpAmount: Scalars['Float'];
};

export type SocsoRefInput = {
  SocsoRefID?: Maybe<Scalars['String']>;
  EffectiveDate: Scalars['DateTime'];
  FromAmount: Scalars['Float'];
  ToAmount: Scalars['Float'];
  FirstCatCmpAmount: Scalars['Float'];
  FirstCatEmpAmount: Scalars['Float'];
  SecondCatEmpAmount: Scalars['Float'];
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export enum SocsoRule {
  FirstCategory = 'FirstCategory',
  SecondCategory = 'SecondCategory'
}

export enum SoftwareCode {
  Contract = 'CONTRACT',
  Client = 'CLIENT',
  Subcontractor = 'SUBCONTRACTOR',
  Supplier = 'SUPPLIER',
  Hr = 'HR',
  Property = 'PROPERTY',
  Rent = 'RENT',
  Hotel = 'HOTEL',
  Account = 'ACCOUNT',
  User = 'USER',
  Tenant = 'TENANT',
  LeaseTodo = 'LEASE_TODO',
  LeaseOperation = 'LEASE_OPERATION',
  LeaseSales = 'LEASE_SALES'
}

export type StatesEntity = InterfaceBase & {
  __typename?: 'StatesEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  StatesID: Scalars['String'];
  Country?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CalendarOffDay?: Maybe<Array<CalendarOffDayEntity>>;
  RecurringRestOffDays?: Maybe<Array<RecurringRestOffDayEntity>>;
};

export enum Status {
  Active = 'Active',
  Inactive = 'Inactive',
  Confirmed = 'Confirmed',
  Probation = 'Probation',
  Resigned = 'Resigned',
  Terminated = 'Terminated',
  Deceased = 'Deceased',
  Retired = 'Retired',
  Illness = 'Illness',
  Absconded = 'Absconded',
  Draft = 'Draft',
  Hidden = 'Hidden',
  SoftDelete = 'SoftDelete'
}

export type SubmitClaimRecordsInput = {
  ClaimHeaderID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  Subject?: Maybe<Scalars['String']>;
  ExpenseAccountID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  ModifiedBy?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  ClaimDate?: Maybe<Scalars['DateTime']>;
  ClaimRecordsItems?: Maybe<Array<ClaimRecordsItemInput>>;
};

export type Subscription = {
  __typename?: 'Subscription';
  newNotifications: NotificationEntity;
};


export type SubscriptionNewNotificationsArgs = {
  ToEmployeeID: Scalars['String'];
};

export type SystemAdminSummaryOutput = {
  __typename?: 'SystemAdminSummaryOutput';
  PublicHolidayCalendarLastPostedDate?: Maybe<Scalars['DateTime']>;
  TaxReliefLastPostedDate?: Maybe<Scalars['DateTime']>;
  EAPartFLastPostedDate?: Maybe<Scalars['DateTime']>;
  EPFPolicyLastPostedDate?: Maybe<Scalars['DateTime']>;
  SocsoPolicyLastPostedDate?: Maybe<Scalars['DateTime']>;
  EISPolicyLastPostedDate?: Maybe<Scalars['DateTime']>;
};

export type TaxzakatCalculationInput = {
  PayrollCycleIsZakat: Scalars['Boolean'];
  IsZakat: Scalars['Boolean'];
  ZakatRate: Scalars['Float'];
  PayrollCycleIsMTD: Scalars['Boolean'];
  IsLastProcess: Scalars['Boolean'];
  CurrentMTD: Scalars['Float'];
  AccumulatedTAXPaid: Scalars['Float'];
  AccumulatedZAKATPaid: Scalars['Float'];
  AccumulatedTax: Scalars['Float'];
};

export type TmsSummary = {
  __typename?: 'TMSSummary';
  ShiftGroup?: Maybe<Scalars['Float']>;
  AttendanceExclusion?: Maybe<Scalars['Float']>;
  EarnLeave?: Maybe<Scalars['Float']>;
  TimeOff?: Maybe<Scalars['Float']>;
  Overtime?: Maybe<Scalars['Float']>;
  ChangeShift?: Maybe<Scalars['Float']>;
  AttendanceRecord?: Maybe<Scalars['Float']>;
  LateIn?: Maybe<Scalars['Float']>;
  Absent?: Maybe<Scalars['Float']>;
  IncompleteClocking?: Maybe<Scalars['Float']>;
  DutyRosterDate?: Maybe<Scalars['DateTime']>;
  AttendanceProcessing?: Maybe<Scalars['DateTime']>;
  AttendancePosting?: Maybe<Scalars['DateTime']>;
  IncompleteClockingEmployees?: Maybe<Array<Scalars['JSON']>>;
  LateInEmployees?: Maybe<Array<Scalars['JSON']>>;
  AbsentEmployee?: Maybe<Array<Scalars['JSON']>>;
};

export enum TargetType {
  Company = 'COMPANY',
  Designation = 'DESIGNATION',
  Department = 'DEPARTMENT',
  JobGrade = 'JOB_GRADE',
  DailyAllowancePolicy = 'DAILY_ALLOWANCE_POLICY',
  MonthlyAllowancePolicy = 'MONTHLY_ALLOWANCE_POLICY',
  DeductionPolicy = 'DEDUCTION_POLICY',
  ShiftSetup = 'SHIFT_SETUP',
  ShiftPattern = 'SHIFT_PATTERN',
  ShiftGroup = 'SHIFT_GROUP',
  TimeOff = 'TIME_OFF',
  OvertimeRoundingPolicy = 'OVERTIME_ROUNDING_POLICY',
  AllowanceDeductionRoundingPolicy = 'ALLOWANCE_DEDUCTION_ROUNDING_POLICY',
  OvertimeReasonCodes = 'OVERTIME_REASON_CODES',
  TimeOffReasonCodes = 'TIME_OFF_REASON_CODES',
  MobileCheckInLocation = 'MOBILE_CHECK_IN_LOCATION',
  OvertimeClaimTable = 'OVERTIME_CLAIM_TABLE',
  OvertimePolicy = 'OVERTIME_POLICY',
  Employee = 'EMPLOYEE',
  AttendanceRecord = 'ATTENDANCE_RECORD'
}

export type TargetUnion = CompanyEntity | DepartmentEntity | JobDesignationEntity | JobGradeEntity | DailyAllowancePolicyEntity | MonthlyAllowancePolicyEntity | DeductionPolicyEntity | ShiftEntity | ShiftPatternEntity | ShiftGroupEntity | TimeOffPolicyEntity | OvertimeRoundingSettingEntity | AllowanceDeductionRoundingEntity | OvertimeReasonEntity | TimeOffReasonEntity | MobileCheckInEntity | OvertimeClaimTableEntity | OvertimePolicyEntity | EmployeeEntity | AttendanceRecordEntity;

export enum TaxMaritialStatus {
  SingleSpouseNotClaimedChildren = 'Single_Spouse_Not_Claimed_Children',
  MarriedAndSpouseNotWorking = 'Married_and_Spouse_Not_Working',
  MarriedAndSpouseWorking = 'Married_and_Spouse_Working',
  DivorceWidowWidower = 'Divorce_Widow_Widower'
}

export type TaxOutput = {
  __typename?: 'TaxOutput';
  FinalTax1: Scalars['Float'];
  FinalTax2: Scalars['Float'];
  NetMTD: Scalars['Float'];
  MTD_A: Scalars['Float'];
  MTD_B: Scalars['Float'];
  YearlyTax_CS: Scalars['Float'];
  MTD_C: Scalars['Float'];
  FinalZakat: Scalars['Float'];
  TaxZakat: Scalars['Float'];
  Tax1: Scalars['Float'];
  Tax2: Scalars['Float'];
};

export type TaxRefEntity = InterfaceBase & {
  __typename?: 'TaxRefEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TaxReflD: Scalars['String'];
  TaxRefType: TaxRefType;
  PeriodYear: Scalars['Float'];
  PFromAmount: Scalars['Float'];
  PToAmount: Scalars['Float'];
  MAmount: Scalars['Float'];
  RTaxRate: Scalars['Float'];
  BIN3Amount: Scalars['Float'];
  B2Amount: Scalars['Float'];
  NonResidentRate: Scalars['Float'];
};

export enum TaxRefType {
  ResidentNonResident = 'ResidentNonResident',
  ReturningExpertProgram = 'Returning_Expert_Program',
  KnowledgeWorker = 'Knowledge_Worker'
}

export type TaxReliefEntity = InterfaceBase & {
  __typename?: 'TaxReliefEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TaxReliefID: Scalars['String'];
  ReliefCode: Scalars['String'];
  Description: Scalars['String'];
  PeriodYear: Scalars['Float'];
  MaxAmount: Scalars['Float'];
  IsDefault?: Maybe<Scalars['Boolean']>;
  SequenceNo?: Maybe<Scalars['Float']>;
};

export type TaxReliefInput = {
  ReliefCode?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  PeriodYear?: Maybe<Scalars['Float']>;
  MaxAmount?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  IsDefault?: Maybe<Scalars['Boolean']>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type TaxZakatCalculationOutput = {
  __typename?: 'TaxZakatCalculationOutput';
  NetMTD: Scalars['Float'];
  ZAKATPaid: Scalars['Float'];
  PayrollCycleIsMTD: Scalars['Boolean'];
  PayrollCycleIsZakat: Scalars['Boolean'];
  ZakatRate: Scalars['Float'];
  IsZakat: Scalars['Boolean'];
  FinalTotalTAXPaid: Scalars['Float'];
  FinalTotalZAKATPaid: Scalars['Float'];
  FinalTotalMTD: Scalars['Float'];
};

export type TemplateLibraryAssignmentEntity = InterfaceBase & {
  __typename?: 'TemplateLibraryAssignmentEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TemplateLibraryAssignmentID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Module?: Maybe<Module>;
  SourceType?: Maybe<TargetType>;
  SourceID?: Maybe<Scalars['String']>;
  TargetType?: Maybe<TargetType>;
  TargetID?: Maybe<Scalars['String']>;
  EnableTarget?: Maybe<Scalars['Boolean']>;
  Company?: Maybe<CompanyEntity>;
  Target?: Maybe<TargetUnion>;
};

export type TemplateLibraryAssignmentInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  TemplateLibraryAssignmentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Module?: Maybe<Module>;
  SourceType?: Maybe<TargetType>;
  SourceID?: Maybe<Scalars['String']>;
  TargetType?: Maybe<TargetType>;
  TargetID?: Maybe<Scalars['String']>;
  EnableTarget?: Maybe<Scalars['Boolean']>;
};

export type TemplateLibrarySummaryOutput = {
  __typename?: 'TemplateLibrarySummaryOutput';
  ShiftSetup?: Maybe<Scalars['Float']>;
  ShiftPattern?: Maybe<Scalars['Float']>;
  ShiftGroup?: Maybe<Scalars['Float']>;
  OvertimePolicy?: Maybe<Scalars['Float']>;
  OvertimeClaimTable?: Maybe<Scalars['Float']>;
  OvertimeReasonCode?: Maybe<Scalars['Float']>;
  OvertimeRoundingPolicy?: Maybe<Scalars['Float']>;
  DailyAllowancePolicy?: Maybe<Scalars['Float']>;
  MonthlyAllowancePolicy?: Maybe<Scalars['Float']>;
  DeudctionPolicy?: Maybe<Scalars['Float']>;
  DeductionRoundingPolicy?: Maybe<Scalars['Float']>;
  TimeOff?: Maybe<Scalars['Float']>;
  JobGrade?: Maybe<Scalars['Float']>;
  assignedJob?: Maybe<Scalars['Float']>;
  TimeOffReasonCode?: Maybe<Scalars['Float']>;
  MobileCheckInLocation?: Maybe<Scalars['Float']>;
  templateDate?: Maybe<Scalars['DateTime']>;
  company?: Maybe<Scalars['JSON']>;
};

export type TimeOffEntity = InterfaceBase & {
  __typename?: 'TimeOffEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TimeOffID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  TimeOffStatus: TimeOffStatus;
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  EmployeeID?: Maybe<Scalars['String']>;
  ToReasonID?: Maybe<Scalars['String']>;
  WorkDate: Scalars['DateTime'];
  EndTime: Scalars['DateTime'];
  StartTime: Scalars['DateTime'];
  VisitingPlace?: Maybe<Scalars['String']>;
  TravelFrom?: Maybe<Scalars['String']>;
  TravelTo?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  Approver?: Maybe<Scalars['String']>;
  RejectionRemarks?: Maybe<Scalars['String']>;
  Duration?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  ApproveDate?: Maybe<Scalars['DateTime']>;
  ApproverID?: Maybe<Scalars['String']>;
  CancelledBy?: Maybe<Scalars['String']>;
  Employee?: Maybe<EmployeeEntity>;
  ToReason?: Maybe<TimeOffReasonEntity>;
  TimeOffHistory?: Maybe<Array<TimeOffEntity>>;
};

export type TimeOffInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  EmployeeID?: Maybe<Scalars['String']>;
  TimeOffID?: Maybe<Scalars['String']>;
  WorkDate?: Maybe<Scalars['DateTime']>;
  ToReasonID?: Maybe<Scalars['String']>;
  StartTime?: Maybe<Scalars['DateTime']>;
  EndTime?: Maybe<Scalars['DateTime']>;
  VisitingPlace?: Maybe<Scalars['String']>;
  TravelFrom?: Maybe<Scalars['String']>;
  TravelTo?: Maybe<Scalars['String']>;
  Reason?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Duration?: Maybe<Scalars['Float']>;
  TimeOffStatus?: Maybe<Scalars['String']>;
  Approver?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  CancelledBy?: Maybe<Scalars['String']>;
  ApproveDate?: Maybe<Scalars['DateTime']>;
};

export type TimeOffPolicyEntity = InterfaceBase & {
  __typename?: 'TimeOffPolicyEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TimeOffPolicyID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  AllowTimeOff: Scalars['Boolean'];
  RequireTimeOffReason: Scalars['Boolean'];
  TimesPerMonth: Scalars['Float'];
  MinIntervalPerRequest: Scalars['Float'];
  MaxIntervalPerRequest: Scalars['Float'];
  MaxIntervalPerDay: Scalars['Float'];
  MaxIntervalPerMonth: Scalars['Float'];
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
};

export type TimeOffPolicyInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  TimeOffPolicyID?: Maybe<Scalars['String']>;
  AllowTimeOff: Scalars['Boolean'];
  TimesPerMonth: Scalars['Float'];
  MinIntervalPerRequest: Scalars['Float'];
  MaxIntervalPerRequest: Scalars['Float'];
  MaxIntervalPerDay: Scalars['Float'];
  MaxIntervalPerMonth: Scalars['Float'];
};

export type TimeOffReasonEntity = InterfaceBase & {
  __typename?: 'TimeOffReasonEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TimeOffReasonID: Scalars['String'];
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};

export type TimeOffReasonInput = {
  TimeOffReasonID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};

export type TimeOffSettingEntity = InterfaceBase & {
  __typename?: 'TimeOffSettingEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  TimeOffSettingID: Scalars['String'];
  Name: Scalars['String'];
  SubscriptionAccountID: Scalars['String'];
  EnableTimeOff?: Maybe<Scalars['Boolean']>;
  TimesPerMonth?: Maybe<Scalars['Float']>;
  MinIntervalPerRequest?: Maybe<Scalars['Float']>;
  MaxIntervalPerRequest?: Maybe<Scalars['Float']>;
  MaxTimeOffPerDay?: Maybe<Scalars['Float']>;
  MaxTimeOffPerMonth?: Maybe<Scalars['Float']>;
  TimeOffReasonID?: Maybe<Scalars['String']>;
  TimeOffReasonEntity?: Maybe<TimeOffReasonEntity>;
};

export type TimeOffSettingInput = {
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Status?: Maybe<Status>;
  TimeOffSettingID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  TimesPerMonth?: Maybe<Scalars['Float']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  MinIntervalPerRequest?: Maybe<Scalars['Float']>;
  MaxIntervalPerRequest?: Maybe<Scalars['Float']>;
  MaxTimeOffPerDay?: Maybe<Scalars['Float']>;
  MaxTimeOffPerMonth?: Maybe<Scalars['Float']>;
  Reasons?: Maybe<Scalars['String']>;
  ReasonIDs?: Maybe<Scalars['String']>;
  EnableTimeOff?: Maybe<Scalars['Boolean']>;
};

export enum TimeOffStatus {
  Draft = 'DRAFT',
  Submitted = 'SUBMITTED',
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED'
}

export type TopClaimTakerOutput = {
  __typename?: 'TopClaimTakerOutput';
  Employee?: Maybe<EmployeeEntity>;
  ClaimTaken?: Maybe<Scalars['Float']>;
};

export type TopRequisitionTakerOutput = {
  __typename?: 'TopRequisitionTakerOutput';
  Employee?: Maybe<EmployeeEntity>;
  RequisitionTaken?: Maybe<Scalars['Float']>;
};

export enum TransportMode {
  Flight = 'Flight',
  Car = 'Car',
  Bus = 'Bus',
  Train = 'Train',
  Motorcycle = 'Motorcycle'
}

export type UpdateAuditInput = {
  modBy?: Maybe<Scalars['String']>;
};


export type UserEntity = AuditEntity & {
  __typename?: 'UserEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  userName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  contactNo: Scalars['String'];
  commonStatus: CommonStatus;
  confirmed: Scalars['Boolean'];
  avatar?: Maybe<Scalars['String']>;
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  softwareCode: Scalars['String'];
  loginAttempt: Scalars['Float'];
  blockDuration: Scalars['Float'];
  blockCount: Scalars['Float'];
  loginTs?: Maybe<Scalars['DateTime']>;
  deviceIP?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
};

export type UserInput = {
  ID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  address?: Maybe<AddressInput>;
  superUser?: Maybe<Scalars['Boolean']>;
  avatar?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
};

export type UserLogInput = {
  createdDT?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  modifiedDT?: Maybe<Scalars['String']>;
  modifiedBy?: Maybe<Scalars['String']>;
};

export type UserLogs = {
  __typename?: 'UserLogs';
  createdDT?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  modifiedDT?: Maybe<Scalars['String']>;
  modifiedBy?: Maybe<Scalars['String']>;
};

export type UserResponse = AuditEntity & {
  __typename?: 'UserResponse';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  userName: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  address?: Maybe<Address>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  confirmed: Scalars['Boolean'];
  avatar?: Maybe<Scalars['String']>;
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  lastestAccessedEntity?: Maybe<Scalars['String']>;
  deviceIP?: Maybe<Scalars['String']>;
  loginTs?: Maybe<Scalars['DateTime']>;
  blockDuration?: Maybe<Scalars['Float']>;
  blockCount?: Maybe<Scalars['Float']>;
  loginAttempt?: Maybe<Scalars['Float']>;
  errors?: Maybe<Array<FieldError>>;
};

export type ValidateResult = {
  __typename?: 'ValidateResult';
  subscriptionaccountid?: Maybe<Scalars['String']>;
  datamodule?: Maybe<Scalars['String']>;
  datarow?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  rowno?: Maybe<Scalars['String']>;
  totalrecord?: Maybe<Scalars['String']>;
  exceltotalrecord?: Maybe<Scalars['String']>;
};

export type VerifiedClaimDetail = {
  __typename?: 'VerifiedClaimDetail';
  FinanceApprovalStatus?: Maybe<ApprovalStatus>;
  FinanceAmount?: Maybe<Scalars['Float']>;
  FinanceRemark?: Maybe<Scalars['String']>;
  FinanceApprovalByID?: Maybe<Scalars['String']>;
  FinanceApprovalByName?: Maybe<Scalars['String']>;
  FinanceApprovalDate?: Maybe<Scalars['DateTime']>;
};

export type VerifyApprovedClaimDetailInput = {
  VerifyStatus?: Maybe<ApprovalStatus>;
  SubscriptionUserID?: Maybe<Scalars['String']>;
  VerifyAmount?: Maybe<Scalars['Float']>;
  VerifyRemark?: Maybe<Scalars['String']>;
  ClaimHeaderID?: Maybe<Scalars['String']>;
  ClaimDetailIDs?: Maybe<Array<Scalars['String']>>;
};

export type VerifyCompany = {
  __typename?: 'VerifyCompany';
  Company?: Maybe<Array<ValidateResult>>;
  Department?: Maybe<Array<ValidateResult>>;
  JobGrade?: Maybe<Array<ValidateResult>>;
  RecruitmentSource?: Maybe<Array<ValidateResult>>;
};

export type VerifyEmployee = {
  __typename?: 'VerifyEmployee';
  Employee?: Maybe<Array<ValidateResult>>;
  JobInfo?: Maybe<Array<ValidateResult>>;
  Statutory?: Maybe<Array<ValidateResult>>;
  Dependent?: Maybe<Array<ValidateResult>>;
};

export type VerifyPayroll = {
  __typename?: 'VerifyPayroll';
  CutOff?: Maybe<Array<ValidateResult>>;
  Cycle?: Maybe<Array<ValidateResult>>;
  Bank?: Maybe<Array<ValidateResult>>;
  PayItem?: Maybe<Array<ValidateResult>>;
  EmpSalary?: Maybe<Array<ValidateResult>>;
  FixedAllowDec?: Maybe<Array<ValidateResult>>;
  History?: Maybe<Array<ValidateResult>>;
};

export type ViewPayrollHistoryDetailListingOutput = {
  __typename?: 'ViewPayrollHistoryDetailListingOutput';
  Description?: Maybe<Scalars['String']>;
  PayrollCycleId?: Maybe<Scalars['String']>;
  TotalEmployee?: Maybe<Scalars['String']>;
  BasicSalary?: Maybe<Scalars['Float']>;
  AdditionalEarnings?: Maybe<Scalars['Float']>;
  GrossPay?: Maybe<Scalars['Float']>;
  Deduction?: Maybe<Scalars['Float']>;
  NetPay?: Maybe<Scalars['Float']>;
  EmployeeEPF?: Maybe<Scalars['Float']>;
  EmployeeEPFVE?: Maybe<Scalars['Float']>;
  EmployeeSocso?: Maybe<Scalars['Float']>;
  EmployeeEIS?: Maybe<Scalars['Float']>;
  Tax?: Maybe<Scalars['Float']>;
  Zakat?: Maybe<Scalars['Float']>;
  EmployerEPF?: Maybe<Scalars['Float']>;
  EmployerEPFVE?: Maybe<Scalars['Float']>;
  EmployerSocso?: Maybe<Scalars['Float']>;
  EmployerEIS?: Maybe<Scalars['Float']>;
  EmployerHRDF?: Maybe<Scalars['Float']>;
};

export type ViewPayrollHistoryInput = {
  CompanyID: Scalars['String'];
  PayPeriodID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeStatus?: Maybe<Array<Scalars['String']>>;
  DepartmentIDs?: Maybe<Array<Scalars['String']>>;
  PayrollCycleIDs?: Maybe<Array<Scalars['String']>>;
};

export type ViewPayrollHistorySummaryOutput = {
  __typename?: 'ViewPayrollHistorySummaryOutput';
  PayPeriodID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  TotalPayrollCycle?: Maybe<Scalars['Float']>;
  Listing?: Maybe<Array<ViewPayrollHistoryDetailListingOutput>>;
};

export type WorkGroupShiftEntity = InterfaceBase & {
  __typename?: 'WorkGroupShiftEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  WorkGroupShiftID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  ShiftID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  Shift?: Maybe<ShiftEntity>;
};

export type WorkdayAdjustmentEntity = InterfaceBase & {
  __typename?: 'WorkdayAdjustmentEntity';
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogs>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  WorkdayAdjustmentID: Scalars['String'];
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  WorkDays: Scalars['Float'];
  AdjustedWorkDays: Scalars['Float'];
  Company?: Maybe<CompanyEntity>;
  Employee?: Maybe<EmployeeEntity>;
};

export type WorkdayAdjustmentInput = {
  EmployeeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PayPeriodID?: Maybe<Scalars['String']>;
  WorkDays?: Maybe<Scalars['Float']>;
  AdjustedWorkDays?: Maybe<Scalars['Float']>;
  Status?: Maybe<Status>;
  UserLogs?: Maybe<UserLogInput>;
  CreatedDT?: Maybe<Scalars['DateTime']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['DateTime']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type YecfListingOutput = {
  __typename?: 'YECFListingOutput';
  HeaderStatus?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  NoOfPendingApprovalApplication?: Maybe<Scalars['Float']>;
  LeaveItem?: Maybe<LeaveItemEntity>;
  LeaveBalanceSummary?: Maybe<LeaveBalanceSummary>;
};

export type YearEndCarryForwardOutput = {
  __typename?: 'YearEndCarryForwardOutput';
  HeaderStatus?: Maybe<Scalars['String']>;
  EmployeeID?: Maybe<Scalars['String']>;
  EmployeeName?: Maybe<Scalars['String']>;
  EmployeeNo?: Maybe<Scalars['String']>;
  LeaveTypes?: Maybe<Array<LeaveIndentity>>;
};

export type YearlyError = {
  __typename?: 'YearlyError';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
};

export type ArrMesg = {
  __typename?: 'arrMesg';
  Index?: Maybe<Scalars['Float']>;
  ErrorMsg?: Maybe<Scalars['String']>;
  JoinedDateError?: Maybe<Array<JoinedDateError>>;
  ClaimTrxError?: Maybe<Array<ClaimTrxError>>;
  BiYearlyError?: Maybe<Array<BiYearlyError>>;
  YearlyError?: Maybe<Array<YearlyError>>;
  MonthlyError?: Maybe<Array<MonthlyError>>;
  LifeTimeError?: Maybe<Array<LifeTimeError>>;
};

export type DropDownItem = {
  __typename?: 'dropDownItem';
  Value?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};

export type PasswordInfo = {
  ID?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  confirmedPassword: Scalars['String'];
};

export type RoleUser = {
  __typename?: 'roleUser';
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type UserRoleId = {
  __typename?: 'userRoleID';
  roleID: Scalars['String'];
  userID: Scalars['String'];
};

export type LoggedInUserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type LoggedInUserProfileQuery = (
  { __typename?: 'Query' }
  & { loggedInUserProfile: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'name' | 'email' | 'contactNo' | 'department' | 'accountID' | 'jobTitle' | 'superUser'>
  ) }
);

export type LoginMutationVariables = Exact<{
  password: Scalars['String'];
  loginId: Scalars['String'];
  softwareCode: SoftwareCode;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'accessToken'>
    & { user: (
      { __typename?: 'UserResponse' }
      & Pick<UserResponse, 'accountID' | 'ID' | 'name' | 'superUser'>
    ) }
  ) }
);

export type ChangePasswordMutationVariables = Exact<{
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changePassword'>
);

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'forgotPassword'>
);

export type ResetPasswordMutationVariables = Exact<{
  password: Scalars['String'];
  token: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetPassword'>
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type ActivateUserMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type ActivateUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activateUser'>
);

export type CreatePasswordMutationVariables = Exact<{
  input: PasswordInfo;
}>;


export type CreatePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createPassword'>
);

export type SubscriptionUserSummaryQueryVariables = Exact<{
  SubscriptionUserID: Scalars['String'];
}>;


export type SubscriptionUserSummaryQuery = (
  { __typename?: 'Query' }
  & { EmployeeSummaryBySubscription: (
    { __typename?: 'EmployeeEntity' }
    & Pick<EmployeeEntity, 'EmployeeID' | 'CompanyID' | 'DepartmentID' | 'DivisionID' | 'DesignationID' | 'JobGradeID' | 'EmployeeNo' | 'CreatedDT' | 'CreatedBy' | 'ModifiedDT' | 'ModifiedBy' | 'GeneralDocCount' | 'EAFormDocCount' | 'PaySlipDocCount' | 'PersonalDocCount' | 'GeneralDocTotalSize' | 'EAFormDocTotalSize' | 'PaySlipDocTotalSize' | 'PersonalDocTotalSize' | 'TotalLeave' | 'TotalAnnualLeave' | 'TotalSickLeave' | 'TotalUnpaidLeave' | 'TotalClaim' | 'TotalClaimApproved' | 'TotalClaimAmountApproved' | 'TotalRequisition' | 'TotalPurchaseRequisition' | 'TotalTravelRequest' | 'TotalCashAdvanceRequisition' | 'TotalOtherRequisition' | 'TotalSubordinate' | 'TotalUnreadNews'>
    & { Contact?: Maybe<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ContactID' | 'FullName' | 'Email' | 'MobileNo'>
    )>, Company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyID' | 'CompanyName'>
    )>, EmployeeDepartment?: Maybe<(
      { __typename?: 'DepartmentEntity' }
      & Pick<DepartmentEntity, 'Description' | 'DepartmentType'>
    )>, Designation?: Maybe<(
      { __typename?: 'JobDesignationEntity' }
      & Pick<JobDesignationEntity, 'JobDesignationID' | 'Name'>
    )>, EmployeeCareerLogs?: Maybe<Array<(
      { __typename?: 'CareerLogEntity' }
      & Pick<CareerLogEntity, 'CareerLogID' | 'EffectiveDate' | 'MovementType'>
      & { ToPositionTable?: Maybe<(
        { __typename?: 'JobDesignationEntity' }
        & Pick<JobDesignationEntity, 'Name'>
      )> }
    )>>, LastUpdatedDates: (
      { __typename?: 'LastUpdatedDatesOutput' }
      & Pick<LastUpdatedDatesOutput, 'EmployeeLastDate' | 'AssetsLastDate' | 'BIKLastDate' | 'CareerLastDate' | 'SubordinateLastDate' | 'TaxLastDate'>
    ) }
  ) }
);


export const LoggedInUserProfileDocument = gql`
    query loggedInUserProfile {
  loggedInUserProfile {
    ID
    name
    email
    contactNo
    department
    accountID
    jobTitle
    superUser
  }
}
    `;

/**
 * __useLoggedInUserProfileQuery__
 *
 * To run a query within a React component, call `useLoggedInUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggedInUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggedInUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoggedInUserProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>(LoggedInUserProfileDocument, baseOptions);
      }
export function useLoggedInUserProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>(LoggedInUserProfileDocument, baseOptions);
        }
export type LoggedInUserProfileQueryHookResult = ReturnType<typeof useLoggedInUserProfileQuery>;
export type LoggedInUserProfileLazyQueryHookResult = ReturnType<typeof useLoggedInUserProfileLazyQuery>;
export type LoggedInUserProfileQueryResult = ApolloReactCommon.QueryResult<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>;
export const LoginDocument = gql`
    mutation login($password: String!, $loginId: String!, $softwareCode: SoftwareCode!) {
  login(password: $password, loginId: $loginId, softwareCode: $softwareCode) {
    accessToken
    user {
      accountID
      ID
      name
      superUser
    }
  }
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      password: // value for 'password'
 *      loginId: // value for 'loginId'
 *      softwareCode: // value for 'softwareCode'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($newPassword: String!, $currentPassword: String!) {
  changePassword(newPassword: $newPassword, currentPassword: $currentPassword)
}
    `;
export type ChangePasswordMutationFn = ApolloReactCommon.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      currentPassword: // value for 'currentPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, baseOptions);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = ApolloReactCommon.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!) {
  forgotPassword(email: $email)
}
    `;
export type ForgotPasswordMutationFn = ApolloReactCommon.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, baseOptions);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = ApolloReactCommon.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($password: String!, $token: String!) {
  resetPassword(password: $password, token: $token)
}
    `;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = ApolloReactCommon.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const ActivateUserDocument = gql`
    mutation activateUser($token: String!) {
  activateUser(token: $token)
}
    `;
export type ActivateUserMutationFn = ApolloReactCommon.MutationFunction<ActivateUserMutation, ActivateUserMutationVariables>;

/**
 * __useActivateUserMutation__
 *
 * To run a mutation, you first call `useActivateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateUserMutation, { data, loading, error }] = useActivateUserMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useActivateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateUserMutation, ActivateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivateUserMutation, ActivateUserMutationVariables>(ActivateUserDocument, baseOptions);
      }
export type ActivateUserMutationHookResult = ReturnType<typeof useActivateUserMutation>;
export type ActivateUserMutationResult = ApolloReactCommon.MutationResult<ActivateUserMutation>;
export type ActivateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateUserMutation, ActivateUserMutationVariables>;
export const CreatePasswordDocument = gql`
    mutation createPassword($input: passwordInfo!) {
  createPassword(input: $input)
}
    `;
export type CreatePasswordMutationFn = ApolloReactCommon.MutationFunction<CreatePasswordMutation, CreatePasswordMutationVariables>;

/**
 * __useCreatePasswordMutation__
 *
 * To run a mutation, you first call `useCreatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPasswordMutation, { data, loading, error }] = useCreatePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePasswordMutation, CreatePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePasswordMutation, CreatePasswordMutationVariables>(CreatePasswordDocument, baseOptions);
      }
export type CreatePasswordMutationHookResult = ReturnType<typeof useCreatePasswordMutation>;
export type CreatePasswordMutationResult = ApolloReactCommon.MutationResult<CreatePasswordMutation>;
export type CreatePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePasswordMutation, CreatePasswordMutationVariables>;
export const SubscriptionUserSummaryDocument = gql`
    query SubscriptionUserSummary($SubscriptionUserID: String!) {
  EmployeeSummaryBySubscription(SubscriptionUserID: $SubscriptionUserID) {
    EmployeeID
    CompanyID
    DepartmentID
    DivisionID
    DesignationID
    JobGradeID
    Contact {
      ContactID
      FullName
      Email
      MobileNo
    }
    EmployeeNo
    Company {
      CompanyID
      CompanyName
    }
    EmployeeDepartment {
      Description
      DepartmentType
    }
    Designation {
      JobDesignationID
      Name
    }
    EmployeeCareerLogs {
      CareerLogID
      EffectiveDate
      MovementType
      ToPositionTable {
        Name
      }
    }
    CreatedDT
    CreatedBy
    ModifiedDT
    ModifiedBy
    GeneralDocCount
    EAFormDocCount
    PaySlipDocCount
    PersonalDocCount
    GeneralDocTotalSize
    EAFormDocTotalSize
    PaySlipDocTotalSize
    PersonalDocTotalSize
    TotalLeave
    TotalAnnualLeave
    TotalSickLeave
    TotalUnpaidLeave
    TotalClaim
    TotalClaimApproved
    TotalClaimAmountApproved
    TotalRequisition
    TotalPurchaseRequisition
    TotalTravelRequest
    TotalCashAdvanceRequisition
    TotalOtherRequisition
    TotalSubordinate
    TotalUnreadNews
    LastUpdatedDates {
      EmployeeLastDate
      AssetsLastDate
      BIKLastDate
      CareerLastDate
      SubordinateLastDate
      TaxLastDate
    }
  }
}
    `;

/**
 * __useSubscriptionUserSummaryQuery__
 *
 * To run a query within a React component, call `useSubscriptionUserSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionUserSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionUserSummaryQuery({
 *   variables: {
 *      SubscriptionUserID: // value for 'SubscriptionUserID'
 *   },
 * });
 */
export function useSubscriptionUserSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SubscriptionUserSummaryQuery, SubscriptionUserSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<SubscriptionUserSummaryQuery, SubscriptionUserSummaryQueryVariables>(SubscriptionUserSummaryDocument, baseOptions);
      }
export function useSubscriptionUserSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SubscriptionUserSummaryQuery, SubscriptionUserSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SubscriptionUserSummaryQuery, SubscriptionUserSummaryQueryVariables>(SubscriptionUserSummaryDocument, baseOptions);
        }
export type SubscriptionUserSummaryQueryHookResult = ReturnType<typeof useSubscriptionUserSummaryQuery>;
export type SubscriptionUserSummaryLazyQueryHookResult = ReturnType<typeof useSubscriptionUserSummaryLazyQuery>;
export type SubscriptionUserSummaryQueryResult = ApolloReactCommon.QueryResult<SubscriptionUserSummaryQuery, SubscriptionUserSummaryQueryVariables>;